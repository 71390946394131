import { useEffect, useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Visibility } from "@mui/icons-material";
import styles from "../Styles/loginPage.module.css";
import {
  Button,
  Paper,
  IconButton,
  InputAdornment,
  Typography,
  Grid,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { postData } from "../Services/postData";
import { localUrlEnum, urlEnum } from "../Utils/UrlEnum";
import { Vocabulary } from "../Utils/Vocabulary";
import { LoginModel } from "../Utils/Models";
import Config from "../Utils/Config";
import { getData } from "../Services/getData";
import axios from "axios";

type loginRequestResponse = {
  data: {
    id: string;
    userName: string;
    email: string;
    jwt: string;
    roles: string[];
    profilePicture: string;
  };
};

export default function Login() {
  const captchaRef = useRef<any>(null);
  const [user, setUser] = useState<LoginModel>(new LoginModel());
  const [showPassword, setShowPassword] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [url, setUrl] = useState("");

  /**
   *
   */
  useEffect(() => {
    getData(urlEnum.getLoginPageImagePath).then((res) => {
      if (res && res.data) {
        setUrl(res.data);
      }
    });
  }, []);

  /**
   *
   */
  async function handleSubmit() {
    try {
      if (captchaRef.current) {
        const token = captchaRef.current.getValue();
        user.reCaptcha = token;
        const response: loginRequestResponse = await postData(
          urlEnum.login,
          user
        );
        if (response && response.data) {
          const request = axios.create({
            headers: {
              Authorization: `Bearer ${response.data.jwt}`,
            },
          });
          const cacheDataResponse = await request.get(
            `${urlEnum.getTaskStatusesForFilter}`
          );
          if (cacheDataResponse && cacheDataResponse.data) {
            localStorage.setItem(
              "cacheData",
              JSON.stringify({ taskStatuses: cacheDataResponse.data })
            );
            localStorage.setItem("userId", response.data.id);
            localStorage.setItem(
              "userRoles",
              JSON.stringify(response.data.roles)
            );
            localStorage.setItem("userName", response.data.userName);
            localStorage.setItem("userEmail", response.data.email);
            localStorage.setItem("access_token", response.data.jwt);
            localStorage.setItem(
              "profilePicture",
              response.data.profilePicture
            );
            window.location.href = localUrlEnum.dashboard;
          }
        } else {
          setErrorText(Vocabulary.loginValidationError);
        }
        captchaRef.current.reset();
      }
    } catch (err: any) {
      captchaRef.current.reset();
      if (err.response && err.response.status === 401)
        setErrorText(Vocabulary.loginValidationError);
      else if (err.message) setErrorText(err.message);
    }
  }

  /**
   *
   * @param event
   */
  function onChangeTextField(event: any) {
    const newUser: any = Object.assign({}, user);
    newUser[event.target.name] = event.target.value;
    setUser(newUser);
  }

  return (
    <div className={styles.App}>
      <Paper className={styles.loginContainer} elevation={Config.elevation}>
        <Grid container spacing={1} className={styles.gridMainContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={styles.firstGrid}
          >
            <img src={"/marelvi.svg"} alt="MARELVI" width={"65%"} />
            <ValidatorForm className={styles.form} onSubmit={handleSubmit}>
              <TextValidator
                variant="standard"
                id="email"
                name="email"
                fullWidth
                label="Email"
                validators={["required", "isEmail"]}
                errorMessages={[
                  Vocabulary.requiredField,
                  Vocabulary.emailValidation,
                ]}
                value={user.email}
                onChange={(e) => onChangeTextField(e)}
                className={styles.textfield}
              />
              <TextValidator
                variant="standard"
                id="password"
                name="password"
                type={!showPassword ? "password" : "text"}
                label={Vocabulary.password}
                fullWidth
                validators={["required"]}
                value={user.password}
                onChange={(e) => onChangeTextField(e)}
                className={styles.textfield}
                errorMessages={[Vocabulary.requiredField]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className={styles.loginPageVisibilityIcon}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        <Visibility />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className={styles.ReCAPTCHA}>
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={
                    process.env.REACT_APP_SITE_KEY
                      ? process.env.REACT_APP_SITE_KEY
                      : ""
                  }
                />
              </div>
              <Button
                variant="contained"
                type="submit"
                className={styles.submit}
              >
                {Vocabulary.login}
              </Button>
              <Typography className={styles.errorText}>{errorText}</Typography>
            </ValidatorForm>
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            md={6}
            lg={6}
            className={styles.imageContainer}
          >
            <img
              src={`${urlEnum.getFilesAndMedia}${url}`}
              alt="MARELVI"
              className={styles.img}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
