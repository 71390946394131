/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from "@mui/material";
import { ComponentsNames } from "../../Utils/Constants";
import { MeetingModel } from "../../Utils/Models";
import { entityPath, urlEnum } from "../../Utils/UrlEnum";
import { Vocabulary } from "../../Utils/Vocabulary";
import FileExplorerMin from "../Widgets/FileExplorerMin";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useRef } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import WYSIWYGEditor from "../Messages/WYSIWYGEditor";
import { postData } from "../../Services/postData";
type Props = {
  meeting: MeetingModel | null;
};

export default function MeetingSummary(props: Props) {
  const ref: any = useRef(null);
  const messageValidationSchema = yup
    .object()
    .shape({
      agenda: yup.string().required(Vocabulary.requiredField),
      createdAt: yup.date().required(Vocabulary.requiredField),
    })
    .required();
  const { getValues, reset, handleSubmit, control } = useForm({
    defaultValues: {
      createdAt: new Date(),
    },
    resolver: yupResolver(messageValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });
  const toolBarOptions = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "emoji",
      "remove",
      "history",
    ],
    inline: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    history: { inDropdown: true },
  };

  /**
   *
   */
  const handleSubmitMinuta = () => {
    const newMeeting: any = Object.assign({}, props.meeting);
    newMeeting.minutes = {
      agenda: getValues("agenda"),
      createdAt: getValues("createdAt"),
    };
    postData(urlEnum.createOrUpdateMeeting, newMeeting).then((res: any) => {
      if (res?.data) {
        reset(res.data);
      }
    });
  };
  /**
   *
   */
  useEffect(() => {
    if (props.meeting?.id) {
      reset(props.meeting?.minutes);
    }
  }, [props.meeting?.id]);

  return (
    <form onSubmit={handleSubmit(handleSubmitMinuta)}>
      <Controller
        name="agenda"
        render={({ field }) => (
          <fieldset className={genericStyles.basicFieldSet}>
            <legend>{Vocabulary.agenda}</legend>
            <WYSIWYGEditor
              formRef={ref}
              value={field.value}
              onChange={field.onChange}
              toolbar={toolBarOptions}
            />
          </fieldset>
        )}
        control={control}
        defaultValue=""
      />
      <FileExplorerMin
        shouldCRUDFile={true}
        name={ComponentsNames.FileExplorerMin}
        id={props.meeting?.id}
        baseFolderPath={
          props.meeting?.id
            ? `${`${entityPath.meetings}/${props.meeting?.id}`}`
            : undefined
        }
      />
      <div className={genericStyles.minutaSaveButton}>
        <Button type="submit" variant="contained">
          {Vocabulary.save}
        </Button>
      </div>
    </form>
  );
}
