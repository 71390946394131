import { Editor } from "@monaco-editor/react";
import { useEffect, useState } from "react";
import { getData } from "../Services/getData";
import { urlEnum } from "../Utils/UrlEnum";
import { postData } from "../Services/postData";
import { Box, Button } from "@mui/material";
import { Vocabulary } from "../Utils/Vocabulary";
import genericStyles from "../Styles/genericStyles.module.css";
import Loading from "./Loading";

export default function StylesEditor() {
  const [userCssStyle, setUserCssStyle] = useState("");
  const customStylesFileName = "custom.css";
  /**
   *
   */
  useEffect(() => {
    getData(urlEnum.getCustomStyles).then((res) => {
      if (res && res.data) setUserCssStyle(res.data);
    });
  }, []);

  /**
   *
   * @param value
   * @param event
   */
  const handleStyleEditorChange = (value: any, event: any) => {
    setUserCssStyle(value);
  };

  /**
   *
   */
  const handleSaveCss = () => {
    const blob = new Blob([userCssStyle], { type: "text/plain" });
    const formData = new FormData();
    formData.append("file", blob, customStylesFileName);
    postData(urlEnum.getCustomStyles, formData).then((res) => {
      console.log(res);
    });
  };

  return (
    <>
      <Editor
        height="70vh"
        width="100%"
        defaultLanguage="css"
        value={userCssStyle}
        theme="vs-dark"
        onChange={handleStyleEditorChange}
        loading={<Loading />}
      />
      <Box className={genericStyles.styleEditorBox}>
        <Button variant="contained" onClick={handleSaveCss}>
          {Vocabulary.save}
        </Button>
      </Box>
    </>
  );
}
