import React from "react";

type Props = {
  text: string;
};

const styles: any = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexDirection: "column",
  },
};

export default function PageNotFound(props: Props) {
  return (
    <div style={styles.container}>
      <img
        src={"/pageNotFound.svg"}
        alt="MARELVI"
        width={"100%"}
        height={"50%"}
      />
      <p>{props.text}</p>
    </div>
  );
}
