import { createContext, useState } from "react";

export const DrawerStateContext = createContext({
  open: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOpen: (value: boolean) => {},
});

export const DrawerStateContextProvider = (props: any) => {
  const setOpen = (open: boolean) => {
    setState({ ...state, open: open });
  };
  const initState = {
    open: false,
    setOpen: setOpen,
  };
  const [state, setState] = useState(initState);
  return (
    <DrawerStateContext.Provider value={state}>
      {props.children}
    </DrawerStateContext.Provider>
  );
};
