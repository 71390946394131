/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Assignment,
  Delete,
  MeetingRoom,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Fab,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/calendar.module.css";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Months, defaultStatusesForCalendar } from "../../Utils/Constants";
import { CalendarFiltersAutocompleteModel } from "../../Utils/Models";
import withRole from "../../Hooks/withRole";
import { useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import StatusLegend from "../StatusLegend";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import Config from "../../Utils/Config";
import { CustomThemeContext } from "../../Context/CustomThemeContext";

type Props = {
  name: string;
  threshold: number;
  calendarCurrentDate: any;
  filtersAutocompleteData: CalendarFiltersAutocompleteModel;
  entityFilterShouldNotBeDisabled: boolean;
  shouldRenderFilters?: boolean;
  marelviCalendar?: boolean;
};

function CalendarFilters(props: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<any>(null);
  const customSearchParam = useCustomSearchParam();
  const themeContext: any = useContext(CustomThemeContext);
  const [difference, setDifference] = useState<number>(
    searchParams.get("difference")
      ? parseInt(searchParams.get("difference")?.toString() || "0")
      : 0
  );
  const {
    threshold,
    shouldRenderFilters,
    calendarCurrentDate,
    filtersAutocompleteData,
  } = props;

  /**
   *
   * @param difference
   */
  const handleChangeMonth = (difference: number) => {
    const currDiff = searchParams.get("difference");
    const newDiff = currDiff ? parseInt(currDiff) + difference : difference;
    customSearchParam.addParamToQueryString("difference", newDiff);
    setDifference(newDiff);
  };

  /**
   *
   */
  const resetAllFilters = () => {
    customSearchParam.removeParamFromQueryString([
      "difference",
      "type",
      "user",
    ]);
    setDifference(0);
    setSelectedUser(null);
    setSelectedType(null);
  };

  /**
   *
   */
  useEffect(() => {
    if (filtersAutocompleteData?.users.length > 0) {
      resetFilterData();
    }
  }, [filtersAutocompleteData]);

  /**
   *
   */
  const resetFilterData = () => {
    const urlParams: any = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParams) {
      switch (key) {
        case "difference":
          setDifference(value);
          break;
        case "type":
          setSelectedType(value);
          break;
        case "user":
          setSelectedUser(
            filtersAutocompleteData?.users.find(
              (user: any) => user?.id === value
            )
          );
          break;
        default:
          break;
      }
    }
  };

  /**
   *
   * @param event
   * @param value
   */
  const handleChangeUserFilter = (event: any, value: any) => {
    if (value) {
      customSearchParam.addParamToQueryString("user", value?.id);
      setSelectedUser(value);
    } else {
      customSearchParam.removeParamFromQueryString(["user"]);
      setSelectedUser(null);
    }
  };

  /**
   *
   * @param value
   */
  const handleChangeTypeFilter = (value: number) => {
    customSearchParam.addParamToQueryString("type", value);
    setSelectedType(value);
  };

  return (
    <div className={styles.calendarHeader}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <div className={styles.filterSubContainer}>
            <Fab
              className={styles.headerButtons}
              size="small"
              disabled={
                difference <= -threshold &&
                threshold === Config.dailyTasksCalendarThreshold
              }
              onClick={() => handleChangeMonth(-1)}
            >
              <NavigateBefore />
            </Fab>
            <Typography variant="h5">
              {`${
                Months[calendarCurrentDate.getMonth()]
              } ${calendarCurrentDate.getYear()}`}
            </Typography>
            <Fab
              className={styles.headerButtons}
              size="small"
              onClick={() => handleChangeMonth(1)}
              disabled={difference >= threshold}
            >
              <NavigateNext />
            </Fab>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <div className={styles.filterSubContainer}>
            <StatusLegend statuses={defaultStatusesForCalendar} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              className={genericStyles.flexContainer}
            >
              {props.entityFilterShouldNotBeDisabled && (
                <ButtonGroup
                  variant="contained"
                  fullWidth
                  className={styles.calendarButtonGroup}
                >
                  <Button
                    size="large"
                    startIcon={<Assignment />}
                    color="blueGrey"
                    key="one"
                    className={`${
                      selectedType == 1 ? genericStyles.nonSelectedFilter : ""
                    } ${genericStyles.calendarButtonGroupButton}`}
                    onClick={() => {
                      handleChangeTypeFilter(0);
                    }}
                  >
                    {Vocabulary.tasks}
                  </Button>
                  <Button
                    endIcon={<MeetingRoom />}
                    color="purpleGrey"
                    key="two"
                    className={`${
                      selectedType == 0 ? genericStyles.nonSelectedFilter : ""
                    } ${genericStyles.calendarButtonGroupButton}`}
                    onClick={() => {
                      handleChangeTypeFilter(1);
                    }}
                  >
                    {Vocabulary.meetings}
                  </Button>
                </ButtonGroup>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={6}>
              {shouldRenderFilters && !props.marelviCalendar && (
                <Autocomplete
                  noOptionsText={Vocabulary.noResultsFound}
                  id="user"
                  disablePortal
                  onChange={(event: any, value: any) => {
                    handleChangeUserFilter(event, value);
                  }}
                  getOptionLabel={(option: any) =>
                    typeof option === "object"
                      ? `${option?.lastName || ""} ${option?.firstName || ""}`
                      : Vocabulary.currentUser
                  }
                  isOptionEqualToValue={(option: any, value: any) =>
                    value?.id ? option.id === value.id : option.id === value
                  }
                  fullWidth
                  options={
                    filtersAutocompleteData?.users
                      ? filtersAutocompleteData?.users
                      : []
                  }
                  value={selectedUser ? selectedUser : null}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={Vocabulary.user}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "50px",
                        },
                      }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={1}
              className={styles.calendarFiltersDeleteButton}
            >
              {!props.marelviCalendar && (
                <Tooltip
                  title={Vocabulary.deleteFilters}
                  sx={{
                    backgroundColor: themeContext.theme?.palette.cancel?.main,
                  }}
                >
                  <IconButton
                    onClick={resetAllFilters}
                    color="secondary"
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRole(CalendarFilters);
