import axios from "axios";
import { HttpStatusCode, HttpVerbs } from "./Constants";
import { Vocabulary } from "./Vocabulary";
import { toast } from "react-toastify";
import { localUrlEnum, urlEnum } from "./UrlEnum";
import { logout } from "./Utils";

const notifyError = (message: string) => toast.error(message);
const notifySuccess = (message: string) => toast.success(message);

const token = localStorage.getItem("access_token");
let axiosConfig = {} as any;
if (token) {
  axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

const request = axios.create(axiosConfig);

request.interceptors.response.use(
  (response) => {
    if (
      response.status === HttpStatusCode.OK ||
      response.status === HttpStatusCode.Created
    ) {
      if (response.config.method === HttpVerbs.post) {
        notifySuccess(Vocabulary.createDataSuccess);
      }
      if (response.config.method === HttpVerbs.put) {
        notifySuccess(Vocabulary.updateDataSuccess);
      }
      if (response.config.method === HttpVerbs.delete) {
        notifySuccess(Vocabulary.deleteDataSuccess);
      }
    }
    setTimeout(stopLoading, 500);

    return response;
  },
  (error) => {
    switch (error.response?.status) {
      case HttpStatusCode.Unauthorized:
      case HttpStatusCode.UnprocessableEntity:
        logout();
        window.location.href = localUrlEnum.login;
        break;
      case HttpStatusCode.BadRequest:
      case HttpStatusCode.InternalServerError:
      case HttpStatusCode.Forbidden:
        handleError(error);
        break;
      case HttpStatusCode.NotFound:
        notifyError(Vocabulary.notFound);
        break;
      default:
        notifyError(Vocabulary.error);
        break;
    }
    setTimeout(stopLoading, 500);
  }
);

request.interceptors.request.use(
  (config) => {
    //check if the request is for chat list
    if (config?.url !== `${urlEnum.getChats}`) {
      startLoading();
    }
    return config;
  },
  (error) => {
    setTimeout(stopLoading, 500);
  }
);

/**
 * Start loading
 */
function startLoading() {
  const element = document.getElementById("loading");
  element?.classList.remove("loadingDisabled");
  element?.classList.add("loading");
}

/**
 * Stop loading
 */
function stopLoading() {
  const element = document.getElementById("loading");
  element?.classList.remove("loading");
  element?.classList.add("loadingDisabled");
}

/**
 * Handle error
 * @param error
 */
function handleError(error: any) {
  if (error.response.data.message && error.response.data.message !== "")
    notifyError(error.response.data.message);
  else {
    if (error.config.method === HttpVerbs.post) {
      notifyError(Vocabulary.createDataError);
    }
    if (error.config.method === HttpVerbs.put) {
      notifyError(Vocabulary.updateDataError);
    }
    if (error.config.method === HttpVerbs.delete) {
      notifyError(Vocabulary.deleteDataError);
    }
  }
}

export default request;
