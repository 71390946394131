import { Paper } from "@material-ui/core";
import { ResponsiveBar } from "@nivo/bar";
import style from "../../Styles/graphicResponsiveBar.module.css";
import withRole from "../../Hooks/withRole";
import { isMobile } from "../../Utils/Utils";
type Props = {
  data: any;
  keys: string[];
  title: string;
  roleLegend?: string;
};
function GraphicResponsiveBar(props: Props) {
  return (
    <Paper className={style.graphicPaper}>
      <p className={style.statisticallyText}>
        <span>{props.title}</span>
      </p>
      <ResponsiveBar
        data={props.data}
        groupMode="grouped"
        margin={{ top: 10, right: 150, bottom: 200, left: 80 }}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: "gray",
              },
            },
            legend: {
              text: {
                fontSize: isMobile() ? 16 : 18,
                fill: "gray",
              },
            },
            ticks: {
              line: {
                stroke: "gray",
                strokeWidth: 1,
              },
              text: {
                fill: "gray",
                fontSize: isMobile() ? 10 : 16,
              },
            },
          },
          legends: {
            text: {
              fontSize: isMobile() ? 10 : 16,
              fill: "gray",
            },
          },
        }}
        keys={props.keys}
        indexBy="month"
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "pastel1" }}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          legend: "Lunile anului",
          legendPosition: "middle",
          legendOffset: isMobile() ? 70 : 100,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: `Număr task-uri ${
            props.roleLegend ? `(${props.roleLegend})` : ""
          }`,
          legendPosition: "middle",
          legendOffset: -60,
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
      />
    </Paper>
  );
}

export default withRole(GraphicResponsiveBar);
