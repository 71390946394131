import noData from "../Images/noData.svg";
import genericStyles from "../Styles/genericStyles.module.css";

type Props = {
  classNames?: any;
};
function NoDataFound(props: Props) {
  return (
    <div
      className={`${genericStyles.noDataFoundContainer} ${props.classNames}`}
    >
      <img
        alt="noData"
        src={noData}
        className={genericStyles.noDataFoundImage}
      />
    </div>
  );
}

export default NoDataFound;
