/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import MainHeader from "./MainHeader";
import MainSlider from "./MainSlider";
import styles from "../Styles/dashboardContainer.module.css";
import genericStyles from "../Styles/genericStyles.module.css";
import { ComponentsNames, MainSliderDataForUser } from "../Utils/Constants";
import { Outlet, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../Context/GlobalContext";
import GenericModal from "./GenericModal";
import { Vocabulary } from "../Utils/Vocabulary";
import { readCookie } from "../Utils/Utils";

type Props = {
  shouldRenderSearchBar?: boolean;
};

export default function DashboardContainer(props: Props) {
  const globalContext = useContext(GlobalContext);
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);

  /**
   *
   */
  const toggleOpenMobileForTableAndMobile = () => {
    setOpenMenu(!openMenu);
  };
  /**
   *
   */
  useEffect(() => {
    globalContext.setProjectSelected(null);
  }, [location]);

  /**
   *
   */
  const handleToggleModal = () => {
    setOpenModal(!openModal);
  };

  /**
   *
   */
  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.includes("applewebkit") && ua.includes("mobile")) {
      //nu facem nimic
    } else {
      if (Notification.permission !== "granted") {
        const notificationCookie = readCookie("accept_notification");
        if (notificationCookie === "false" || notificationCookie === "") {
          setOpenModal(true);
          document.cookie = "accept_notification =;";
        } else if (notificationCookie === "true") {
          Notification.requestPermission().then((permission) => {
            //nu facem nimic
          });
        }
      }
    }
  }, []);

  /**
   *
   */
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const ua = navigator.userAgent.toLowerCase();
    if (ua.includes("applewebkit") && ua.includes("mobile")) {
      //nu facem nimic
    } else {
      document.cookie = "accept_notification = true;";
      Notification.requestPermission().then((permission) => {
        //nu facem nimic
      });
    }
    handleToggleModal();
  };

  return (
    <div className={styles.dashboardMainContainer}>
      <GenericModal
        open={openModal}
        onClose={handleToggleModal}
        title={Vocabulary.allowBrowserNotifications}
        children={
          <form onSubmit={handleSubmit} className={genericStyles.minWidthModal}>
            <Button
              type="submit"
              className={genericStyles.cancelButton}
              variant="contained"
            >
              {Vocabulary.block}
            </Button>
            <Button type="submit" color="blueGrey" variant="contained">
              {Vocabulary.allow}
            </Button>
          </form>
        }
      />
      <div className={styles.mainHeader}>
        <MainHeader
          title=""
          name={ComponentsNames.MainHeader}
          shouldRenderSearchBar={props.shouldRenderSearchBar}
          toggleOpenMobileForTableAndMobile={toggleOpenMobileForTableAndMobile}
        />
      </div>
      <div className={genericStyles.flexDashboardContainer}>
        <MainSlider
          openMenu={openMenu}
          toggleOpenMobileForTableAndMobile={toggleOpenMobileForTableAndMobile}
          name={ComponentsNames.MainSlider}
          data={MainSliderDataForUser}
        />

        <div className={genericStyles.page}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
