import { IconButton, InputBase } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Vocabulary } from "../Utils/Vocabulary";
import { Close, Search } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

export default function SearchComponent() {
  const [searchField, setSearchField]: any = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   *
   */
  useEffect(() => {
    if (!searchParams.get("search")) {
      setSearchField("");
    } else {
      setSearchField(searchParams.get("search"));
    }
  }, [searchParams]);

  /**
   *
   * @param event
   */
  const handleChangeSearch = (event: any) => {
    if (event.target.value.length > 3) {
      setTimeout(() => {
        searchParams.set("search", event.target.value);
        searchParams.set("page", "0");
        setSearchParams(searchParams);
      }, 1000);
    } else if (event.target.value.length === 0) {
      searchParams.delete("search");
    }
    setSearchField(event.target.value);
  };

  /**
   *
   */
  const handleClearSearch = () => {
    setSearchField("");
    searchParams.delete("search");
    setSearchParams(searchParams);
  };
  return (
    <Fragment>
      <InputBase
        sx={{ ml: 3, flex: 2 }}
        placeholder={Vocabulary.search}
        value={searchField}
        onChange={handleChangeSearch}
      />
      <IconButton onClick={handleClearSearch} type="button" sx={{ p: "6px" }}>
        {searchField.length > 0 ? <Close /> : <Search />}
      </IconButton>
    </Fragment>
  );
}
