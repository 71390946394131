/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import FilterContainer from "../FilterContainer";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { convertToBoolean } from "../../Utils/Utils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function ModifiedTasksFilters() {
  const customSearchParams = useCustomSearchParam();
  const location = useLocation();
  const [rerenderKey, setRerenderKey] = useState(0);
  const modifiedTasksFiltersValidationSchema = yup
    .object()
    .shape({
      modifiedPeriodicity: yup.boolean(),
      modifiedResponsible: yup.boolean(),
    })
    .required();

  const methods = useForm({
    resolver: yupResolver(modifiedTasksFiltersValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    // shouldUnregister: false,
    criteriaMode: "firstError",
  });
  /**
   *
   */
  const resetTasksFilters = () => {
    methods.reset();
    setRerenderKey(rerenderKey + 1);
    customSearchParams.removeParamFromQueryString([
      "search",
      "modifiedResponsible",
      "modifiedPeriodicity",
    ]);
  };

  /**
   *
   */
  const resetFilterData = () => {
    const urlParams: any = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParams) {
      switch (key) {
        case "modifiedPeriodicity":
        case "modifiedResponsible":
          methods.setValue(key, convertToBoolean(value));
          break;
        default:
          methods.setValue(key, value);
          break;
      }
    }
  };

  /**
   *
   */
  useEffect(() => {
    resetFilterData();
  }, [location]);

  /**
   *
   * @param key
   * @param value
   */
  const handleChangeFilters = (key: string, value: any) => {
    if (value !== null && value !== undefined) {
      customSearchParams.addParamToQueryString(key, value);
    } else {
      customSearchParams.removeParamFromQueryString([key]);
    }
    window.scrollTo(0, 0);
  };

  return (
    <FilterContainer
      key={rerenderKey}
      resetFilters={resetTasksFilters}
      filters={
        <>
          <div className={genericStyles.filter}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller
                    name={"modifiedPeriodicity"}
                    control={methods.control}
                    disabled={methods.getValues("modifiedResponsible")}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          handleChangeFilters(
                            "modifiedPeriodicity",
                            e.target.checked
                          );
                        }}
                      />
                    )}
                  />
                }
                label={Vocabulary.modifiedPeriodicity}
              />
            </FormGroup>
          </div>
          <div className={genericStyles.filter}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller
                    name={"modifiedResponsible"}
                    control={methods.control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        disabled={methods.getValues("modifiedPeriodicity")}
                        checked={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.checked);
                          handleChangeFilters(
                            "modifiedResponsible",
                            e.target.checked
                          );
                        }}
                      />
                    )}
                  />
                }
                label={Vocabulary.responsibleChanged}
              />
            </FormGroup>
          </div>
        </>
      }
    />
  );
}
