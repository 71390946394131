import { Tooltip, Typography } from "@mui/material";
import genericStyles from "../Styles/genericStyles.module.css";
import { Circle } from "@mui/icons-material";

type Props = {
  statuses: any;
};

export default function StatusLegend(props: Props) {
  const { statuses } = props;
  return (
    <div className={genericStyles.flexContainer}>
      {statuses?.map((status: any, index: any) => {
        return (
          <div className={genericStyles.flexAlignedContainer} key={index}>
            <Tooltip
              title={
                status.helperText ? (
                  <Typography>{status.helperText}</Typography>
                ) : (
                  ""
                )
              }
            >
              <div className={genericStyles.flexAlignedContainer}>
                <Circle
                  style={{ color: `${status.color}` }}
                  className={genericStyles.statusLegendColor}
                />
                <Typography>{status.translation}</Typography>
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
}
