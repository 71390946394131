/* eslint-disable no-case-declarations */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import genericStyles from "../../Styles/genericStyles.module.css";
import Config from "../../Utils/Config";
import { checkIfTaskIsLate, formatStringDate } from "../../Utils/Utils";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTableCustomStyle from "../MUIDataTable/MUIDataTableCustomStyle";
import { Delete, Edit } from "@mui/icons-material";
import { Fragment, useContext, useEffect, useState } from "react";
import { CommonTableOptions, subtaskColumns } from "../../Utils/Constants";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import { TasksStateModel } from "../../Utils/Models";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import QueryStringParser from "../QueryStringParser";
import ApprovalModal from "../ApprovalModal";
import { deleteData } from "../../Services/deleteData";
import { GlobalContext } from "../../Context/GlobalContext";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { useLocation } from "react-router-dom";

export default function UnassignedTasks() {
  const customSearchParams = useCustomSearchParam();
  const [state, setState] = useState<TasksStateModel>(new TasksStateModel());
  const [openModal, setOpenModal] = useState(false);
  const refreshContext = useContext(RefreshDataContext);
  const location = useLocation();
  const globalTask = useContext(GlobalContext);
  /**
   *
   * @param page
   * @param perPage
   * @param sortProperty
   * @param sort
   * @param search
   */
  const getUnassignedTasks = (
    page: number,
    perPage: number,
    sortProperty: string,
    sort: string,
    search: string | null
  ) => {
    const url = `${
      urlEnum.getUnassignedTasks
    }?page=${page}&perPage=${perPage}&sortProperty=${sortProperty}&sort=${sort}${
      search ? `&search=${search}` : ""
    }`;
    getData(url).then((res: any) => {
      if (res.data) {
        setState({
          ...state,
          tasks: res.data.results,
          count: res.data.nrResults,
          page: page,
          perPage: perPage,
          sortProperty: sortProperty,
          sort: sort,
        });
      }
    });
  };

  const taskHeader = [
    {
      label: Vocabulary.fancyName,
      name: "text",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return `${
            value.length >= 40 ? `${value?.substring(0, 40)}...` : value
          }`;
        },
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return value?.translation;
        },
      },
    },
    {
      label: Vocabulary.priority,
      name: "priority",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return (
            <div className={genericStyles.customFieldContainer}>
              <div
                className={genericStyles.colorCircle}
                style={{
                  backgroundColor: value?.color,
                }}
              />
              <p>{value?.translation}</p>
            </div>
          );
        },
      },
    },
    {
      label: Vocabulary.periodicity,
      name: "periodicity",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return value?.translation;
        },
      },
    },
    {
      label: Vocabulary.type,
      name: "type",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return value?.translation;
        },
      },
    },
    {
      label: Vocabulary.startDate,
      name: "start",
      options: {
        sort: true,
        customBodyRender: (value: any, meta: any) => {
          return formatStringDate(value, Config.momentEUDateFormat);
        },
      },
    },
    {
      label: Vocabulary.endDate,
      name: "end",
      options: {
        sort: true,
        customBodyRender: (value: any, meta: any) => {
          return formatStringDate(value, Config.momentEUDateFormat);
        },
      },
    },
    {
      label: "subTasks",
      name: "subTasks",
      options: { display: false },
    },
    {
      label: Vocabulary.delay,
      name: "delay",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) =>
          value ? `${value} ${Vocabulary.days}` : "-",
      },
    },
    {
      label: "createdBy",
      name: "createdBy",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      label: Vocabulary.options,
      name: "Optiuni",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
      },
    },
  ];

  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    return {
      ...CommonTableOptions,
      count: state.count,
      rowsPerPage: state.perPage,
      page: state.page,
      onColumnSortChange: (changedColumn: any, direction: any) => {
        customSearchParams.addParamToQueryString("sortProperty", changedColumn);
        customSearchParams.addParamToQueryString("sort", direction);
      },
      onChangePage: (page: any) => {
        customSearchParams.addParamToQueryString("page", page);
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        customSearchParams.addParamToQueryString("perPage", numberOfRows);
        window.scrollTo(0, 0);
      },
      customRowRender: (data: any, dataIndex: any, rowIndex: any) => {
        return renderCustomRow(data, dataIndex, rowIndex);
      },
    };
  };

  /**
   *
   */
  const toggleModalState = () => {
    setOpenModal(!openModal);
  };

  /**
   *
   * @param element
   * @param key
   * @param rowIndex
   * @returns
   */
  const renderColumnCustom = (element: any, key: any, rowIndex: any) => {
    switch (key) {
      case 7:
        return (
          <TableCell key={key} align="center">
            <ToggleButtonGroup exclusive aria-label="text alignment">
              <Tooltip
                title={Vocabulary.edit}
                className={genericStyles.tooltipEditButton}
              >
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    getTask(
                      state.tasks.length ? state.tasks[rowIndex].id : null
                    );
                  }}
                >
                  <Edit />
                </ToggleButton>
              </Tooltip>
              <Tooltip
                title={Vocabulary.delete}
                className={genericStyles.tooltipDeleteButton}
              >
                <ToggleButton
                  value="center"
                  aria-label="centered"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setState({
                      ...state,
                      selectedTask: state.tasks[rowIndex],
                    });
                    toggleModalState();
                  }}
                >
                  <Delete />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </TableCell>
        );
      default:
        return (
          <TableCell key={key} align="left">
            {element}
          </TableCell>
        );
    }
  };

  /**
   *
   * @param data
   * @param rowIndex
   * @returns
   */
  const renderOtherProperties: any = (data: any, rowIndex: any) => {
    const propertiesArrayCopy = [...data];
    propertiesArrayCopy.splice(9, 1);
    propertiesArrayCopy.splice(0, 1);
    propertiesArrayCopy.splice(6, 1);
    return propertiesArrayCopy?.map((element: any, key: any) => {
      return renderColumnCustom(element, key, rowIndex);
    });
  };

  /**
   *
   * @param data
   * @param dataIndex
   * @param rowIndex
   * @returns
   */
  const renderCustomRow = (data: any, dataIndex: any, rowIndex: any) => {
    return (
      <>
        <TableRow
          onClick={() => {
            getTask(state.tasks.length ? state.tasks[rowIndex].id : null);
          }}
          className={`${
            checkIfTaskIsLate(state.tasks[rowIndex])
              ? genericStyles.lateTask
              : ""
          }`}
        >
          <TableCell align="left">
            <p>
              {data[0]}
              <p>
                {Vocabulary.createdBy}
                <b>
                  {data[9]
                    ? `${data[9]?.firstName || ""} ${data[9]?.lastName || ""}`
                    : Vocabulary.noUserFound}
                </b>
              </p>
            </p>
          </TableCell>

          {renderOtherProperties(data, rowIndex)}
        </TableRow>
        {data[7] && data[7].length ? (
          <Fragment key={rowIndex}>
            <tr>
              <td colSpan={10} className={genericStyles.unassignedTasksTd}>
                <TableContainer>
                  <Table className={genericStyles.fullWidthElement}>
                    <TableHead>
                      <TableRow
                        className={genericStyles.unassignedTasksTableRow}
                      >
                        {subtaskColumns?.map((value: any, index: any) => (
                          <TableCell key={index}>{value.label}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody className={genericStyles.subtask}>
                      {data[7]?.map((row: any, dataIndex: any) => (
                        <TableRow key={dataIndex}>
                          {subtaskColumns?.map((column: any, index: any) => (
                            <TableCell key={index} component="th" scope="row">
                              {row[column.name]?.translation ||
                                row[column.name] ||
                                index + 1}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </td>
            </tr>
          </Fragment>
        ) : null}
      </>
    );
  };

  /**
   *
   */
  useEffect(() => {
    if (refreshContext.refresh && location.pathname === localUrlEnum.reports) {
      getTasksUsingStateData();
      refreshContext.setRefresh(false);
    }
  }, [refreshContext.refresh]);

  /**
   *
   */
  const getTasksUsingStateData = () => {
    getUnassignedTasks(
      state.page,
      state.perPage,
      state.sortProperty,
      state.sort,
      state.search
    );
  };

  /**
   *
   * @param id
   */
  const deleteTask = () => {
    deleteData(`${urlEnum.deleteTask}/${state.selectedTask?.id}`)
      .then((res: any) => {
        if (res) {
          getTasksUsingStateData();
        }
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  /**
   *
   * @param id
   */
  const getTask = (id: string | null) => {
    if (id) globalTask.setTaskId(id);
  };

  return (
    <div className={genericStyles.mainContainer}>
      <QueryStringParser
        requestFunction={(data: any) => {
          getUnassignedTasks(
            data.page ? parseInt(data.page) : state.page,
            data.perPage ? parseInt(data.perPage) : state.perPage,
            data.sortProperty ? data.sortProperty : state.sortProperty,
            data.sort ? data.sort : state.sort,
            data.search
          );
        }}
      />
      <div className={genericStyles.fullWidthElement}>
        <MUIDataTableCustomStyle
          title={Vocabulary.unassignedTasks}
          data={state.tasks}
          columns={taskHeader}
          options={getTableOptions()}
        />
      </div>
      <ApprovalModal
        handleApproveAction={deleteTask}
        openModal={openModal}
        handleClose={toggleModalState}
      />
    </div>
  );
}
