import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import Config from "../../Utils/Config";
import {
  MoreVert,
  Refresh,
  Send,
  SentimentDissatisfied,
} from "@mui/icons-material";
import Messages from "./Messages";
import { Vocabulary } from "../../Utils/Vocabulary";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useWebSocket } from "../../Context/WebSocketContext";
import { ChatModel } from "../../Utils/Models";
import { useEffect, useRef, useState } from "react";
import styles from "../../Styles/messageChanel.module.css";
import { getData } from "../../Services/getData";
import { foldersNames, urlEnum } from "../../Utils/UrlEnum";
import GenericModal from "../GenericModal";
import genericStyles from "../../Styles/genericStyles.module.css";
import WYSIWYGEditor from "./WYSIWYGEditor";
import {
  mapUserListToChatSuggestions,
  restoreAllEscapedChars,
  sanitizeHtml,
} from "../../Utils/Utils";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js";

type Props = {
  id: string;
};

export default function ChatWindow(props: Props) {
  const ref: any = useRef(null);
  let subscribed = false;
  const { id } = props;
  const [resetData, setResetData] = useState<boolean>(false);
  const messageValidationSchema = yup
    .object()
    .shape({
      message: yup.string().required(Vocabulary.requiredField),
    })
    .required();
  const { getValues, reset, handleSubmit, control } = useForm({
    resolver: yupResolver(messageValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });
  const authenticatedUser = localStorage.getItem("userId");
  const { stompClient } = useWebSocket();
  const [chat, setChat] = useState<ChatModel>(new ChatModel());
  const [messages, setMessages] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [shouldScrollPage, setShouldScrollPage] = useState<boolean>(false);
  const toolbarOptions = {
    options: ["emoji"],
  };
  const mentionOptions = {
    separator: " ",
    trigger: "@",
    suggestions: chat.participants
      ? mapUserListToChatSuggestions(chat.participants)
      : [],
  };
  const hashtagOptions = {
    separator: " ",
    trigger: "#",
  };

  /**
   *
   */
  useEffect(() => {
    if (id && id !== "") {
      getData(`${urlEnum.getChats}/${id}?page=${page}`).then((res: any) => {
        if (res?.data) {
          setChat(res.data);
          if (page > 0) {
            const newMessages = messages.slice();
            setMessages([...res.data.messages, ...newMessages]);
            setShouldScrollPage(true);
          } else {
            setMessages(res.data.messages?.length ? res.data.messages : []);
          }
        }
      });
    }
  }, [id, page]);

  /**
   * Subscribe to private message (receive messages from other users in the current chat)
   */
  useEffect(() => {
    if (stompClient && !subscribed && chat?.id) {
      subscribed = true;
      stompClient.subscribe(
        `/notification/${chat?.id}/message`,
        onPrivateMessage
      );
    }
    return () => {
      subscribed = false;
      stompClient?.unsubscribe(`/notification/${chat?.id}/message`);
    };
  }, [stompClient, chat?.id]);

  /**
   *
   * @param e
   */
  const sendMessage = () => {
    let messageCopy = getValues("message");
    if (checkIfTextIsEmpty(messageCopy)) return;
    messageCopy = messageCopy.replace(/\s/g, "");
    if (messageCopy && messageCopy !== "") {
      const chatMessage = {
        message: getValues("message"),
        senderId: authenticatedUser,
        chatId: chat?.id,
      };
      stompClient?.send(`/app/privateMessage`, {}, JSON.stringify(chatMessage));
      reset();
      setResetData(!resetData);
    }
  };

  /**
   *
   * @param text
   * @returns
   */
  const checkIfTextIsEmpty = (text: string) => {
    const defaultValue = text ? text : "";
    const escapedHtmlContent = sanitizeHtml(
      restoreAllEscapedChars(defaultValue)
    );

    const blocksFromHtml = htmlToDraft(escapedHtmlContent);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHtml.contentBlocks,
      blocksFromHtml.entityMap
    );
    const newEditorState = EditorState.createWithContent(contentState);
    const editorStateCurrentContent = newEditorState
      .getCurrentContent()
      .getPlainText();
    if (editorStateCurrentContent === "") {
      return true;
    }
    return false;
  };

  /**
   *
   * @param payload
   */
  const onPrivateMessage = (payload: any) => {
    const payloadData = JSON.parse(payload.body);
    messages.push(payloadData);
    if (messages.length === 0) {
      setMessages([payloadData]);
    }
    const newMessages = messages.slice();
    setMessages(newMessages);
    setShouldScrollPage(false);
    //send request to update last read for user
    const chatMessage = {
      message: "",
      senderId: authenticatedUser,
      chatId: chat?.id,
    };
    stompClient?.send(`/app/readConversation`, {}, JSON.stringify(chatMessage));
  };

  /**
   *
   */
  const toggleModalState = () => {
    setOpen(!open);
  };

  /**
   *
   */
  const renderMoreMessagesButton = () => {
    setPage(page + 1);
  };

  /**
   *
   * @returns
   */
  const renderChatParticipants = () => {
    return (
      <Grid container spacing={2}>
        {chat?.participants?.map((participant: any, index: number) => {
          return (
            <Grid key={index} item xs={4} className={styles.chatUserInfo}>
              <Avatar
                className={`${genericStyles.listOfUsersAvatar} ${genericStyles.assignedUsersAvatar}`}
                src={`${urlEnum.getFilesAndMedia}${foldersNames.usersProfiles}${participant.user?.profilePicture}`}
              />
              <Typography variant="body2" sx={{ marginLeft: "1px" }}>{`${
                participant.user.lastName ?? ""
              } ${participant.user.firstName ?? ""}`}</Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <>
      <Paper className={styles.conversationContainer}>
        <div className={styles.chatHeader}>
          <Typography sx={{ fontWeight: "bold" }} variant="h6">
            {chat?.name}
          </Typography>
          <IconButton color="cancel" onClick={toggleModalState}>
            <MoreVert />
          </IconButton>
        </div>
        <div id="messagesContainer" className={styles.messagesContainer}>
          {messages.length > 0 ? (
            <div>
              <Button
                fullWidth
                color="blueGrey"
                onClick={renderMoreMessagesButton}
                endIcon={<Refresh />}
              >
                {Vocabulary.loadMoreMessages}
              </Button>
              <Messages
                shouldScrollPage={shouldScrollPage}
                messages={messages}
              />
            </div>
          ) : (
            <div className={styles.noMessages}>
              <SentimentDissatisfied fontSize="large" />
              <Typography variant="body1">
                {Vocabulary.noMessagesYet}
              </Typography>
            </div>
          )}
        </div>
      </Paper>
      <form ref={ref} onSubmit={handleSubmit(sendMessage)}>
        <Paper
          elevation={Config.elevation}
          className={styles.messageInputContainer}
        >
          <Controller
            render={({ field }) => (
              <WYSIWYGEditor
                classOverride={{
                  borderRadius: "50px",
                  paddingLeft: "20px",
                  marginRight: "10px",
                  backgroundColor: "rgb(77, 94, 127, 0.15)",
                  padding: "5px",
                  height: "60px",
                  overflow: "auto",
                  lineHeight: "normal",
                }}
                toolbar={toolbarOptions}
                formRef={ref}
                reset={resetData}
                value={field.value}
                onChange={field.onChange}
                mention={mentionOptions}
                hashtag={hashtagOptions}
                id="chatTextEditorContainer"
                className={genericStyles.chatTextEditorContainer}
              />
            )}
            name="message"
            control={control}
            defaultValue=""
          />
          <Tooltip title={Vocabulary.send}>
            <IconButton type="submit" color="blueGrey">
              <Send
                fontSize="large"
                sx={{
                  transform: "rotate(-45deg)",
                }}
              />
            </IconButton>
          </Tooltip>
        </Paper>
      </form>
      <GenericModal
        maxWidth={"lg"}
        onClose={toggleModalState}
        open={open}
        title={Vocabulary.participants}
        children={renderChatParticipants()}
      />
    </>
  );
}
