import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Vocabulary } from "../Utils/Vocabulary";
import Config from "../Utils/Config";
import genericStyles from "../Styles/genericStyles.module.css";
import { Controller } from "react-hook-form";

type Props = {
  handleChangeFilters: (type: string, value: any) => void;
  methods: any;
  customStyle?: any;
};

export default function DatePickersIntervalForFilters(props: Props) {
  const { handleChangeFilters, methods } = props;
  return (
    <div style={props.customStyle ? props.customStyle : null}>
      <div
        className={genericStyles.filter}
        style={props.customStyle ? { marginRight: "20px" } : {}}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Controller
            name="start"
            control={methods.control}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format={Config.momentEUDateFormat}
                className={genericStyles.fullWidthElement}
                label={Vocabulary.startDate}
                onChange={(date: any) => {
                  field.onChange(date);
                  handleChangeFilters(
                    "start",
                    date.format(Config.momentUSDateFormat)
                  );
                }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <div className={genericStyles.filter}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Controller
            name="end"
            control={methods.control}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <DatePicker
                {...field}
                className={genericStyles.fullWidthElement}
                format={Config.momentEUDateFormat}
                label={Vocabulary.endDate}
                onChange={(date: any) => {
                  field.onChange(date);
                  handleChangeFilters(
                    "end",
                    date.format(Config.momentUSDateFormat)
                  );
                }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
