/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import { DayPilot } from "@daypilot/daypilot-lite-react";
import {
  CalendarFiltersAutocompleteModel,
  CalendarFiltersModel,
} from "../../Utils/Models";
import GenericWeeklyCalendar from "./GenericWeeklyCalendar";
import { GlobalContext } from "../../Context/GlobalContext";
import CalendarFilters from "../Calendar/CalendarFilters";
import Config from "../../Utils/Config";
import { ComponentsNames } from "../../Utils/Constants";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { predictTasksAndMeetings } from "../../Utils/Utils";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { useLocation } from "react-router-dom";
import useQueryStringParser from "../../Hooks/useQueryStringParser";

type Props = {
  shouldRenderFilters?: boolean;
};

function MarelviCalendar(props: Props) {
  const [calendarCurrentDate, setCalendarCurrentDate] = useState(
    new DayPilot.Date(new Date())
  );
  const location = useLocation();
  const refreshContext = useContext(RefreshDataContext);
  const currentUserId = localStorage.getItem("userId");
  const globalContext = useContext(GlobalContext);
  const calendarRef: any = useRef();
  const [staticCalendarMeetings, setStaticCalendarMeeting] = useState<any>([]);
  const [meetings, setMeetings] = useState<any>([]);
  const [calendarFilters, setCalendarFilters] = useState<CalendarFiltersModel>({
    type: null,
    start: new DayPilot.Date(
      calendarCurrentDate.toString(Config.momentUSDateFormatForCalendar)
    )
      .firstDayOfWeek()
      .toString(Config.momentUSDateFormatForCalendar),
    end: new DayPilot.Date(
      calendarCurrentDate
        .addDays(21)
        .toString(Config.momentUSDateFormatForCalendar)
    ).toString(Config.momentUSDateFormatForCalendar),
    difference: 0,
    user: currentUserId,
  });
  /**
   *
   * @param {*} args
   * @returns
   */
  const onEventClick = async (args: any) => {
    if (!args.e.data.disabled) {
      globalContext.setMeetingSelectedId(args.e.data.id);
    }
  };

  /**
   *
   */
  useQueryStringParser((data: any) => {
    //check if data is an empty object or only contains tab property then get data from server (
    //it means it;s the first time when the page is loaded or the filters were deleted)
    let { start } = calendarFilters;
    let { end } = calendarFilters;
    const newDiff =
      data.difference !== undefined
        ? parseInt(data.difference)
        : calendarFilters.difference;
    if (newDiff !== calendarFilters.difference) {
      const newStart = calendarCurrentDate.addDays(
        newDiff > calendarFilters.difference ? 1 * 7 : -1 * 7
      );
      setCalendarCurrentDate(newStart);
      start = new DayPilot.Date(
        newStart.toString(Config.momentUSDateFormatForCalendar)
      )
        .firstDayOfWeek()
        .toString(Config.momentUSDateFormatForCalendar);
      end = new DayPilot.Date(start)
        .addDays(7)
        .toString(Config.momentUSDateFormatForCalendar);
    }
    setCalendarFilters({
      ...calendarFilters,
      difference: newDiff,
      type: data.type !== undefined ? parseInt(data.type) : null,
      start: start,
      end: end,
    });
  });

  /**
   *
   */
  useEffect(() => {
    refetchCalendarData();
  }, [meetings]);

  /**
   * refetch data when it's needed
   */
  async function refetchCalendarData() {
    calendarRef.current?.control.update({
      start: calendarCurrentDate,
      events: meetings,
    });
  }

  /**
   *
   */
  useEffect(() => {
    getMarelviCalendarData();
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (
      calendarFilters.difference < 0 ||
      (refreshContext.refresh &&
        location.pathname.includes(localUrlEnum.calendar))
    ) {
      //get data from server
      getMarelviCalendarData();
    } else {
      //use data from state
      setMeetings(staticCalendarMeetings);
    }
  }, [
    calendarFilters.difference,
    calendarFilters.user,
    refreshContext.refresh,
  ]);

  /**
   *
   */
  const getMarelviCalendarData = () => {
    getData(
      `${urlEnum.getOpenMeetingsCalendar}/${calendarFilters.start}/${calendarFilters.end}`
    ).then((res: any) => {
      if (res?.data) {
        predictTasksAndMeetings(res.data, (futureData: any) => {
          setMeetings([...res.data, ...futureData]);
          if (staticCalendarMeetings?.length === 0) {
            setStaticCalendarMeeting([...res.data, ...futureData]);
          }
        });
      }
    });
  };

  return (
    <>
      <CalendarFilters
        threshold={Config.monthlyAndWeeklyTasksCalendarThreshold}
        renderEntityFilter={false}
        name={ComponentsNames.CalendarFilters}
        calendarCurrentDate={calendarCurrentDate}
        filtersAutocompleteData={new CalendarFiltersAutocompleteModel()}
        marelviCalendar={true}
      />
      <GenericWeeklyCalendar
        calendarCurrentDate={calendarCurrentDate}
        calendarRef={calendarRef}
        onEventClick={onEventClick}
      />
    </>
  );
}
export default MarelviCalendar;
