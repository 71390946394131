import { DayPilot } from "@daypilot/daypilot-lite-react";
import { useContext, useEffect, useRef, useState } from "react";
import Config from "../../Utils/Config";
import {
  CalendarFiltersAutocompleteModel,
  CalendarFiltersModel,
} from "../../Utils/Models";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { GlobalContext } from "../../Context/GlobalContext";
import CalendarFilters from "../Calendar/CalendarFilters";
import { ComponentsNames } from "../../Utils/Constants";
import {
  checkIfTaskIsLate,
  checkIfUserIsPartOfMeeting,
  formatStringDate,
  predictTasksAndMeetings,
} from "../../Utils/Utils";
import styles from "../../Styles/calendar.module.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import QueryStringParser from "../QueryStringParser";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { useLocation } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";
import { EMeetingType } from "../../Utils/Enums";
import moment from "moment";
import GenericWeeklyCalendar from "./GenericWeeklyCalendar";

type Props = {
  calendarFilters: CalendarFiltersAutocompleteModel;
  shouldRenderFilters?: boolean;
};

function WeeklyCalendar(props: Props) {
  const refreshContext = useContext(RefreshDataContext);
  const location = useLocation();
  const [calendarCurrentDate, setCalendarCurrentDate] = useState(
    new DayPilot.Date(new Date())
  );
  const [open, setOpen] = useState(true);
  const [staticCalendarTasks, setStaticCalendarTasks] = useState<any>([]);
  const [staticCalendarMeetings, setStaticCalendarMeeting] = useState<any>([]);
  const [meetings, setMeetings] = useState<any>([]);
  const [tasks, setTasks] = useState<any>([]);
  const currentUserId = localStorage.getItem("userId");
  const [calendarFilters, setCalendarFilters] = useState<CalendarFiltersModel>({
    type: null,
    start: new DayPilot.Date(
      calendarCurrentDate.toString(Config.momentUSDateFormatForCalendar)
    )
      .firstDayOfWeek()
      .toString(Config.momentUSDateFormatForCalendar),
    end: new DayPilot.Date(
      calendarCurrentDate
        .addDays(21)
        .toString(Config.momentUSDateFormatForCalendar)
    ).toString(Config.momentUSDateFormatForCalendar),
    difference: 0,
    user: currentUserId,
  });
  const globalContext = useContext(GlobalContext);
  const calendarRef: any = useRef();
  const userRef = useRef(null);

  /**
   * refetch data when it's needed
   */
  async function refetchCalendarData() {
    calendarRef.current?.control.update({
      start: calendarCurrentDate,
      events: meetings,
    });
  }

  /**
   *
   */
  useEffect(() => {
    if (
      calendarFilters.difference < 0 ||
      calendarFilters.user !== userRef.current ||
      (refreshContext.refresh &&
        location.pathname.includes(localUrlEnum.calendar))
    ) {
      //get data from server
      getDataForCalendar();
    } else {
      //use data from state
      setTasks(filterTasksBasedOnWeek(staticCalendarTasks));
      setMeetings(staticCalendarMeetings);
    }
  }, [
    calendarFilters.difference,
    calendarFilters.user,
    refreshContext.refresh,
  ]);

  /**
   *
   * @param tasks
   * @returns
   */
  const filterTasksBasedOnWeek = (tasks: any) => {
    const filteredTasks: any = [];
    const firstDayOfWeek = new DayPilot.Date(calendarFilters?.start);
    const lastDayOfWeek = new DayPilot.Date(firstDayOfWeek).addDays(7);
    tasks.forEach((task: any) => {
      //format start date
      const start = new DayPilot.Date(task.start);
      //also keep in mind if its a late task
      if (
        (start > firstDayOfWeek && start <= lastDayOfWeek) ||
        checkIfTaskIsLate(task)
      ) {
        filteredTasks.push(task);
      }
    });
    return filteredTasks;
  };

  /**
   *
   */
  useEffect(() => {
    getDataForCalendar();
  }, []);

  /**
   *
   */
  const getDataForCalendar = () => {
    getData(
      `${urlEnum.getTaskCalendar}/${
        calendarFilters.user?.id
          ? calendarFilters.user?.id
          : calendarFilters.user
      }/${calendarFilters.start}/${calendarFilters.end}/${false}`
    ).then((res: any) => {
      predictTasksAndMeetings(res.data.calendarTasks, (futureData: any) => {
        setTasks(
          filterTasksBasedOnWeek([...res.data.calendarTasks, ...futureData])
        );
        if (
          staticCalendarTasks?.length === 0 ||
          calendarFilters.user !== userRef.current
        ) {
          setStaticCalendarTasks([...res.data.calendarTasks, ...futureData]);
        }
      });
      predictTasksAndMeetings(res.data.calendarMeetings, (futureData: any) => {
        setMeetings([...res.data.calendarMeetings, ...futureData]);
        if (
          staticCalendarMeetings?.length === 0 ||
          calendarFilters.user !== userRef.current
        ) {
          setStaticCalendarMeeting([
            ...res.data.calendarMeetings,
            ...futureData,
          ]);
        }
      });
    });
  };

  /**
   *
   */
  useEffect(() => {
    refetchCalendarData();
  }, [meetings]);

  /**
   *
   */
  useEffect(() => {
    if (calendarCurrentDate) {
      calendarRef.current?.control.update({
        start: calendarCurrentDate,
        events: meetings,
      });
    }
  }, [calendarCurrentDate, meetings]);

  /**
   *
   * @param {*} args
   * @returns
   */
  const onEventClick = async (args: any) => {
    if (!args.e.data.disabled) {
      if (args.e.data.duration && args.e.data.duration > "") {
        //check if meeting is open or private
        if (
          args.e.data.type?.name === EMeetingType.MEETING_OPEN ||
          checkIfUserIsPartOfMeeting(args.e.data)
        ) {
          globalContext.setMeetingSelectedId(args.e.data.id);
        }
      } else {
        globalContext.setTaskId(args.e.data.id);
      }
    }
  };

  return (
    <>
      <QueryStringParser
        requestFunction={(data: any) => {
          //check if data is an empty object or only contains tab property then get data from server (
          //it means it;s the first time when the page is loaded or the filters were deleted)
          let { start } = calendarFilters;
          let { end } = calendarFilters;

          const newDiff =
            data.difference !== undefined
              ? parseInt(data.difference)
              : calendarFilters.difference;
          if (newDiff !== calendarFilters.difference) {
            const newStart = calendarCurrentDate.addDays(
              newDiff > calendarFilters.difference ? 1 * 7 : -1 * 7
            );
            setCalendarCurrentDate(newStart);
            start = new DayPilot.Date(
              newStart.toString(Config.momentUSDateFormatForCalendar)
            )
              .firstDayOfWeek()
              .toString(Config.momentUSDateFormatForCalendar);
            end = new DayPilot.Date(start)
              .addDays(7)
              .toString(Config.momentUSDateFormatForCalendar);
          }
          userRef.current = calendarFilters.user;
          setCalendarFilters({
            ...calendarFilters,
            difference: newDiff,
            type: data.type !== undefined ? parseInt(data.type) : null,
            user: data.user ? data.user : currentUserId,
            start: start,
            end: end,
          });
        }}
      />
      <CalendarFilters
        threshold={Config.monthlyAndWeeklyTasksCalendarThreshold}
        renderEntityFilter={false}
        name={ComponentsNames.CalendarFilters}
        calendarCurrentDate={calendarCurrentDate}
        filtersAutocompleteData={props.calendarFilters}
      />
      <Accordion
        className={styles.weeklyCalendarTasksContainer}
        expanded={open}
        onChange={() => {
          setOpen(!open);
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography
            sx={{
              textAlign: "center",
            }}
            variant="h6"
          >
            {`${Vocabulary.tasks} (${calendarFilters.start} - ${moment(
              calendarFilters.start
            )
              .add(7, "days")
              .format(Config.momentEUDateFormat)})`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {tasks?.map((task: any, index: any) => (
              <div
                key={index}
                className={
                  task.disabled
                    ? styles.weeklyCalendarTaskPredicted
                    : styles.weeklyCalendarTask
                }
                onClick={() => onEventClick({ e: { data: task } })}
              >
                <Typography>{task.text}</Typography>
                <Typography>{`${formatStringDate(
                  task?.start,
                  Config.momentEUDateFormat
                )} / ${formatStringDate(
                  task?.end,
                  Config.momentEUDateFormat
                )}`}</Typography>
              </div>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <div className={styles.weeklyCalendarHeader}>
        <Typography variant="h6" textAlign={"center"}>
          {Vocabulary.meetings}
        </Typography>
      </div>
      <GenericWeeklyCalendar
        calendarCurrentDate={calendarCurrentDate}
        calendarRef={calendarRef}
        onEventClick={onEventClick}
      />
    </>
  );
}

export default WeeklyCalendar;
