import { useEffect, useState } from "react";
import { roleUI } from "../Utils/Constants";
import moment from "moment";
import hash from "object-hash";

/**
 * Hook that checks the role of the logged user and renders the component based on the role
 * @param WrappedComponent
 * @returns
 */

const withRole = (WrappedComponent: any) => {
  return function useWithRole(props: any) {
    const [newProps, setNewProps] = useState<any>(null);
    const userRolesJson = window.localStorage.getItem("userRoles");

    /**
     * Check the role of the logged user and and get props from roleUI based on the priority of the role
     */
    useEffect(() => {
      const userRoles = userRolesJson ? JSON.parse(userRolesJson) : [];
      //get role with highest priority (lower value in this case)
      const higherPriority =
        userRoles.length > 0 &&
        userRoles?.reduce((prev: any, curr: any) =>
          prev.priority < curr.priority ? prev : curr
        );
      //get props from roleUI based on the priority of the role
      const ui = roleUI[higherPriority.name];
      let uiProps;
      if (ui) uiProps = ui[props.name];
      //merge props of the higher role with props of the other roles
      for (const role of userRoles) {
        if (role.name === higherPriority.name) continue;
        uiProps = deepMergeObject(roleUI[role.name][props.name], uiProps);
      }

      //merge props from roleUI with the props of the component
      const updatedProps = deepMergeObject(props, uiProps);

      setNewProps(updatedProps);
    }, [props]);

    /**
     *
     * @param initialObj
     * @param obj
     * @returns
     */
    function deepMergeObject(initialObj: any, obj: any) {
      const newObj = { ...initialObj };
      for (const key in obj) {
        const isArray = newObj[key] instanceof Array;
        if (
          typeof newObj[key] === "object" &&
          !isArray &&
          typeof obj[key] === "object" &&
          !moment.isMoment(obj[key])
        ) {
          newObj[key] = deepMergeObject(newObj[key], obj[key]);
        } else {
          if (isArray) {
            newObj[key] = compareArraysAndMerge(newObj[key], obj[key]);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
      return newObj;
    }

    /**
     *
     * @param initialObj
     * @param obj
     */
    function compareArraysAndMerge(initialObj: any[], obj: any[]) {
      //create copy of the second array (in general bigger than the first one)
      const newArray = [...obj];
      //hash every element in both arrays
      const initialObjHash = initialObj?.map((obj) => hash(obj));
      const objHash = obj?.map((obj) => hash(obj));
      //check if the hash of the element in the first array is present in the second array
      const diff = initialObjHash?.filter((obj) => !objHash.includes(obj));
      //if the hash is not present, add the element to the second array based on the index of the hash
      diff.forEach((hash) => {
        const index = initialObjHash?.indexOf(hash);
        newArray.push(initialObj[index]);
      });
      return newArray;
    }

    return newProps ? <WrappedComponent {...newProps} /> : <></>;
  };
};

export default withRole;
