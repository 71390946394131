import moment, { Moment } from "moment";
import Config from "./Config";
import { PeriodicityNrOfDays, ResolutionBreakPoints } from "./Constants";
import { Vocabulary } from "./Vocabulary";
import { EMeetingStatus, ETaskStatus } from "./Enums";
import { TaskTypeModel } from "./Models";
import { urlEnum } from "./UrlEnum";
import { toast } from "react-toastify";
import hash from "object-hash";

/**
 *
 * @param model
 * @param propertiesArray
 * @returns
 */
export const generateFormData = (model: any) => {
  const formData = new FormData();
  for (const property in model) {
    if (typeof model[property] === "boolean")
      formData.append(property, model[property]);
    else if (model[property] === null) {
      continue;
    } else {
      formData.append(property, model[property] ? model[property] : null);
    }
  }

  return formData;
};

/**
 *
 */
export const logout = () => {
  localStorage.removeItem("userId");
  localStorage.removeItem("lastName");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("access_token");
  localStorage.removeItem("userRoles");
  localStorage.removeItem("userName");
};

/**
 *
 * @param basicUrl
 * @param otherParams
 * @returns
 */
export const createURLFromParams = (basicUrl: string, otherParams: any) => {
  let url = basicUrl;
  let firstIteration = true;
  for (const [key, value] of Object.entries(otherParams)) {
    if (value && value.toString() !== "") {
      url += `${firstIteration ? "" : ","}${key}=${value}`;
      firstIteration = false;
    }
  }
  return url;
};

/**
 *
 * @param src
 * @returns
 */
export const checkIfSrcBelongsToPdf = (src: string) => {
  return src.endsWith(".pdf");
};

/**
 * test if file is image
 * @param file
 * @returns
 */
export const isImage = (file: any) => {
  const ext = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
  return ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "gif";
};

/**
 *
 * @param file
 * @param type
 * @returns
 */
export const checkFileType = (file: any, type: string) => {
  const ext = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
  return ext === type;
};

/**
 *
 */
export const checkIfVariableIsString = (variable: any) => {
  return typeof variable === "string" || variable instanceof String;
};

/**
 *
 * @param array
 * @param id
 * @returns
 */
export const returnEntityIfExistsInArray = (array: any, id: any) => {
  if (!id) return null;
  return array.find((entity: any) => entity.id === id) || null;
};

/**
 *
 * @param date
 * @param format
 * @returns
 */
export const formatStringDate = (date: string | Date | Moment, format: any) => {
  if (!date) return "";
  return moment(date).format(format);
};

/**
 *
 * @param data
 * @param callback
 */
export const predictTasksAndMeetings = (
  data: any,
  callback: any,
  dailyCalendar?: boolean
) => {
  //filter only tasks or meetings that are repetitive and are not finished
  const newTasks = data.filter((task: any) => {
    return (
      task?.periodicity?.nrOfDays !== 0 &&
      task.status?.name !== ETaskStatus.STATUS_FINISHED &&
      task.status?.name !== EMeetingStatus.MEETING_STATUS_COMPLETED
    );
  });
  //create new Tasks
  const futureTasksPredicted: Array<any> = [];
  newTasks.forEach((task: any) => {
    let pastTask: any = Object.assign({}, task);
    const nrOfDailyTasksToPredict = dailyCalendar
      ? 7
      : Config.nrOfDailyTasksToPredict;
    switch (task.periodicity && task.periodicity?.nrOfDays) {
      case PeriodicityNrOfDays.PERIODICITY_DAILY:
        for (let i = 1; i <= nrOfDailyTasksToPredict; i++) {
          const newTask = createNewTaskOrMeeting(
            pastTask,
            PeriodicityNrOfDays.PERIODICITY_DAILY
          );
          futureTasksPredicted.push(newTask);
          pastTask = { ...newTask };
        }
        break;
      case PeriodicityNrOfDays.PERIODICITY_WEEKLY:
        if (dailyCalendar) break;
        for (let i = 1; i <= Config.nrOfWeeklyTasksToPredict; i++) {
          const newTask = createNewTaskOrMeeting(
            pastTask,
            PeriodicityNrOfDays.PERIODICITY_WEEKLY
          );
          futureTasksPredicted.push(newTask);
          pastTask = { ...newTask };
        }
        break;
      case PeriodicityNrOfDays.PERIODICITY_MONTHLY:
        if (dailyCalendar) break;
        for (let i = 1; i <= Config.nrOfMonthlyTasksToPredict; i++) {
          const newTask = createNewTaskOrMeeting(
            pastTask,
            PeriodicityNrOfDays.PERIODICITY_MONTHLY
          );
          futureTasksPredicted.push(newTask);
          pastTask = { ...newTask };
        }
        break;
      default:
        break;
    }
  });
  callback(futureTasksPredicted);
};

/**
 *
 * @param event
 * @returns
 */
export const checkIfMeeting = (event: any) => {
  return event.evaluation !== undefined;
};

/**
 *
 * @param pastTask
 * @param nrOfDays
 * @returns
 */
const createNewTaskOrMeeting = (pastTask: any, nrOfDays: number) => {
  const newTask = Object.assign({}, pastTask);
  newTask.start = changeWeekendDayToWeekDay(
    moment(pastTask.start).add(nrOfDays, "days"),
    newTask.skipWeekend,
    pastTask.periodicity?.nrOfDays === PeriodicityNrOfDays.PERIODICITY_DAILY
  ).format(
    checkIfMeeting(pastTask)
      ? Config.momentUSDateAndTimeFormat
      : Config.momentUSDateFormat
  );
  newTask.end = changeWeekendDayToWeekDay(
    moment(pastTask.end).add(nrOfDays, "days"),
    newTask.skipWeekend,
    pastTask.periodicity?.nrOfDays === PeriodicityNrOfDays.PERIODICITY_DAILY
  ).format(
    checkIfMeeting(pastTask)
      ? Config.momentUSDateAndTimeFormat
      : Config.momentUSDateFormat
  );
  //check if task or meeting is in weekend and skip it
  newTask.backColor = "#CDC2AE";
  newTask.disabled = true;
  return newTask;
};

/**
 *
 * @param obj
 * @param property
 * @returns
 */
export const checkIfObjectIsEmptyOrHasProperty = (
  obj: any,
  property: string
) => {
  if (checkIfObjectIsEmpty(obj)) return true;
  return Object.prototype.hasOwnProperty.call(obj, property);
};

/**
 *
 * @param obj
 * @returns
 */
export const checkIfObjectIsEmpty = (obj: any) => {
  return Object.keys(obj).length === 0;
};

/**
 *
 * @param date1
 * @param date2
 * @returns
 */
export const calculateDifferenceBetweenTwoDates = (date1: any, date2: any) => {
  const date1Moment = moment(date1);
  const date2Moment = moment(date2);
  const diff = date2Moment.diff(date1Moment, "days");
  return Math.abs(diff);
};

/**
 *
 * @param value
 * @returns
 */
export const convertToBoolean = (value: string) => {
  return value === "true" ? true : false;
};

/**
 *
 * @param task
 * @returns
 */
export const checkIfTaskIsLate = (task: any) => {
  //check if task end date is before today and status is not finished
  if (!task.status || task.status.name === ETaskStatus.STATUS_CUSTOM)
    return false;
  const endDate = new Date(task.end);
  const finishDate = task.finishDate ? new Date(task.finishDate) : null;
  return (
    (moment(task.end).isBefore(moment().subtract(1, "days").endOf("day")) &&
      task.status?.name !== ETaskStatus.STATUS_FINISHED) ||
    (finishDate && finishDate > endDate)
  );
};

/**
 *
 * @param userId
 * @returns
 */
export const checkIfItsAuthenticatedUser = (userId: any) => {
  const currentUserId = localStorage.getItem("userId");
  return currentUserId === userId;
};

/**
 * Read a cookie
 * @param {String} name
 */
export const readCookie = (name: string) => {
  const n = `${name}=`;
  const index = document.cookie.indexOf(n);
  if (index < 0) {
    return "";
  }
  let cookieValue = document.cookie.substring(index + n.length);
  const end = cookieValue.indexOf(";");
  if (end >= 0) {
    cookieValue = cookieValue.substring(0, end);
  }
  return cookieValue;
};

/**
 *
 * @param meeting
 * @returns
 */
export const checkIfUserShouldSeeMeetingDetails = (meeting: any) => {
  const userId = localStorage.getItem("userId");
  if (meeting.type.translation === Vocabulary.open) return true;
  if (meeting?.responsible?.id === userId) return true;
  if (meeting?.assignedUsers?.some((user: any) => user.id === userId))
    return true;
  return false;
};

/**
 *
 * @param entityStatus
 * @param status
 * @returns
 */
export const checkEntityStatus = (entityStatus: string, status: string) => {
  if (entityStatus === status) return true;
  return false;
};

/**
 *
 * @param users
 * @returns
 */
export const mapUserListToChatSuggestions = (users: any) => {
  return users?.map((participant: any) => {
    return {
      text: `${participant.user.lastName || ""} ${
        participant.user.firstName || ""
      }`,
      value: `${participant.user.lastName || ""} ${
        participant.user.firstName || ""
      }`,
      url: "",
    };
  });
};

/**
 *
 * @param str
 * @returns
 */
export const replaceAllUnescapedChars = (str: string) => {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")
    .replace(/ /g, "&nbsp;");
};

/**
 *
 * @param str
 * @returns
 */
export const restoreAllEscapedChars = (str: string) => {
  return str
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")
    .replace(/&nbsp;/g, " ");
};

/**
 *
 * @param html
 * @returns
 */
export const sanitizeHtml = (html: string): string => {
  // Create a DOMParser to parse the HTML string
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Remove script elements
  const scriptElements = doc.querySelectorAll("script");
  scriptElements.forEach((script) => {
    script.parentNode?.removeChild(script);
  });

  // Remove link elements (including their attributes)
  const linkElements = doc.querySelectorAll("link");
  linkElements.forEach((link) => {
    link.parentNode?.removeChild(link);
  });

  // Serialize the cleaned DOM back to a string
  const cleanedHtml = new XMLSerializer().serializeToString(doc);

  return cleanedHtml;
};

/**
 *
 * @param htmlContent
 * @returns
 */
export const extractContentFromPastedContent = (htmlContent: string) => {
  const span = document.createElement("span");
  span.innerHTML = htmlContent;
  return span.textContent || span.innerText;
};

/**
 * Check if date is weekend and if so, change it to next week day for sunday, and previous week day for saturday.
 * If skipWeekend is true, change it to monday.
 */
export const changeWeekendDayToWeekDay = (
  date: Moment,
  skipWeekend: boolean,
  daily?: boolean
) => {
  switch (date.day()) {
    case 0: //sunday
      return date.add(1, "days");
    case 6: //saturday
      return skipWeekend || daily
        ? date.add(2, "days")
        : date.subtract(1, "days");
    default:
      return date;
  }
};

/**
 *
 * @param types
 * @param translation
 * @returns
 */
export const checkTaskType = (types: TaskTypeModel[], translation: string) => {
  if (!types || types.length === 0) return false;
  return types.find((type: TaskTypeModel) => type.translation === translation);
};

/**
 *
 * @param name
 * @param value
 * @param days
 */
export const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Set expiration time
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};`;
};

/**
 * checks if device is mobile
 * @returns
 */
export const isMobile = () => {
  return document.body.offsetWidth <= ResolutionBreakPoints.sm;
};

/**
 * check if device is tablet
 * @returns
 */
export const isTablet = () => {
  return document.body.offsetWidth <= ResolutionBreakPoints.lg;
};

/**
 *
 * @param meeting
 * @returns
 */
export const checkIfUserIsPartOfMeeting = (meeting: any) => {
  const userId = localStorage.getItem("userId");
  if (meeting?.responsible?.id === userId) return true;
  if (meeting?.assignedUsers?.some((user: any) => user.id === userId))
    return true;
  return false;
};

/**
 *
 * @param nrOfElements
 */
export const calculateNumberOfPages = (
  nrOfElements: any,
  nrOfItemsPerPage: any
) => {
  const nEl = nrOfElements / nrOfItemsPerPage;
  const nrOfPages = nrOfElements % nrOfItemsPerPage;
  if (nrOfPages === 0) {
    return nEl;
  }
  return Math.trunc(nEl) + 1;
};

export function disableAllFields() {
  const previewDialogContent = document.getElementById("previewDialogContent");
  if (!previewDialogContent) return;

  const fullInputHTML = previewDialogContent.getElementsByTagName("input");
  if (fullInputHTML.length <= 0) return;

  const fullDatePickerHTLM =
    previewDialogContent.getElementsByClassName("disableWrapper");

  const fullSelectHTML = previewDialogContent.getElementsByTagName("select");
  const fullLabelHTML = previewDialogContent.getElementsByTagName("label");
  const fieldSets = previewDialogContent.getElementsByTagName("fieldset");
  const fullTextAreaHTML =
    previewDialogContent.getElementsByTagName("textarea");
  let index = 0;

  if (fullDatePickerHTLM.length > 0)
    for (index = 0; index < fullDatePickerHTLM.length; index++) {
      fullDatePickerHTLM[index].addEventListener(
        "click",
        (e) => {
          e.stopImmediatePropagation();
          e.stopPropagation();
        },
        true
      );
      fullDatePickerHTLM[index].addEventListener(
        "mousedown",
        (e) => {
          e.stopImmediatePropagation();
          e.stopPropagation();
        },
        true
      );
    }

  for (index = 0; index < fullTextAreaHTML.length; index++)
    fullTextAreaHTML[index].setAttribute("disabled", "disabled");

  for (index = 0; index < fieldSets.length; index++)
    if (!fieldSets[index].getAttribute("aria-hidden"))
      fieldSets[index].style.border = "none";

  for (index = 0; index < fullInputHTML.length; index++) {
    if (fullInputHTML[index].id === "CautareNume")
      fullInputHTML[index].removeAttribute("disabled");
    else {
      fullInputHTML[index].setAttribute("disabled", "disabled");
    }
  }

  for (index = 0; index < fullSelectHTML.length; index++) {
    if (fullSelectHTML[index].id !== "roles[0].FK_compartmentId")
      fullSelectHTML[index].setAttribute("disabled", "disabled");
  }

  for (index = 0; index < fullLabelHTML.length; index++)
    fullLabelHTML[index].setAttribute("disabled", "disabled");
}

/**
 *
 * @param text
 * @returns
 */
export const getNumberOfLinesFromText = (text: string | null | undefined) => {
  if (text) {
    const splittedText = text.split(/\r\n|\r|\n/);
    let numLines = splittedText.length;
    splittedText.forEach((line: string) => {
      if (line.length > 90) {
        numLines += line.length / 90;
      }
    });
    return numLines;
  }
  return 2;
};

/**
 *
 * @param text
 * @param length
 * @returns
 */
export const renderSubstring = (text: string, length: number) => {
  if (!text) return "";
  return text.slice(0, length) + (text.length > length ? "..." : "");
};

/**
 *
 * @param file
 */
export const downloadFile = (file: any) => {
  fetch(`${urlEnum.getFilesAndMedia}${file?.path}`)
    .then((response) => response.blob())
    .then((response) => {
      if (response.size === 0) {
        toast.warn(Vocabulary.missingFile);
        return;
      }
      const href = URL.createObjectURL(response);
      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", file.name); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

/**
 * these doesn't compare two arrays of objects
 * @param prop1
 * @param prop2
 * @returns
 */
export const handlePropertiesEqual = (prop1: any, prop2: any) => {
  // Check if both properties are of the same type
  if (typeof prop1 !== typeof prop2) {
    return false;
  }
  // Check for primitive types (number, string, boolean)
  if (typeof prop1 !== "object" || prop2 === null) {
    return prop1 === prop2;
  }

  //check if its array
  if (Array.isArray(prop1)) {
    const initialObjHash = prop1?.map((obj: any) => hash(obj));
    const objHash = prop2?.map((obj: any) => hash(obj));
    //compare hash arrays
    if (initialObjHash.length !== objHash.length) return false;
    return initialObjHash.every((element: any) => objHash.includes(element));
  }

  if (Object.keys(prop1).length !== Object.keys(prop2).length) return false;
  for (const key in prop2) {
    if (prop1[key] !== prop2[key]) return false;
  }

  return true;
};
