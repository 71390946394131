import DatePickersIntervalForFilters from "../DatePickersIntervalForFilters";
import FilterContainer from "../FilterContainer";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Category, Class, People, Repeat } from "@mui/icons-material";
import { Autocomplete, Chip, TextField, Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import { Controller } from "react-hook-form";
import { useEffect } from "react";
import moment from "moment";

type Props = {
  methods: any;
  filtersData: any;
};

function MeetingsFilters(props: Props) {
  const { methods, filtersData } = props;
  const customSearchParams = useCustomSearchParam();

  /**
   *
   * @param key
   * @param value
   */
  const handleChangeFilters = (key: string, value: any) => {
    if (value !== null && value !== undefined) {
      //if value is an array then create a string from with, whit values separated from comma
      if (Array.isArray(value)) {
        value = value?.map((x) => x.id).join(";");
      }
      customSearchParams.addParamToQueryString(key, value);
    } else {
      //check if parameter deleted is type, then delete selected project as well
      customSearchParams.removeParamFromQueryString([key]);
    }
    window.scrollTo(0, 0);
  };

  /**
   *
   */
  const resetFilters = () => {
    methods.reset();
    customSearchParams.removeParamFromQueryString([
      "start",
      "end",
      "search",
      "periodicity",
      "type",
      "department",
      "search",
      "user",
    ]);
  };

  /**
   *
   * @param property
   * @returns
   */
  const getOptionsArray = (property: string) => {
    switch (property) {
      case "type":
        return filtersData?.types;
      case "periodicity":
        return filtersData?.periodicities;
      case "department":
        return filtersData?.departments;
      case "user":
        return filtersData?.users;
      default:
        return [];
    }
  };

  /**
   *
   * @param methods
   */
  const resetFilterData = () => {
    const urlParams: any = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParams) {
      const stringValuesArray = value.split(";");
      const objectValuesArray: any = [];
      switch (key) {
        case "periodicity":
        case "department":
          //for each value in array, find the object in the options array and add it to the selectedValues object
          stringValuesArray.forEach((value: any) => {
            const newValue = getOptionsArray(key).find((option: any) => {
              return option.id === value;
            });
            objectValuesArray.push(newValue);
          });
          methods.setValue(key, objectValuesArray);
          break;
        case "user":
        case "type":
          //for each value in array, find the object in the options array and add it to the selectedValues object
          stringValuesArray.forEach((value: any) => {
            const newValue = getOptionsArray(key).find((option: any) => {
              return option.id === value;
            });
            objectValuesArray.push(newValue);
          });
          methods.setValue(key, objectValuesArray[0]);
          break;
        case "start":
        case "end":
          methods.setValue(key, moment(value));
          break;
        default:
          methods.setValue(key, value);
          break;
      }
    }
  };

  /**
   *
   */
  useEffect(() => {
    if (filtersData?.types?.length > 0) {
      resetFilterData();
    }
  }, [filtersData]);

  return (
    <FilterContainer
      filters={
        <>
          <div className={genericStyles.filter}>
            <People color="secondary" sx={{ marginRight: "5px" }} />
            <Controller
              name="user"
              control={methods.control}
              defaultValue={null}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  noOptionsText={Vocabulary.noResultsFound}
                  {...field}
                  className={genericStyles.fullWidthElement}
                  id={"user"}
                  options={filtersData?.users || []}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) =>
                    `${
                      option
                        ? `${option?.lastName ? option?.lastName : ""} ${
                            option?.firstName ? option?.firstName : ""
                          }`
                        : ""
                    }`
                  }
                  onChange={(event, value) => {
                    field.onChange(value);
                    handleChangeFilters("user", value?.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.chooseUser}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </div>
          <div className={genericStyles.filter}>
            <Repeat color="secondary" sx={{ marginRight: "5px" }} />
            <Controller
              name="periodicity"
              control={methods.control}
              defaultValue={[]}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  noOptionsText={Vocabulary.noResultsFound}
                  {...field}
                  multiple
                  id={"periodicity"}
                  className={genericStyles.fullWidthElement}
                  options={filtersData?.periodicities || []}
                  getOptionLabel={(option: any) => option.translation}
                  isOptionEqualToValue={(option: any, value: any) => {
                    const valueId = value.id ? value.id : value;
                    return option.id === valueId;
                  }}
                  onChange={(event: any, value: any) => {
                    field.onChange(value);
                    handleChangeFilters(
                      "periodicity",
                      value.length > 0 ? value : null
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.choosePeriodicity}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </div>
          <div className={genericStyles.filter}>
            <Class color="secondary" sx={{ marginRight: "5px" }} />
            <Controller
              name="type"
              defaultValue={null}
              control={methods.control}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  noOptionsText={Vocabulary.noResultsFound}
                  className={genericStyles.fullWidthElement}
                  id={"type"}
                  options={filtersData?.types || []}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option: any) => option?.translation || ""}
                  onChange={(event, value) => {
                    field.onChange(value);
                    handleChangeFilters("type", value?.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.chooseType}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </div>
          <div className={genericStyles.filter}>
            <Category color="secondary" sx={{ marginRight: "5px" }} />
            <Controller
              name="department"
              control={methods.control}
              defaultValue={[]}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  multiple
                  noOptionsText={Vocabulary.noResultsFound}
                  id={"department"}
                  className={genericStyles.fullWidthElement}
                  options={filtersData?.departments || []}
                  getOptionLabel={(option: any) => option.name}
                  isOptionEqualToValue={(option: any, value: any) => {
                    const valueId = value.id ? value.id : value;
                    return option.id === valueId;
                  }}
                  onChange={(event: any, value: any) => {
                    field.onChange(value);
                    handleChangeFilters(
                      "department",
                      value.length > 0 ? value : null
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.department}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </div>
          <DatePickersIntervalForFilters
            methods={methods}
            handleChangeFilters={handleChangeFilters}
          />
          <div className={genericStyles.filter}>
            <fieldset className={genericStyles.fieldSet}>
              <legend>{Vocabulary.explanations}</legend>
              <div className={genericStyles.legendItem}>
                <Typography>{Vocabulary.finishedMeeting}: </Typography>
                <Chip className={genericStyles.finishedMeetingLegend} />
              </div>
            </fieldset>
          </div>
        </>
      }
      resetFilters={resetFilters}
    />
  );
}

export default MeetingsFilters;
