/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState } from "react";

export const GlobalContext = createContext({
  taskId: "",
  setTaskId: (value: string) => {},
  projectSelected: null,
  setProjectSelected: (value: any) => {},
  meetingSelectedId: null,
  setMeetingSelectedId: (value: any) => {},
});

export const GlobalContextProvider = (props: any) => {
  /**
   *
   * @param taskId
   */
  const setTaskId = (taskId: string) => {
    setState({ ...state, taskId: taskId });
  };

  /**
   *
   * @param projectSelected
   */
  const setProjectSelected = (projectSelected: any) => {
    setState({ ...state, projectSelected: projectSelected });
  };

  /**
   *
   * @param meetingSelectedId
   */
  const setMeetingSelectedId = (meetingSelectedId: any) => {
    setState({ ...state, meetingSelectedId: meetingSelectedId });
  };

  const initState = {
    taskId: "",
    setTaskId: setTaskId,
    projectSelected: null,
    setProjectSelected: setProjectSelected,
    meetingSelectedId: null,
    setMeetingSelectedId: setMeetingSelectedId,
  };

  const [state, setState] = useState(initState);

  return (
    <GlobalContext.Provider value={state}>
      {props.children}
    </GlobalContext.Provider>
  );
};
