import { useContext, useEffect, useRef, useState } from "react";
import {
  Theme,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import { pdfjs, Document as PdfDocument, Page } from "react-pdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import { getData } from "../../Services/getData";
import { Vocabulary } from "../../Utils/Vocabulary";
import genericStyles from "../../Styles/genericStyles.module.css";
import { CustomThemeContext } from "../../Context/CustomThemeContext";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type PreviewDocumentProps = {
  open: boolean;
  title: string;
  onClose: (emailSent: boolean) => void;
  htmlContent: string;
  url?: string;
  customTopMenu?: JSX.Element | null | undefined;
};

const useStyles = makeStyles((theme: Theme) => ({
  loadingWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  actionsWrapper: {
    background: "#7D8FB3",
    zIndex: 100,
  },
  overflowHidden: {
    overflow: "hidden",
  },
  pdfPreviewPage: {
    margin: "10px 0px 10px 10px",
    border: "1px solid #ccc",
  },
  dialogContent: {
    background: "#eeeeee !important",
  },
}));

function PreviewPDF(props: PreviewDocumentProps) {
  const { url, htmlContent, open, onClose, title } = props;
  const classes = useStyles(props);
  const contentRef: any = useRef(null);
  const scaleAmount = 0.5;
  const themeContext: any = useContext(CustomThemeContext);
  const [state, setState] = useState({
    numPages: 0,
    pdfPath: null,
    templateLoaded: 0,
    pageNumber: 1,
    scale: 1.75,
    showEmail: false,
    emailSent: false,
    addImage: false,
  });
  const [content, setContent] = useState(htmlContent);

  /**
   *
   */
  useEffect(() => {
    if (url && url !== "") {
      getData(url).then((response: any) => {
        if (response?.errors) return;
        setState({
          ...state,
          pdfPath: response?.data,
          templateLoaded: 1,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    if (!content) {
      setContent(htmlContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlContent]);

  /**
   *
   * @param data
   */
  function onDocumentLoadSuccess(data: { numPages: any }) {
    const { numPages } = data;
    setState({ ...state, numPages });
  }

  /**
   *
   */
  function zoomIn() {
    setState({ ...state, scale: state.scale + scaleAmount });
  }

  /**
   *
   */
  function zoomOut() {
    setState({ ...state, scale: state.scale - scaleAmount });
  }

  /**
   *
   */
  function renderPages() {
    const pages: Array<any> = [];
    for (let i = 0; i < state.numPages; i++) {
      pages.push(
        <Page
          renderTextLayer={false}
          renderAnnotationLayer={false}
          className={classes.pdfPreviewPage}
          key={`page_${i + 1}`}
          scale={state.scale}
          pageNumber={i + 1}
        />
      );
    }
    return pages;
  }

  /**
   *
   */
  const downloadPDF = () => {
    if (url)
      fetch(url)
        .then((response) => response.blob())
        .then((response) => {
          const blob = new Blob([response], { type: "application/pdf" });
          const link = document.createElement("a");
          link.download = title;
          link.href = URL.createObjectURL(blob);
          link.click();
        });
  };

  return (
    <Dialog
      style={{ zIndex: 2000 }}
      maxWidth="xl"
      classes={{ paper: classes.overflowHidden }}
      open={open}
      onClose={() => onClose(state.emailSent)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        className={genericStyles.fullWidthElement}
      >
        <div style={{ flexGrow: 2 }}>{title}</div>
        <div>{props.customTopMenu && props.customTopMenu}</div>
      </DialogTitle>
      <DialogContent ref={contentRef} className={classes.dialogContent}>
        {url ? (
          <>
            {!state.templateLoaded ? (
              <div className={classes.loadingWrapper}>
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <PdfDocument file={url} onLoadSuccess={onDocumentLoadSuccess}>
                {renderPages()}
              </PdfDocument>
            )}
          </>
        ) : (
          Vocabulary.loading
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsWrapper }}>
        <IconButton onClick={downloadPDF}>
          <GetAppIcon />
        </IconButton>
        <IconButton disabled={state.scale <= 0.3} onClick={zoomOut}>
          <ZoomOutIcon />
        </IconButton>
        <IconButton disabled={state.scale >= 2} onClick={zoomIn}>
          <ZoomInIcon />
        </IconButton>
        <Button
          onClick={() => props.onClose(state.emailSent)}
          style={{ background: themeContext.theme?.palette.cancel?.main }}
        >
          {Vocabulary.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PreviewPDF;
