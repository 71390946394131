import { Avatar, Tooltip, Typography } from "@mui/material";
import { MessageModel } from "../../Utils/Models";
import { foldersNames, urlEnum } from "../../Utils/UrlEnum";
import styles from "../../Styles/messageChanel.module.css";
import {
  checkIfItsAuthenticatedUser,
  formatStringDate,
} from "../../Utils/Utils";
import Config from "../../Utils/Config";
import { useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import WYSIWYGEditor from "./WYSIWYGEditor";

type Props = {
  messages: MessageModel[];
  shouldScrollPage: boolean;
};

export default function Messages(props: Props) {
  const { messages, shouldScrollPage } = props;
  const toolbarOptions = {
    options: ["emoji"],
  };
  /**
   *
   */
  useEffect(() => {
    if (!shouldScrollPage) {
      const messageBody: any = document.querySelector("#messagesContainer");
      if (messageBody)
        messageBody.scrollTop =
          messageBody?.scrollHeight - messageBody?.clientHeight;
    }
  }, [messages, shouldScrollPage]);

  return (
    <div className={`${styles.messageContainerPadding}`}>
      {messages.length &&
        messages?.map((message: MessageModel, index: number) =>
          checkIfItsAuthenticatedUser(message.sender?.id) ? (
            <div
              key={index}
              className={`${styles.messageItem} ${styles.authenticatedUserMessageContainer}`}
            >
              <div className={`${styles.authenticatedUserMessages}`}>
                <Typography variant="caption">{`${
                  message.sender?.lastName || ""
                } ${message.sender?.firstName || ""}`}</Typography>
                <WYSIWYGEditor
                  toolbar={toolbarOptions}
                  value={message.message}
                  readOnly={true}
                />
                <Typography align="right" variant="caption">
                  {formatStringDate(
                    message.createdAt,
                    Config.momentEUDateTimeFormat
                  )}
                </Typography>
              </div>
              <Tooltip
                title={`${message.sender?.lastName || ""} ${
                  message.sender?.firstName || ""
                }`}
              >
                <Avatar
                  alt={message.sender?.lastName?.charAt(0).toUpperCase() || ""}
                  src={`${urlEnum.getFilesAndMedia}${foldersNames.usersProfiles}${message.sender?.profilePicture}`}
                />
              </Tooltip>
            </div>
          ) : (
            <div
              key={index}
              className={`${styles.messageItem} ${styles.otherUsersMessageContainer}`}
            >
              <Tooltip
                title={`${message.sender?.lastName || ""} ${
                  message.sender?.firstName || ""
                }`}
              >
                <Avatar
                  alt={message.sender?.lastName?.charAt(0).toUpperCase() || ""}
                  src={`${urlEnum.getFilesAndMedia}${foldersNames.usersProfiles}${message.sender?.profilePicture}`}
                />
              </Tooltip>
              <div className={`${styles.otherUsersMessages}`}>
                <Typography variant="caption">{`${
                  message.sender?.lastName || ""
                } ${message.sender?.firstName || ""}`}</Typography>
                <WYSIWYGEditor
                  toolbar={toolbarOptions}
                  value={message.message}
                  readOnly={true}
                />
                <Typography align="right" variant="caption">
                  {formatStringDate(
                    message.createdAt,
                    Config.momentEUDateTimeFormat
                  )}
                </Typography>
              </div>
            </div>
          )
        )}
    </div>
  );
}
