import FileExplorerMin from "../Components/Widgets/FileExplorerMin";
import genericStyles from "../Styles/genericStyles.module.css";
import { ComponentsNames } from "../Utils/Constants";
import { entityPath } from "../Utils/UrlEnum";

export default function Media() {
  return (
    <div className={genericStyles.mainContainer}>
      <div className={genericStyles.fullWidthElement}>
        <FileExplorerMin
          name={ComponentsNames.FileExplorerMin}
          baseFolderPath={entityPath.media}
        />
      </div>
    </div>
  );
}
