import { useContext, useEffect, useState } from "react";
import MUIDataTableCustomStyle from "../MUIDataTable/MUIDataTableCustomStyle";
import MeetingsFilters from "./MeetingsFilters";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { AutocompleteDataForMeetings, MeetingsState } from "../../Utils/Models";
import { CommonTableOptions } from "../../Utils/Constants";
import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Add, Delete, Edit, Preview } from "@mui/icons-material";
import ApprovalModal from "../ApprovalModal";
import QueryStringParser from "../QueryStringParser";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { GlobalContext } from "../../Context/GlobalContext";
import moment from "moment";
import Config from "../../Utils/Config";
import { deleteData } from "../../Services/deleteData";
import {
  checkEntityStatus,
  checkIfUserShouldSeeMeetingDetails,
  checkIfVariableIsString,
  createURLFromParams,
} from "../../Utils/Utils";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import withRole from "../../Hooks/withRole";
import GenericModal from "../GenericModal";
import MeetingSummary from "./MeetingSummary";
import { EMeetingStatus } from "../../Utils/Enums";
import clsx from "clsx";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

type Props = {
  name: string;
  shouldCRUDMeeting?: boolean;
};

function MeetingsList(props: Props) {
  const authenticatedUser = localStorage.getItem("userId");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalSummary, setOpenModalSummary] = useState<boolean>(false);
  const [filtersData, setFiltersData] = useState<AutocompleteDataForMeetings>(
    new AutocompleteDataForMeetings()
  );
  const [state, setState] = useState<MeetingsState>(new MeetingsState());
  const refreshContext = useContext(RefreshDataContext);
  const globalContext = useContext(GlobalContext);
  const customSearchParams = useCustomSearchParam();
  const location = useLocation();
  const meetingsFiltersValidationSchema = yup
    .object()
    .shape({
      periodicity: yup.string(),
      type: yup.string(),
      department: yup.string(),
      start: yup.object(),
      end: yup.object(),
      user: yup.object(),
    })
    .required();
  const methods = useForm({
    defaultValues: {
      start: moment().startOf("month").subtract(2, "months"),
      end: moment().endOf("month"),
    },
    resolver: yupResolver(meetingsFiltersValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  /**
   *
   * @param id
   */
  const handleEditMeeting = (id: string) => {
    globalContext.setMeetingSelectedId(id);
  };

  /**
   *
   */
  const toggleModalState = () => {
    setOpenModal(!openModal);
  };

  const meetingsHeader = [
    {
      label: Vocabulary.crtNo,
      name: "",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return state.page * state.perPage + meta.rowIndex + 1;
        },
      },
    },
    {
      label: Vocabulary.fancyName,
      name: "text",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return `${
            value?.length >= 40 ? `${value?.substring(0, 40)}...` : value
          }`;
        },
      },
    },
    {
      label: Vocabulary.periodicity,
      name: "periodicity",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return value?.translation;
        },
      },
    },
    {
      label: Vocabulary.type,
      name: "type",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return value?.translation;
        },
      },
    },
    {
      label: Vocabulary.startDate,
      name: "start",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return value?.split("T")[0]
            ? moment(value?.split("T")[0]).format(Config.momentEUDateTimeFormat)
            : "";
        },
      },
    },
    {
      label: Vocabulary.aproxDuration,
      name: "duration",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return value?.translation;
        },
      },
    },
    {
      label: Vocabulary.options,
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <ToggleButtonGroup>
              {props.shouldCRUDMeeting ||
              checkIfUserShouldSeeMeetingDetails(state.meetings[rowIndex]) ? (
                <Tooltip
                  title={Vocabulary.edit}
                  className={genericStyles.tooltipEditButton}
                >
                  <ToggleButton
                    value="left"
                    aria-label="left aligned"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      handleEditMeeting(state.meetings[rowIndex].id);
                    }}
                  >
                    {props.shouldCRUDMeeting ? <Edit /> : <Preview />}
                  </ToggleButton>
                </Tooltip>
              ) : null}
              {props.shouldCRUDMeeting ? (
                checkEntityStatus(
                  state.meetings[rowIndex].status.name,
                  EMeetingStatus.MEETING_STATUS_COMPLETED
                ) ? (
                  <ToggleButton
                    onClick={(event: any) => {
                      event.stopPropagation();
                      toggleSummaryModal();
                      setState({
                        ...state,
                        selectedMeeting: state.meetings[rowIndex],
                      });
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    {Vocabulary.summary}
                  </ToggleButton>
                ) : null
              ) : null}
              {props.shouldCRUDMeeting ? (
                <Tooltip
                  title={Vocabulary.delete}
                  className={genericStyles.tooltipDeleteButton}
                >
                  <ToggleButton
                    value="center"
                    aria-label="centered"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      setState({
                        ...state,
                        selectedMeeting: state.meetings[rowIndex],
                      });
                      toggleModalState();
                    }}
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              ) : null}
            </ToggleButtonGroup>
          );
        },
      },
    },
  ];

  /**
   *
   * @param id
   */
  const deleteMeeting = () => {
    deleteData(`${urlEnum.deleteMeeting}/${state.selectedMeeting?.id}`)
      .then((res: any) => {
        refreshContext.setRefresh(true);
        if (res) {
          getMeetingsUsingStateData();
        }
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  /**
   *
   */
  useEffect(() => {
    const promises = [
      getData(urlEnum.getDepartmentsForFilter),
      getData(urlEnum.getMeetingTypesForFilters),
      getData(urlEnum.getTaskPeriodicities),
      getData(
        `${urlEnum.getUsersForFilter}/${authenticatedUser}?shouldGetAll=true`
      ),
    ];
    Promise.all(promises)
      .then((res) => {
        setFiltersData({
          departments: res[0].data,
          types: res[1].data,
          periodicities: res[2].data,
          users: res[3].data,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  const getMeetings = (
    page: number,
    perPage: number,
    search: string | null,
    start?: string,
    end?: string,
    periodicity?: string,
    type?: any,
    department?: string,
    assignedUsers?: any
  ) => {
    const url = createURLFromParams(
      `${
        urlEnum.getMeetings
      }?page=${page}&perPage=${perPage}&start=${start}&end=${end}${
        search ? `&search=${search}` : ""
      }${
        assignedUsers
          ? `&userId=${
              checkIfVariableIsString(assignedUsers)
                ? assignedUsers
                : assignedUsers.id
            }`
          : ""
      }&allParams=`,
      {
        periodicity: periodicity ? periodicity : null,
        type: type ? (checkIfVariableIsString(type) ? type : type.id) : null,
        departments: department ? department : null,
      }
    );

    getData(url).then((res: any) => {
      if (res && res.data)
        setState({
          ...state,
          meetings: res.data?.results,
          count: res.data?.nrResults,
          page: page,
          perPage: perPage,
          search: search,
        });
    });
  };

  /**
   *
   */
  useEffect(() => {
    if (
      refreshContext.refresh &&
      location.pathname.includes(localUrlEnum.meetings)
    ) {
      refreshContext.setRefresh(false);
      getMeetingsUsingStateData();
    }
  }, [refreshContext.refresh]);

  /**
   *
   */
  const getMeetingsUsingStateData = () => {
    getMeetings(
      state.page,
      state.perPage,
      state.search,
      moment(methods.getValues("start"))?.format(Config.momentUSDateFormat),
      moment(methods.getValues("end"))?.format(Config.momentUSDateFormat),
      methods.getValues("periodicity"),
      methods.getValues("type"),
      methods.getValues("department"),
      methods.getValues("user")
    );
  };

  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    return {
      ...CommonTableOptions,
      count: state.count,
      rowsPerPage: state.perPage,
      page: state.page,
      customToolbar: () => {
        return props.shouldCRUDMeeting ? (
          <Button
            variant="contained"
            className={genericStyles.addButton}
            startIcon={<Add />}
            onClick={() => {
              globalContext.setMeetingSelectedId("");
            }}
          >
            {Vocabulary.add}
          </Button>
        ) : null;
      },
      onChangePage: (page: any) => {
        customSearchParams.addParamToQueryString("page", page);
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        customSearchParams.addParamToQueryString("perPage", numberOfRows);
        window.scrollTo(0, 0);
      },
      setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
        return {
          className: clsx({
            [genericStyles.finishedMeeting]:
              row[6] === Vocabulary.finishedMeeting,
          }),
        };
      },
      onRowClick: (rowData: any, rowMeta: any) => {
        if (
          props.shouldCRUDMeeting ||
          checkIfUserShouldSeeMeetingDetails(state.meetings[rowMeta.dataIndex])
        ) {
          handleEditMeeting(state.meetings[rowMeta.dataIndex].id);
        }
      },
    };
  };

  /**
   *
   */
  const toggleSummaryModal = () => {
    setOpenModalSummary(!openModalSummary);
  };

  return (
    <div className={genericStyles.mainContainer}>
      <QueryStringParser
        requestFunction={(data: any) => {
          getMeetings(
            data.page ? parseInt(data.page) : state.page,
            data.perPage ? parseInt(data.perPage) : state.perPage,
            data.search,
            data.start
              ? data.start
              : moment(methods.getValues("start")).format(
                  Config.momentEUDateFormat
                ),
            data.end
              ? data.end
              : moment(methods.getValues("end")).format(
                  Config.momentEUDateFormat
                ),
            data.periodicity,
            data.type,
            data.department,
            data.user
          );
        }}
      />

      <MeetingsFilters methods={methods} filtersData={filtersData} />
      <div className={genericStyles.fullWidthElement}>
        <MUIDataTableCustomStyle
          title={Vocabulary.meetingsList}
          data={state.meetings}
          columns={meetingsHeader}
          options={getTableOptions()}
        />
      </div>

      <GenericModal
        maxWidth={"lg"}
        onClose={toggleSummaryModal}
        open={openModalSummary}
        children={<MeetingSummary meeting={state.selectedMeeting} />}
        title={`${Vocabulary.summary} ${
          state.selectedMeeting?.text &&
          state.selectedMeeting?.text?.length >= 40
            ? `${state.selectedMeeting?.text?.substring(0, 70)}...`
            : state.selectedMeeting?.text
        }
        `}
      />
      <ApprovalModal
        handleApproveAction={deleteMeeting}
        openModal={openModal}
        handleClose={toggleModalState}
      />
    </div>
  );
}

export default withRole(MeetingsList);
