import { useState } from "react";
import DynamicTabs from "../Components/DynamicTabs";
import genericStyles from "../Styles/genericStyles.module.css";
import CustomTabPanel from "../Components/CustomTabPanel";
import TaskStatuses from "../Components/TaskStatuses/TaskStatuses";
import StylesEditor from "../Components/StylesEditor";
import withRole from "../Hooks/withRole";
import {
  AppSettingsAdminTabs,
  AppSettingsDesignerHeadTabs,
} from "../Utils/Constants";
import GenericSettings from "../Components/GenericSettings";

type Props = {
  shouldRenderTabs?: boolean;
  shouldRenderSearchBar?: boolean;
};

function AppSettings(props: Props) {
  const { shouldRenderTabs } = props;
  const [tabValue, setTabValue] = useState(0);

  return (
    <>
      <div className={genericStyles.header}>
        <DynamicTabs
          tabValue={tabValue}
          handleChange={(tabIndex: number) => setTabValue(tabIndex)}
          labels={
            shouldRenderTabs
              ? AppSettingsAdminTabs
              : AppSettingsDesignerHeadTabs
          }
        />
      </div>
      <CustomTabPanel value={tabValue} index={0}>
        <StylesEditor />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <GenericSettings />
      </CustomTabPanel>
      {shouldRenderTabs && (
        <CustomTabPanel value={tabValue} index={2}>
          <TaskStatuses />
        </CustomTabPanel>
      )}
    </>
  );
}

export default withRole(AppSettings);
