import { useState, useEffect, useRef, useContext } from "react";
import { DayPilotMonth, DayPilot } from "@daypilot/daypilot-lite-react";
import Config from "../../Utils/Config";
import { format } from "date-fns";
import "../../Styles/monthlyCalendar.css";
import { ComponentsNames, DayPilotRoConfig } from "../../Utils/Constants";
import { postData } from "../../Services/postData";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import {
  CalendarFiltersAutocompleteModel,
  CalendarFiltersModel,
} from "../../Utils/Models";
import { GlobalContext } from "../../Context/GlobalContext";
import CalendarFilters from "../Calendar/CalendarFilters";
import { checkIfMeeting, predictTasksAndMeetings } from "../../Utils/Utils";
import QueryStringParser from "../QueryStringParser";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { useLocation } from "react-router-dom";
import { EMeetingType } from "../../Utils/Enums";

type Props = {
  calendarFilters: CalendarFiltersAutocompleteModel;
};

DayPilot.Locale.register(new DayPilot.Locale("ro-ro", DayPilotRoConfig));

const MonthlyCalendarComponent = (props: Props) => {
  const [shouldRender, setShouldRender] = useState(true);
  const currentUserId = localStorage.getItem("userId");
  const calendarRef: any = useRef(null);
  const [calendarCurrentDate, setCalendarCurrentDate] = useState(
    new DayPilot.Date(new Date())
  );
  const refreshContext = useContext(RefreshDataContext);
  const [calendarFilters, setCalendarFilters] = useState<CalendarFiltersModel>({
    type: null,
    start: new DayPilot.Date(
      calendarCurrentDate.toString(Config.momentUSDateFormatForCalendar)
    )
      .firstDayOfMonth()
      .toString(Config.momentUSDateFormatForCalendar),
    end: new DayPilot.Date(
      calendarCurrentDate
        .addMonths(2)
        .toString(Config.momentUSDateFormatForCalendar)
    )
      .lastDayOfMonth()
      .toString(Config.momentUSDateFormatForCalendar),
    difference: 0,
    user: currentUserId,
  });
  const userRef = useRef(null);
  const location = useLocation();
  const [calendarData, setCalendarData] = useState<any>();
  const [staticCalendarData, setStaticCalendarData] = useState<any>([]);
  const globalContext = useContext(GlobalContext);

  /**
   *
   */
  useEffect(() => {
    getDataForCalendar();
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (
      calendarFilters.difference < 0 ||
      calendarFilters.user !== userRef.current ||
      (refreshContext.refresh &&
        location.pathname.includes(localUrlEnum.calendar))
    ) {
      getDataForCalendar();
      setShouldRender(true);
    } else {
      setShouldRender(false);
      setCalendarData(staticCalendarData);
    }
    refreshContext.setRefresh(false);
  }, [
    calendarFilters.difference,
    calendarFilters.user,
    refreshContext.refresh,
  ]);

  /**
   *
   */
  const getDataForCalendar = () => {
    getData(
      `${urlEnum.getTaskCalendar}/${
        calendarFilters.user?.id
          ? calendarFilters.user?.id
          : calendarFilters.user
      }/${calendarFilters.start}/${calendarFilters.end}/${false}`
    ).then((res: any) => {
      const tasks = res.data.calendarTasks.concat(res.data.calendarMeetings);
      predictTasksAndMeetings(tasks, (futureData: any) => {
        //here i apply static filters
        if (calendarFilters.type !== null) {
          getTasksOrMeetings(calendarFilters.type, [...tasks, ...futureData]);
        } else {
          //set the tasks and meetings
          setCalendarData([...tasks, ...futureData]);
          if (
            staticCalendarData.length === 0 ||
            calendarFilters.user !== userRef.current
          )
            setStaticCalendarData([...tasks, ...futureData]);
        }
      });
    });
  };

  /**
   *
   * @param type
   */
  const getTasksOrMeetings = (type: any, receivedTasks?: any[]) => {
    const clone = receivedTasks
      ? receivedTasks
      : JSON.parse(JSON.stringify(staticCalendarData));
    let tasks = clone;
    if (type === 1) {
      //meeting
      tasks = clone.filter((task: any) => task.evaluation !== undefined);
    } else if (type === 0) {
      //task
      tasks = clone.filter((task: any) => task.budget !== undefined);
    }
    setCalendarData(tasks);
  };

  /**
   *
   */
  useEffect(() => {
    getTasksOrMeetings(calendarFilters.type);
  }, [calendarFilters.type]);

  /**
   *
   */
  useEffect(() => {
    refetchCalendarData();
  }, [calendarData]);

  /**
   *
   */
  useEffect(() => {
    if (calendarCurrentDate) {
      calendarRef.current?.control.update({
        start: calendarCurrentDate,
        events: calendarData,
      });
    }
  }, [calendarCurrentDate, calendarData]);

  /**
   * refetch data when it's needed
   */
  async function refetchCalendarData() {
    calendarRef.current?.control.update({
      start: calendarCurrentDate,
      events: calendarData,
    });
  }

  /**
   *
   * @param {*} args
   */
  const onEventUpdate = async (args: any) => {
    if (args.e.data.disabled) setShouldRender(false);
    else setShouldRender(true);
    if (!args.e.data.disabled) {
      const currentEvent = args.e.data;
      currentEvent.start = format(
        new Date(args.newStart),
        checkIfMeeting(currentEvent)
          ? Config.rangeDateAndTimePickerFormat
          : Config.rangeDatePickerFormat
      );
      currentEvent.end = format(
        new Date(args.newEnd),
        checkIfMeeting(currentEvent)
          ? Config.rangeDateAndTimePickerFormat
          : Config.rangeDatePickerFormat
      );
      postData(
        checkIfMeeting(currentEvent)
          ? urlEnum.createOrUpdateMeeting
          : urlEnum.createTask,
        currentEvent
      ).then((res: any) => {
        if (res) {
          refetchCalendarData();
        }
      });
    }
  };

  /**
   *
   * @param {*} args
   * @returns
   */
  const onEventClick = async (args: any) => {
    if (args.e.data.disabled) setShouldRender(false);
    else setShouldRender(true);
    if (!args.e.data.disabled) {
      if (args.e.data.duration && args.e.data.duration > "") {
        //check if meeting is open or private
        if (args.e.data.type?.name === EMeetingType.MEETING_OPEN) {
          globalContext.setMeetingSelectedId(args.e.data.id);
        }
      } else {
        globalContext.setTaskId(args.e.data.id);
      }
    }
  };

  return (
    <>
      <QueryStringParser
        requestFunction={(data: any) => {
          //check if data is an empty object or only contains tab property then get data from server (
          //it means it;s the first time when the page is loaded or the filters were deleted)
          let { start } = calendarFilters;
          let { end } = calendarFilters;
          const newDiff =
            data.difference !== undefined
              ? parseInt(data.difference)
              : calendarFilters.difference;
          if (newDiff > 0) {
            setShouldRender(false);
          } else {
            setShouldRender(true);
          }
          if (newDiff !== calendarFilters.difference) {
            const newStart = calendarCurrentDate.addMonths(
              newDiff > calendarFilters.difference ? 1 : -1
            );
            setCalendarCurrentDate(newStart);
            start = new DayPilot.Date(
              newStart.toString(Config.momentUSDateFormatForCalendar)
            )
              .firstDayOfMonth()
              .toString(Config.momentUSDateFormatForCalendar);
            end = new DayPilot.Date(
              newStart.toString(Config.momentUSDateFormatForCalendar)
            )
              .lastDayOfMonth()
              .toString(Config.momentUSDateFormatForCalendar);
          }
          userRef.current = calendarFilters.user;
          setCalendarFilters({
            ...calendarFilters,
            difference: newDiff,
            type: data.type !== undefined ? parseInt(data.type) : null,
            user: data.user ? data.user : currentUserId,
            start: start,
            end: end,
          });
        }}
      />
      <CalendarFilters
        threshold={Config.monthlyAndWeeklyTasksCalendarThreshold}
        entityFilterShouldNotBeDisabled={true}
        name={ComponentsNames.CalendarFilters}
        calendarCurrentDate={calendarCurrentDate}
        filtersAutocompleteData={props.calendarFilters}
      />
      <DayPilotMonth
        viewType="Month"
        startDate={calendarCurrentDate}
        timeRangeSelectedHandling={shouldRender ? "Enabled" : "Disabled"}
        eventMoveHandling={shouldRender ? "Update" : "Disabled"}
        onEventResized={onEventUpdate}
        onEventMoved={onEventUpdate}
        eventDeleteHandling={"Disabled"}
        headerDateFormat="DD-MM-yyyy"
        onEventClick={onEventClick}
        ref={calendarRef}
        cellHeight="200"
        locale={"ro-ro"}
        lineSpace={5}
      />
    </>
  );
};

export default MonthlyCalendarComponent;
