import Dropzone from "react-dropzone";
import { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { Vocabulary } from "../Utils/Vocabulary";
import { CloudUpload } from "@mui/icons-material";
import styles from "../Styles/dropzone.module.css";
import Config from "../Utils/Config";
import { toast } from "react-toastify";
import { checkIfSrcBelongsToPdf } from "../Utils/Utils";
import EmptyPdf from "./EmptyPdf";
import { FilesTypes } from "../Utils/Constants";

type Props = {
  onSave: (file: any) => void;
  paths: any[];
  accept?: any;
  maxFiles?: number;
};
export default function DropzoneComponent(props: Props) {
  const [paths, setPaths] = useState<any>(props.paths);
  const [shouldRerender, setShouldRerender] = useState(false);
  const nrOfAttachments = props.maxFiles
    ? props.maxFiles
    : Config.defaultNumberOfAttachments;

  /**
   *
   */
  useEffect(() => {
    //check if the first path exists
    const index = props.paths[0].indexOf("imagePath=");
    const imagePathValue =
      index !== -1
        ? props.paths[0].substring(index + "imagePath=".length)
        : null;
    if (imagePathValue && !shouldRerender) {
      setPaths(props.paths);
    }
  }, [props.paths]);

  /**
   *
   * @param acceptedFiles
   */
  const handleDropFiles = (acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      props.onSave(file);
      setShouldRerender(!shouldRerender);
    });
    const newPaths = acceptedFiles.map((file: any) => {
      if (file.type === FilesTypes.pdf) {
        return {
          typePdf: true,
          src: URL.createObjectURL(file),
        };
      }
      return URL.createObjectURL(file);
    });
    if (props.maxFiles) {
      const finalPathsArr = paths.concat(newPaths);
      if (finalPathsArr.length > nrOfAttachments) {
        toast.error(
          `${Vocabulary.attachingFilesNumberError}${nrOfAttachments}!`
        );
      } else {
        setPaths(finalPathsArr);
      }
    } else {
      setPaths(newPaths);
    }
  };

  return (
    <Dropzone
      accept={props.accept ? props.accept : Config.defaultAcceptedFiles}
      maxFiles={nrOfAttachments}
      onDrop={(acceptedFiles) => {
        handleDropFiles(acceptedFiles);
      }}
      onDropRejected={(rejectedFiles) => {
        if (rejectedFiles.length > nrOfAttachments)
          toast.error(
            `${Vocabulary.attachingFilesNumberError}${nrOfAttachments}!`
          );
        else toast.error(Vocabulary.generalAttachmentError);
      }}
      onError={(error) => {
        toast.error(Vocabulary.generalAttachmentError);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <Paper elevation={Config.elevation}>
          <div {...getRootProps()} className={styles.dropzoneArea}>
            <input {...getInputProps()} />
            <p>{Vocabulary.dragAndDrop}</p>
            <CloudUpload fontSize="large" />
            <div className={styles.imageContainer}>
              {paths &&
                paths?.map((path: any) => {
                  if (
                    checkIfSrcBelongsToPdf(path.src ? path.src : path) ||
                    path.typePdf
                  ) {
                    return <EmptyPdf />;
                  }
                  return (
                    <img
                      className={styles.imageStyle}
                      width={200}
                      key={path}
                      src={path}
                      alt={Vocabulary.missingAttachment}
                    />
                  );
                })}
            </div>
          </div>
        </Paper>
      )}
    </Dropzone>
  );
}
