import {
  Autocomplete,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import genericStyles from "../Styles/genericStyles.module.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Vocabulary } from "../Utils/Vocabulary";
import Config from "../Utils/Config";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import useCustomSearchParam from "../Hooks/useCustomSearchParam";
import withRole from "../Hooks/withRole";
import { FilterList } from "@mui/icons-material";
import { taskSortPropertiesList } from "../Utils/Constants";
import SortingComponent from "./SortingComponent";

type Props = {
  name: string;
  filterData: any;
  users: any[];
  shouldRenderUserFilter?: boolean;
};

function MainDashboardTabFilters(props: Props) {
  const { shouldRenderUserFilter, filterData, users } = props;
  const customSearchParam = useCustomSearchParam();

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid
          item
          xl={1}
          lg={1}
          md={4}
          sm={6}
          xs={12}
          className={genericStyles.mainDashboardTabFiltersName}
        >
          <FilterList />
          <Typography sx={{ fontWeight: "bold" }}>
            {Vocabulary.filters}
          </Typography>
        </Grid>
        <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={Vocabulary.startDate}
              value={filterData?.start}
              slotProps={{ textField: { fullWidth: true } }}
              format={Config.momentEUDateFormat}
              onChange={(date: any) => {
                customSearchParam.addParamToQueryString(
                  "start",
                  date.format(Config.momentUSDateFormat)
                );
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={Vocabulary.endDate}
              slotProps={{ textField: { fullWidth: true } }}
              value={filterData?.end}
              format={Config.momentEUDateFormat}
              onChange={(date: any) => {
                customSearchParam.addParamToQueryString(
                  "end",
                  date.format(Config.momentUSDateFormat)
                );
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
          {shouldRenderUserFilter ? (
            <Autocomplete
              noOptionsText={Vocabulary.noResultsFound}
              id="user"
              disablePortal
              onChange={(event: any, value: any) => {
                customSearchParam.addParamToQueryString("user", value?.id);
              }}
              getOptionLabel={(option: any) =>
                `${option?.lastName ? option?.lastName : ""} ${
                  option?.firstName ? option?.firstName : ""
                }`
              }
              isOptionEqualToValue={(option: any, value: any) => {
                return option?.id === value;
              }}
              options={users ? users : []}
              value={filterData.user}
              fullWidth
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={Vocabulary.user}
                />
              )}
            />
          ) : null}
        </Grid>
        <Grid
          item
          xl={2}
          lg={2}
          md={4}
          sm={6}
          xs={12}
          className={genericStyles.itemAlignedCenter}
        >
          <SortingComponent
            sort={filterData.sort}
            sortProperty={filterData.sortProperty}
            sortList={taskSortPropertiesList}
          />
        </Grid>
      </Grid>
      <br />
      <Divider />
    </Grid>
  );
}

export default withRole(MainDashboardTabFilters);
