import { Chip, Typography } from "@mui/material";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";

export default function TasksLegend() {
  return (
    <fieldset className={genericStyles.fieldSet}>
      <legend>{Vocabulary.explanations}</legend>
      <div className={genericStyles.legendItem}>
        <Typography>{Vocabulary.subtasks}: </Typography>
        <Chip className={genericStyles.subTaskLegend} />
      </div>
      <div className={genericStyles.legendItem}>
        <Typography>{Vocabulary.lateTasks}: </Typography>
        <Chip className={genericStyles.lateTaskLegend} />
      </div>
    </fieldset>
  );
}
