import { useContext } from "react";
import { Button, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { Vocabulary } from "../../Utils/Vocabulary";
import genericStyles from "../../Styles/genericStyles.module.css";
import { DrawerStateContext } from "../../Context/DrawerStateContext";
import { isTablet } from "../../Utils/Utils";

type Props = {
  style?: string;
};

export default function NewTaskButton(props: Props) {
  const drawerStateContext = useContext(DrawerStateContext);

  /**
   *
   */
  const handleChangeDrawerOpen = () => {
    drawerStateContext.setOpen(true);
  };

  return (
    <>
      <Button
        startIcon={<AddCircle />}
        onClick={handleChangeDrawerOpen}
        size="large"
        color="secondary"
        className={`${genericStyles.newTaskButton} ${props.style}`}
        variant="contained"
      >
        <Typography sx={{ fontWeight: "bold" }}>
          {isTablet() ? Vocabulary.task : Vocabulary.newTask}
        </Typography>
      </Button>
    </>
  );
}
