import Config from "./Config";

export const urlEnum = {
  login: `${Config.apiUrl}/auth/signin`,
  logout: `${Config.apiUrl}/auth/signout`,
  getUsers: `${Config.apiUrl}/user/search`,
  getUsersForFilter: `${Config.apiUrl}/user/filter`,
  getTasks: `${Config.apiUrl}/task/search`,
  getChats: `${Config.apiUrl}/chat/search`,
  getCustomStyles: `${Config.apiUrl}/style`,
  getMeetings: `${Config.apiUrl}/meeting/search`,
  getDepartments: `${Config.apiUrl}/department/search`,
  getDepartmentsForFilter: `${Config.apiUrl}/department/filter`,
  getRoles: `${Config.apiUrl}/role/search`,
  deleteUser: `${Config.apiUrl}/user/delete`,
  deleteDepartment: `${Config.apiUrl}/department/delete`,
  deleteTaskStatus: `${Config.apiUrl}/taskStatus/delete`,
  deleteTask: `${Config.apiUrl}/task/delete`,
  deleteProject: `${Config.apiUrl}/project/delete`,
  deleteMeeting: `${Config.apiUrl}/meeting/delete`,
  importUsers: `${Config.apiUrl}/user/import`,
  importDepartments: `${Config.apiUrl}/department/import`,
  importTasks: `${Config.apiUrl}/task/import`,
  createUser: `${Config.apiUrl}/user/create`,
  createDepartment: `${Config.apiUrl}/department/create`,
  createTask: `${Config.apiUrl}/task/create`,
  duplicateTask: `${Config.apiUrl}/task/duplicate`,
  createOrUpdateMeeting: `${Config.apiUrl}/meeting/create`,
  createOrUpdateChat: `${Config.apiUrl}/chat/create`,
  createProject: `${Config.apiUrl}/project/create`,
  createSubtasks: `${Config.apiUrl}/task/subtasks`,
  updateDepartment: `${Config.apiUrl}/department/update`,
  updateUser: `${Config.apiUrl}/user/update`,
  updateTaskStatus: `${Config.apiUrl}/taskStatus/update`,
  media: `${Config.apiUrl}/media`,
  markChatAsRead: `${Config.apiUrl}/chat/markAsRead`,
  getFilesAndMedia: `${Config.apiUrl}/media/public?imagePath=`,
  getMedia: `${Config.apiUrl}/media/search?folderPath=`,
  getTaskPriorities: `${Config.apiUrl}/taskPriority/search`,
  getTaskPeriodicities: `${Config.apiUrl}/taskPeriodicity/search`,
  getTaskStatuses: `${Config.apiUrl}/taskStatus/search`,
  getTaskStatusesForFilter: `${Config.apiUrl}/taskStatus/filter`,
  getRequestStatusesForFilter: `${Config.apiUrl}/requestStatus/filter`,
  getTaskTypes: `${Config.apiUrl}/taskType/search`,
  getMeetingTypesForFilters: `${Config.apiUrl}/meetingType/search`,
  getMeetingStatusesForFilters: `${Config.apiUrl}/meetingStatus/search`,
  getTaskDashboard: `${Config.apiUrl}/task/dashboard`,
  getTaskCalendar: `${Config.apiUrl}/task/calendar`,
  getOpenMeetingsCalendar: `${Config.apiUrl}/meeting/calendar`,
  getTaskKanban: `${Config.apiUrl}/task/kanban`,
  getSubtasksByParent: `${Config.apiUrl}/task/parent`,
  getProjectStatuses: `${Config.apiUrl}/projectStatuses/search`,
  getProjects: `${Config.apiUrl}/project/search`,
  getProjectTasks: `${Config.apiUrl}/task/project`,
  getProjectsForFilter: `${Config.apiUrl}/project/filter`,
  postProjectFolder: `${Config.apiUrl}/project/newFolder`,
  basicProjectPath: `${Config.apiUrl}/project`,
  newFolder: "/newFolder",
  deleteFolder: "/deleteFolder",
  newFile: "/newFile",
  getNotifications: `${Config.apiUrl}/notification/search`,
  markNotificationAsRead: `${Config.apiUrl}/notification/update`,
  webSocket: `${Config.apiUrl}/wss`,
  dashboardSummary: `${Config.apiUrl}/task/summary`,
  getDashboardsMeetings: `${Config.apiUrl}/meeting/dashboard`,
  getConfiguration: `${Config.apiUrl}/configuration/search`,
  createConfiguration: `${Config.apiUrl}/configuration/create`,
  getDailyDashboardWorkload: `${Config.apiUrl}/workload/dashboard`,
  getUnassignedTasks: `${Config.apiUrl}/task/unassignedTasks`,
  deallocateTask: `${Config.apiUrl}/task/deallocate`,
  modifiedTasks: `${Config.apiUrl}/task/modified`,
  getTaskStatistics: `${Config.apiUrl}/task/statistics`,
  markAsInactive: `${Config.apiUrl}/chat/markAsInactive`,
  unreadConversations: `${Config.apiUrl}/chat/unreadConversations`,
  getLoginPageImagePath: `${Config.apiUrl}/configuration/loginPage`,
  createOrUpdateRequest: `${Config.apiUrl}/request/create`,
  getRequest: `${Config.apiUrl}/request/search`,
  deleteRequest: `${Config.apiUrl}/request/delete`,
  approveRequest: `${Config.apiUrl}/request/approve`,
  rejectRequest: `${Config.apiUrl}/request/reject`,
  getClients: `${Config.apiUrl}/client/search`,
  getClientContractTemplates: `${Config.apiUrl}/client/contractTemplates`,
  generateContracts: `${Config.apiUrl}/client/generateContracts`,
};

export const localUrlEnum = {
  login: "/login",
  users: "/users",
  projects: "/projects",
  tasks: "/tasks",
  reports: "/reports",
  meetings: "/meetings",
  media: "/media",
  settings: "/settings",
  account: "/account",
  departments: "/departments",
  project: "/project",
  messages: "/messages",
  task: "/task",
  report: "/report",
  dashboard: "/",
  calendar: "/calendar",
  requests: "/requests",
  documentGenerator: "/documentGenerator",
  pageNotFound: "*",
  renderChildren: "",
};

export const pathParams = {
  optionalId: "/:id?",
};

export const basicOperationsEnum = {
  create: "/create",
  update: "/update",
  delete: "/delete",
  import: "/import",
  export: "/export",
};

export const foldersNames = {
  usersProfiles: "uploadedFiles/usersProfiles/",
  loginPageImages: "uploadedFiles/loginPage/",
};

export const entityPath = {
  media: "/media",
  chat: "/chat",
  projects: "/projects",
  tasks: "/tasks",
  meetings: "/meetings",
  templates: "/templates",
  clients: "/clients",
};

export const customPaths = {
  downloadClientsContracts: "/uploadedFiles/clients.zip",
};
