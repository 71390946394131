/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import withRole from "../../Hooks/withRole";
import Config from "../../Utils/Config";
import { DayPilotCalendar, DayPilot } from "@daypilot/daypilot-lite-react";
import {
  CalendarFiltersAutocompleteModel,
  CalendarFiltersModel,
} from "../../Utils/Models";
import { useContext, useEffect, useRef, useState } from "react";
import { EMeetingType } from "../../Utils/Enums";
import { GlobalContext } from "../../Context/GlobalContext";
import CalendarFilters from "../Calendar/CalendarFilters";
import { ComponentsNames } from "../../Utils/Constants";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { useLocation } from "react-router-dom";
import { getData } from "../../Services/getData";
import { checkIfMeeting, predictTasksAndMeetings } from "../../Utils/Utils";
import QueryStringParser from "../QueryStringParser";
import "../../Styles/weeklyCalendar.css";
import moment from "moment";

type Props = {
  calendarFilters: CalendarFiltersAutocompleteModel;
  name: string;
  shouldRenderFilters?: boolean;
};

function DailyCalendarComponent(props: Props) {
  const calendarRef: any = useRef();
  const [events, setEvents] = useState<any>([]);
  const [staticEvents, setStaticEvents] = useState<any>([]);
  const refreshContext = useContext(RefreshDataContext);
  const [calendarCurrentDate, setCalendarCurrentDate] = useState(
    new DayPilot.Date(new Date())
  );
  const location = useLocation();
  const userRef = useRef(null);
  const currentUserId = localStorage.getItem("userId");
  const [calendarFilters, setCalendarFilters] = useState<CalendarFiltersModel>({
    type: null,
    start: new DayPilot.Date(
      calendarCurrentDate.toString(Config.momentUSDateFormatForCalendar)
    )
      .addDays(-1)
      .toString(Config.momentUSDateFormatForCalendar),
    end: new DayPilot.Date(
      calendarCurrentDate
        .addDays(1)
        .toString(Config.momentUSDateFormatForCalendar)
    ).toString(Config.momentUSDateFormatForCalendar),
    difference: 0,
    user: currentUserId,
  });
  const config = {
    viewType: "Day",
    durationBarVisible: false,
    cellHeight: 50,
    headerDateFormat: Config.rangeDatePickerFormat,
  };
  const globalContext = useContext(GlobalContext);

  /**
   *
   */
  useEffect(() => {
    refetchCalendarData();
  }, [events]);

  /**
   * refetch data when it's needed
   */
  async function refetchCalendarData() {
    calendarRef.current?.control.update({
      start: calendarCurrentDate,
      events: events,
    });
  }

  /**
   *
   */
  useEffect(() => {
    getDataForCalendar();
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (
      calendarFilters.difference < 0 ||
      calendarFilters.user !== userRef.current ||
      (refreshContext.refresh &&
        location.pathname.includes(localUrlEnum.calendar))
    ) {
      //get data from server
      getDataForCalendar();
    } else {
      if (staticEvents.length > 0) setEvents(staticEvents);
    }
  }, [
    calendarFilters.difference,
    calendarFilters.user,
    refreshContext.refresh,
  ]);

  /**
   *
   */
  useEffect(() => {
    getTasksOrMeetings(calendarFilters.type);
  }, [calendarFilters.type]);

  /**
   *
   * @param type
   */
  const getTasksOrMeetings = (type: any, receivedTasks?: any[]) => {
    const clone = receivedTasks
      ? receivedTasks
      : JSON.parse(JSON.stringify(staticEvents));
    let tasks = clone;
    if (type === 1) {
      //meeting
      tasks = clone.filter((task: any) => task.evaluation !== undefined);
    } else if (type === 0) {
      //task
      tasks = clone.filter((task: any) => task.budget !== undefined);
    }
    setEvents(tasks);
  };

  /**
   *
   */
  const getDataForCalendar = () => {
    getData(
      `${urlEnum.getTaskCalendar}/${
        calendarFilters.user?.id
          ? calendarFilters.user?.id
          : calendarFilters.user
      }/${calendarFilters.start}/${calendarFilters.end}/${true}`
    ).then((res: any) => {
      const allEvents = res.data.calendarTasks.concat(
        res.data.calendarMeetings
      );
      predictTasksAndMeetings(
        allEvents,
        (futureData: any) => {
          if (calendarFilters.type !== null) {
            getTasksOrMeetings(calendarFilters.type, [
              ...allEvents,
              ...futureData,
            ]);
          } else {
            const correctTasksAndMeetings = renderCorrectTasksAndMeeting([
              ...allEvents,
              ...futureData,
            ]);
            setEvents(correctTasksAndMeetings);
            if (
              staticEvents.length === 0 ||
              calendarFilters.user !== userRef.current
            )
              setStaticEvents(correctTasksAndMeetings);
          }
        },
        true
      );
    });
  };

  /**
   * divides a task that takes place over 5 days into n tasks
   * that start at 9 a.m. and end at 6 p.m.
   * @param events
   * @returns
   */
  const renderCorrectTasksAndMeeting = (events: any) => {
    const futureTasksPredicted: Array<any> = [];
    const newEvents = events?.map((event: any) => {
      if (!checkIfMeeting(event)) {
        //check difference between endDate and current date
        const datePart = calendarCurrentDate.toString().split("T")[0];
        let difference = moment(event.end).diff(moment(datePart), "days");
        if (difference > 7) difference = 7;
        // if (difference > 0) {
        for (let i = 0; i <= difference; i++) {
          const newEvent = JSON.parse(JSON.stringify(event));
          newEvent.start = moment(datePart)
            .add(i, "days")
            .set({
              hour: 9,
              minute: 0,
              second: 0,
            })
            .format(Config.momentUSDateAndTimeFormat);
          newEvent.backColor = newEvent.disabled ? "#CDC2AE" : "#91a1b2";
          newEvent.end = moment(datePart)
            .add(i, "days")
            .set({
              hour: 18,
              minute: 0,
              second: 0,
            })
            .format(Config.momentUSDateAndTimeFormat);
          futureTasksPredicted.push(newEvent);
        }
        // }
      } else {
        event.backColor = "#b291a2";
        futureTasksPredicted.push(event);
      }
    });
    return futureTasksPredicted;
  };

  /**
   *
   * @param {*} args
   * @returns
   */
  const onEventClick = async (args: any) => {
    if (!args.e.data.disabled) {
      if (args.e.data.duration && args.e.data.duration > "") {
        //check if meeting is open or private
        if (args.e.data.type?.name === EMeetingType.MEETING_OPEN) {
          globalContext.setMeetingSelectedId(args.e.data.id);
        }
      } else {
        globalContext.setTaskId(args.e.data.id);
      }
    }
  };

  return (
    <>
      <QueryStringParser
        requestFunction={(data: any) => {
          //check if data is an empty object or only contains tab property then get data from server (
          //it means it;s the first time when the page is loaded or the filters were deleted)
          let { start } = calendarFilters;
          let { end } = calendarFilters;
          const newDiff =
            data.difference !== undefined
              ? parseInt(data.difference)
              : calendarFilters.difference;
          if (newDiff !== calendarFilters.difference) {
            const newStart = calendarCurrentDate.addDays(
              newDiff > calendarFilters.difference ? 1 : -1
            );
            setCalendarCurrentDate(newStart);
            start = new DayPilot.Date(
              newStart.toString(Config.momentUSDateFormatForCalendar)
            )
              .addDays(-1)
              .toString(Config.momentUSDateFormatForCalendar);
            end = new DayPilot.Date(
              newStart.toString(Config.momentUSDateFormatForCalendar)
            )
              .addDays(1)
              .toString(Config.momentUSDateFormatForCalendar);
          }
          userRef.current = calendarFilters.user;
          setCalendarFilters({
            ...calendarFilters,
            difference: newDiff,
            type: data.type !== undefined ? parseInt(data.type) : null,
            user: data.user ? data.user : currentUserId,
            start: start,
            end: end,
          });
        }}
      />
      <CalendarFilters
        threshold={Config.dailyTasksCalendarThreshold}
        renderEntityFilter={true}
        name={ComponentsNames.CalendarFilters}
        calendarCurrentDate={calendarCurrentDate}
        entityFilterShouldNotBeDisabled={true}
        filtersAutocompleteData={props.calendarFilters}
      />
      <DayPilotCalendar
        startDate={calendarCurrentDate}
        {...config}
        ref={calendarRef}
        timeRangeSelectedHandling={"Disabled"}
        eventMoveHandling={"Disabled"}
        eventDeleteHandling={"Disabled"}
        headerDateFormat="dd-MM-yyyy"
        locale={"ro-ro"}
        onEventClick={onEventClick}
      />
    </>
  );
}

export default withRole(DailyCalendarComponent);
