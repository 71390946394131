import { Divider, Grid, Typography } from "@mui/material";
import genericStyles from "../../Styles/genericStyles.module.css";
type Props = {
  property: string;
  propertyInput: any;
  propertyIcon?: any;
};

export default function PropertyContainer(props: Props) {
  const { property, propertyInput, propertyIcon } = props;
  return (
    <>
      <Grid item xs={3} className={genericStyles.gridContainer}>
        <Typography>{property}</Typography>
        {propertyIcon}
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item xs={8} className={genericStyles.gridContainer}>
        {propertyInput}
      </Grid>
    </>
  );
}
