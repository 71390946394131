import { Backdrop, IconButton, Typography } from "@mui/material";

import styles from "../Styles/mainSlider.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import withRole from "../Hooks/withRole";
import { isTablet } from "../Utils/Utils";

type Props = {
  data: any;
  name: string;
  openMenu: boolean;
  toggleOpenMobileForTableAndMobile: () => void;
};
function MainSlider(props: Props, location: any) {
  location = useLocation();
  const navigate = useNavigate();

  /**
   *
   * @returns
   */
  const renderMobile = () => {
    return (
      <Backdrop
        open={isTablet() ? props.openMenu : false}
        onClick={props.toggleOpenMobileForTableAndMobile}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 99999,
          display: "unset",
          marginTop: "60px",
        }}
      >
        <div
          className={`${
            isTablet()
              ? props.openMenu
                ? styles.mainSliderContainerMobileOrTablet
                : styles.mainSliderContainerClosed
              : styles.mainSliderContainer
          }`}
        >
          <div className={`${styles.slider}`}>
            {props.data && props.data.length
              ? props.data?.map((element: any, key: any) => {
                  return (
                    <div
                      key={key}
                      className={styles.sliderOption}
                      onClick={() => {
                        navigate(element.path);
                        props.toggleOpenMobileForTableAndMobile();
                      }}
                    >
                      <IconButton
                        id={element.name}
                        className={
                          location.pathname === element.path
                            ? styles.selected
                            : styles.nonSelected
                        }
                      >
                        {element.icon}
                      </IconButton>
                      <Typography
                        className={
                          location.pathname === element.path
                            ? styles.selectedText
                            : styles.nonSelectedText
                        }
                      >
                        {element.name}
                      </Typography>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </Backdrop>
    );
  };

  /**
   *
   * @returns
   */
  const renderDesktop = () => {
    return (
      <div
        className={`${
          isTablet()
            ? props.openMenu
              ? styles.mainSliderContainerMobileOrTablet
              : styles.mainSliderContainerClosed
            : styles.mainSliderContainer
        }`}
      >
        <div className={`${styles.slider}`}>
          {props.data && props.data.length
            ? props.data?.map((element: any, key: any) => {
                return (
                  <div
                    key={key}
                    className={styles.sliderOption}
                    onClick={() => {
                      navigate(element.path);
                      props.toggleOpenMobileForTableAndMobile();
                    }}
                  >
                    <IconButton
                      id={element.name}
                      className={
                        location.pathname === element.path
                          ? styles.selected
                          : styles.nonSelected
                      }
                    >
                      {element.icon}
                    </IconButton>
                    <Typography
                      className={
                        location.pathname === element.path
                          ? styles.selectedText
                          : styles.nonSelectedText
                      }
                    >
                      {element.name}
                    </Typography>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  };

  return isTablet() ? renderMobile() : renderDesktop();
}

export default withRole(MainSlider);
