/* eslint-disable no-unused-vars */
import {
  AdminPanelSettings,
  Assessment,
  Assignment,
  BackHand,
  CalendarMonth,
  Dashboard,
  Domain,
  DynamicFeed,
  FileCopy,
  Group,
  MeetingRoom,
  NotesOutlined,
  People,
  PhotoAlbum,
  Work,
} from "@mui/icons-material";
import { localUrlEnum, pathParams } from "./UrlEnum";
import { Vocabulary } from "./Vocabulary";
import Config from "./Config";
import moment from "moment";

export const HttpStatusCode = {
  OK: 200,
  Created: 201,
  Accepted: 202,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  RequestTimeout: 408,
  InternalServerError: 500,
  NotImplemented: 501,
  UnprocessableEntity: 422,
};

export const PeriodicityNrOfDays = {
  PERIODICITY_DAILY: 1,
  PERIODICITY_WEEKLY: 7,
  PERIODICITY_MONTHLY: 30,
  PERIODICITY_YEARLY: 365,
  PERIODICITY_SEMESTRIAL: 180,
  PERIODICITY_TRIMESTRIAL: 120,
  PERIODICITY_UNIQUE: 0,
};

export const ImportantDates = {
  START_OF_MONTH: moment().startOf("month"),
  END_OF_MONTH: moment().endOf("month"),
};

export const specialFinishConditions = [
  {
    value: 0,
    name: Vocabulary.none,
  },
  {
    value: 1,
    name: Vocabulary.file,
  },
];

export const HttpVerbs = {
  post: "post",
  get: "get",
  put: "put",
  delete: "delete",
};

export const RequestsHeader = [
  { name: Vocabulary.status, maxWidth: 1 },
  { name: Vocabulary.fancyName, maxWidth: 5 },
  { name: Vocabulary.createdBy, maxWidth: 1 },
  { name: Vocabulary.solvedBy, maxWidth: 1 },
  { name: Vocabulary.departments, maxWidth: 3 },
];

export const ClientsTableColumns = [
  { field: "id", headerName: Vocabulary.crtNo, width: 75 },
  {
    field: "fullName",
    headerName: Vocabulary.partnerName,
    width: 300,
    render: (params: any) => params.row.name,
  },
  {
    field: "representative",
    headerName: Vocabulary.representative,
    width: 300,
  },
  {
    field: "address",
    headerName: Vocabulary.address,
    width: 300,
    valueGetter: (params: any) => {
      //iterate over address object params and create string
      let address = "";
      Object.keys(params.row.address).forEach((key) => {
        address += `${params.row.address[key]}, `;
      });
      return address;
    },
  },
];

export const MainSliderData = [
  {
    name: Vocabulary.dashboard,
    path: localUrlEnum.dashboard,
    icon: <Dashboard />,
  },
  {
    name: Vocabulary.calendar,
    path: localUrlEnum.calendar,
    icon: <CalendarMonth />,
  },
  {
    name: Vocabulary.users,
    path: localUrlEnum.users,
    icon: <People />,
  },
  {
    name: Vocabulary.tasks,
    path: localUrlEnum.tasks,
    icon: <Assignment />,
  },
  {
    name: Vocabulary.taskRequests,
    path: localUrlEnum.requests,
    icon: <BackHand />,
  },
  {
    name: Vocabulary.reports,
    path: localUrlEnum.reports,
    icon: <Assessment />,
  },
  {
    name: Vocabulary.documentsGenerator,
    path: localUrlEnum.documentGenerator,
    icon: <FileCopy />,
  },
  {
    name: Vocabulary.projects,
    path: localUrlEnum.projects,
    icon: <Work />,
  },
  {
    name: Vocabulary.meetings,
    path: localUrlEnum.meetings,
    icon: <MeetingRoom />,
  },
  {
    name: Vocabulary.media,
    path: localUrlEnum.media,
    icon: <PhotoAlbum />,
  },
  {
    name: Vocabulary.configurations,
    path: localUrlEnum.settings,
    icon: <AdminPanelSettings />,
  },
];

export const MainSliderDataForUser = [
  {
    name: Vocabulary.dashboard,
    path: localUrlEnum.dashboard,
    icon: <Dashboard />,
  },
  {
    name: Vocabulary.calendar,
    path: localUrlEnum.calendar,
    icon: <CalendarMonth />,
  },
  {
    name: Vocabulary.tasks,
    path: localUrlEnum.tasks,
    icon: <Assignment />,
  },
  {
    name: Vocabulary.taskRequests,
    path: localUrlEnum.requests,
    icon: <BackHand />,
  },
  {
    name: Vocabulary.projects,
    path: localUrlEnum.projects,
    icon: <Work />,
  },
  {
    name: Vocabulary.meetings,
    path: localUrlEnum.meetings,
    icon: <MeetingRoom />,
  },
  {
    name: Vocabulary.media,
    path: localUrlEnum.media,
    icon: <PhotoAlbum />,
  },
];

export const MainSliderDataForDesigner = [
  {
    name: Vocabulary.configurations,
    path: localUrlEnum.settings,
    icon: <AdminPanelSettings />,
  },
];

export const MainSliderDataForDepartmentHead = [
  {
    name: Vocabulary.dashboard,
    path: localUrlEnum.dashboard,
    icon: <Dashboard />,
  },
  {
    name: Vocabulary.calendar,
    path: localUrlEnum.calendar,
    icon: <CalendarMonth />,
  },
  {
    name: Vocabulary.tasks,
    path: localUrlEnum.tasks,
    icon: <Assignment />,
  },
  {
    name: Vocabulary.taskRequests,
    path: localUrlEnum.requests,
    icon: <BackHand />,
  },
  {
    name: Vocabulary.reports,
    path: localUrlEnum.reports,
    icon: <Assessment />,
  },
  {
    name: Vocabulary.projects,
    path: localUrlEnum.projects,
    icon: <Work />,
  },
  {
    name: Vocabulary.meetings,
    path: localUrlEnum.meetings,
    icon: <MeetingRoom />,
  },
  {
    name: Vocabulary.media,
    path: localUrlEnum.media,
    icon: <PhotoAlbum />,
  },
];

const AdminRouter = {
  routes: [
    localUrlEnum.pageNotFound,
    localUrlEnum.dashboard,
    `${localUrlEnum.users}${pathParams.optionalId}`,
    `${localUrlEnum.departments}${pathParams.optionalId}`,
    `${localUrlEnum.task}${pathParams.optionalId}`,
    localUrlEnum.renderChildren,
    localUrlEnum.project,
    `${localUrlEnum.project}${pathParams.optionalId}`,
    `${localUrlEnum.messages}${pathParams.optionalId}`,
    localUrlEnum.account,
    localUrlEnum.projects,
    localUrlEnum.reports,
    localUrlEnum.tasks,
    localUrlEnum.meetings,
    localUrlEnum.media,
    localUrlEnum.calendar,
    localUrlEnum.requests,
    localUrlEnum.settings,
    localUrlEnum.documentGenerator,
  ],
};

const HeadDepartmentRouter = {
  routes: [
    localUrlEnum.pageNotFound,
    localUrlEnum.dashboard,
    `${localUrlEnum.task}${pathParams.optionalId}`,
    localUrlEnum.renderChildren,
    localUrlEnum.project,
    `${localUrlEnum.project}${pathParams.optionalId}`,
    `${localUrlEnum.messages}${pathParams.optionalId}`,
    localUrlEnum.account,
    localUrlEnum.projects,
    localUrlEnum.reports,
    localUrlEnum.tasks,
    localUrlEnum.meetings,
    localUrlEnum.media,
    localUrlEnum.calendar,
    localUrlEnum.requests,
  ],
};

const UserRouter = {
  routes: [
    localUrlEnum.pageNotFound,
    localUrlEnum.dashboard,
    localUrlEnum.renderChildren,
    localUrlEnum.project,
    `${localUrlEnum.project}${pathParams.optionalId}`,
    `${localUrlEnum.messages}${pathParams.optionalId}`,
    localUrlEnum.account,
    localUrlEnum.projects,
    localUrlEnum.tasks,
    localUrlEnum.media,
    localUrlEnum.meetings,
    localUrlEnum.requests,
    localUrlEnum.calendar,
  ],
};

export const DesignerRouter = {
  routes: [localUrlEnum.settings],
};

export const AppSettingsAdminTabs = [
  Vocabulary.styles,
  Vocabulary.generics,
  Vocabulary.taskStatuses,
];

export const AdminAndDepartmentHeadRequestsTabs = [
  Vocabulary.personalRequests,
  Vocabulary.requestsToResolve,
];

export const TaskReportsAdminTabs = [
  Vocabulary.reports,
  Vocabulary.unassigned,
  Vocabulary.modified,
];

export const TaskReportsDepartmentHeadTabs = [
  Vocabulary.reports,
  Vocabulary.unassigned,
];

export const UserRequestsTabs = [Vocabulary.personalRequests];

export const MeetingsTabs = [
  Vocabulary.meetings,
  Vocabulary.marelviMeetingsCalendar,
];

export const AppSettingsDesignerHeadTabs = [
  Vocabulary.styles,
  Vocabulary.generics,
];

export const roleUI: { [key: string]: any } = {
  ROLE_ADMINISTRATOR: {
    MainSlider: {
      data: MainSliderData,
    },
    MainDashboardTabFilters: {
      shouldRenderUserFilter: true,
    },
    MainDashboardTab: {
      shouldRenderUserFilter: true,
    },
    LocalRouter: AdminRouter,
    Projects: {
      shouldCRUDPRoject: true,
    },
    ProjectsFilters: {
      shouldRenderProjectManagerFilter: true,
    },
    FileExplorerMin: {
      shouldCRUDFile: true,
    },
    Project: {
      shouldEditProject: true,
    },
    Meeting: {
      disableFields: false,
    },
    MainHeader: {
      shouldRenderAllData: true,
    },
    CalendarFilters: {
      shouldRenderFilters: true,
    },
    AppSettings: {
      shouldRenderTabs: true,
    },
    MeetingsList: {
      shouldCRUDMeeting: true,
    },
    SummaryOfUsersSubordinates: {
      renderSelectFilterUser: true,
    },
    GraphicResponsiveBar: {
      roleLegend: Vocabulary.roleLegendAdmin,
    },
    MeetingsWidget: { shouldAddNewMeetings: true },
    RequestTabs: {
      shouldRenderManagementTab: true,
      tabs: AdminAndDepartmentHeadRequestsTabs,
    },
    Task: {
      advancedOptions: true,
    },
    ReportsAndUnassignedTasksPage: {
      shouldRenderModifiedTasksTab: true,
    },
  },
  ROLE_DEPARTMENT_HEAD: {
    MainSlider: {
      data: MainSliderDataForDepartmentHead,
    },
    MainDashboardTabFilters: {
      shouldRenderUserFilter: true,
    },
    MainDashboardTab: {
      shouldRenderUserFilter: true,
    },
    Meeting: {
      disableFields: false,
    },
    LocalRouter: HeadDepartmentRouter,
    Projects: {
      shouldCRUDPRoject: true,
    },
    ProjectsFilters: {
      shouldRenderProjectManagerFilter: true,
    },
    FileExplorerMin: {
      shouldCRUDFile: true,
    },
    Project: {
      shouldEditProject: true,
    },
    CalendarFilters: {
      shouldRenderFilters: true,
    },
    AppSettings: {
      shouldRenderTabs: false,
    },
    MeetingsList: {
      shouldCRUDMeeting: true,
    },
    SummaryOfUsersSubordinates: {
      renderSelectFilterUser: true,
    },
    GraphicResponsiveBar: {
      roleLegend: Vocabulary.roleLegendDepartmentHead,
    },
    MeetingsWidget: { shouldAddNewMeetings: true },
    RequestTabs: {
      shouldRenderManagementTab: true,
      tabs: AdminAndDepartmentHeadRequestsTabs,
    },
    Task: {
      advancedOptions: true,
    },
  },
  ROLE_DESIGNER: {
    MainSlider: {
      data: MainSliderDataForDesigner,
    },
    LocalRouter: DesignerRouter,
    Task: {
      advancedOptions: false,
    },
  },
  ROLE_STORE_HEAD: {
    MainSlider: {
      data: MainSliderDataForUser,
    },
    LocalRouter: UserRouter,
    Meeting: {
      disableFields: true,
    },
    RequestTabs: {
      tabs: UserRequestsTabs,
    },
    Task: {
      advancedOptions: false,
    },
  },
  ROLE_USER: {
    MainSlider: {
      data: MainSliderDataForUser,
    },
    LocalRouter: UserRouter,
    Meeting: {
      disableFields: true,
    },
    GraphicResponsiveBar: {
      roleLegend: Vocabulary.roleLegendUser,
    },
    RequestTabs: {
      tabs: UserRequestsTabs,
    },
    Task: {
      advancedOptions: false,
    },
  },
  ROLE_STORE_MANAGER: {
    MainSlider: {
      data: MainSliderDataForUser,
    },
    LocalRouter: UserRouter,
    Meeting: {
      disableFields: true,
    },
    RequestTabs: {
      tabs: UserRequestsTabs,
    },
    Task: {
      advancedOptions: false,
    },
  },
  ROLE_PROJECTS_ADMINISTRATOR: {
    MainSlider: {
      data: MainSliderDataForUser,
    },
    LocalRouter: UserRouter,
    Project: {
      shouldEditProject: true,
    },
    Projects: {
      shouldCRUDPRoject: true,
    },
    Meeting: {
      disableFields: true,
    },
    RequestTabs: {
      tabs: UserRequestsTabs,
    },
    Task: {
      advancedOptions: false,
    },
  },
};

export const Months = [
  Vocabulary.january,
  Vocabulary.february,
  Vocabulary.march,
  Vocabulary.april,
  Vocabulary.may,
  Vocabulary.june,
  Vocabulary.july,
  Vocabulary.august,
  Vocabulary.september,
  Vocabulary.october,
  Vocabulary.november,
  Vocabulary.december,
];

export const Days = [
  Vocabulary.sunday,
  Vocabulary.monday,
  Vocabulary.tuesday,
  Vocabulary.wednesday,
  Vocabulary.thursday,
  Vocabulary.friday,
  Vocabulary.saturday,
];

export const UserExcelHeader = [
  Vocabulary.lastName,
  Vocabulary.firstName,
  Vocabulary.email,
  Vocabulary.department,
];

export const DepartmentsExcelHeader = [Vocabulary.lastName];

export const TasksExcelHeader = [
  Vocabulary.department,
  Vocabulary.task,
  Vocabulary.type,
  Vocabulary.periodicity,
  Vocabulary.priority,
  Vocabulary.start,
  Vocabulary.stop,
  Vocabulary.state,
  Vocabulary.budget,
  Vocabulary.equipments,
  Vocabulary.staff,
  Vocabulary.progress,
  Vocabulary.assignedUser,
  Vocabulary.observationsWithoutDiacritics,
];

export const WeekDays = [
  { id: 0, name: Vocabulary.monday, key: "l" },
  { id: 1, name: Vocabulary.tuesday, key: "m" },
  { id: 2, name: Vocabulary.wednesday, key: "mi" },
  { id: 3, name: Vocabulary.thursday, key: "j" },
  { id: 4, name: Vocabulary.friday, key: "v" },
  { id: 5, name: Vocabulary.saturday, key: "s" },
  { id: 6, name: Vocabulary.sunday, key: "d" },
];

export const ComponentsNames = {
  MainSlider: "MainSlider",
  MainDashboardTabFilters: "MainDashboardTabFilters",
  MainDashboardTab: "MainDashboardTab",
  LocalRouter: "LocalRouter",
  Projects: "Projects",
  ProjectsFilters: "ProjectsFilters",
  FileExplorerMin: "FileExplorerMin",
  Project: "Project",
  MainHeader: "MainHeader",
  MonthlyCalendarComponent: "MonthlyCalendarComponent",
  Meeting: "Meeting",
  WeeklyCalendar: "WeeklyCalendar",
  AppSettings: "AppSettings",
  CalendarFilters: "CalendarFilters",
  MeetingsList: "MeetingsList",
  SummaryOfUsersSubordinates: "SummaryOfUsersSubordinates",
  GraphicResponsiveBar: "GraphicResponsiveBar",
  DailyCalendarComponent: "DailyCalendarComponent",
  MeetingsWidget: "MeetingsWidget",
  RequestTabs: "RequestTabs",
  Task: "Task",
  ReportsAndUnassignedTasksPage: "ReportsAndUnassignedTasksPage",
};

const responsive: "standard" | "vertical" | "simple" | undefined = "standard";

export const CommonTableOptions = {
  selectableRows: "none",
  serverSide: true,
  viewColumns: false,
  responsive: responsive,
  rowsPerPageOptions: Config.rowsPerPage,
  confirmFilters: true,
  filter: false,
  search: false,
  textLabels: {
    body: {
      noMatch: Vocabulary.noResultsFound,
    },
    pagination: {
      next: Vocabulary.nextPage,
      previous: Vocabulary.previousPage,
      rowsPerPage: Vocabulary.rowsPerPage,
      displayRows: Vocabulary.of,
    },
    toolbar: {
      search: Vocabulary.search,
      downloadCsv: Vocabulary.downloadCSV,
      print: Vocabulary.print,
    },
    selectedRows: {
      text: Vocabulary.rowsSelected,
      delete: Vocabulary.delete,
    },
  },
};

export const UserSecondMenuRoutes = [
  {
    name: Vocabulary.users,
    path: localUrlEnum.users,
    icon: <Group />,
  },
  {
    name: Vocabulary.departments,
    path: localUrlEnum.departments,
    icon: <Domain />,
  },
];

export const ProjectSteps = [
  {
    name: Vocabulary.baseInfo,
    icon: <NotesOutlined fontSize="large" color="secondary" />,
  },
  {
    name: Vocabulary.dynamicInfo,
    icon: <DynamicFeed fontSize="large" color="secondary" />,
  },
];

export const AllAcceptedFiles = {
  "image/*": [".png", ".jpeg", ".jpg"],
  "application/pdf": [".pdf"],
};

export const FilesTypes = {
  pdf: "application/pdf",
  png: "image/png",
  jpeg: "image/jpeg",
  jpg: "image/jpg",
};

export const DayPilotRoConfig = {
  dayNames: [
    Vocabulary.sunday,
    Vocabulary.monday,
    Vocabulary.tuesday,
    Vocabulary.wednesday,
    Vocabulary.thursday,
    Vocabulary.friday,
    Vocabulary.saturday,
  ],
  dayNamesShort: ["L", "M", "Me", "J", "V", "S"],
  monthNames: [
    Vocabulary.january,
    Vocabulary.february,
    Vocabulary.march,
    Vocabulary.april,
    Vocabulary.may,
    Vocabulary.june,
    Vocabulary.july,
    Vocabulary.august,
    Vocabulary.september,
    Vocabulary.october,
    Vocabulary.november,
    Vocabulary.december,
  ],
  monthNamesShort: [
    "Ian",
    "Feb",
    "Mar",
    "Apr",
    "Mai",
    "Iun",
    "Iul",
    "Aug",
    "Sep",
    "Oct",
    "Noi",
    "Dec",
  ],
  timePattern: Config.momentEUTimeFormat,
  datePattern: Config.momentUSDateFormat,
  dateTimePattern: Config.momentEUDateTimeFormat,
  timeFormat: "Clock12Hours",
  weekStarts: 1,
};

export const defaultStatusesForCalendar = [
  {
    translation: Vocabulary.actualEvent,
    color: "#fff",
  },
  {
    translation: Vocabulary.futureEvent,
    color: "#CDC2AE",
    helperText: Vocabulary.futureEventsHelperText,
  },
];

export const allFilters = [
  "search",
  "difference",
  "type",
  "user",
  "start",
  "end",
  "periodicity",
  "department",
  "projectManager",
  "status",
  "lateTask",
  "assignedUsers",
  "priority",
  "project",
  "role",
];

export const taskSortPropertiesList = [
  {
    value: "start",
    label: Vocabulary.startDate,
  },
  {
    value: "end",
    label: Vocabulary.endDate,
  },
];

export const subtaskColumns = [
  {
    label: Vocabulary.fancyName,
    name: "text",
  },
  {
    label: Vocabulary.status,
    name: "status",
  },
];

/**
 * responsive design resolution breakpoints
 */
export const ResolutionBreakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};
