import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Vocabulary } from "../Utils/Vocabulary";
import { Add, Delete, Visibility } from "@mui/icons-material";
import MUIDataTableCustomStyle from "../Components/MUIDataTable/MUIDataTableCustomStyle";
import { useContext, useEffect, useState } from "react";
import { getData } from "../Services/getData";
import { localUrlEnum, urlEnum } from "../Utils/UrlEnum";
import { useNavigate } from "react-router-dom";
import QueryStringParser from "../Components/QueryStringParser";
import ProjectsFilters from "../Components/Projects/ProjectsFilters";
import moment from "moment";
import { deleteData } from "../Services/deleteData";
import ApprovalModal from "../Components/ApprovalModal";
import { CommonTableOptions, ComponentsNames } from "../Utils/Constants";
import withRole from "../Hooks/withRole";
import GenericModal from "../Components/GenericModal";
import ProjectModal from "../Components/Projects/ProjectModal";
import { ProjectsState } from "../Utils/Models";
import genericStyles from "../Styles/genericStyles.module.css";
import { RefreshDataContext } from "../Context/RefreshDataContext";
import {
  checkIfVariableIsString,
  createURLFromParams,
  formatStringDate,
} from "../Utils/Utils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Config from "../Utils/Config";
import useCustomSearchParam from "../Hooks/useCustomSearchParam";
import Collaborators from "../Components/Projects/Collaborators";

type Props = {
  shouldCRUDPRoject?: boolean;
};

function Projects(props: Props) {
  const [filtersData, setFiltersData] = useState<any>({
    statuses: [],
    users: [],
  });
  const customSearchParams = useCustomSearchParam();
  const navigate = useNavigate();
  const [state, setState] = useState<ProjectsState>(new ProjectsState());
  const [openProjectModal, setOpenProjectModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const refreshContext = useContext(RefreshDataContext);
  const projectsFiltersValidationSchema = yup
    .object()
    .shape({
      status: yup.string(),
      projectManager: yup.object(),
      start: yup.object(),
      end: yup.object(),
    })
    .required();
  const methods = useForm({
    defaultValues: {
      start: moment().startOf("month").subtract(2, "months"),
      end: moment().endOf("month"),
    },
    resolver: yupResolver(projectsFiltersValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  /**
   *
   */
  const toggleModalState = () => {
    setOpenModal(!openModal);
  };

  /**
   *
   */
  useEffect(() => {
    if (refreshContext.refresh) {
      getProjectsUsingStateData();
      refreshContext.setRefresh(false);
    }
  }, [refreshContext.refresh]);

  /**
   *
   */
  useEffect(() => {
    const promises = [
      getData(urlEnum.getProjectStatuses),
      getData(`${urlEnum.getUsersForFilter}/${localStorage.getItem("userId")}`),
    ];
    Promise.all(promises)
      .then((res) => {
        setFiltersData({
          ...filtersData,
          statuses: res[0].data,
          users: res[1].data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   *
   * @param id
   */
  const deleteProject = () => {
    deleteData(`${urlEnum.deleteProject}/${state.selectedProject?.id}`)
      .then((res: any) => {
        if (res) {
          getProjectsUsingStateData();
        }
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  /**
   *
   * @param page
   * @param perPage
   */
  const getProjects = (
    page: number,
    perPage: number,
    search: string | null,
    status?: string,
    projectManager?: any,
    start?: any,
    end?: any
  ) => {
    const url = createURLFromParams(
      `${
        urlEnum.getProjects
      }?page=${page}&perPage=${perPage}&userId=${localStorage.getItem(
        "userId"
      )}&start=${start}&end=${end}${
        search ? `&search=${search}` : ""
      }&allParams=`,
      {
        status: status ? status : null,
        projectManager: projectManager
          ? checkIfVariableIsString(projectManager)
            ? projectManager
            : projectManager?.id
          : null,
      }
    );
    getData(url).then((res) => {
      setState({
        ...state,
        projects: res.data?.results,
        count: res.data.nrResults,
        page: page,
        perPage: perPage,
        search: search,
      });
    });
  };

  /**
   *
   */
  const handleAddNewProject = () => {
    setState({ ...state, selectedProject: null });
    setOpenProjectModal(true);
  };

  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    return {
      ...CommonTableOptions,
      count: state.count,
      rowsPerPage: state.perPage,
      page: state.page,
      customToolbar: () => {
        return props.shouldCRUDPRoject ? (
          <Button
            variant="contained"
            startIcon={<Add />}
            className={genericStyles.addButton}
            onClick={handleAddNewProject}
          >
            {Vocabulary.add}
          </Button>
        ) : null;
      },
      onChangePage: (page: any) => {
        customSearchParams.addParamToQueryString("page", page);
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        customSearchParams.addParamToQueryString("perPage", numberOfRows);
        window.scrollTo(0, 0);
      },
      onRowClick: (rowData: any, rowMeta: any) => {
        navigateToProject(state.projects[rowMeta.dataIndex].id);
      },
    };
  };

  /**
   *
   * @param id
   */
  const navigateToProject = (id: string) => {
    navigate(`${localUrlEnum.project}/${id}`);
  };

  const projectsHeader = [
    {
      label: Vocabulary.crtNo,
      name: "",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return state.page * state.perPage + meta.rowIndex + 1;
        },
      },
    },
    { label: Vocabulary.name, name: "name", options: { sort: false } },
    {
      label: Vocabulary.projectManager,
      name: "projectManager",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return `${value?.firstName ? value?.firstName : ""} ${
            value?.lastName ? value?.lastName : ""
          }; `;
        },
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return <p>{value ? value.translation : "-"}</p>;
        },
      },
    },
    {
      label: Vocabulary.collaborators,
      name: "projectUsers",
      options: {
        sort: false,
        customBodyRender: (value: any[], tableMeta: any, updateValue: any) => (
          <Collaborators collaborators={value} />
        ),
      },
    },
    {
      label: Vocabulary.deadline,
      name: "endDate",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return formatStringDate(value, Config.momentEUDateFormat);
        },
      },
    },
    {
      label: Vocabulary.options,
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <ToggleButtonGroup exclusive aria-label="text alignment">
              <Tooltip
                title={Vocabulary.view}
                className={genericStyles.tooltipEditButton}
              >
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    navigateToProject(state.projects[rowIndex].id);
                  }}
                >
                  <Visibility />
                </ToggleButton>
              </Tooltip>
              {props.shouldCRUDPRoject ? (
                <Tooltip
                  title={Vocabulary.delete}
                  className={genericStyles.tooltipDeleteButton}
                >
                  <ToggleButton
                    value="center"
                    aria-label="centered"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      setState({
                        ...state,
                        selectedProject: state.projects[rowIndex],
                      });
                      toggleModalState();
                    }}
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              ) : null}
            </ToggleButtonGroup>
          );
        },
      },
    },
  ];

  /**
   *
   */
  const handleChangeProjectModalState = () => {
    setOpenProjectModal(!openProjectModal);
  };

  /**
   *
   */
  const getProjectsUsingStateData = () => {
    getProjects(
      state.page,
      state.perPage,
      state.search,
      methods.getValues("status"),
      methods.getValues("projectManager"),
      moment(methods.getValues("start"))?.format(Config.momentUSDateFormat),
      moment(methods.getValues("end"))?.format(Config.momentUSDateFormat)
    );
  };

  return (
    <div className={genericStyles.mainContainer}>
      <QueryStringParser
        requestFunction={(data: any) => {
          getProjects(
            data.page ? parseInt(data.page) : state.page,
            data.perPage ? parseInt(data.perPage) : state.perPage,
            data.search,
            data.status,
            data.projectManager,
            data.start
              ? data.start
              : moment(methods.getValues("start"))?.format(
                  Config.momentUSDateFormat
                ),
            data.end
              ? data.end
              : moment(methods.getValues("end"))?.format(
                  Config.momentUSDateFormat
                )
          );
        }}
      />
      <GenericModal
        title={Vocabulary.addNewProject}
        open={openProjectModal}
        onClose={handleChangeProjectModalState}
        children={
          <ProjectModal
            closeModal={handleChangeProjectModalState}
            project={state.selectedProject}
          />
        }
        actions={
          <>
            <Button
              variant="contained"
              onClick={handleChangeProjectModalState}
              className={genericStyles.cancelButton}
            >
              {Vocabulary.cancel}
            </Button>
            <Button type="submit" variant="contained" form="projectForm">
              {Vocabulary.save}
            </Button>
          </>
        }
      />
      <ProjectsFilters
        name={ComponentsNames.ProjectsFilters}
        methods={methods}
        filtersData={filtersData}
      />
      <div className={genericStyles.fullWidthElement}>
        <MUIDataTableCustomStyle
          title={Vocabulary.projectsList}
          data={state.projects}
          columns={projectsHeader}
          options={getTableOptions()}
        />
      </div>
      <ApprovalModal
        handleApproveAction={deleteProject}
        openModal={openModal}
        handleClose={toggleModalState}
      />
    </div>
  );
}

export default withRole(Projects);
