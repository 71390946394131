/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Divider, Typography } from "@mui/material";
import styles from "../../Styles/introWidget.module.css";
import { Days, Months } from "../../Utils/Constants";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Checklist,
  Diversity3,
  FolderShared,
  FormatQuote,
} from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { DailyWorkloadDashboardWidgetModel } from "../../Utils/Models";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { useLocation, useNavigate } from "react-router-dom";
import { RefreshDataContext } from "../../Context/RefreshDataContext";

export default function IntroWidget() {
  const [state, setState] = useState<DailyWorkloadDashboardWidgetModel>(
    new DailyWorkloadDashboardWidgetModel()
  );
  const refreshContext = useContext(RefreshDataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const userName = localStorage.getItem("userName")?.split(" ")[1] || "";

  /**
   *
   */
  useEffect(() => {
    getDataFromServer();
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (
      refreshContext.refresh &&
      location.pathname === localUrlEnum.dashboard
    ) {
      getDataFromServer();
    }
  }, [refreshContext.refresh]);

  /**
   *
   */
  const getDataFromServer = () => {
    getData(urlEnum.getDailyDashboardWorkload).then((res: any) => {
      if (res && res.data) setState(res?.data);
    });
  };

  /**
   *
   * @returns
   */
  const createDateString = () => {
    const today = new Date();
    const day = today.getDay();
    const month = today.getMonth();
    const dayName = Days[day].toLowerCase();
    const monthName = Months[month];
    if (dayName && monthName) {
      return `${dayName}, ${today.getDate()} ${monthName} ${today.getFullYear()}`;
    }
  };

  return (
    <div className={styles.introWidgetContainer}>
      <div className={styles.introWidgetContent}>
        <Typography
          align="center"
          variant="subtitle2"
          sx={{ fontWeight: "bold" }}
        >
          {createDateString()}
        </Typography>
        <div className={styles.addressingTheUser}>
          <Typography variant="h5" align="center">
            {`${Vocabulary.hello},`}
          </Typography>
          <Typography
            variant="h5"
            align="center"
            sx={{ fontWeight: "bold", marginLeft: 1 }}
          >
            {userName}
          </Typography>
        </div>

        <Typography align="center" variant="body2">
          {Vocabulary.todaysStatus}
        </Typography>
        <div className={styles.dailyStatus}>
          <div
            className={styles.dailyStatusSubContainer}
            onClick={() => {
              navigate(localUrlEnum.tasks);
            }}
          >
            <Checklist />
            <Typography sx={{ fontWeight: "bold", margin: 1 }} variant="h6">
              {state.dailyTasksNr}
            </Typography>
            <Typography>{Vocabulary.tasks.toLowerCase()}</Typography>
          </div>
          <Divider
            sx={{ backgroundColor: "#FFF" }}
            orientation="vertical"
            flexItem
          />
          <div
            className={styles.dailyStatusSubContainer}
            onClick={() => {
              navigate(localUrlEnum.projects);
            }}
          >
            <FolderShared />
            <Typography sx={{ fontWeight: "bold", margin: 1 }} variant="h6">
              {state.projectsNr}
            </Typography>
            <Typography>{Vocabulary.projects.toLowerCase()}</Typography>
          </div>
          <Divider
            sx={{ backgroundColor: "#FFF" }}
            orientation="vertical"
            flexItem
          />
          <div
            className={styles.dailyStatusSubContainer}
            onClick={() => {
              navigate(localUrlEnum.meetings);
            }}
          >
            <Diversity3 />
            <Typography sx={{ fontWeight: "bold", margin: 1 }} variant="h6">
              {state.dailyMeetingsNr}
            </Typography>
            <Typography>{Vocabulary.meetings.toLowerCase()}</Typography>
          </div>
        </div>
        <Typography align="center" variant="body2" sx={{ marginTop: 2 }}>
          {Vocabulary.messageOfTheDay}
        </Typography>
        <div className={styles.messageOfTheDayContainer}>
          <FormatQuote
            sx={{
              fontSize: 50,
              alignSelf: "flex-start",
              transform: "rotate(180deg)",
            }}
            color="primary"
          />
          <Typography variant="h5" sx={{ alignSelf: "flex-end" }}>
            {state.quoteOfTheDay}
          </Typography>
        </div>
        <Divider
          variant="middle"
          sx={{ marginLeft: 0, backgroundColor: "#B02D21" }}
        />
      </div>
    </div>
  );
}
