/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Avatar,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { SyntheticEvent, useState } from "react";
import { foldersNames, urlEnum } from "../../Utils/UrlEnum";
import {
  AssignedUsersOptionsStateModal,
  DepartmentModel,
  UserModel,
} from "../../Utils/Models";
import genericStyles from "../../Styles/genericStyles.module.css";

type Props = {
  id: string | undefined;
  open: boolean;
  anchorEl: any;
  handleClose: () => void;
  departments: Array<DepartmentModel>;
  users: Array<UserModel>;
  handleAdvancedAssignedUsersOptions: (
    option: number,
    departments: Array<DepartmentModel>
  ) => void;
  shouldRenderAllData?: boolean;
};

export default function AssignedUsersOptions(props: Props) {
  const {
    id,
    open,
    anchorEl,
    handleAdvancedAssignedUsersOptions,
    handleClose,
    departments,
    shouldRenderAllData,
  } = props;
  const [state, setState] = useState(new AssignedUsersOptionsStateModal());

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="left-start"
      sx={{ zIndex: 9999999999999, boxShadow: 2, maxWidth: 500 }}
    >
      <Paper style={{ padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {Vocabulary.advancedOptions}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                checked={state.checkAllUsers}
                onChange={(event: any) => {
                  setState({
                    ...state,
                    checkAllUsers: event.target.checked,
                    selectedDepartments: [],
                    chooseAllDepartmentHead: false,
                    option: 1,
                  });
                }}
                control={<Checkbox color="primary" />}
                label={Vocabulary.checkAllUsers}
              />
            </FormGroup>
          </Grid>
          {shouldRenderAllData && (
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  checked={state.chooseAllDepartmentHead}
                  onChange={(event: any) => {
                    setState({
                      ...state,
                      checkAllUsers: false,
                      selectedDepartments: [],
                      chooseAllDepartmentHead: event.target.checked,
                      option: 2,
                    });
                  }}
                  control={<Checkbox color="primary" />}
                  label={Vocabulary.chooseAllDepartmentHead}
                />
              </FormGroup>
            </Grid>
          )}
          {shouldRenderAllData && (
            <Grid item xs={12}>
              <Autocomplete
                id="departments"
                noOptionsText={Vocabulary.noResultsFound}
                disablePortal
                multiple
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  value: any
                ) => {
                  setState({
                    ...state,
                    checkAllUsers: false,
                    chooseAllDepartmentHead: false,
                    selectedDepartments: value,
                    option: 3,
                  });
                }}
                getOptionLabel={(option: any) => `${option?.name || ""}`}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                options={departments}
                value={state.selectedDepartments}
                fullWidth
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={Vocabulary.chooseDepartments}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
                renderTags={(value: any, getTagProps: any) =>
                  value?.map((option: any, index: number) => (
                    <Chip
                      avatar={
                        <Avatar
                          src={`${urlEnum.getFilesAndMedia}${foldersNames.usersProfiles}${option?.profilePicture}`}
                        />
                      }
                      variant="outlined"
                      label={`${option?.name || ""}`}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Button
              className={genericStyles.cancelButton}
              fullWidth
              variant="contained"
              onClick={() => {
                setState(new AssignedUsersOptionsStateModal());
                handleClose();
              }}
            >
              {Vocabulary.cancel}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => {
                handleAdvancedAssignedUsersOptions(
                  state.option,
                  state.selectedDepartments
                );
                handleClose();
                setState(new AssignedUsersOptionsStateModal());
              }}
            >
              {Vocabulary.save}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Popper>
  );
}
