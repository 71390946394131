import { Button, Paper } from "@mui/material";
import genericStyles from "../Styles/genericStyles.module.css";
import { Vocabulary } from "../Utils/Vocabulary";

type Props = {
  filters: any;
  resetFilters: () => void;
  style?: any;
};
export default function FilterContainer(props: Props) {
  const { filters, resetFilters } = props;
  return (
    <Paper
      className={`${props.style ? props.style : ""}${
        genericStyles.sliderContainer
      }`}
    >
      <div className={genericStyles.filtersContainer}>{filters}</div>
      <Button
        className={genericStyles.deleteButton}
        onClick={resetFilters}
        variant="contained"
      >
        {Vocabulary.deleteFilters}
      </Button>
    </Paper>
  );
}
