import { useContext, useEffect, useState } from "react";
import styles from "../../Styles/kanban.module.css";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Task } from "../../Utils/Models";
import TaskWidget from "../Widgets/TaskWidget";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import { useLocation } from "react-router-dom";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { postData } from "../../Services/postData";
import Config from "../../Utils/Config";
import NoDataFound from "../NoDataFound";

const KanbanBoard = () => {
  const [boardColumns, setBoardColumns] = useState<any[]>([]);
  const [draggedTask, setDraggedTask] = useState<Task | null>(null);
  const [draggedOverColumn, setDraggedOverColumn] = useState<number | null>(
    null
  );
  const [currentColumn, setCurrentColumn] = useState<any>(null);
  const [draggedOverTask, setDraggedOverTask] = useState<number | null>(null);
  const location = useLocation();
  const refreshContext = useContext(RefreshDataContext);

  /**
   *
   * @param task
   */
  const handleUpdateTask = async (task: any, callback: any) => {
    const res = await postData(urlEnum.createTask, task);
    if (res) {
      callback();
    }
  };

  /**
   *
   */
  useEffect(() => {
    if (
      refreshContext.refresh &&
      location.pathname === localUrlEnum.dashboard
    ) {
      refreshContext.setRefresh(false);
      getDataFromServer();
    }
  }, [refreshContext.refresh]);
  /**
   *
   * @param e
   * @param task
   */
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, task: Task) => {
    e.dataTransfer.setData("text/plain", task.id.toString());
    setDraggedTask(task);
  };

  /**
   *
   */
  useEffect(() => {
    getDataFromServer();
  }, []);

  /**
   *
   */
  const getDataFromServer = () => {
    getData(`${urlEnum.getTaskKanban}/${localStorage.getItem("userId")}`)
      .then((res) => {
        if (res) {
          setBoardColumns(res.data);
        }
      })
      .catch((err) => {
        refreshContext.setRefresh(false);
      });
  };

  /**
   *
   * @param e
   * @param columnId
   */
  const handleDragEnterColumn = (
    e: React.DragEvent<HTMLDivElement>,
    columnId: number
  ) => {
    e.preventDefault();
    setDraggedOverColumn(columnId);
  };

  /**
   *
   * @param e
   * @param task
   * @param currentColumnId
   */
  const handleDragEnterTask = (
    e: React.DragEvent<HTMLDivElement>,
    task: any,
    currentColumnId: any
  ) => {
    setCurrentColumn(currentColumnId);
    e.preventDefault();
    setDraggedOverTask(task.id);
  };

  /**
   *
   */
  const handleDragLeaveTask = () => {
    setDraggedOverTask(null);
  };

  /**
   *
   * @param e
   * @param taskId
   */
  const handleDragOver = (
    e: React.DragEvent<HTMLDivElement>,
    taskId: number
  ) => {
    e.preventDefault();
    setDraggedOverTask(taskId);
  };

  /**
   *
   * @param sourceColumnId
   * @param targetColumnId
   * @param targetTaskIndex
   */
  const moveTaskBetweenColumns = (
    sourceColumnId: string,
    targetColumnId: string,
    targetTaskIndex: string
  ) => {
    let taskUpdated = true;
    //create copy of boardsColumns
    const newBoardColumns = JSON.parse(JSON.stringify(boardColumns));
    newBoardColumns?.map(async (column: any) => {
      // if (column.id === sourceColumnId && draggedTask) {
      //   //delete task from source column
      //   column.tasks = column.tasks.filter(
      //     (task: any) => task.id !== draggedTask.id
      //   );
      // }
      if (column.id === targetColumnId && draggedTask) {
        //update task object and add it to the target column
        const newColumnStatus: any = Object.assign({}, column);
        delete newColumnStatus.tasks;
        const newTask: any = Object.assign({}, draggedTask);
        newTask.status = newColumnStatus;
        const tasks = [...column.tasks];
        tasks.splice(parseInt(targetTaskIndex), 0, newTask);
        column.tasks = tasks;
        await handleUpdateTask(newTask, () => {
          taskUpdated = false;
          getDataFromServer();
        });
      }
      return column;
    });

    setBoardColumns(taskUpdated ? newBoardColumns : boardColumns);
  };

  /**
   *
   */
  const handleDragEnd = () => {
    const columnIndex: any =
      draggedOverColumn != null ? draggedOverColumn : currentColumn;
    if (draggedTask) {
      const result = boardColumns.find((obj) => {
        return obj.id === columnIndex;
      });
      const targetTaskIndex =
        draggedOverTask !== null
          ? result.tasks.findIndex((task: any) => task.id === draggedOverTask)
          : result.tasks.length;

      if (currentColumn !== columnIndex) {
        moveTaskBetweenColumns(currentColumn, columnIndex, targetTaskIndex);
      }
    }
    setDraggedTask(null);
    setDraggedOverColumn(null);
    setDraggedOverTask(null);
  };

  return (
    <div className={styles.kanbanBoard}>
      <Grid container spacing={3}>
        {boardColumns?.map((column: any, index: number) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
            <Card
              elevation={Config.elevation}
              key={column?.id}
              style={{ backgroundColor: column?.color }}
              className={`${styles.kanbanColumn} ${
                draggedOverColumn === column.id ? genericStyles.draggedOver : ""
              }`}
              onDragEnter={(e) => handleDragEnterColumn(e, column?.id)}
              onDragOver={(e) => e.preventDefault()}
              onDrop={() => handleDragEnd()}
            >
              <CardHeader
                title={
                  <Typography variant="h5">{`${column?.translation} (${column?.tasks.length}) `}</Typography>
                }
              />
              <CardContent>
                <div className={styles.kanbanColumnContent}>
                  {column.tasks.length ? (
                    column?.tasks?.map((task: any, index: any) => (
                      <TaskWidget
                        key={index}
                        task={task}
                        currentColumnId={column.id}
                        handleDragEnd={handleDragEnd}
                        handleDragEnterTask={handleDragEnterTask}
                        handleDragLeaveTask={handleDragLeaveTask}
                        handleDragOver={handleDragOver}
                        handleDragStart={handleDragStart}
                        draggedTask={draggedTask}
                        draggedOverTask={draggedOverTask}
                      />
                    ))
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <NoDataFound />
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default KanbanBoard;
