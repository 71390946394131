/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import DynamicTabs from "../Components/DynamicTabs";
import genericStyles from "../Styles/genericStyles.module.css";
import { useSearchParams } from "react-router-dom";
import { ComponentsNames, MeetingsTabs } from "../Utils/Constants";
import CustomTabPanel from "../Components/CustomTabPanel";
import MeetingsList from "../Components/Meetings/MeetingsList";
import MarelviCalendar from "../Components/Widgets/MarelviCalendar";

type Props = {
  shouldRenderSearchBar?: boolean;
};

export default function Meetings(props: Props) {
  const [tabValue, setTabValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  /**
   *
   */
  useEffect(() => {
    const tab = searchParams.get("tab");
    setTabValue(tab ? parseInt(tab) : 0);
  }, [searchParams]);

  return (
    <>
      <div className={genericStyles.header}>
        <DynamicTabs tabValue={tabValue} labels={MeetingsTabs} />
      </div>
      <div className={genericStyles.tabPanelContainer}>
        <CustomTabPanel value={tabValue} index={0}>
          <MeetingsList name={ComponentsNames.MeetingsList} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <MarelviCalendar />
          <></>
        </CustomTabPanel>
      </div>
    </>
  );
}
