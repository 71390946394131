import { DayPilotCalendar } from "@daypilot/daypilot-lite-react";
import Config from "../../Utils/Config";
import { foldersNames, urlEnum } from "../../Utils/UrlEnum";
import "../../Styles/weeklyCalendar.css";

type Props = {
  calendarCurrentDate: Date;
  calendarRef: any;
  onEventClick: (args: any) => void;
};

export default function GenericWeeklyCalendar(props: Props) {
  const { calendarCurrentDate, calendarRef, onEventClick } = props;

  const config = {
    viewType: "Week",
    durationBarVisible: false,
    cellHeight: 50,
    headerDateFormat: Config.rangeDatePickerFormat,
    onBeforeEventRender: (args: any) => {
      args.data.areas = [];
      args.data.areas.push({
        top: 0,
        left: 5,
        html: '<div style="width: 10px; height: 100vh; background-color: #b291a2; border-top-left-radius: 15px; border-bottom-left-radius: 15px;"></div>',
      });
      const participants = args.data.assignedUsers;
      if (participants?.length > 0) {
        // show one icon for each participant
        const nrOfParticipantsDisplayed =
          participants?.length > 3 ? 3 : participants?.length;

        for (let i = 0; i < nrOfParticipantsDisplayed; i++) {
          args.data.areas.push({
            bottom: 5,
            right: 5 + i * 30,
            width: 30,
            height: 30,
            action: "None",
            image: `${
              participants[i]?.profilePicture !== "null"
                ? `${urlEnum.getFilesAndMedia}${foldersNames.usersProfiles}${participants[i]?.profilePicture}`
                : "/user.png"
            }`,
            cssClass: "calendar_user_avatar",
          });
        }
        if (nrOfParticipantsDisplayed === 3) {
          args.data.areas.push({
            bottom: 5,
            right: 5 + nrOfParticipantsDisplayed * 30,
            width: 30,
            height: 30,
            action: "None",
            text: `+${participants?.length - 3}`,
            cssClass: "calendar_user_avatar",
          });
        }
      }
    },
  };

  return (
    <>
      <DayPilotCalendar
        startDate={calendarCurrentDate}
        {...config}
        ref={calendarRef}
        timeRangeSelectedHandling={"Disabled"}
        eventMoveHandling={"Disabled"}
        eventDeleteHandling={"Disabled"}
        headerDateFormat="dd-MM-yyyy"
        locale={"ro-ro"}
        onEventClick={onEventClick}
      />
    </>
  );
}
