import { Email } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { localUrlEnum, urlEnum } from "../Utils/UrlEnum";
import styles from "../Styles/mainHeader.module.css";
import { useEffect, useState } from "react";
import { getData } from "../Services/getData";
import { useWebSocket } from "../Context/WebSocketContext";

export default function NewMessageAnimated() {
  const navigate = useNavigate();
  const [hasNewMessages, setHasNewMessages] = useState<boolean>(false);
  const { stompClient } = useWebSocket();
  let subscribed = false;
  const authenticatedUser = localStorage.getItem("userId");
  const location = useLocation();

  /**
   *
   */
  useEffect(() => {
    if (stompClient && !subscribed) {
      subscribed = true;
      stompClient.subscribe(
        `/notification/${authenticatedUser}/newMessage`,
        (payload: any) => {
          if (payload.body) {
            setHasNewMessages(JSON.parse(payload.body));
            if (JSON.parse(payload.body)) {
              new Notification("Ai un mesaj nou!");
            }
          }
        }
      );
    }
    return () => {
      subscribed = false;
      stompClient?.unsubscribe(`/notification/${authenticatedUser}/newMessage`);
    };
  }, [stompClient]);

  /**
   *
   */
  useEffect(() => {
    getData(urlEnum.unreadConversations).then((res) => {
      if (res && res.data !== null && res.data !== undefined) {
        setHasNewMessages(res.data);
        if (res.data) {
          new Notification("Ai un mesaj nou!");
        }
      }
    });
  }, []);

  /**
   *
   */
  useEffect(() => {
    const path = location.pathname;
    const regexPattern = "\\/messages\\/[a-zA-Z0-9]+$";
    const regex = new RegExp(regexPattern, "i");
    if (regex.test(path)) {
      getData(urlEnum.unreadConversations).then((res) => {
        if (res && res.data !== null && res.data !== undefined) {
          setHasNewMessages(res.data);
          if (res.data) {
            new Notification("Ai un mesaj nou!");
          }
        }
      });
    }
  }, [location]);

  /**
   *
   */
  const navigateToMessages = () => {
    navigate(localUrlEnum.messages);
  };

  return (
    <div className={styles.newMessagesContainer}>
      <Fab
        size="small"
        className={`${styles.headerMessageFab} ${styles.fab}`}
        id="headerMessageFab"
        onClick={navigateToMessages}
      >
        <Email />
      </Fab>
      <div
        className={`${hasNewMessages ? styles.pulse : styles.noUnreadMessages}`}
      >
        +
      </div>
    </div>
  );
}
