import {
  Autocomplete,
  Button,
  Chip,
  Divider,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import genericStyles from "../Styles/genericStyles.module.css";
import styles from "../Styles/usersPage.module.css";
import { Add, CloudUpload, Delete, Edit } from "@mui/icons-material";
import { Vocabulary } from "../Utils/Vocabulary";
import { localUrlEnum, urlEnum } from "../Utils/UrlEnum";
import { SyntheticEvent, useEffect, useState } from "react";
import { UserExcelHeader, CommonTableOptions } from "../Utils/Constants";
import { getData } from "../Services/getData";
import { deleteData } from "../Services/deleteData";
import { postData } from "../Services/postData";
import UserForm from "../Components/Users/UserForm";
import clsx from "clsx";
import MUIDataTableCustomStyle from "../Components/MUIDataTable/MUIDataTableCustomStyle";
import { UserModel, UserImportModel, UsersState } from "../Utils/Models";
import { updateData } from "../Services/updateData";
import { generateFormData } from "../Utils/Utils";
import GenericModal from "../Components/GenericModal";
import QueryStringParser from "../Components/QueryStringParser";
import ImportExcelModal from "../Components/ImportExcelModal";
import ApprovalModal from "../Components/ApprovalModal";
import useCustomSearchParam from "../Hooks/useCustomSearchParam";
import { ERole } from "../Utils/Enums";

export default function Users() {
  const [shouldHandleSubmit, setShouldHandleSubmit] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const customSearchParams = useCustomSearchParam();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeallocateModal, setOpenDeallocateModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserModel>(new UserModel());
  const [state, setState] = useState<UsersState>({
    page: 0,
    perPage: 20,
    count: 1,
    users: [],
    search: "",
    roleFilter: null,
    departmentFilter: null,
    sort: "ASC",
  });
  const [filterData, setFilterData] = useState<any>({
    roles: [],
    departments: [],
    projects: [],
  });
  const navigate = useNavigate();

  /**
   *
   */
  const toggleDeleteModalState = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const toggleDeallocateModalState = () => {
    setOpenDeallocateModal(!openDeallocateModal);
  };

  /**
   *
   */
  useEffect(() => {
    const promises = [
      getData(urlEnum.getRoles),
      getData(urlEnum.getDepartmentsForFilter),
    ];
    Promise.all(promises)
      .then((values: any) => {
        setFilterData({
          ...filterData,
          roles: values[0].data,
          departments: values[1].data,
        });
      })
      .catch((ex) => {
        console.log(ex);
      });
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (filterData?.roles.length > 0) {
      resetFilterData();
    }
  }, [filterData]);

  /**
   *
   */
  const resetFilterData = () => {
    const urlParams: any = new URLSearchParams(window.location.search);
    //create copy of the state object with object assign
    const newState: UsersState = Object.assign({}, state);

    for (const [key, value] of urlParams) {
      switch (key) {
        case "department":
          newState.departmentFilter = filterData.departments.find(
            (dep: any) => dep.id === value
          );
          break;
        case "role":
          newState.roleFilter = filterData.roles.find(
            (rol: any) => rol.id === value
          );
          break;
        default:
          break;
      }
      setState(newState);
    }
  };

  /**
   *
   * @param filter
   */
  const getUsers = (
    page: number,
    perPage: number,
    department: string | null,
    role: string | null,
    search: string | null
  ) => {
    getData(
      `${urlEnum.getUsers}?page=${page}&perPage=${perPage}${
        search ? `&search=${search}` : ""
      }&allParams=${department ? `department=${department}` : ""}${
        role ? `${department ? "," : ""}roles=${role}` : ""
      }`
    ).then((res: any) => {
      if (res) {
        setState({
          ...state,
          users: res.data.results,
          count: res.data.nrResults,
          departmentFilter: filterData.departments?.find(
            (dep: any) => dep?.id === department
          )
            ? filterData.departments?.find((dep: any) => dep?.id === department)
            : null,
          roleFilter: filterData.roles?.find((rol: any) => rol?.id === role)
            ? filterData.roles?.find((rol: any) => rol?.id === role)
            : null,
          search: search ? search : null,
          page: page,
          perPage: perPage,
        });
      }
    });
  };

  /**
   *
   * @param id
   */
  const deleteUser = () => {
    deleteData(`${urlEnum.deleteUser}/${selectedUser.id}`)
      .then((res: any) => {
        if (res) {
          getUsers(
            state.page,
            state.perPage,
            state.departmentFilter,
            state.roleFilter,
            state.search
          );
        }
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  /**
   *
   * @param data
   */
  const importUsers = (data: any) => {
    postData(urlEnum.importUsers, data).then((res: any) => {
      if (res) {
        setOpenImportModal(false);
        getUsers(
          state.page,
          state.perPage,
          state.departmentFilter,
          state.roleFilter,
          state.search
        );
      }
    });
  };

  /**
   *
   * @param user
   */
  const handleCreateOrUpdateUser = (user: any) => {
    const userFormData = generateFormData(user);
    if (user.id) {
      updateData(urlEnum.updateUser, userFormData)
        .then((res: any) => {
          if (res) {
            //check if updated user is the same as the logged in user
            if (res.data.id === localStorage.getItem("userId")) {
              localStorage.setItem("profilePicture", res.data.profilePicture);
              localStorage.setItem("userName", res.data.lastName);
            }
            onSuccessUpdateOrCreate();
          }
        })
        .catch((ex) => {
          console.log(ex);
        });
    } else {
      postData(urlEnum.createUser, userFormData)
        .then((res: any) => {
          if (res) {
            onSuccessUpdateOrCreate();
          }
        })
        .catch((ex) => {
          console.log(ex);
        });
    }
  };

  /**
   *
   * @param id
   */
  const getSelectedUserData = (id: string) => {
    getData(`${urlEnum.getUsers}/${id}`).then((res: any) => {
      if (res) {
        setSelectedUser(res.data);
        setOpenModal(true);
      }
    });
  };

  /**
   *
   */
  const onSuccessUpdateOrCreate = () => {
    // close modal
    setOpenModal(false);
    //get new data
    getUsers(
      state.page,
      state.perPage,
      state.departmentFilter,
      state.roleFilter,
      state.search
    );
    // reset selected user
    setSelectedUser(new UserModel());
    // reset path
    navigate(localUrlEnum.users);
  };

  /**
   *
   * @param userId
   */
  const deallocateTasksForUser = () => {
    postData(urlEnum.deallocateTask, selectedUser).then((res: any) => {
      if (res.data) {
        console.log("");
      }
    });
  };

  /**
   *
   */
  const deleteFilters = () => {
    customSearchParams.removeParamFromQueryString([
      "role",
      "department",
      "search",
    ]);
    setState({ ...state, roleFilter: null, departmentFilter: null });
  };

  const userHeader = [
    {
      label: Vocabulary.crtNo,
      name: "",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return state.page * state.perPage + meta.rowIndex + 1;
        },
      },
    },
    { label: Vocabulary.lastName, name: "lastName", options: { sort: false } },
    {
      label: Vocabulary.firstName,
      name: "firstName",
      options: { sort: false },
    },
    { label: Vocabulary.email, name: "email", options: { sort: false } },
    {
      label: Vocabulary.department,
      name: "departmentName",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return <p>{value ? value : "-"}</p>;
        },
      },
    },
    {
      label: Vocabulary.roles,
      name: "rolesArray",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return value?.map((elem: any, key: any) => {
            const label = filterData.roles.filter((role: any) => {
              return role.name === elem;
            });
            return (
              <Chip
                className={clsx({
                  [styles.chipForAdmin]: elem === ERole.ROLE_ADMINISTRATOR,
                  [styles.chipForDepartmentHead]:
                    elem === ERole.ROLE_DEPARTMENT_HEAD,
                  [styles.chipForUser]: elem === ERole.ROLE_USER,
                  [styles.chipForStoreHead]: elem === ERole.ROLE_STORE_HEAD,
                  [styles.chipForStoreManager]:
                    elem === ERole.ROLE_STORE_MANAGER,
                  [styles.chipForDesigner]: elem === ERole.ROLE_DESIGNER,
                  [styles.chipForProjectAdmin]:
                    elem === ERole.ROLE_PROJECTS_ADMINISTRATOR,
                })}
                key={key}
                label={label[0]?.translation}
              />
            );
          });
        },
      },
    },
    {
      label: Vocabulary.options,
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,

        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip
                  title={Vocabulary.edit}
                  className={genericStyles.tooltipEditButton}
                >
                  <ToggleButton
                    value="left"
                    aria-label="left aligned"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      getSelectedUserData(state.users[rowIndex].id);
                    }}
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                <ToggleButton
                  value="center"
                  aria-label="centered"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setSelectedUser(state.users[rowIndex]);
                    toggleDeallocateModalState();
                  }}
                >
                  {Vocabulary.deallocateTasks}
                </ToggleButton>
                <Tooltip
                  title={Vocabulary.delete}
                  className={genericStyles.tooltipDeleteButton}
                >
                  <ToggleButton
                    value="center"
                    aria-label="centered"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      toggleDeleteModalState();
                      setSelectedUser(state.users[rowIndex]);
                    }}
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  const getTableOptions = () => {
    return {
      ...CommonTableOptions,
      count: state.count,

      rowsPerPage: state.perPage,
      page: state.page,
      customToolbar: () => {
        return (
          <>
            <Button
              variant="contained"
              className={genericStyles.addButton}
              startIcon={<Add />}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              {Vocabulary.add}
            </Button>
            <Button
              className={genericStyles.addButton}
              variant="contained"
              startIcon={<CloudUpload />}
              onClick={() => {
                setOpenImportModal(true);
              }}
            >
              {`${Vocabulary.import}  ${Vocabulary.users}`}
            </Button>
          </>
        );
      },
      onChangePage: (page: number) => {
        customSearchParams.addParamToQueryString("page", page);
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        customSearchParams.addParamToQueryString("perPage", numberOfRows);
        window.scrollTo(0, 0);
      },
      onRowClick: (rowData: any, rowMeta: any) => {
        getSelectedUserData(state.users[rowMeta.dataIndex].id);
      },
    };
  };

  /**
   *
   * @param row
   * @returns
   */
  function getNewUserRow(row: UserImportModel) {
    return {
      lastName: row.Nume,
      firstName: row.Prenume,
      email: row.Email,
      departmentName: row.Departament,
      accountStatus: row.Cont,
      position: row.Functie,
      rolesArray: row.Roluri?.split(","),
      subordinateDepartmentsArray: row.Subordine?.split(","),
    };
  }

  /**
   *
   */
  const onCloseUserModal = () => {
    setOpenModal(false);
    setSelectedUser(new UserModel());
    navigate(localUrlEnum.users);
  };

  /**
   *
   * @param key
   * @param value
   */
  const handleChangeFilter = (key: string, value: string) => {
    if (value) customSearchParams.addParamToQueryString(key, value);
    else customSearchParams.removeParamFromQueryString([key]);
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.usersPageMainContainer}>
      <QueryStringParser
        requestFunction={(data: any) => {
          getUsers(
            data.page ? parseInt(data.page) : state.page,
            data.perPage ? parseInt(data.perPage) : state.perPage,
            data.department,
            data.role,
            data.search
          );
        }}
      />
      <div className={styles.mainTable}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  id="department"
                  disablePortal
                  noOptionsText={Vocabulary.noResultsFound}
                  getOptionLabel={(option: any) => option.name}
                  size="small"
                  options={filterData?.departments || []}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={state.departmentFilter ? state.departmentFilter : null}
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    value: any
                  ) => {
                    handleChangeFilter("department", value ? value?.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.department}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  id="role"
                  disablePortal
                  noOptionsText={Vocabulary.noResultsFound}
                  getOptionLabel={(option: any) => option.translation}
                  size="small"
                  options={filterData?.roles || []}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={state.roleFilter ? state.roleFilter : null}
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    value: any
                  ) => {
                    handleChangeFilter("role", value ? value.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.role}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Button
              className={genericStyles.deleteButton}
              variant="contained"
              onClick={() => {
                deleteFilters();
              }}
              fullWidth
              startIcon={<Delete />}
            >
              {Vocabulary.deleteFilters}
            </Button>
          </Grid>
        </Grid>
        <Divider className={styles.usersFilterDivider} variant="middle" />

        <MUIDataTableCustomStyle
          title={Vocabulary.usersList}
          data={state.users}
          columns={userHeader}
          options={getTableOptions()}
        />
      </div>

      <ImportExcelModal
        headers={UserExcelHeader}
        title={`${Vocabulary.import} ${Vocabulary.users}`}
        openImportModal={openImportModal}
        setOpenImportModal={setOpenImportModal}
        getNewRow={getNewUserRow}
        getRowsAsArrayData={(data: any) => importUsers(data)}
      />
      <GenericModal
        title={
          JSON.stringify(selectedUser) === JSON.stringify(new UserModel())
            ? Vocabulary.addNewUser
            : Vocabulary.editUser
        }
        children={
          <UserForm
            selectedUser={selectedUser}
            handleCreateOrUpdateUser={handleCreateOrUpdateUser}
            shouldHandleSubmit={shouldHandleSubmit}
            filterData={filterData}
          />
        }
        open={openModal}
        actions={
          <div className={styles.addUserAction}>
            <Button
              variant="contained"
              className={genericStyles.cancelButton}
              onClick={onCloseUserModal}
            >
              {Vocabulary.cancel}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                setShouldHandleSubmit(!shouldHandleSubmit);
              }}
            >
              {Vocabulary.save}
            </Button>
          </div>
        }
        onClose={onCloseUserModal}
      />
      <ApprovalModal
        handleApproveAction={deleteUser}
        openModal={openDeleteModal}
        handleClose={toggleDeleteModalState}
      />
      <ApprovalModal
        handleApproveAction={deallocateTasksForUser}
        openModal={openDeallocateModal}
        handleClose={toggleDeallocateModalState}
        message={Vocabulary.deallocateMessage}
      />
    </div>
  );
}
