import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getData } from "../Services/getData";
import { localUrlEnum, urlEnum } from "../Utils/UrlEnum";
import MUIDataTableCustomStyle from "../Components/MUIDataTable/MUIDataTableCustomStyle";
import { Vocabulary } from "../Utils/Vocabulary";
import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import genericStyles from "../Styles/genericStyles.module.css";
import styles from "../Styles/departments.module.css";
import { Add, CloudUpload, Delete, Edit } from "@mui/icons-material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { deleteData } from "../Services/deleteData";
import { postData } from "../Services/postData";
import { updateData } from "../Services/updateData";
import { DepartmentModel } from "../Utils/Models";
import { DepartmentsExcelHeader, CommonTableOptions } from "../Utils/Constants";
import GenericModal from "../Components/GenericModal";
import ImportExcelModal from "../Components/ImportExcelModal";
import QueryStringParser from "../Components/QueryStringParser";
import ApprovalModal from "../Components/ApprovalModal";
import useCustomSearchParam from "../Hooks/useCustomSearchParam";

export default function Departments() {
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState<DepartmentModel>(
    new DepartmentModel()
  );
  const [shouldHandleSubmit, setShouldHandleSubmit] = useState(false);
  const ref: any = useRef();
  const navigate = useNavigate();
  const customSearchParam = useCustomSearchParam();
  const [state, setState] = useState<any>({
    page: 0,
    perPage: 20,
    count: 1,
    departments: [],
    search: "",
  });

  /**
   *
   */
  const toggleModalState = () => {
    setOpenModal(!openModal);
  };

  /**
   *
   */
  const toggleDeleteModalState = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  /**
   * Get departments from server
   * @param page
   * @param perPage
   * @param search
   */
  const getDepartments = (
    page: number,
    perPage: number,
    search?: string | null
  ) => {
    getData(
      `${urlEnum.getDepartments}?page=${page}&perPage=${perPage}${
        search ? `&name=${search}` : ""
      }`
    ).then((res: any) => {
      if (res) {
        setState({
          ...state,
          departments: res.data.results,
          count: res.data.nrResults,
          search: search ? search : "",
          page: page,
          perPage: perPage,
        });
      }
    });
  };

  /**
   *
   * @param event
   */
  const onChangeTextField = (event: any) => {
    const newUser: any = Object.assign({}, selectedDepartment);
    newUser[event.target.name] = event.target.value;
    setSelectedDepartment(newUser);
  };

  /**
   *
   * @param id
   */
  const deleteDepartment = () => {
    deleteData(`${urlEnum.deleteDepartment}/${selectedDepartment.id}`)
      .then((res: any) => {
        if (res) {
          getDepartments(state.page, state.perPage, state.search);
        }
      })
      .catch((error: any) => console.log(error));
  };

  /**
   *
   * @param data
   */
  const importDepartments = (data: any) => {
    postData(urlEnum.importDepartments, data).then((res: any) => {
      if (res) {
        setOpenImportModal(false);
        getDepartments(state.page, state.perPage, state.search);
      }
    });
  };

  /**
   *
   * @param row
   * @returns
   */
  function getNewDepartmentRow(row: any) {
    return {
      name: row.Nume,
    };
  }

  /**
   *
   */
  useEffect(() => {
    ref.current?.submit();
  }, [shouldHandleSubmit]);

  /**
   *
   *
   */
  const handleUpdateOrCreateDepartment = () => {
    if (selectedDepartment.id) {
      updateData(urlEnum.updateDepartment, selectedDepartment).then(
        (res: any) => {
          if (res) {
            toggleModalState();
            getDepartments(state.page, state.perPage, state.search);
            setSelectedDepartment(new DepartmentModel());
          }
        }
      );
    } else {
      postData(urlEnum.createDepartment, selectedDepartment).then(
        (res: any) => {
          if (res) {
            toggleModalState();
            getDepartments(state.page, state.perPage, state.search);
            setSelectedDepartment(new DepartmentModel());
          }
        }
      );
    }
  };

  /**
   *
   * @param id
   */
  const handleEditDepartment = (dep: any) => {
    toggleModalState();
    setSelectedDepartment(dep);
  };

  const departmentHeader = [
    {
      label: Vocabulary.crtNo,
      name: "",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return state.page * state.perPage + meta.rowIndex + 1;
        },
      },
    },
    { label: Vocabulary.name, name: "name", options: { sort: false } },
    {
      label: Vocabulary.departmentHead,
      name: "departmentHead",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <>
              {value
                ? value.map((el: any, key: any) => {
                    return key == value.length - 1 ? el : el + ", ";
                  })
                : "-"}
            </>
          );
        },
      },
    },

    {
      label: Vocabulary.options,
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,

        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <ToggleButtonGroup exclusive aria-label="text alignment">
              <Tooltip
                title={Vocabulary.edit}
                className={genericStyles.tooltipEditButton}
              >
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    handleEditDepartment(state.departments[rowIndex]);
                  }}
                >
                  <Edit />
                </ToggleButton>
              </Tooltip>
              <Tooltip title={Vocabulary.delete}>
                <ToggleButton
                  value="center"
                  className={genericStyles.tooltipDeleteButton}
                  aria-label="centered"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setSelectedDepartment(state.departments[rowIndex]);
                    toggleDeleteModalState();
                  }}
                >
                  <Delete />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          );
        },
      },
    },
  ];

  const getTableOptions = () => {
    return {
      ...CommonTableOptions,
      count: state.count,
      rowsPerPage: state.perPage,
      page: state.page,
      customToolbar: () => {
        return (
          <>
            <Button
              variant="contained"
              className={genericStyles.addButton}
              startIcon={<Add />}
              onClick={() => {
                toggleModalState();
                setSelectedDepartment(new DepartmentModel());
              }}
            >
              {Vocabulary.add}
            </Button>
            <Button
              variant="contained"
              className={genericStyles.addButton}
              startIcon={<CloudUpload />}
              onClick={() => {
                setOpenImportModal(true);
              }}
            >
              {`${Vocabulary.import}  ${Vocabulary.departments}`}
            </Button>
          </>
        );
      },
      onSearchChange: async (search: string | null) => {
        if (search && search.length > 3) {
          customSearchParam.addParamToQueryString("search", search);
        } else if (search?.length == 0) {
          customSearchParam.removeParamFromQueryString(["search"]);
        }
      },
      onSearchClose: async () => {
        customSearchParam.removeParamFromQueryString(["search"]);
      },
      onChangePage: (page: any) => {
        customSearchParam.addParamToQueryString("page", page);
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        customSearchParam.addParamToQueryString("perPage", numberOfRows);
        window.scrollTo(0, 0);
      },
      onRowClick: (rowData: any, rowMeta: any) => {
        handleEditDepartment(state.departments[rowMeta.dataIndex]);
      },
    };
  };

  /**
   * Close modal actions
   */
  const onCloseDepartmentModal = () => {
    toggleModalState();
    setSelectedDepartment(new DepartmentModel());
    navigate(localUrlEnum.departments);
  };

  return (
    <>
      <QueryStringParser
        requestFunction={(data: any) => {
          getDepartments(
            data.page ? parseInt(data.page) : state.page,
            data.perPage ? parseInt(data.perPage) : state.perPage,
            data.search
          );
        }}
      />
      <ImportExcelModal
        headers={DepartmentsExcelHeader}
        title={`${Vocabulary.import} ${Vocabulary.departments}`}
        openImportModal={openImportModal}
        setOpenImportModal={setOpenImportModal}
        getNewRow={getNewDepartmentRow}
        getRowsAsArrayData={(data: any) => importDepartments(data)}
      />
      <GenericModal
        maxWidth={"lg"}
        title={Vocabulary.addNewDepartment}
        children={
          <ValidatorForm
            ref={ref}
            onSubmit={() => {
              handleUpdateOrCreateDepartment();
            }}
          >
            <TextValidator
              variant="outlined"
              name="name"
              id="name"
              label={Vocabulary.name}
              fullWidth
              className={styles.textFields}
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={selectedDepartment?.name}
              onChange={onChangeTextField}
            />
          </ValidatorForm>
        }
        open={openModal}
        actions={
          <div className={styles.addDepAction}>
            <Button
              variant="contained"
              className={genericStyles.cancelButton}
              onClick={onCloseDepartmentModal}
            >
              {Vocabulary.cancel}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={() => setShouldHandleSubmit(!shouldHandleSubmit)}
            >
              {Vocabulary.save}
            </Button>
          </div>
        }
        onClose={onCloseDepartmentModal}
      />
      <div className={styles.paper}>
        <MUIDataTableCustomStyle
          title={Vocabulary.departmentsList}
          data={state.departments}
          columns={departmentHeader}
          options={getTableOptions()}
        />
      </div>

      <ApprovalModal
        handleApproveAction={deleteDepartment}
        openModal={openDeleteModal}
        handleClose={toggleDeleteModalState}
      />
    </>
  );
}
