import { ReactNode } from "react";
import genericStyles from "../Styles/genericStyles.module.css";

type Props = {
  children: ReactNode;
  value: number;
  index: number;
};

export default function CustomTabPanel(props: Props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className={genericStyles.fullWidthElement}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
