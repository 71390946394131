import { useState, Fragment, useEffect, useContext } from "react";
import wordIcon from "../../Images/Icons/Word-icon.png";
import excelIcon from "../../Images/Icons/Excel-icon.png";
import pdfIcon from "../../Images/Icons/PDF-icon.png";
import genericIcon from "../../Images/Icons/Generic-icon.png";
import folderIcon from "../../Images/Icons/folder.png";
import { DropzoneArea } from "material-ui-dropzone";
import clsx from "clsx";
import {
  Button,
  Fade,
  Grid,
  LinearProgress,
  Modal,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  CloudUpload,
  CreateNewFolder,
  DeleteForever,
} from "@mui/icons-material";
import styles from "../../Styles/fileExploreMin.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { checkFileType, downloadFile, isImage } from "../../Utils/Utils";
import { postData } from "../../Services/postData";
import { urlEnum } from "../../Utils/UrlEnum";
import GenericModal from "../GenericModal";
import NoDataFound from "../NoDataFound";
import Config from "../../Utils/Config";
import PreviewPDF from "./PreviewPDF";
import { getData } from "../../Services/getData";
import withRole from "../../Hooks/withRole";
import { CustomThemeContext } from "../../Context/CustomThemeContext";
import { toast } from "react-toastify";

const parentFiles: any[] = [];
let crtPath = "";

type Props = {
  id?: any;
  name: string;
  deleteUrl?: string;
  shouldCRUDFile?: boolean;
  baseFolderPath?: string;
  files?: any[];
  componentTextDescription?: string;
};

function FileExplorerMin(props: Props) {
  const [files, setFiles] = useState<any[]>([]);
  const [select, setSelect] = useState(false);
  const [upload, setUpload] = useState(false);
  const [activeImage, setActiveImage] = useState<any>(null);
  const [newUploadedFiles, setNewUploadedFiles] = useState<any[]>([]);
  const [isPreview, setIsPreview] = useState(false);
  const [previewImages, setPreviewImages] = useState<any>([]);
  const [newFolder, setNewFolder] = useState<any>(null);
  const [path, setPath] = useState(Vocabulary.home);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const themeContext: any = useContext(CustomThemeContext);

  /**
   *
   */
  useEffect(() => {
    if (props.files) setFiles(props.files);
    else if (props.baseFolderPath) {
      getData(`${urlEnum.getMedia}${props.baseFolderPath}`).then((res) => {
        if (res) {
          setFiles(res.data);
        }
      });
    }
  }, [props.files, props.id]);

  /**
   * change select to file from default action
   */
  const handleSelectChange = () => {
    setSelect(!select);
  };

  /**
   * enable or disable upload component
   */
  const showUpload = () => {
    setUpload(!upload);
  };

  /**
   * confirm and delete files
   */
  const deleteFiles = () => {
    const selected = files.filter((file: any) => file.active);
    const notSelected = files.filter((file: any) => !file.active);
    const paths = selected.map((file: any) => file.path);
    const url = props.deleteUrl || urlEnum.media;
    postData(url + urlEnum.deleteFolder, { paths: paths, id: props.id }).then(
      (response: any) => {
        // remove all active files
        setFiles(notSelected);
      }
    );
  };

  /**
   * what happens when you click a file
   * @param {Object} file
   */
  const fileClick = (e: any, file: any) => {
    if (!isPreview) {
      e.preventDefault();
      e.cancelBubble = true;
      if (!select) {
        if (file.isDir) {
          parentFiles.push(files);
          crtPath += `/${file.name}`;
          const newPath = `${path}/${file.name}`;
          setPath(newPath);
          file.files === null ? setFiles([]) : setFiles(file.files);
          return false;
        }

        if (isImage(file)) {
          e.preventDefault();
          setActiveImage(file);
          return false;
        } else if (checkFileType(file, "pdf")) {
          setSelectedFile(file);
        } else {
          downloadFile(file);
        }
      }

      file.active = file.active ? !file.active : true;
      const newFiles = files?.map((el: any) =>
        el.path === file.path ? Object.assign({}, file) : el
      );
      setFiles(newFiles);
      return false;
    }
  };

  /**
   *
   */
  const saveFolder = async (e: any) => {
    if (!newFolder || newFolder?.length === 0) {
      setNewFolder(null);
      return;
    }
    const data = {
      id: props.id,
      crtPath: props.baseFolderPath
        ? `${props.baseFolderPath}${crtPath}/`
        : crtPath,
      folderName: newFolder,
    };
    const response: any = await postData(
      urlEnum.media + urlEnum.newFolder,
      data
    );
    if (!response?.errors) {
      const nf = Object.assign([], files);
      nf.push(response?.data);
      setFiles(nf);
      setNewFolder(null);
    }
  };

  /**
   * go up one folder
   */
  const backClick = () => {
    const nameDir = crtPath.replaceAll("/", "");
    if (parentFiles?.length > 0) {
      const index = parentFiles[0].findIndex(
        (file: any) => file.name === nameDir
      );
      if (index > 0) parentFiles[0][index].files = files;
      setFiles(parentFiles.pop());
      crtPath = crtPath.substring(0, crtPath.lastIndexOf("/"));
      const newPath = path.substring(0, path.lastIndexOf("/"));
      setPath(newPath);
    }
  };

  /**
   *
   * @param {*} files
   */
  const handleChangeDropFile = (uploadedFiles: any) => {
    setNewUploadedFiles(uploadedFiles);
    const images: any = [];
    if (uploadedFiles?.length !== 0)
      uploadedFiles.forEach((file: any) => {
        //check if file already exists
        const index = files.findIndex(
          (existingFile: any) => existingFile.name === file.name
        );
        if (index > -1) {
          toast.warn(`${files[index]?.name} ${Vocabulary.fileAlreadyExists}`);
        }
        const reader = new FileReader();
        if (file.type.match("image.*")) {
          reader.readAsDataURL(file);
          reader.onloadend = function () {
            images.push({
              path: reader.result,
              name: file.name,
              percentage: 0,
              previewDocument: "",
            });
            setPreviewImages(images);
          };
        }
      });
  };

  /**
   * Called when files were selected to be uploaded
   *
   */
  async function uploadImage(formData: any, file: any) {
    const token = localStorage.getItem("access_token");
    const index = previewImages.findIndex(
      (image: any) => image.name === file.name
    );
    const promise = new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      const url = `${urlEnum.media + urlEnum.newFile}`;
      request.responseType = "json";
      request.upload.addEventListener("progress", function (e) {
        const { loaded } = e;
        const { total } = e;
        const percentage = (loaded / total) * 100 ? (loaded / total) * 100 : 0;
        const crtImages: any = previewImages.slice();
        if (index > -1) {
          crtImages[index].percentage = parseInt(percentage?.toString());
          crtImages[index].previewDocument = renderFile(crtImages[index]);
        }
        setPreviewImages(crtImages);
      });
      request.onreadystatechange = function () {
        if (request.readyState === 4) {
          if (request.status !== 200) reject();
          else return resolve(request.response);
        }
      };
      request.open("post", url);
      request.setRequestHeader("Authorization", `Bearer ${token}`);
      request.send(formData);
    });
    return promise;
  }

  /**
   *
   * @returns
   */
  const doneUploadFiles = async () => {
    const promises = [];
    setUpload(false);
    if (newUploadedFiles.length === 0) {
      setUpload(false);
      return;
    }
    let prevImg = [];
    const crtImages: any = previewImages.slice();
    if (previewImages.length !== 0) {
      setIsPreview(true);
      prevImg = previewImages?.map((image: any) => {
        return renderFile(image);
      });
      for (let i = 0; i < previewImages.length; i++)
        crtImages[i].previewDocument = prevImg[i];
      setPreviewImages(crtImages);
    }
    for (const file of newUploadedFiles) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "crtPath",
        props.baseFolderPath ? `${props.baseFolderPath}${crtPath}` : crtPath
      );
      promises.push(await uploadImage(formData, file));
      previewImages.forEach((image: any) => {
        if (file.name === image.name) image.name = "";
      });
    }
    Promise.all(promises).then((responses: any) => {
      const newFiles = [];
      setIsPreview(false);
      for (const response of responses) {
        if (!response.errors) {
          // if a file with that name already exists, replace it else add it
          const index = files.findIndex(
            (file: any) => file.name === response.name
          );
          if (index > -1) files[index] = response;
          else newFiles.push(response);
        }
      }
      setFiles(files.concat(newFiles));
      setNewUploadedFiles([]);
    });
  };

  /**
   * Render One File type
   * @param {Object} file
   */
  const renderFile = (file: any) => {
    if (!file) return;
    const maxFileNameLength = 10;
    const name =
      file.name.length > maxFileNameLength
        ? `${file.name.substring(0, maxFileNameLength)}..${file.name.substring(
            file.name.lastIndexOf(".")
          )}`
        : file.name;
    const { path } = file;
    let image: any = "";
    image = previewImages.find((image: any) => file.name === image?.name);
    const ext = name.substring(name.lastIndexOf(".") + 1).toLowerCase();
    let children = (
      <Fragment>
        <img src={genericIcon} alt="file" />
        <label>{name}</label>
      </Fragment>
    );
    switch (ext) {
      case "doc":
      case "docx":
        children = (
          <Fragment>
            <img src={wordIcon} alt="file" />
            <label>{name}</label>
            {image ? <LinearProgress value={file?.percentage} /> : ""}
          </Fragment>
        );
        break;
      case "xls":
      case "xlsx":
      case "ods":
        children = (
          <Fragment>
            <img src={excelIcon} alt="file" />
            <label>{name}</label>
            {image ? <LinearProgress value={file?.percentage} /> : ""}
          </Fragment>
        );
        break;
      case "pdf":
        children = (
          <Fragment>
            <img src={pdfIcon} alt="file" />
            <label>{name}</label>
            {image ? <LinearProgress value={file?.percentage} /> : ""}
          </Fragment>
        );
        break;
      case "jpg":
      case "jpeg":
      case "gif":
      case "png":
        children = (
          <Fragment>
            <img src={`${urlEnum.getFilesAndMedia}${path}`} alt="file" />
            <label>{name}</label>
            {image ? (
              <LinearProgress
                className={styles.linearProgress}
                variant="determinate"
                value={file?.percentage}
              />
            ) : (
              ""
            )}
          </Fragment>
        );
        break;
      default:
        if (file.isDir) {
          image
            ? (children = (
                <Fragment>
                  <img src={image} alt="folder" />
                  <label>{name}</label>
                  <LinearProgress value={file?.percentage} />
                </Fragment>
              ))
            : (children = (
                <Fragment>
                  <img src={folderIcon} alt="folder" />
                  <label>{name}</label>
                </Fragment>
              ));
        } else {
          children = (
            <Fragment>
              <img src={genericIcon} alt="file" />
              <label>{name}</label>
              {image ? <LinearProgress value={file?.percentage} /> : ""}
            </Fragment>
          );
        }
        break;
    }

    return (
      <Button
        onClick={(e) => {
          return fileClick(e, file);
        }}
        key={name}
        className={clsx(styles.file, {
          [styles.active]: file.active,
        })}
      >
        <a
          href={`${document.location.protocol}//${file.path}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span> {children}</span>
        </a>
      </Button>
    );
  };

  /**
   * Render all files
   */
  const renderFiles = () => {
    if (files && files?.length > 0 && !upload) {
      return files?.map((file: any) => {
        return renderFile(file);
      });
    }
    if (files && files.length === 0 && !upload) {
      return (
        <div className={styles.noDataFoundFileMinExplorer}>
          <NoDataFound />
        </div>
      );
    }

    if (upload) {
      return (
        <DropzoneArea
          showFileNamesInPreview={true}
          showFileNames={true}
          filesLimit={Config.maxNrOfUploadFiles}
          maxFileSize={Config.documentUploadMaxSize} //20 MB
          dropzoneText={Vocabulary.dragAndDrop}
          onChange={handleChangeDropFile}
          getFileAddedMessage={(fileName) => Vocabulary.getFileAddedMessage}
          getDropRejectMessage={(rejectedFile) =>
            Vocabulary.getDropRejectMessage
          }
          getFileRemovedMessage={(fileName) => Vocabulary.getFileRemovedMessage}
          getFileLimitExceedMessage={(filesLimit) =>
            Vocabulary.getFileLimitExceedMessage
          }
        />
      );
    }
  };

  /**
   *
   * @returns
   */
  const renderMenu = () => {
    let render = (
      <div className={styles.fileExplorerHeader}>
        <div>
          <Typography variant="h5">
            {`${props.componentTextDescription || Vocabulary.attachedFiles} (${
              files?.length
            })`}
          </Typography>
        </div>
        <div className={styles.menu}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
              <Button
                onClick={backClick}
                disabled={
                  crtPath === "" ||
                  crtPath === "/" ||
                  crtPath === props.baseFolderPath
                }
              >
                <ArrowBack />
                {Vocabulary.back}
              </Button>
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={9} xl={9}>
              <Grid container spacing={3}>
                {props.shouldCRUDFile ? (
                  <>
                    <Grid item xs={12} sm={6} md={3} lg={6} xl={3}>
                      <label>
                        {Vocabulary.select}
                        <Switch
                          disabled={!files || files?.length === 0}
                          checked={select}
                          onChange={handleSelectChange}
                          style={{
                            color: themeContext.theme?.palette.header?.main,
                          }}
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={6} xl={3}>
                      <Button fullWidth onClick={showUpload}>
                        <CloudUpload />
                        {Vocabulary.upload}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={6} xl={3}>
                      <Button
                        fullWidth
                        onClick={(e) => {
                          e.preventDefault();
                          setNewFolder("new");
                          return false;
                        }}
                      >
                        <CreateNewFolder />
                        {Vocabulary.create}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={6} xl={3}>
                      <Button
                        fullWidth
                        onClick={deleteFiles}
                        disabled={!select || files?.length === 0}
                      >
                        <DeleteForever />
                        {Vocabulary.delete}
                      </Button>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
    if (upload) {
      render = (
        <div className={styles.fileExplorerHeader}>
          <Button onClick={doneUploadFiles}>
            <CloudUpload />
            {Vocabulary.finish}
          </Button>
        </div>
      );
    }
    return render;
  };

  /**
   *
   * @returns
   */
  function renderPath() {
    const names = path.split("/");
    if (upload) return "";
    return (
      <div className={styles.path}>
        {names?.map((n, index) => {
          if (index + 1 === names?.length)
            return (
              <p key={index} className={styles.renderPathP}>
                {n}
              </p>
            );
          return (
            <p key={index} className={styles.renderPathP}>
              {n} /
            </p>
          );
        })}{" "}
      </div>
    );
  }

  return (
    <Paper className={styles.fileExplorerContainer}>
      <div>
        {renderMenu()}
        {renderPath()}
        {renderFiles()}
        {isPreview
          ? previewImages.map((prev: any) => {
              return prev.previewDocument;
            })
          : ""}
        {activeImage ? (
          <Modal
            className={styles.modal}
            open={true}
            onClose={() => setActiveImage(null)}
          >
            <Fade in={true}>
              <Paper
                elevation={Config.elevation}
                className={styles.fileExplorerPaper}
              >
                <img
                  src={`${urlEnum.getFilesAndMedia}${activeImage.path}`}
                  alt={activeImage.name}
                />
              </Paper>
            </Fade>
          </Modal>
        ) : null}
        {newFolder ? (
          <GenericModal
            title={Vocabulary.new}
            children={
              <TextField
                required={true}
                type="text"
                fullWidth
                name="folderName"
                label={Vocabulary.name}
                onChange={(e) => {
                  setNewFolder(e.target.value);
                }}
              />
            }
            open={true}
            onClose={() => {
              setNewFolder(null);
            }}
            actions={
              <Button
                variant="contained"
                style={{
                  color: themeContext.theme?.palette.textColorSecondary?.main,
                  backgroundColor: themeContext.theme?.palette.header?.main,
                }}
                onClick={saveFolder}
              >
                {Vocabulary.save}
              </Button>
            }
          />
        ) : null}

        <PreviewPDF
          title={selectedFile?.name}
          open={selectedFile ? true : false}
          onClose={() => {
            setSelectedFile(null);
          }}
          htmlContent=""
          url={
            selectedFile?.path
              ? `${urlEnum.getFilesAndMedia}${selectedFile?.path}`
              : undefined
          }
        />
      </div>
    </Paper>
  );
}

export default withRole(FileExplorerMin);
