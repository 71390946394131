import { Grid } from "@mui/material";
import genericStyles from "../Styles/genericStyles.module.css";
import styles from "../Styles/messageChanel.module.css";
import ChatList from "../Components/Messages/ChatList";
import ChatContainer from "../Components/Messages/ChatContainer";

export default function Chat() {
  return (
    <div className={genericStyles.mainContainer}>
      <Grid container spacing={3}>
        <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
          <ChatList />
        </Grid>
        <Grid
          item
          xl={10}
          lg={10}
          md={8}
          sm={8}
          xs={12}
          className={styles.chatContainer}
        >
          <ChatContainer />
        </Grid>
      </Grid>
    </div>
  );
}
