import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import genericStyles from "../Styles/genericStyles.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import DropzoneComponent from "../Components/DropzoneComponent";
import { foldersNames, urlEnum } from "../Utils/UrlEnum";
import { useEffect, useState } from "react";
import { getData } from "../Services/getData";
import styles from "../Styles/settings.module.css";
import { Info, Lock, ManageAccounts, Visibility } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { updateData } from "../Services/updateData";
import { UserModel } from "../Utils/Models";
import { generateFormData } from "../Utils/Utils";

export default function UserSettings() {
  const userId = localStorage.getItem("userId");
  const [user, setUser] = useState<UserModel>();
  const [showPassword, setShowPassword] = useState(false);

  /**
   *
   * @param event
   */
  const onChangeTextField = (event: any) => {
    const newUser: any = Object.assign({}, user);
    newUser[event.target.name] = event.target.value;
    setUser(newUser);
  };

  /**
   *
   * @param file
   */
  const onChangeFile = (file: any) => {
    const newUser: any = Object.assign({}, user);
    newUser.file = file;
    setUser(newUser);
  };

  /**
   *
   */
  const updateUser = () => {
    const userFormData = generateFormData(user);
    updateData(urlEnum.updateUser, userFormData)
      .then((res: any) => {
        if (res) {
          setUser(res.data);
          localStorage.setItem("profilePicture", res.data.profilePicture);
          localStorage.setItem("userName", res.data.lastName);
        }
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  /**
   *
   */
  const generatePassword = () => {
    const newUser: any = Object.assign({}, user);
    const pass = uuidv4();
    newUser.password = pass.substring(0, 10);
    setUser(newUser);
  };

  /**
   *
   */
  useEffect(() => {
    getData(`${urlEnum.getUsers}/${userId}`).then((res: any) => {
      if (res) setUser(res.data);
    });
  }, []);

  return (
    <ValidatorForm
      className={styles.settingsMainContainer}
      onSubmit={updateUser}
    >
      <Paper className={styles.dataContainer}>
        <div className={styles.topContainer}>
          <div className={styles.header}>
            <ManageAccounts />
            <Typography className={genericStyles.boldText} variant="h5">
              {Vocabulary.accountSettings}
            </Typography>
          </div>
          <Button type={"submit"} variant="contained">
            {Vocabulary.save}
          </Button>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextValidator
                  id="lastName"
                  name="lastName"
                  label={Vocabulary.lastName}
                  fullWidth
                  validators={["required"]}
                  errorMessages={[Vocabulary.requiredField]}
                  variant="outlined"
                  value={user?.lastName ? user.lastName : ""}
                  onChange={onChangeTextField}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextValidator
                  name="firstName"
                  id="firstName"
                  label={Vocabulary.firstName}
                  fullWidth
                  variant="outlined"
                  validators={["required"]}
                  errorMessages={[Vocabulary.requiredField]}
                  value={user?.firstName ? user.firstName : ""}
                  onChange={onChangeTextField}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  id="email"
                  name="email"
                  label={Vocabulary.email}
                  fullWidth
                  variant="outlined"
                  value={user?.email ? user.email : ""}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  id="position"
                  name="position"
                  disabled={true}
                  label={Vocabulary.position}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={user?.position ? user.position : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  variant="outlined"
                  id="password"
                  name="password"
                  value={user?.password ? user.password : ""}
                  type={!showPassword ? "password" : "text"}
                  label={Vocabulary.password}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => onChangeTextField(e)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className={styles.userSettingsVisibilityIcon}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          <Visibility />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Button
                  variant="contained"
                  endIcon={<Lock />}
                  onClick={generatePassword}
                >
                  {Vocabulary.generate}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <div className={styles.dropZone}>
              <DropzoneComponent
                onSave={onChangeFile}
                key={user?.profilePicture}
                paths={[
                  `${urlEnum.getFilesAndMedia}${foldersNames.usersProfiles}${user?.profilePicture}`,
                ]}
              />
            </div>
          </Grid>
        </Grid>
        <div className={styles.header}>
          <Info />
          <Typography className={genericStyles.boldText} variant="h5">
            {Vocabulary.accountInfo}
          </Typography>
        </div>
        <div className={styles.secondContainer}>
          <Grid container spacing={3}>
            {user?.departmentName && (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Divider className={styles.divider} textAlign="left">
                  {Vocabulary.department}
                </Divider>
                <Chip
                  className={styles.chip}
                  label={user.departmentName}
                  color="primary"
                />
              </Grid>
            )}
            {user?.subordinateDepartmentsArray &&
              user.subordinateDepartmentsArray?.length > 0 && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Divider className={styles.divider} textAlign="left">
                    {Vocabulary.subordinateDepartments}
                  </Divider>
                  {user?.subordinateDepartmentsArray?.map(
                    (subDep: any, key: any) => {
                      return (
                        <Chip
                          className={styles.chip}
                          key={key}
                          label={subDep}
                        />
                      );
                    }
                  )}
                </Grid>
              )}
          </Grid>
        </div>
      </Paper>
    </ValidatorForm>
  );
}
