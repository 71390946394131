/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import { useContext, useEffect, useState } from "react";
import { AutocompleteDataForRequests } from "../../Utils/Models";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Delete } from "@mui/icons-material";
import { CustomThemeContext } from "../../Context/CustomThemeContext";
import DatePickersIntervalForFilters from "../DatePickersIntervalForFilters";

type Props = {
  countRequests: number;
  methods: any;
};

function RequestsFilters(props: Props) {
  const [filters, setFilters] = useState<AutocompleteDataForRequests>(
    new AutocompleteDataForRequests()
  );
  const themeContext: any = useContext(CustomThemeContext);
  const customSearchParams = useCustomSearchParam();
  const { methods } = props;

  /**
   *
   */
  useEffect(() => {
    const promises = [getData(urlEnum.getRequestStatusesForFilter)];
    Promise.all(promises).then((res: any) => {
      setFilters({ ...filters, statuses: res[0].data });
    });
  }, []);

  /**
   *
   */
  const resetRequestsFilters = () => {
    methods.reset();
    customSearchParams.removeParamFromQueryString(["status", "start", "end"]);
  };

  /**
   *
   * @param key
   * @param value
   */
  const handleChangeFilters = (key: string, value: any) => {
    if (value !== null && value !== undefined) {
      //if value is an array then create a string from with, whit values separated from comma
      if (Array.isArray(value)) {
        value = value?.map((x) => x.id).join(";");
      }
      customSearchParams.addParamToQueryString(key, value);
    } else {
      //check if parameter deleted is type, then delete selected project as well
      if (key === "type") {
        customSearchParams.removeParamFromQueryString(["project"]);
      }
      customSearchParams.removeParamFromQueryString([key]);
    }
    window.scrollTo(0, 0);
  };

  return (
    <form className={genericStyles.fullWidthElement}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} xl={3}>
          <Typography variant="h6">
            {`${Vocabulary.results}: ${props.countRequests} `}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={3}>
          <DatePickersIntervalForFilters
            methods={methods}
            handleChangeFilters={handleChangeFilters}
            customStyle={{
              display: "flex",
              justifyContent: "space-between",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={4}>
          <Controller
            name="status"
            control={methods.control}
            defaultValue={[]}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                noOptionsText={Vocabulary.noResultsFound}
                multiple
                className={genericStyles.fullWidthElement}
                id={"status"}
                options={filters?.statuses}
                getOptionLabel={(option: any) => option.translation}
                isOptionEqualToValue={(option: any, value: any) => {
                  const valueId = value.id ? value.id : value;
                  return option.id === valueId;
                }}
                onChange={(event, value) => {
                  field.onChange(value);
                  handleChangeFilters(
                    "status",
                    value.length > 0 ? value : null
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Vocabulary.chooseStatus}
                    variant="outlined"
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={1}>
          <Tooltip
            title={Vocabulary.deleteFilters}
            sx={{
              backgroundColor: themeContext.theme?.palette.cancel?.main,
            }}
          >
            <IconButton
              onClick={resetRequestsFilters}
              color="secondary"
              size="large"
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
}

export default RequestsFilters;
