import { InputLabel, Paper } from "@material-ui/core";
import { ClientContractFile } from "../../Utils/Models";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Button, Grid, IconButton } from "@mui/material";
import genericStyles from "../../Styles/genericStyles.module.css";
import { useContext, useState } from "react";
import { postData } from "../../Services/postData";
import { urlEnum } from "../../Utils/UrlEnum";
import { downloadFile } from "../../Utils/Utils";
import { Download, FileUpload, Save } from "@mui/icons-material";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { Delete } from "@material-ui/icons";
import { toast } from "react-toastify";
import { HttpStatusCode } from "../../Utils/Constants";

type Props = {
  fileData: ClientContractFile;
  uploadPath: string;
  id: string;
};

export default function UploadFileForm(props: Props) {
  const { fileData, uploadPath } = props;
  const [file, setFile] = useState<any>();
  const refreshContext = useContext(RefreshDataContext);

  /**
   *
   * @param event
   */
  const handleInputChange = (event: any) => {
    const file = event.target.files[0];
    const newFile = new File([file], fileData.name, {
      type: file?.type,
    });
    setFile(newFile);
  };

  /**
   *
   */
  const uploadFile = () => {
    if (!file) {
      toast.error(Vocabulary.noFileAttached);
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("crtPath", uploadPath || "");
    postData(`${urlEnum.media + urlEnum.newFile}`, formData).then((res) => {
      if (res && res?.status === HttpStatusCode.OK) {
        refreshContext.setRefresh(true);
      }
    });
  };

  const deleteFile = (file: any) => {
    postData(urlEnum.media + urlEnum.deleteFolder, {
      paths: [file.fileData.path],
      id: props.id,
    }).then((response: any) => {
      refreshContext.setRefresh(true);
    });
  };

  return (
    <Paper
      className={`${genericStyles.uploadFileFormPaper} ${
        fileData.fileContent ? "" : genericStyles.uploadFileFormMissingFile
      }`}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={3}
          className={genericStyles.centeredItem}
        >
          <InputLabel className={genericStyles.uploadFileFormFileName}>
            {fileData.title}
          </InputLabel>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={2}
          className={genericStyles.centeredItem}
        >
          <div className={genericStyles.uploadFileFormButton}>
            <FileUpload />
            <label htmlFor={`${props.id}contractFiles`}>
              {Vocabulary.chooseFile.toUpperCase()}
            </label>
            <input
              style={{ display: "none" }}
              onChange={handleInputChange}
              type="file"
              id={`${props.id}contractFiles`}
              accept=".doc, .docx, .xls, .xlsx"
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={3}
          className={genericStyles.centeredItem}
        >
          <Button
            size="small"
            startIcon={<Save />}
            variant="contained"
            className={genericStyles.uploadFileFormSaveButton}
            onClick={uploadFile}
          >
            {Vocabulary.save}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={3}
          className={genericStyles.centeredItem}
        >
          <Button
            size="small"
            startIcon={<Download />}
            variant="contained"
            className={genericStyles.uploadFileFormDownloadButton}
            disabled={!fileData.fileContent}
            onClick={() => {
              downloadFile(fileData);
            }}
          >
            {Vocabulary.download}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={1}
          className={genericStyles.centeredItem}
        >
          {fileData.fileContent ? (
            <IconButton onClick={() => deleteFile(props)}>
              <Delete />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
}
