/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import genericStyles from "../Styles/genericStyles.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import { CloudUpload, ContentCopy, Delete, Edit } from "@mui/icons-material";
import MUIDataTableCustomStyle from "../Components/MUIDataTable/MUIDataTableCustomStyle";
import { Fragment, useContext, useEffect, useState } from "react";
import { getData } from "../Services/getData";
import { localUrlEnum, urlEnum } from "../Utils/UrlEnum";
import { useLocation } from "react-router-dom";
import Config from "../Utils/Config";
import { deleteData } from "../Services/deleteData";
import { AutocompleteDataForTask, TasksStateModel } from "../Utils/Models";
import TasksFilters from "../Components/Tasks/TasksFilters";
import moment from "moment";
import ApprovalModal from "../Components/ApprovalModal";
import { GlobalContext } from "../Context/GlobalContext";
import {
  CommonTableOptions,
  TasksExcelHeader,
  subtaskColumns,
} from "../Utils/Constants";
import { RefreshDataContext } from "../Context/RefreshDataContext";
import {
  checkIfObjectIsEmpty,
  checkIfTaskIsLate,
  checkIfVariableIsString,
  convertToBoolean,
  createURLFromParams,
  formatStringDate,
} from "../Utils/Utils";
import useCustomSearchParam from "../Hooks/useCustomSearchParam";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ImportExcelModal from "../Components/ImportExcelModal";
import { postData } from "../Services/postData";
import { ETaskStatus } from "../Utils/Enums";
import { CacheContext } from "../Context/CacheContext";
import useQueryStringParser from "../Hooks/useQueryStringParser";

function Tasks(props: any) {
  const [filtersData, setFiltersData] = useState(new AutocompleteDataForTask());
  const [state, setState] = useState<TasksStateModel>(new TasksStateModel());
  const customSearchParams = useCustomSearchParam();
  const user = localStorage.getItem("userId");
  const [statusChange, setStatusChange] = useState<boolean>(false);
  const cacheContext = useContext(CacheContext);
  const [openModal, setOpenModal] = useState(false);
  const globalTask = useContext(GlobalContext);
  const refreshContext = useContext(RefreshDataContext);
  const location = useLocation();
  const [openImportModal, setOpenImportModal] = useState(false);
  const [shouldRenderUserFilters, setShouldRenderUsersFilters] =
    useState<boolean>(false);
  const tasksFiltersValidationSchema = yup
    .object()
    .shape({
      project: yup.string(),
      priority: yup.string(),
      periodicity: yup.array(),
      status: yup.array(),
      type: yup.array(),
      lateTask: yup.boolean(),
      user: yup.object(),
      start: yup.object(),
      end: yup.object(),
      sortProperty: yup.string(),
    })
    .required();

  const methods = useForm({
    defaultValues: {
      lateTask: false,
      start: moment().startOf("month").subtract(2, "months"),
      end: moment().endOf("month"),
      sortProperty: "end",
    },
    resolver: yupResolver(tasksFiltersValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    // shouldUnregister: false,
    criteriaMode: "firstError",
  });

  /**
   *
   * @param data
   */
  const getTasksFromQueryString = (data: any) => {
    let status;
    if (checkIfObjectIsEmpty(data) && !statusChange) {
      const defaultStatuses: any = cacheContext.cache.taskStatuses?.filter(
        (status: any) =>
          status?.name === ETaskStatus.STATUS_IN_PROGRESS ||
          status?.name === ETaskStatus.STATUS_NOT_STARTED
      );
      const newDefaultStatuses = defaultStatuses
        ?.map((status: any) => status?.id)
        .join(";");
      if (!customSearchParams.searchParams.has("status")) {
        customSearchParams.addParamToQueryString("status", newDefaultStatuses);
      }
      status = newDefaultStatuses;
      methods.setValue("status", defaultStatuses);
    }
    getTasks(
      data.page ? parseInt(data.page) : state.page,
      data.perPage ? parseInt(data.perPage) : state.perPage,
      data.start
        ? data.start
        : moment(methods.getValues("start")).format(Config.momentUSDateFormat),
      data.end
        ? data.end
        : moment(methods.getValues("end")).format(Config.momentUSDateFormat),
      data.sortProperty ? data.sortProperty : state.sortProperty,
      data.sort ? data.sort : state.sort,
      data.search,
      data.priority,
      data.user,
      data.periodicity,
      data.status ? data.status : status,
      data.project,
      data.type,
      data.lateTask !== undefined ? convertToBoolean(data.lateTask) : false
    );
  };
  /**
   *
   */
  useQueryStringParser((data: any) => {
    getTasksFromQueryString(data);
  });

  /**
   *
   */
  useEffect(() => {
    //check if page is reports or tasks
    //for tasks don't render user filters
    if (!location.pathname.includes(localUrlEnum.reports)) {
      setShouldRenderUsersFilters(false);
    } else {
      setShouldRenderUsersFilters(true);
    }
  }, []);

  /**
   *
   * @param data
   */
  const importTasks = (data: any) => {
    postData(urlEnum.importTasks, data).then((res: any) => {
      if (res) {
        getTasksUsingStateData();
        setOpenImportModal(false);
      }
    });
  };

  /**
   *
   * @param task
   */
  const duplicateTask = (task: any) => {
    postData(urlEnum.duplicateTask, task).then((res) => {
      refreshContext.setRefresh(true);
    });
  };
  /**
   *
   */
  useEffect(() => {
    const promises = [
      getData(urlEnum.getDepartmentsForFilter),
      getData(`${urlEnum.getUsersForFilter}/${localStorage.getItem("userId")}`),
      getData(`${urlEnum.getTaskPriorities}`),
      getData(`${urlEnum.getTaskStatusesForFilter}`),
      getData(`${urlEnum.getTaskPeriodicities}`),
      getData(`${urlEnum.getTaskTypes}`),
      getData(
        `${urlEnum.getProjectsForFilter}/${localStorage.getItem("userId")}`
      ),
    ];
    Promise.all(promises)
      .then((res) => {
        setFiltersData({
          ...filtersData,
          departments: res[0]?.data,
          users: res[1]?.data,
          priorities: res[2]?.data,
          statuses: res[3]?.data,
          periodicities: res[4]?.data,
          types: res[5]?.data,
          projects: res[6]?.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   *
   * @param filter
   */
  const getTasks = (
    page: number,
    perPage: number,
    start: any,
    end: any,
    sortProperty: string,
    sort: string,
    search: string | null,
    priority?: string | null,
    assignedUsers?: any,
    periodicity?: string | null,
    status?: any,
    project?: string | null,
    type?: string | null,
    lateTask?: boolean
  ) => {
    const isReportPage = location.pathname.includes(localUrlEnum.reports);
    if (lateTask) status = null;
    const userFilter = isReportPage
      ? assignedUsers
        ? `&user=${
            checkIfVariableIsString(assignedUsers)
              ? assignedUsers
              : assignedUsers?.id
          }`
        : null
      : `&user=${user}`;
    const url = createURLFromParams(
      `${
        urlEnum.getTasks
      }?page=${page}&perPage=${perPage}&start=${start}&end=${end}&sortProperty=${sortProperty}&sort=${sort}&lateTask=${lateTask}${
        search ? `&search=${search}` : ""
      }${userFilter ? userFilter : ""}&allParams=`,
      {
        priority: priority ? priority : null,
        periodicity: periodicity ? periodicity : null,
        status: status ? status : null,
        project: project ? project : null,
        type: type ? type : null,
      }
    );
    getData(url).then((res: any) => {
      if (res) {
        setState({
          ...state,
          tasks: res.data.results,
          count: res.data.nrResults,
          processingTasksCount: res.data.processingTasksCount,
          notStartedTasksCount: res.data.notStartedTasksCount,
          search: search,
          page: page,
          perPage: perPage,
          sortProperty: sortProperty,
          sort: sort,
        });
      }
    });
  };

  const getTableOptions = () => {
    return {
      ...CommonTableOptions,
      count: state.count,
      rowsPerPage: state.perPage,
      page: state.page,
      onColumnSortChange: (changedColumn: any, direction: any) => {
        customSearchParams.addParamToQueryString("sortProperty", changedColumn);
        customSearchParams.addParamToQueryString("sort", direction);
      },
      onChangePage: (page: any) => {
        customSearchParams.addParamToQueryString("page", page);
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        customSearchParams.addParamToQueryString("perPage", numberOfRows);
        window.scrollTo(0, 0);
      },
      customToolbar: () => {
        return shouldRenderUserFilters ? (
          <Button
            variant="contained"
            className={genericStyles.addButton}
            startIcon={<CloudUpload />}
            onClick={() => {
              setOpenImportModal(true);
            }}
          >
            {`${Vocabulary.import}  ${Vocabulary.tasks}`}
          </Button>
        ) : null;
      },
      customRowRender: (data: any, dataIndex: any, rowIndex: any) => {
        return renderCustomRow(data, dataIndex, rowIndex);
      },
    };
  };

  /**
   *
   */
  const toggleModalState = () => {
    setOpenModal(!openModal);
  };

  /**
   *
   * @param element
   * @param key
   * @param rowIndex
   * @returns
   */
  const renderColumnCustom = (element: any, key: any, rowIndex: any) => {
    switch (key) {
      case 8:
        return null;
      case 1:
        return (
          <TableCell key={key} align="left">
            {element}
            <p id={`specificParagraph${rowIndex}`}></p>
          </TableCell>
        );
      case 10:
        // Get the reference to the paragraph element by its ID
        const specificParagraph = document.getElementById(
          `specificParagraph${rowIndex}`
        );
        // Your content to render
        const contentToRender = `${Vocabulary.assignedBy}: <b>${
          element?.firstName || ""
        } ${element?.lastName || ""}</b>`;
        // Set the content inside the paragraph element
        if (specificParagraph) specificParagraph.innerHTML = contentToRender;
        return null;
      case 2:
        return shouldRenderUserFilters ? (
          <TableCell key={key} align="left">
            {element}
          </TableCell>
        ) : null;
      case 11:
        return (
          <TableCell key={key} align="center">
            <ToggleButtonGroup exclusive aria-label="text alignment">
              <Tooltip
                title={Vocabulary.edit}
                className={genericStyles.tooltipEditButton}
              >
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    getTask(
                      state.tasks.length ? state.tasks[rowIndex].id : null
                    );
                  }}
                >
                  <Edit />
                </ToggleButton>
              </Tooltip>

              <Tooltip
                title={Vocabulary.duplicateTask}
                className={genericStyles.tooltipDuplicateButton}
              >
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    duplicateTask(state.tasks[rowIndex]);
                  }}
                >
                  <ContentCopy />
                </ToggleButton>
              </Tooltip>
              <Tooltip
                title={Vocabulary.delete}
                className={genericStyles.tooltipDeleteButton}
              >
                <ToggleButton
                  value="center"
                  aria-label="centered"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setState({
                      ...state,
                      selectedTask: state.tasks[rowIndex],
                    });
                    toggleModalState();
                  }}
                >
                  <Delete />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </TableCell>
        );
      default:
        return (
          <TableCell key={key} align="left">
            {element}
          </TableCell>
        );
    }
  };

  /**
   *
   * @param data
   * @param dataIndex
   * @param rowIndex
   * @returns
   */
  const renderCustomRow = (data: any, dataIndex: any, rowIndex: any) => {
    return (
      <Fragment key={dataIndex}>
        <TableRow
          onClick={() => {
            getTask(state.tasks.length ? state.tasks[rowIndex].id : null);
          }}
          className={`${
            checkIfTaskIsLate(state.tasks[rowIndex])
              ? genericStyles.lateTask
              : ""
          }`}
        >
          {data?.map((element: any, key: any) => {
            return renderColumnCustom(element, key, rowIndex);
          })}
        </TableRow>
        {data[8] && data[8].length ? (
          <Fragment key={rowIndex}>
            <tr>
              <td colSpan={10} className={genericStyles.unassignedTasksTd}>
                <TableContainer>
                  <Table className={genericStyles.fullWidthElement}>
                    <TableHead>
                      <TableRow
                        className={genericStyles.unassignedTasksTableRow}
                      >
                        {subtaskColumns?.map((value: any, index: any) => (
                          <TableCell key={index}>{value.label}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody className={genericStyles.subtask}>
                      {data[8]?.map((row: any, dataIndex: any) => (
                        <TableRow key={dataIndex}>
                          {subtaskColumns?.map((column: any, index: any) => (
                            <TableCell key={index} component="th" scope="row">
                              {row[column.name]?.translation ||
                                row[column.name] ||
                                index + 1}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </td>
            </tr>
          </Fragment>
        ) : null}
      </Fragment>
    );
  };

  const taskHeader = [
    {
      label: " ",
      name: "priority",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return (
            <div className={genericStyles.taskHeaderContainer}>
              <div
                className={genericStyles.priorityContainer}
                style={{
                  backgroundColor: value?.color,
                }}
              />
            </div>
          );
        },
      },
    },
    {
      label: Vocabulary.fancyName,
      name: "text",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return `${
            value.length >= 40 ? `${value?.substring(0, 40)}...` : value
          }`;
        },
      },
    },
    {
      label: Vocabulary.assignedUser,
      name: "assignedUsers",
      options: {
        display: shouldRenderUserFilters,
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return value?.map((user: any) => {
            return `${user?.firstName || ""} ${user?.lastName || ""}; `;
          });
        },
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return value?.translation;
        },
      },
    },
    {
      label: Vocabulary.periodicity,
      name: "periodicity",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return value?.translation;
        },
      },
    },
    {
      label: Vocabulary.type,
      name: "type",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return value?.translation;
        },
      },
    },
    {
      label: Vocabulary.startDate,
      name: "start",
      options: {
        sort: true,
        customBodyRender: (value: any, meta: any) => {
          return formatStringDate(value, Config.momentEUDateFormat);
        },
      },
    },
    {
      label: Vocabulary.endDate,
      name: "end",
      options: {
        sort: true,
        customBodyRender: (value: any, meta: any) => {
          return formatStringDate(value, Config.momentEUDateFormat);
        },
      },
    },
    {
      label: "subTasks",
      name: "subTasks",
      options: { display: false },
    },
    {
      label: Vocabulary.delay,
      name: "delay",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) =>
          value ? `${value} ${Vocabulary.days}` : "-",
      },
    },
    {
      label: "createdBy",
      name: "createdBy",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      label: Vocabulary.options,
      name: "Optiuni",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
      },
    },
  ];

  /**
   *
   * @param id
   */
  const getTask = (id: string | null) => {
    if (id) globalTask.setTaskId(id);
  };

  /**
   *
   */
  const getTasksUsingStateData = () => {
    getTasks(
      state.page,
      state.perPage,
      moment(methods.getValues("start"))?.format(Config.momentUSDateFormat),
      moment(methods.getValues("end"))?.format(Config.momentUSDateFormat),
      state.sortProperty,
      state.sort,
      state.search,
      methods.getValues("priority"),
      methods.getValues("user"),
      methods
        .getValues("periodicity")
        ?.map((periodicity: any) => periodicity?.id)
        .join(";"),
      methods
        .getValues("status")
        ?.map((status: any) => status?.id)
        .join(";"),
      methods.getValues("project"),
      methods
        .getValues("type")
        ?.map((type: any) => type?.id)
        .join(";"),
      methods.getValues("lateTask")
    );
  };

  /**
   *
   */
  useEffect(() => {
    if (
      refreshContext.refresh &&
      (location.pathname.includes(localUrlEnum.reports) ||
        location.pathname.includes(localUrlEnum.tasks))
    ) {
      getTasksUsingStateData();
      refreshContext.setRefresh(false);
    }
  }, [refreshContext.refresh]);

  /**
   *
   * @param id
   */
  const deleteTask = () => {
    deleteData(`${urlEnum.deleteTask}/${state.selectedTask?.id}`)
      .then((res: any) => {
        if (res) {
          getTasksUsingStateData();
        }
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  /**
   *
   * @param row
   * @returns
   */
  function getNewTaskRow(row: any) {
    return {
      text: row.Task,
      department: row.Departament,
      taskType: row.Tip,
      taskPriority: row.Prioritate,
      taskPeriodicity: row.Periodicitate,
      taskStatus: row.Stare,
      budget: row.Buget,
      equipment: row.Echipamente,
      progress: row.Progres,
      responsible: row.Responsabil,
      remarks: row.Observatii,
    };
  }

  return (
    <div className={genericStyles.mainContainer}>
      <TasksFilters
        shouldRenderUserFilters={shouldRenderUserFilters}
        methods={methods}
        filtersData={filtersData}
        setStatusChanges={setStatusChange}
      />
      <ImportExcelModal
        headers={TasksExcelHeader}
        title={`${Vocabulary.import} ${Vocabulary.tasks}`}
        openImportModal={openImportModal}
        setOpenImportModal={setOpenImportModal}
        getNewRow={getNewTaskRow}
        getRowsAsArrayData={(data: any) => importTasks(data)}
      />
      <div className={genericStyles.fullWidthElement}>
        <MUIDataTableCustomStyle
          title={
            shouldRenderUserFilters
              ? Vocabulary.reportsList
              : `${Vocabulary.tasksList} (${state.count}) din care în desfășurare (${state.processingTasksCount}), neîncepute (${state.notStartedTasksCount}) `
          }
          data={state.tasks}
          columns={taskHeader}
          options={getTableOptions()}
        />
      </div>
      <ApprovalModal
        handleApproveAction={deleteTask}
        openModal={openModal}
        handleClose={toggleModalState}
      />
    </div>
  );
}

export default Tasks;
