/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Tab, Tabs } from "@mui/material";
import styles from "../Styles/dynamicTabs.module.css";
import { useSearchParams } from "react-router-dom";

type Props = {
  labels: string[];
  tabValue: number;
  handleChange?: (tabIndex: number) => void;
};

export default function DynamicTabs(props: Props) {
  const { labels, tabValue } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Set selected tab value and put it in the url, so it can be remembered
   * @param tabIndex
   */
  const handleChangeTab = (tabIndex: number) => {
    if (props.handleChange) props.handleChange(tabIndex);
    //iterate over all params and delete them
    const params = searchParams.toString().split("&");
    params.forEach((param) => {
      const [key, value] = param.split("=");
      searchParams.delete(key);
    });
    searchParams.set("tab", tabIndex.toString());
    setSearchParams(searchParams);
  };

  return (
    <Tabs value={tabValue}>
      {labels?.map((label, index) => {
        return (
          <Tab
            key={index}
            label={label}
            className={`${
              tabValue === index ? styles.selectedTab : styles.nonSelectedTab
            }`}
            onClick={() => handleChangeTab(index)}
          />
        );
      })}
    </Tabs>
  );
}
