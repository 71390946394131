/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "../../Styles/messageChanel.module.css";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { CommentsDisabled, Create, Delete } from "@mui/icons-material";
import GenericModal from "../GenericModal";
import { Fragment, useContext, useEffect, useState } from "react";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { ChatListAutocompleteModel, ChatsStateModel } from "../../Utils/Models";
import { postData } from "../../Services/postData";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import SearchComponent from "../SearchComponent";
import QueryStringParser from "../QueryStringParser";
import useInterval from "../../Hooks/useInterval";
import ApprovalModal from "../ApprovalModal";
import Config from "../../Utils/Config";

export default function ChatList() {
  const [state, setState] = useState<ChatsStateModel>(new ChatsStateModel());
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [openApprovalModal, setOpenApprovalModal] = useState<boolean>(false);
  const authenticatedUser = localStorage.getItem("userId");
  const [filtersData, setFiltersData] = useState<ChatListAutocompleteModel>(
    new ChatListAutocompleteModel()
  );
  const { id } = useParams();
  const [selectedChatId, setSelectedChatId] = useState<string | null>(null);
  const refreshContext = useContext(RefreshDataContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const chatValidationSchema = yup
    .object()
    .shape({
      name: yup.string().required(Vocabulary.requiredField),
      participants: yup.array().required(Vocabulary.requiredField),
    })
    .required();
  const { register, control, handleSubmit, getValues, formState, reset } =
    useForm({
      resolver: yupResolver(chatValidationSchema),
      mode: "onChange",
      reValidateMode: "onChange",
      context: undefined,
      shouldFocusError: true,
      shouldUnregister: false,
      criteriaMode: "firstError",
    });

  /**
   *
   */
  useEffect(() => {
    if (
      refreshContext.refresh &&
      location.pathname.includes(localUrlEnum.messages)
    ) {
      getData(`${urlEnum.getChats}`).then((res: any) => {
        if (res && res.data) {
          setState({
            ...state,
            chats: res.data,
          });
          refreshContext.setRefresh(false);
        }
      });
    }

    return () => {
      if (location.pathname !== localUrlEnum.messages && refreshContext.refresh)
        setState(new ChatsStateModel());
      refreshContext.setRefresh(false);
    };
  }, [refreshContext.refresh]);

  /**
   *
   */
  useInterval(() => {
    const search: any = searchParams.get("search")
      ? searchParams.get("search")
      : "";
    getChatsForUser(search);
  }, Config.poolingNewChatsInterval);

  /**
   *
   * @param search
   */
  const getChatsForUser = (search?: string) => {
    getData(
      `${urlEnum.getChats}?${
        search && search?.length !== 0 ? `&search=${search}` : ""
      }`
    ).then((res: any) => {
      if (res && res.data)
        setState({
          ...state,
          chats: res.data,
        });
    });
  };

  /**
   *
   */
  useEffect(() => {
    const promises = [
      getData(
        `${urlEnum.getUsersForFilter}/${authenticatedUser}?shouldGetAll=true`
      ),
    ];
    Promise.all(promises).then((values: any) => {
      setFiltersData({
        users: values[0].data,
      });
    });
  }, []);

  /**
   *
   */
  const toggleModalState = () => {
    setOpen(!open);
  };

  /**
   *
   */
  const toggleOpenApprovalModal = () => {
    setOpenApprovalModal(!openApprovalModal);
  };

  /**
   *
   * @param errors
   */
  const onErrors = (errors: any) => {
    console.log(errors);
  };

  /**
   *
   */
  const handleSaveChat = () => {
    postData(urlEnum.createOrUpdateChat, getValues()).then((res: any) => {
      if (res) {
        reset();
        refreshContext.setRefresh(true);
        toggleModalState();
      }
    });
  };

  /**
   *
   * @param id
   */
  const selectChat = (id: string) => {
    const search = searchParams.get("search")
      ? `?search=${searchParams.get("search")}`
      : "";
    getData(`${urlEnum.markChatAsRead}/${id}`).then((res: any) => {
      if (res) {
        navigate(`${localUrlEnum.messages}/${id}${search}`);
      }
    });
  };

  /**
   *
   * @param id
   */
  const markConversationAsInactive = (id: string | null) => {
    if (!id) return;
    getData(`${urlEnum.markAsInactive}/${id}`).then((res: any) => {
      if (res) {
        getChatsForUser();
      }
    });
  };

  /**
   *
   * @returns
   */
  const renderConversation = () => {
    return (
      <form id="chatList" onSubmit={handleSubmit(handleSaveChat, onErrors)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              {...register("name")}
              error={typeof formState.errors.name === "object"}
              helperText={formState.errors.name?.message?.toString()}
              variant="outlined"
              label={Vocabulary.fancyName}
              fullWidth
              id={"name"}
              name={"name"}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="participants"
              control={control}
              render={({ field: { ref, ...field } }) => {
                return (
                  <Autocomplete
                    {...field}
                    noOptionsText={Vocabulary.noResultsFound}
                    multiple
                    handleHomeEndKeys
                    options={filtersData?.users || []}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option: any) =>
                      `${option.lastName ? option.lastName : ""} ${
                        option.firstName ? option.firstName : ""
                      }`
                    }
                    value={getValues("participants") || []}
                    onChange={(event, value) => field.onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={Vocabulary.users}
                        inputRef={ref}
                        error={
                          typeof formState.errors.participants === "object"
                        }
                        helperText={formState.errors.participants?.message?.toString()}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </form>
    );
  };

  return (
    <Paper className={styles.chatListContainer}>
      <QueryStringParser
        requestFunction={(data: any) => {
          getChatsForUser(data.search ? data.search : "");
        }}
      />
      <div className={styles.chatListHeader}>
        <div className={styles.chatListHeaderTitle}>
          <Typography variant="h6">{Vocabulary.conversations}</Typography>
          <Tooltip title={Vocabulary.addNewConversation}>
            <IconButton
              className={styles.addNewChanelButton}
              onClick={toggleModalState}
            >
              <Create />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={`${genericStyles.searchField} ${styles.searchForChats}`}
        >
          <SearchComponent />
        </div>
      </div>

      {state.chats?.length <= 0 ? (
        <div className={genericStyles.missingData}>
          <Typography variant="h6">
            {Vocabulary.noConversationsAvailable}
          </Typography>
          <CommentsDisabled fontSize="large" color="secondary" />
        </div>
      ) : (
        <List className={styles.chatList}>
          {state.chats?.map((chatObject: any, index: any) => {
            return (
              <Fragment key={index}>
                <ListItem
                  className={`${
                    !chatObject.isRead ? styles.messagesNotRead : ""
                  }`}
                  onClick={() => selectChat(chatObject.chat.id)}
                >
                  <ListItemAvatar>
                    <Avatar>
                      {chatObject.chat.name.charAt(0).toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontWeight:
                            id === chatObject.chat.id ? "bold" : "normal",
                        }}
                      >
                        {chatObject.chat.name}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => {
                        setSelectedChatId(chatObject.chat.id);
                        toggleOpenApprovalModal();
                      }}
                      edge="end"
                      aria-label="delete"
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider component="li" />
              </Fragment>
            );
          })}
        </List>
      )}
      <ApprovalModal
        handleApproveAction={() => {
          markConversationAsInactive(selectedChatId);
        }}
        openModal={openApprovalModal}
        handleClose={toggleOpenApprovalModal}
      />
      <GenericModal
        title={Vocabulary.newConversation}
        children={renderConversation()}
        actions={
          <>
            <Button
              variant="contained"
              onClick={toggleModalState}
              className={genericStyles.cancelButton}
            >
              {Vocabulary.cancel}
            </Button>
            <Button type="submit" variant="contained" form="chatList">
              {Vocabulary.save}
            </Button>
          </>
        }
        open={open}
        onClose={toggleModalState}
      />
    </Paper>
  );
}
