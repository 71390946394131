/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import MenuIcon from "@mui/icons-material/Menu";
import { Fab, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import styles from "../Styles/mainHeader.module.css";
import genericStyles from "../Styles/genericStyles.module.css";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { foldersNames, localUrlEnum, urlEnum } from "../Utils/UrlEnum";
import { Vocabulary } from "../Utils/Vocabulary";
import { getData } from "../Services/getData";
import { isTablet, logout } from "../Utils/Utils";
import useNoActivity from "../Hooks/useNoActivity";
import withRole from "../Hooks/withRole";
import Notifications from "./Widgets/Notifications";
import Meeting from "./Meetings/Meeting";
import { GlobalContext } from "../Context/GlobalContext";
import { ComponentsNames } from "../Utils/Constants";
import SearchComponent from "./SearchComponent";
import NewTaskButton from "./Tasks/NewTaskButton";
import Task from "./Tasks/Task";
import NewMessageAnimated from "./NewMessageAnimated";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { CustomThemeContext } from "../Context/CustomThemeContext";

let logoutTimeout: any;

type Props = {
  name: string;
  toggleOpenMobileForTableAndMobile: () => void;
  shouldRenderAllData?: boolean;
  shouldRenderSearchBar?: boolean;
};

function MainHeader(props: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const hasActivity = useNoActivity();
  const [openModal, setOpenModal] = useState(false);
  const globalContext = useContext(GlobalContext);
  const userName = localStorage.getItem("userName");
  const themeContext: any = useContext(CustomThemeContext);

  useEffect(() => {
    setOpenModal(globalContext.meetingSelectedId === null ? false : !openModal);
  }, [globalContext.meetingSelectedId]);
  /**
   *
   */
  useEffect(() => {
    if (!hasActivity) {
      logoutTimeout = setTimeout(() => {
        handleLogout();
      }, 1000 * 60 * 5);
    } else {
      if (logoutTimeout) {
        clearTimeout(logoutTimeout);
      }
    }
  }, [hasActivity]);

  /**
   *
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   *
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   */
  const handleLogout = async () => {
    setAnchorEl(null);
    const response: any = await getData(urlEnum.logout);
    if (response && !response.errors) {
      logout();
      navigate(localUrlEnum.login);
    }
  };

  /**
   *
   */
  const redirectToSettings = () => {
    navigate(localUrlEnum.account);
    setAnchorEl(null);
  };

  /**
   *
   */
  const renderBigScreen = () => {
    return (
      <div className={styles.mainHeaderContainer}>
        <div className={genericStyles.flexContainer}>
          <IconButton
            className={styles.menuIconButton}
            onClick={props.toggleOpenMobileForTableAndMobile}
          >
            <MenuIcon />
          </IconButton>

          <div className={styles.mainHeaderImageContainer}>
            <a href={localUrlEnum.dashboard}>
              <img
                src={"/marelvi_white.svg"}
                alt="MARELVI"
                width={"100%"}
                height={"100%"}
              />
            </a>
          </div>
        </div>
        {props.shouldRenderSearchBar && (
          <div className={genericStyles.searchField}>
            <SearchComponent />
          </div>
        )}
        <div className={styles.headerOptionsContainer}>
          <NewTaskButton />
          <Task
            shouldRenderAllData={props.shouldRenderAllData}
            name={ComponentsNames.Task}
          />
          <Notifications />
          <NewMessageAnimated />
          <Typography className={styles.username}>{userName}</Typography>
          <Fab
            onClick={handleClick}
            size="small"
            className={`${styles.fab} ${styles.accountFab}`}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              src={`${urlEnum.getFilesAndMedia}${
                foldersNames.usersProfiles
              }${localStorage.getItem("profilePicture")}`}
            />
          </Fab>
          {renderMenu()}
        </div>
        <Meeting
          name={ComponentsNames.Meeting}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      </div>
    );
  };

  /**
   *
   * @param event
   */
  const handleThemeChange = () => {
    if (themeContext.currentTheme === "dark") {
      themeContext.setTheme("light");
    } else {
      themeContext.setTheme("dark");
    }
  };

  /**
   *
   * @returns
   */
  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={redirectToSettings}>
          <Avatar
            src={`${urlEnum.getFilesAndMedia}${
              foldersNames.usersProfiles
            }${localStorage.getItem("profilePicture")}`}
          />
          {Vocabulary.myAccount}
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleThemeChange}>
          <ListItemIcon>
            {themeContext.theme?.palette.mode === "dark" ? (
              <Brightness7 />
            ) : (
              <Brightness4 />
            )}
          </ListItemIcon>
          {themeContext.theme.palette.mode} mode
        </MenuItem>
        <Divider /> */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {Vocabulary.logout}
        </MenuItem>
      </Menu>
    );
  };

  /**
   *
   * @returns
   */
  const renderSmallScreen = () => {
    return (
      <Grid container spacing={2} className={styles.mainHeaderContainer}>
        <Grid
          item
          md={12}
          xs={12}
          id="firstGrid"
          className={`${styles.mobileGrid} ${styles.mobileFirstGrid}`}
        >
          <Grid container spacing={2}>
            <Grid item md={1} sm={1} xs={1}>
              <IconButton
                className={styles.menuIconButton}
                onClick={props.toggleOpenMobileForTableAndMobile}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item lg={8} md={10} sm={10} xs={9}>
              <div className={styles.mainHeaderImageContainer}>
                <a href={localUrlEnum.dashboard}>
                  <img
                    src={"/marelvi_white.svg"}
                    alt="MARELVI"
                    width={"100%"}
                    height={"100%"}
                  />
                </a>
              </div>
            </Grid>
            <Grid
              item
              lg={3}
              md={1}
              sm={1}
              xs={2}
              className={genericStyles.flexContainer}
            >
              <Fab
                onClick={handleClick}
                size="small"
                id="accountFab"
                className={`${styles.fab}`}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  src={`${urlEnum.getFilesAndMedia}${
                    foldersNames.usersProfiles
                  }${localStorage.getItem("profilePicture")}`}
                />
              </Fab>
              {renderMenu()}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          id="secondGrid"
          className={` ${styles.mobileGrid} ${styles.mobileSecondGrid}`}
        >
          <Grid container spacing={2}>
            <Grid item md={1} sm={1} xs={2}>
              <Notifications />
            </Grid>
            <Grid item lg={8} md={9} sm={9} xs={6}>
              {props.shouldRenderSearchBar && (
                <div className={genericStyles.searchField}>
                  <SearchComponent />
                </div>
              )}
            </Grid>
            <Grid item md={3} sm={2} xs={4}>
              <NewTaskButton />
              <Task
                shouldRenderAllData={props.shouldRenderAllData}
                name={ComponentsNames.Task}
              />
            </Grid>
          </Grid>
        </Grid>
        <NewMessageAnimated />
      </Grid>
    );
  };

  return isTablet() ? renderSmallScreen() : renderBigScreen();
}

export default withRole(MainHeader);
