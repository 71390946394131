import { ThemeProvider, createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none !important",
          border: "1px solid #eeeeee !important",
          borderRadius: "15px !important",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Quicksand",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&:after": {
            borderBottom: "2px solid #B02D21 !important",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: "#B02D21",
          },
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#dce5ed !important",
          padding: "5px !important",
        },
      },
    },
    MUIDataTableHeadRow: {
      styleOverrides: {
        root: {
          backgroundColor: "#dce5ed !important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "7px !important",
          fontFamily: "Quicksand",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          fontFamily: "Quicksand",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          backgroundColor: "#FFF",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
          color: "#000",
          borderRadius: "50px",
          "&:hover": {
            backgroundColor: "#fff",
            color: "#B02D21",
          },
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },
  },
});

type Props = {
  title: string;
  data: any[];
  columns: any;
  options: any;
};

export default function MUIDataTableCustomStyle(props: Props) {
  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable
        title={props.title}
        data={props.data}
        columns={props.columns}
        options={props.options}
      />
    </ThemeProvider>
  );
}
