const Config: any = {
  apiUrl: "/api",
  momentUSDateFormat: "YYYY-MM-DD",
  momentUSDateAndTimeFormat: "YYYY-MM-DD HH:mm:ss",
  momentUSDateFormatForCalendar: "yyyy-MM-dd",
  momentEUDateFormat: "DD-MM-YYYY",
  momentEUTimeFormat: "HH:mm:ss",
  momentEUTimeFormatForCalendar: "HH:mm",
  momentEUDateTimeFormat: "DD-MM-YYYY HH:mm",
  momentEUDateFormatForDateRange: "MM dd yyyy",
  dateTimePickerFormat: "dd-MM-yyyy HH:mm:ss",
  datePickerFormat: "dd-MM-yyyy",
  rowsPerPage: [20, 50, 100],
  rangeDatePickerFormat: "yyyy-MM-dd",
  rangeDateAndTimePickerFormat: "yyyy-MM-dd HH:mm:ss",
  elevation: 4,
  maxNotificationsNumber: 999,
  missingNumberValue: 0,
  defaultNumberOfAttachments: 1,
  poolingNewChatsInterval: 30000,
  dailyTasksCalendarThreshold: 7,
  monthlyAndWeeklyTasksCalendarThreshold: 2,
  nrOfDailyTasksToPredict: 90,
  nrOfWeeklyTasksToPredict: 12,
  nrOfMonthlyTasksToPredict: 3,
  documentUploadMaxSize: 20000000,
  minUploadFilesNo: 1,
  maxNrOfUploadFiles: 50,
  defaultAcceptedFiles: {
    "image/*": [".png", ".jpeg", ".jpg"],
  },
};
export default Config;
