/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Circle,
  Close,
  DoneAll,
  NotificationsActive,
  TagFaces,
} from "@mui/icons-material";
import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
} from "@mui/material";
import styles from "../../Styles/mainHeader.module.css";
import { Fragment, useContext, useEffect, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import Config from "../../Utils/Config";
import { postData } from "../../Services/postData";
import moment from "moment";
import { GlobalContext } from "../../Context/GlobalContext";
import { useWebSocket } from "../../Context/WebSocketContext";

export default function Notifications() {
  let subscribed = false;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [notifications, setNotifications] = useState<any[]>([]);
  const globalTask = useContext(GlobalContext);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { stompClient } = useWebSocket();
  /**
   *
   */
  useEffect(() => {
    getNotificationsForUser();
  }, []);

  /**
   *
   */
  const getNotificationsForUser = () => {
    getData(
      `${urlEnum.getNotifications}/${localStorage.getItem("userId")}`
    ).then((res: any) => {
      if (res) {
        setNotifications(res.data.results);
        setNotificationCount(res.data.nrResults);
      }
    });
  };

  /**
   *
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  /**
   *
   */
  const handleClose = () => {
    //set notifications to read
    //create array containing ids of notifications  with status unread
    const unreadNotifications = notifications
      .filter((notification) => notification.isRead === false)
      ?.map((notification) => notification.id);
    if (unreadNotifications.length > 0) {
      postData(urlEnum.markNotificationAsRead, {
        notificationsId: unreadNotifications,
      }).then((res: any) => {
        if (res) {
          getNotificationsForUser();
        }
      });
    }
    setAnchorEl(null);
  };

  /**
   *
   */
  useEffect(() => {
    if (stompClient && !subscribed) {
      subscribed = true;
      stompClient.subscribe(
        `/notification/${localStorage.getItem("userId")}/private`,
        onPrivateMessage
      );
    }
    return () => {
      subscribed = false;
      stompClient?.unsubscribe(
        `/notification/${localStorage.getItem("userId")}/private`
      );
    };
  }, [stompClient]);

  /**
   *
   * @param payload
   */
  const onPrivateMessage = (payload: any) => {
    setNotificationCount((prev) => {
      return prev + 1;
    });
    setNotifications((prev) => {
      prev.unshift(JSON.parse(payload.body));
      return prev;
    });
    new Notification(JSON.parse(payload.body).message || "");
  };

  /**
   *
   * @param notification
   */
  const handleNotificationClick = (notification: any) => {
    //check notification type and redirect to the corresponding page
    switch (notification.entityName) {
      case "Task":
        if (notification.entityId) {
          globalTask.setTaskId(notification.entityId);
          handleClose();
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Fab
        id="notificationsFab"
        size="small"
        className={`${styles.fab}`}
        onClick={handleClick}
      >
        <Badge
          badgeContent={notificationCount}
          max={Config.maxNotificationCount}
          classes={{ badge: styles.badge }}
        >
          <NotificationsActive />
        </Badge>
      </Fab>
      <Popover
        id={id}
        open={open}
        className="notificationsPopover"
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Card>
          <CardHeader
            subheader={Vocabulary.notifications}
            action={
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            }
          />
          <CardContent sx={{ padding: 0 }}>
            <List>
              {notifications && notifications.length > 0 ? (
                notifications?.map((notification: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <ListItemButton
                        onClick={() => handleNotificationClick(notification)}
                        className={`${
                          notification.isRead ? styles.notificationRead : ""
                        }`}
                      >
                        <ListItemAvatar
                          className={`${
                            notification.isRead
                              ? styles.messageRead
                              : styles.messageNotRead
                          }`}
                        >
                          {notification.isRead ? <DoneAll /> : <Circle />}
                        </ListItemAvatar>
                        <ListItemText
                          className={`${
                            notification.isRead
                              ? styles.messageRead
                              : styles.messageNotRead
                          }`}
                          primary={`${notification.message?.substring(
                            0,
                            50
                          )}...`}
                          secondary={moment(notification.createdAt).format(
                            Config.momentEUDateTimeFormat
                          )}
                        />
                      </ListItemButton>
                      <Divider />
                    </Fragment>
                  );
                })
              ) : (
                <ListItem>
                  <ListItemText>{Vocabulary.noNotifications} </ListItemText>
                  <ListItemAvatar>
                    <TagFaces />
                  </ListItemAvatar>
                </ListItem>
              )}
            </List>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
}
