import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import styles from "../../Styles/tasksTable.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Class, People, Repeat, TaskAltSharp, Work } from "@mui/icons-material";
import genericStyles from "../../Styles/genericStyles.module.css";
import FilterContainer from "../FilterContainer";
import TasksLegend from "./TasksLegend";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import { Controller } from "react-hook-form";
import { checkTaskType, convertToBoolean } from "../../Utils/Utils";
import { useEffect } from "react";
import moment from "moment";
import DatePickersIntervalForFilters from "../DatePickersIntervalForFilters";
import { useLocation } from "react-router-dom";

type Props = {
  methods: any;
  filtersData: any;
  shouldRenderUserFilters?: boolean;
  setStatusChanges: (statusChanges: any) => void;
};

function TasksFilters(props: Props) {
  const { filtersData, methods, shouldRenderUserFilters } = props;
  const customSearchParams = useCustomSearchParam();
  const location = useLocation();

  /**
   *
   * @param key
   * @param value
   */
  const handleChangeFilters = (key: string, value: any) => {
    if (value !== null && value !== undefined) {
      //if value is an array then create a string from with, whit values separated from comma
      if (Array.isArray(value)) {
        value = value?.map((x) => x.id).join(";");
      }
      customSearchParams.addParamToQueryString(key, value);
    } else {
      //check if parameter deleted is type, then delete selected project as well
      if (key === "type") {
        customSearchParams.removeParamFromQueryString(["project"]);
      }
      customSearchParams.removeParamFromQueryString([key]);
    }
    window.scrollTo(0, 0);
  };

  /**
   *
   */
  const resetTasksFilters = () => {
    methods.reset();
    customSearchParams.removeParamFromQueryString([
      "user",
      "department",
      "project",
      "priority",
      "periodicity",
      "status",
      "assignedUsers",
      "start",
      "end",
      "lateTask",
      "search",
      "sortProperty",
      "sort",
      "type",
    ]);
  };

  /**
   *
   * @param property
   * @returns
   */
  const getOptionsArray = (property: string) => {
    switch (property) {
      case "status":
        return filtersData?.statuses;
      case "priority":
        return filtersData?.priorities;
      case "type":
        return filtersData?.types;
      case "periodicity":
        return filtersData?.periodicities;
      case "project":
        return filtersData?.projects;
      case "user":
        return filtersData?.users;
      default:
        return [];
    }
  };

  /**
   *
   * @param methods
   */
  const resetFilterData = () => {
    const urlParams: any = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParams) {
      const stringValuesArray = value.split(";");
      const objectValuesArray: any = [];
      switch (key) {
        case "status":
        case "type":
        case "periodicity":
          //for each value in array, find the object in the options array and add it to the selectedValues object
          stringValuesArray.forEach((value: any) => {
            const newValue = getOptionsArray(key).find((option: any) => {
              return option.id === value;
            });
            objectValuesArray.push(newValue);
          });
          methods.setValue(key, objectValuesArray);
          break;
        case "project":
        case "user":
        case "priority":
          //for each value in array, find the object in the options array and add it to the selectedValues object
          stringValuesArray.forEach((value: any) => {
            const newValue = getOptionsArray(key).find((option: any) => {
              return option.id === value;
            });
            objectValuesArray.push(newValue);
          });
          methods.setValue(key, objectValuesArray[0]);
          break;
        case "start":
        case "end":
          methods.setValue(key, moment(value));
          break;
        case "lateTask":
          methods.setValue(key, convertToBoolean(value));
          break;
        default:
          methods.setValue(key, value);
          break;
      }
    }
  };

  /**
   *
   */
  useEffect(() => {
    if (filtersData?.types?.length > 0) {
      resetFilterData();
    }
  }, [filtersData, location]);

  return (
    <FilterContainer
      resetFilters={resetTasksFilters}
      filters={
        <>
          {shouldRenderUserFilters ? (
            <div className={genericStyles.filter}>
              <People color="secondary" sx={{ marginRight: "5px" }} />
              <Controller
                name="user"
                defaultValue={null}
                control={methods.control}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...field}
                    noOptionsText={Vocabulary.noResultsFound}
                    className={genericStyles.fullWidthElement}
                    id={"user"}
                    options={filtersData?.users || []}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) =>
                      `${option?.lastName ? option?.lastName : ""} ${
                        option?.firstName ? option?.firstName : ""
                      }`
                    }
                    onChange={(event, value) => {
                      field.onChange(value);
                      handleChangeFilters("user", value?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={Vocabulary.chooseUser}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </div>
          ) : null}
          <div className={genericStyles.filter}>
            <TaskAltSharp color="secondary" sx={{ marginRight: "5px" }} />
            <Controller
              name="status"
              control={methods.control}
              defaultValue={[]}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  noOptionsText={Vocabulary.noResultsFound}
                  className={genericStyles.fullWidthElement}
                  multiple
                  id={"status"}
                  disabled={methods.getValues("lateTask")}
                  options={filtersData?.statuses || []}
                  getOptionLabel={(option: any) => option.translation}
                  isOptionEqualToValue={(option: any, value: any) => {
                    const valueId = value.id ? value.id : value;
                    return option.id === valueId;
                  }}
                  onChange={(event, value) => {
                    field.onChange(value);
                    if (value?.length <= 0) {
                      props.setStatusChanges(true);
                    }
                    handleChangeFilters(
                      "status",
                      value.length > 0 ? value : null
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.chooseStatus}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </div>
          <div className={genericStyles.filter}>
            <Class color="secondary" sx={{ marginRight: "5px" }} />
            <Controller
              defaultValue={[]}
              name="type"
              control={methods.control}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  multiple
                  noOptionsText={Vocabulary.noResultsFound}
                  className={genericStyles.fullWidthElement}
                  id={"type"}
                  options={filtersData?.types || []}
                  getOptionLabel={(option: any) => option.translation}
                  isOptionEqualToValue={(option: any, value: any) => {
                    const valueId = value.id ? value.id : value;
                    return option.id === valueId;
                  }}
                  onChange={(event: any, value: any) => {
                    field.onChange(value);
                    handleChangeFilters(
                      "type",
                      value.length > 0 ? value : null
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.chooseType}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </div>
          {checkTaskType(methods.getValues("type"), Vocabulary.project) ? (
            <div className={genericStyles.filter}>
              <Work color="secondary" sx={{ marginRight: "5px" }} />
              <Controller
                name="project"
                control={methods.control}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    className={genericStyles.fullWidthElement}
                    id={"project"}
                    noOptionsText={Vocabulary.noResultsFound}
                    options={filtersData?.projects || []}
                    getOptionLabel={(option: any) => option.name}
                    isOptionEqualToValue={(option: any, value: any) => {
                      const valueId = value.id ? value.id : value;
                      return option.id === valueId;
                    }}
                    onChange={(event: any, value: any) => {
                      field.onChange(value);
                      handleChangeFilters("project", value?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={Vocabulary.chooseProject}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </div>
          ) : null}
          <div className={genericStyles.filter}>
            <Repeat color="secondary" sx={{ marginRight: "5px" }} />
            <Controller
              name="periodicity"
              control={methods.control}
              defaultValue={[]}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  multiple
                  noOptionsText={Vocabulary.noResultsFound}
                  id={"periodicity"}
                  className={genericStyles.fullWidthElement}
                  options={filtersData?.periodicities || []}
                  getOptionLabel={(option: any) => option.translation}
                  isOptionEqualToValue={(option: any, value: any) => {
                    const valueId = value.id ? value.id : value;
                    return option.id === valueId;
                  }}
                  onChange={(event: any, value: any) => {
                    field.onChange(value);
                    handleChangeFilters(
                      "periodicity",
                      value.length > 0 ? value : null
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.choosePeriodicity}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </div>
          <DatePickersIntervalForFilters
            methods={methods}
            handleChangeFilters={handleChangeFilters}
          />
          <Controller
            name="priority"
            control={methods.control}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <ToggleButtonGroup
                {...fieldProps}
                value={value}
                exclusive
                fullWidth
                onChange={(event, newValue) => {
                  onChange(newValue);
                  handleChangeFilters("priority", newValue?.id);
                }}
              >
                {filtersData.priorities?.map((option: any, index: number) => (
                  <ToggleButton
                    key={index}
                    style={{ backgroundColor: option?.color }}
                    className={`${styles[option.name]}
                    ${
                      methods.getValues("priority") &&
                      methods.getValues("priority").id !== option.id
                        ? genericStyles.nonSelectedFilter
                        : ""
                    }`}
                    value={option}
                    aria-label={`${option.value} aligned`}
                  >
                    {option.translation}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
          />
          <div className={genericStyles.filter}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller
                    name={"lateTask"}
                    control={methods.control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.checked);
                          handleChangeFilters("lateTask", e.target.checked);
                        }}
                      />
                    )}
                  />
                }
                label={Vocabulary.lateTasks}
              />
            </FormGroup>
          </div>
          <div className={genericStyles.filter}>
            <TasksLegend />
          </div>
        </>
      }
    />
  );
}

export default TasksFilters;
