import {
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/project.module.css";

import genericStyles from "../../Styles/genericStyles.module.css";
import { entityPath, localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import PropertyContainer from "../Tasks/PropertyContainer";
import { Assignment, Chat, Edit } from "@mui/icons-material";
import GenericModal from "../GenericModal";
import ProjectModal from "./ProjectModal";
import Config from "../../Utils/Config";
import TasksListCardWidget from "../Widgets/TasksListCardWidget";
import FileExplorerMin from "../Widgets/FileExplorerMin";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { ComponentsNames } from "../../Utils/Constants";
import withRole from "../../Hooks/withRole";
import { GlobalContext } from "../../Context/GlobalContext";
import { formatStringDate } from "../../Utils/Utils";
import ChatWindow from "../Messages/ChatWindow";
import ListOfUsersComponent from "../ListOfUsersComponent";
import ReactDOM from "react-dom";
import NewTaskButton from "../Tasks/NewTaskButton";

type Props = {
  name: string;
  shouldEditProject?: boolean;
};

function Project(props: Props) {
  const location = useLocation();
  const globalContext = useContext(GlobalContext);
  const refreshContext = useContext(RefreshDataContext);
  const [state, setState] = useState<any>({
    project: null,
    tasks: [],
    nrOfProjectFinishedTasks: 0,
  });
  const [openEditModal, setOpenEditModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  /**
   *
   */
  useEffect(() => {
    if ((id && id !== undefined) || refreshContext.refresh) {
      const promises = [
        getData(`${urlEnum.getProjects}/${id}`),
        getData(`${urlEnum.getProjectTasks}/${id}`),
      ];
      Promise.all(promises).then((res) => {
        if (res) {
          setState({
            project: res[0]?.data,
            tasks: res[1]?.data.results,
            nrOfProjectFinishedTasks: res[1]?.data.nrResults,
          });
          globalContext.setProjectSelected(res[0].data);
          refreshContext.setRefresh(false);
        }
      });
    }
  }, [location, refreshContext.refresh]);

  /**
   *
   * @param project
   */
  const handleChangeProject = (project: any) => {
    setState({
      ...state,
      project: project,
    });
  };

  /**
   *
   * @returns
   */
  const checkIfUserIsProjectManager = () => {
    if (state.project?.projectManager?.id === localStorage.getItem("userId")) {
      return true;
    }
    return false;
  };

  /**
   *
   */
  const handleChangeProjectModalState = () => {
    setOpenEditModal(!openEditModal);
  };

  /**
   *
   */
  const navigateToMessages = () => {
    navigate(`${localUrlEnum.messages}/${state.project.chat.id}`);
  };

  /**
   *
   * @returns
   */
  const renderNewTaskButton = () => {
    const ssd: any = document.getElementById("newProjectTaskButton");
    if (ssd)
      return ReactDOM.createPortal(
        <NewTaskButton style={styles.projectTaskButton} />,
        ssd
      );
  };

  return (
    <div className={genericStyles.fullWidthElement}>
      {renderNewTaskButton()}
      <Grid container spacing={3}>
        <Grid item xl={8} xs={12}>
          <Grid container spacing={3}>
            <Grid item xl={6} xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div
                    className={`${genericStyles.header} ${genericStyles.underLineElement}`}
                  >
                    <Typography variant="h5">{`${Vocabulary.project} ${state.project?.name}`}</Typography>
                    <div>
                      <Tooltip title={Vocabulary.messages} arrow>
                        <IconButton onClick={navigateToMessages}>
                          <Chat fontSize="large" color="secondary" />
                        </IconButton>
                      </Tooltip>
                      {(props.shouldEditProject ||
                        checkIfUserIsProjectManager()) && (
                        <Tooltip title={Vocabulary.editProject}>
                          <IconButton onClick={handleChangeProjectModalState}>
                            <Edit fontSize="large" color="secondary" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={genericStyles.fullWidthElement}>
                    <Typography variant="h6">{`${Vocabulary.members} (${
                      state.project?.projectUsers
                        ? state.project?.projectUsers?.length
                        : Config.missingNumberValue
                    })`}</Typography>
                    <div className={styles.userListAvatar}>
                      <ListOfUsersComponent
                        users={state.project?.projectUsers}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={styles.projectPaper}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          {Vocabulary.baseInfo}
                        </Typography>
                      </Grid>
                      <PropertyContainer
                        property={Vocabulary.description}
                        propertyInput={
                          <Typography variant="body1">
                            {state.project?.description}
                          </Typography>
                        }
                      />
                      <PropertyContainer
                        property={Vocabulary.projectManager}
                        propertyInput={
                          <Typography variant="body1">
                            {`${
                              state.project?.projectManager?.lastName ?? ""
                            } ${
                              state.project?.projectManager?.firstName ?? ""
                            }`}
                          </Typography>
                        }
                      />
                      <PropertyContainer
                        property={Vocabulary.startDate}
                        propertyInput={
                          <Typography variant="body1">
                            {formatStringDate(
                              state.project?.startDate,
                              Config.momentEUDateFormat
                            )}
                          </Typography>
                        }
                      />
                      <PropertyContainer
                        property={Vocabulary.endDate}
                        propertyInput={
                          <Typography variant="body1">
                            {formatStringDate(
                              state.project?.endDate,
                              Config.momentEUDateFormat
                            )}
                          </Typography>
                        }
                      />
                      {state.project &&
                        state.project.customFields?.map(
                          (customField: any, key: any) => {
                            return (
                              <PropertyContainer
                                key={key}
                                property={customField?.name}
                                propertyInput={
                                  <Typography variant="body1">
                                    {customField?.value}
                                  </Typography>
                                }
                              />
                            );
                          }
                        )}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xl={6} xs={12}>
              <TasksListCardWidget
                avatar={<Assignment fontSize="large" />}
                id={"projectTasks"}
                title={Vocabulary.tasks}
                taskList={state.tasks}
                totalFinished={state.nrOfProjectFinishedTasks}
                style={styles.tasksListContainerForProjects}
                shouldRenderDeleteOption={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FileExplorerMin
                shouldCRUDFile={true}
                name={ComponentsNames.FileExplorerMin}
                id={state.project?.id}
                files={state.project?.fileGallery}
                baseFolderPath={
                  state.project?.id
                    ? `${`${entityPath.projects}/${state.project?.id}`}`
                    : undefined
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={4} xs={12}>
          {state.project?.chat?.id && (
            <ChatWindow id={state.project?.chat?.id} />
          )}
        </Grid>
      </Grid>
      <GenericModal
        title={state.project ? state.project?.name : Vocabulary.editProject}
        open={openEditModal}
        onClose={handleChangeProjectModalState}
        children={
          <ProjectModal
            isProjectManager={
              !checkIfUserIsProjectManager() && !props.shouldEditProject
            }
            handleChangeProject={handleChangeProject}
            closeModal={handleChangeProjectModalState}
            project={state.project}
          />
        }
        actions={
          <>
            <Button
              variant="contained"
              onClick={handleChangeProjectModalState}
              className={genericStyles.cancelButton}
            >
              {Vocabulary.cancel}
            </Button>
            <Button type="submit" variant="contained" form="projectForm">
              {Vocabulary.save}
            </Button>
          </>
        }
      />
    </div>
  );
}

export default withRole(Project);
