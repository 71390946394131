import { Straight, SwapVert } from "@mui/icons-material";
import {
  Divider,
  Fab,
  Fade,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../Utils/Vocabulary";
import { Fragment, useState } from "react";
import { Sort } from "../Utils/Enums";
import useCustomSearchParam from "../Hooks/useCustomSearchParam";
import genericStyles from "../Styles/genericStyles.module.css";

type Props = {
  sortList: any[];
  sort: string;
  sortProperty: string;
};

export default function SortingComponent(props: Props) {
  const { sortList, sort, sortProperty } = props;
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const customSearchParam = useCustomSearchParam();
  /**
   *
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  return (
    <>
      <Typography sx={{ marginRight: 3 }}>{Vocabulary.sort}</Typography>
      <Fab
        onClick={handleClick}
        size="small"
        sx={{ backgroundColor: "#fff", zIndex: 1 }}
      >
        <SwapVert color="action" />
      </Fab>

      <Popper
        placement="bottom-start"
        open={open}
        anchorEl={anchorEl}
        transition
        className={genericStyles.sortingComponentPopper}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={genericStyles.sortingComponentPaper}>
              <List>
                {sortList?.map((property: any, index: number) => (
                  <Fragment key={index}>
                    <ListItemButton
                      selected={sortProperty === property.value}
                      onClick={(event: any) =>
                        customSearchParam.addParamToQueryString(
                          "sortProperty",
                          property.value
                        )
                      }
                    >
                      <ListItemText primary={property.label} />
                      <ListItemIcon>
                        <Tooltip
                          title={
                            sort === Sort.ASC ? Vocabulary.asc : Vocabulary.desc
                          }
                        >
                          <IconButton
                            onClick={() => {
                              customSearchParam.addParamToQueryString(
                                "sort",
                                sort === Sort.ASC ? Sort.DESC : Sort.ASC
                              );
                            }}
                          >
                            {sort === Sort.ASC ? (
                              <Straight />
                            ) : (
                              <Straight
                                sx={{
                                  transform: "rotate(180deg)",
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      </ListItemIcon>
                    </ListItemButton>
                    <Divider />
                  </Fragment>
                ))}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
}
