import { CheckCircle, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fab,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Fragment,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import GenericModal from "../GenericModal";
import styles from "../../Styles/tasksListCardWidget.module.css";
import genericStyles from "../../Styles/genericStyles.module.css";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { postData } from "../../Services/postData";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { TaskModel } from "../../Utils/Models";
import Config from "../../Utils/Config";
import { formatStringDate } from "../../Utils/Utils";
import { useLocation } from "react-router-dom";
import NoDataFound from "../NoDataFound";
import { ListItemSecondaryAction } from "@material-ui/core";
import ApprovalModal from "../ApprovalModal";
import { deleteData } from "../../Services/deleteData";

type Props = {
  id: string;
  title: string;
  taskList: TaskModel[];
  totalFinished: number;
  avatar: any;
  style?: any;
  shouldRenderDeleteOption?: boolean;
};

export default function TasksListCardWidget(props: Props) {
  const { title, avatar, id, taskList, totalFinished, style } = props;
  const globalTask = useContext(GlobalContext);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [taskStatuses, setTaskStatuses] = useState<any[]>([]);
  const [selectedTask, setSelectedTask] = useState<any>({});
  const refreshDataContext = useContext(RefreshDataContext);
  const location = useLocation();

  /**
   *
   */
  const toggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  /**
   *
   */
  const toggleModalState = () => {
    setOpenModal(!openModal);
  };

  /**
   *
   */
  const handleChangeStatus = () => {
    postData(urlEnum.createTask, selectedTask).then((res: any) => {
      if (res) {
        setOpenModal(false);
        refreshDataContext.setRefresh(true);
      }
    });
  };

  /**
   *
   * @param event
   * @param value
   */
  const handleChangeTaskStatus = (value: any) => {
    setSelectedTask({ ...selectedTask, status: value });
  };

  /**
   *
   */
  useEffect(() => {
    getData(`${urlEnum.getTaskStatusesForFilter}`).then((response: any) => {
      if (response && response.data) setTaskStatuses(response?.data);
    });
  }, []);

  /**
   *
   * @param id
   */
  const deleteTask = () => {
    deleteData(`${urlEnum.deleteTask}/${selectedTask.id}`)
      .then((res: any) => {
        if (res) {
          refreshDataContext.setRefresh(true);
        }
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  /**
   *
   * @returns
   */
  const shouldRenderOnlyOnProjectPage = () => {
    return location.pathname.includes(localUrlEnum.project);
  };

  return (
    <>
      <Card className={`${styles.tasksListCardContainer} ${id}`}>
        <CardHeader
          className={`${styles.taskListCardWidgetHeader} ${styles[id]}`}
          avatar={
            <Fab size="small" className={styles.taskListCardWidgetFab}>
              {avatar}
            </Fab>
          }
          title={
            <Typography sx={{ fontSize: 16 }} variant="h6">
              {title}
            </Typography>
          }
          subheader={
            <>
              <Typography variant="body2" sx={{ color: "#fff" }}>{`${
                Vocabulary.finishedTasks
              }: ${totalFinished ? totalFinished : 0}/${
                taskList?.length ? taskList?.length : 0
              }`}</Typography>
              <LinearProgress
                color="warning"
                variant="determinate"
                value={
                  totalFinished ? (totalFinished * 100) / taskList?.length : 0
                }
              />
            </>
          }
        />
        <CardContent
          className={`${styles.tasksListCardContent} ${
            taskList?.length ? "" : genericStyles.centeredItem
          } ${style}`}
        >
          <>
            <List className={`${style}`}>
              {taskList?.length > 0 ? (
                taskList?.map((task: TaskModel, index: number) => {
                  return (
                    <Fragment key={index}>
                      <ListItem
                        className={genericStyles.taskListCardWidgetHeader}
                      >
                        <ListItemAvatar>
                          <CheckCircle
                            onClick={() => {
                              toggleModalState();
                              setSelectedTask(task);
                            }}
                            sx={{ color: task.status?.color }}
                            fontSize="large"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          onClick={() => {
                            if (task.id) globalTask.setTaskId(task.id);
                          }}
                          primary={
                            <Fragment>
                              <Typography variant="body2">
                                {`${
                                  task.project
                                    ? `${Vocabulary.project}: ${task.project.name}`
                                    : ""
                                }`}
                              </Typography>
                              {`${index + 1} ${task.text}`}
                            </Fragment>
                          }
                          secondary={
                            <Fragment>
                              <Typography>
                                {`${Vocabulary.endDate}: ${formatStringDate(
                                  task.end,
                                  Config.momentEUDateFormat
                                )}${
                                  shouldRenderOnlyOnProjectPage()
                                    ? ` |  ${
                                        Vocabulary.assignedUser
                                      }: ${task.assignedUsers?.map(
                                        (user: any) => {
                                          return `${user.firstName || ""} ${
                                            user.lastName || ""
                                          }`;
                                        }
                                      )}`
                                    : ""
                                }`}
                              </Typography>
                            </Fragment>
                          }
                          secondaryTypographyProps={{ fontSize: "16px" }}
                        />
                        {props.shouldRenderDeleteOption && (
                          <ListItemSecondaryAction>
                            <Tooltip title={Vocabulary.delete}>
                              <Delete
                                color="primary"
                                onClick={() => {
                                  setSelectedTask(task);
                                  toggleDeleteModal();
                                }}
                              />
                            </Tooltip>
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>

                      <Divider />
                    </Fragment>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </List>
            {shouldRenderOnlyOnProjectPage() && (
              <div
                id="newProjectTaskButton"
                className={styles.newProjectsTaskButton}
              />
            )}
          </>
        </CardContent>
      </Card>
      <ApprovalModal
        handleApproveAction={deleteTask}
        openModal={openDeleteModal}
        handleClose={toggleDeleteModal}
      />
      <GenericModal
        title={Vocabulary.changeTaskStatus}
        maxWidth={"md"}
        children={
          <Autocomplete
            fullWidth
            noOptionsText={Vocabulary.noResultsFound}
            options={taskStatuses || []}
            isOptionEqualToValue={(option: any, value: any) =>
              value.id ? option.id === value.id : option.id === value
            }
            onChange={(event: SyntheticEvent<Element, Event>, value: any) => {
              handleChangeTaskStatus(value);
            }}
            disabled={selectedTask.assignedUsers?.length === 0}
            className={genericStyles.taskListCardWidgetAutocomplete}
            getOptionLabel={(option) => option.translation}
            value={selectedTask.status ? selectedTask.status : null}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.chooseStatus}
                variant="outlined"
                fullWidth
                error={selectedTask.assignedUsers?.length === 0}
                helperText={
                  selectedTask.assignedUsers?.length === 0
                    ? Vocabulary.noAssignedUsers
                    : ""
                }
              />
            )}
          />
        }
        open={openModal}
        actions={
          <>
            <Button
              variant="contained"
              className={genericStyles.cancelButton}
              onClick={toggleModalState}
            >
              {Vocabulary.cancel}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                handleChangeStatus();
              }}
            >
              {Vocabulary.save}
            </Button>
          </>
        }
        onClose={() => {
          setOpenModal(false);
        }}
      />
    </>
  );
}
