import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Vocabulary } from "../Utils/Vocabulary";
import DashboardContainer from "../Components/DashboardContainer";
import Login from "../Pages/LoginPage";
import { localUrlEnum, pathParams } from "../Utils/UrlEnum";
import Users from "../Pages/Users";
import UserSettings from "../Pages/UserSettings";
import Projects from "../Pages/Projects";
import Media from "../Pages/Media";
import SecondMenu from "../Components/SecondMenu";
import Departments from "../Pages/Departments";
import Project from "../Components/Projects/Project";
import Chat from "../Pages/Chat";
import Dashboard from "../Pages/Dashboard";
import PrivateRoute from "./PrivateRouter";
import PageNotFound from "../Components/PageNotFound";
import Calendar from "../Pages/Calendar";
import { useEffect, useState } from "react";
import withRole from "../Hooks/withRole";
import { ComponentsNames, UserSecondMenuRoutes } from "../Utils/Constants";
import RequestTabs from "../Pages/RequestTabs";
import AppSettings from "../Pages/AppSettings";
import ReportsAndUnassignedTasksPage from "../Pages/ReportsAndUnassignedTasksPage";
import Tasks from "../Pages/Tasks";
import DocumentGenerator from "../Pages/DocumentGenerator";
import Meetings from "../Pages/Meetings";

type Props = {
  routes: any;
};

const LocalRouter = (props: Props) => {
  const [router, setRouter] = useState<any>();

  const routerPaths = [
    {
      path: localUrlEnum.pageNotFound,
      element: <PageNotFound text={Vocabulary.pageNotFound} />,
    },
    { path: localUrlEnum.login, element: <Login /> },
    {
      path: localUrlEnum.dashboard,
      element: (
        <PrivateRoute>
          <DashboardContainer />
        </PrivateRoute>
      ),
      children: [
        {
          path: localUrlEnum.dashboard,
          element: <Dashboard shouldRenderSearchBar={false} />,
        },
        {
          path: `${localUrlEnum.users}${pathParams.optionalId}`,
          element: (
            <SecondMenu
              shouldRenderSearchBar={true}
              routes={UserSecondMenuRoutes}
            />
          ),
          children: [{ path: "", element: <Users /> }],
        },
        {
          path: `${localUrlEnum.departments}${pathParams.optionalId}`,
          element: (
            <SecondMenu
              shouldRenderSearchBar={true}
              routes={UserSecondMenuRoutes}
            />
          ),
          children: [{ path: "", element: <Departments /> }],
        },
        {
          path: `${localUrlEnum.task}${pathParams.optionalId}`,
          element: <Tasks />,
        },
        {
          path: `${localUrlEnum.project}${pathParams.optionalId}`,
          element: <Project name={ComponentsNames.Project} />,
        },
        {
          path: `${localUrlEnum.messages}${pathParams.optionalId}`,
          element: <Chat />,
        },
        { path: localUrlEnum.account, element: <UserSettings /> },
        {
          path: localUrlEnum.projects,
          element: (
            <Projects
              shouldRenderSearchBar={true}
              name={ComponentsNames.Projects}
            />
          ),
        },
        {
          path: localUrlEnum.reports,
          element: (
            <ReportsAndUnassignedTasksPage
              name={ComponentsNames.ReportsAndUnassignedTasksPage}
              shouldRenderSearchBar={true}
            />
          ),
        },
        {
          path: localUrlEnum.tasks,
          element: <Tasks shouldRenderSearchBar={true} />,
        },
        {
          path: localUrlEnum.meetings,
          element: <Meetings shouldRenderSearchBar={true} />,
        },
        { path: localUrlEnum.media, element: <Media /> },
        {
          path: localUrlEnum.calendar,
          element: <Calendar />,
        },
        {
          path: localUrlEnum.requests,
          element: (
            <RequestTabs
              name={ComponentsNames.RequestTabs}
              shouldRenderSearchBar={true}
            />
          ),
        },
        {
          path: localUrlEnum.settings,
          element: (
            <AppSettings
              name={ComponentsNames.AppSettings}
              shouldRenderSearchBar={true}
            />
          ),
        },
        {
          path: localUrlEnum.documentGenerator,
          element: <DocumentGenerator />,
        },
      ],
    },
  ];
  /**
   *
   * @param routes
   * @returns
   */
  const renderRoutes = (routes: any) => {
    return (
      routes &&
      routes?.map((route: any, index: number) => (
        <Route key={index} path={route.path} element={route.element}>
          {route.children && renderRoutes(route.children)}
        </Route>
      ))
    );
  };

  /**
   *
   * @param routerPaths
   * @param routes
   * @returns
   */
  function filterAndVerifyRoutes(routerPaths: any, routes: any) {
    return routerPaths.reduce((filteredPaths: any, pathObj: any) => {
      const isValidPath = routes.includes(pathObj.path);

      if (isValidPath) {
        if (pathObj.children && pathObj.children.length > 0) {
          const validChildren = filterAndVerifyRoutes(pathObj.children, routes);
          if (validChildren.length > 0) {
            filteredPaths.push({ ...pathObj, children: validChildren });
          }
        } else {
          filteredPaths.push(pathObj);
        }
      }

      return filteredPaths;
    }, []);
  }

  /**
   *
   */
  useEffect(() => {
    if (!props.routes) return;
    setRouter(filterAndVerifyRoutes(routerPaths, props.routes));
  }, [props]);

  return (
    <BrowserRouter>
      <Routes>
        {renderRoutes(router)}
        <Route path={localUrlEnum.login} element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};
export default withRole(LocalRouter);
