import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { MeetingAutocompleteData, MeetingModel } from "../../Utils/Models";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import genericStyles from "../../Styles/genericStyles.module.css";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Config from "../../Utils/Config";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { EventRepeat } from "@mui/icons-material";
import { postData } from "../../Services/postData";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { GlobalContext } from "../../Context/GlobalContext";
import GenericModal from "../GenericModal";
import moment from "moment";
import withRole from "../../Hooks/withRole";
import { EMeetingStatus, ETaskPeriodicity } from "../../Utils/Enums";

type Props = {
  name: string;
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  disableFields?: boolean;
};

function Meeting(props: Props) {
  const [autocompleteData, setAutocompleteData] =
    useState<MeetingAutocompleteData>(new MeetingAutocompleteData());
  const [meeting, setMeeting] = useState<MeetingModel>(new MeetingModel());
  const userId = localStorage.getItem("userId");
  const refreshContext = useContext(RefreshDataContext);
  const globalContext = useContext(GlobalContext);

  /**
   *
   */
  useEffect(() => {
    if (
      globalContext.meetingSelectedId &&
      globalContext.meetingSelectedId !== ""
    ) {
      getData(`${urlEnum.getMeetings}/${globalContext.meetingSelectedId}`).then(
        (res: any) => {
          setMeeting(res.data);
          props.setOpenModal(true);
        }
      );
    }
  }, [globalContext]);

  /**
   * get data from server for autocomplete
   */
  useEffect(() => {
    const promises = [
      getData(`${urlEnum.getMeetingTypesForFilters}`),
      getData(`${urlEnum.getTaskPeriodicities}`),
      getData(`${urlEnum.getUsersForFilter}/${userId}?shouldGetAll=true`),
      getData(urlEnum.getDepartmentsForFilter),
      getData(urlEnum.getMeetingStatusesForFilters),
    ];
    Promise.all(promises)
      .then((res: any) => {
        setAutocompleteData({
          types: res[0]?.data,
          periodicities: res[1]?.data,
          users: res[2]?.data,
          departments: res[3]?.data,
          statuses: res[4]?.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   *
   * @param event
   */
  const handleChange = (event: any) => {
    const newMeeting: any = Object.assign({}, meeting);
    newMeeting[event.target.name] = event.target.value;
    setMeeting(newMeeting);
  };

  /**
   *
   */
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const newMeeting: any = Object.assign({}, meeting);
    newMeeting.start = moment(newMeeting.start).format(
      Config.momentUSDateAndTimeFormat
    );
    newMeeting.end = moment(newMeeting.end).format(
      Config.momentUSDateAndTimeFormat
    );
    postData(urlEnum.createOrUpdateMeeting, newMeeting).then((res: any) => {
      if (res) {
        props.setOpenModal(false);
        setMeeting(new MeetingModel());
        globalContext.setMeetingSelectedId(null);
        refreshContext.setRefresh(true);
      }
    });
  };

  /**
   *
   */
  const handleCloseModal = () => {
    props.setOpenModal(false);
    globalContext.setMeetingSelectedId(null);
    setMeeting(new MeetingModel());
  };

  return (
    <GenericModal
      maxWidth={"md"}
      title={meeting?.id ? Vocabulary.editMeeting : Vocabulary.addNewMeeting}
      open={props.openModal}
      onClose={handleCloseModal}
      children={
        <form id="my-form" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                value={meeting?.text ? meeting?.text : ""}
                id="text"
                name="text"
                label={Vocabulary.fancyName}
                fullWidth
                disabled={props.disableFields}
                required
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Autocomplete
                noOptionsText={Vocabulary.noResultsFound}
                id="type"
                handleHomeEndKeys
                options={autocompleteData.types ? autocompleteData.types : []}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                disabled={props.disableFields}
                getOptionLabel={(option: any) =>
                  option.translation ? option.translation : ""
                }
                value={meeting.type}
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  value: any
                ) => {
                  handleChange({ target: { name: "type", value: value } });
                }}
                renderInput={(params) => (
                  <TextField {...params} label={Vocabulary.type} required />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Autocomplete
                noOptionsText={Vocabulary.noResultsFound}
                id="periodicity"
                className={genericStyles.fullWidthElement}
                disablePortal
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                getOptionLabel={(option: any) =>
                  option?.translation ? option?.translation : ""
                }
                disabled={props.disableFields}
                options={autocompleteData.periodicities || []}
                value={meeting.periodicity ? meeting.periodicity : null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={Vocabulary.repeatsOn}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <EventRepeat />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  value: any
                ) => {
                  handleChange({
                    target: { name: "periodicity", value: value },
                  });
                }}
              />
            </Grid>

            {meeting.periodicity?.name ===
            ETaskPeriodicity.PERIODICITY_DAILY ? (
              <Grid item xs={12} sm={3}>
                <FormGroup>
                  <FormControlLabel
                    checked={meeting.skipWeekend || false}
                    onChange={(event: any) =>
                      handleChange({
                        target: {
                          name: "skipWeekend",
                          value: event.target.checked,
                        },
                      })
                    }
                    control={<Checkbox color="primary" />}
                    label={Vocabulary.skipWeekend}
                  />
                </FormGroup>
              </Grid>
            ) : null}

            <Grid
              item
              xs={12}
              sm={
                meeting.periodicity?.name === ETaskPeriodicity.PERIODICITY_DAILY
                  ? 3
                  : 6
              }
            >
              <Autocomplete
                multiple
                noOptionsText={Vocabulary.noResultsFound}
                disabled={props.disableFields}
                handleHomeEndKeys
                options={
                  autocompleteData.departments
                    ? autocompleteData.departments
                    : []
                }
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                getOptionLabel={(option: any) =>
                  option.name ? option.name : ""
                }
                value={meeting.departments ? meeting.departments : []}
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  value: any
                ) => {
                  handleChange({
                    target: { name: "departments", value: value },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label={Vocabulary.departments} />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  ampm={false}
                  slotProps={{ textField: { fullWidth: true } }}
                  openTo={"day"}
                  format={Config.momentEUDateTimeFormat}
                  value={moment(meeting?.start)}
                  disabled={props.disableFields}
                  views={[
                    "day",
                    "month",
                    "year",
                    "hours",
                    "minutes",
                    "seconds",
                  ]}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  onChange={(date: any) =>
                    handleChange({
                      target: {
                        name: "start",
                        value: date,
                      },
                    })
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  ampm={false}
                  slotProps={{ textField: { fullWidth: true } }}
                  value={moment(meeting?.end)}
                  openTo={"day"}
                  disabled={props.disableFields}
                  format={Config.momentEUDateTimeFormat}
                  views={[
                    "day",
                    "month",
                    "year",
                    "hours",
                    "minutes",
                    "seconds",
                  ]}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  onChange={(date: any) =>
                    handleChange({
                      target: {
                        name: "end",
                        value: date,
                      },
                    })
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                noOptionsText={Vocabulary.noResultsFound}
                id="responsible"
                multiple={false}
                handleHomeEndKeys
                options={autocompleteData.users ? autocompleteData.users : []}
                isOptionEqualToValue={(option: any, value: any) => {
                  return option?.id === value?.id;
                }}
                disabled={props.disableFields}
                getOptionLabel={(option: any) => {
                  return `${option.lastName ? option.lastName : ""} ${
                    option.firstName ? option.firstName : ""
                  }`;
                }}
                value={meeting.responsible}
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  value: any
                ) => {
                  handleChange({
                    target: { name: "responsible", value: value },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label={Vocabulary.assignedUser} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                id="status"
                noOptionsText={Vocabulary.noResultsFound}
                handleHomeEndKeys
                options={
                  autocompleteData.statuses ? autocompleteData.statuses : []
                }
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                disabled={props.disableFields}
                getOptionLabel={(option: any) =>
                  option.translation ? option.translation : ""
                }
                defaultValue={autocompleteData.statuses?.find((status: any) => {
                  return status.id === EMeetingStatus.MEETING_STATUS_PLANNED;
                })}
                value={meeting.status}
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  value: any
                ) => {
                  handleChange({ target: { name: "status", value: value } });
                }}
                renderInput={(params) => (
                  <TextField {...params} label={Vocabulary.status} required />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={meeting?.location ? meeting?.location : ""}
                id="location"
                disabled={props.disableFields}
                name="location"
                label={Vocabulary.location}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={meeting?.objective ? meeting?.objective : ""}
                id="objective"
                disabled={props.disableFields}
                name="objective"
                label={Vocabulary.objective}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={meeting?.resources ? meeting?.resources : ""}
                id="resources"
                disabled={props.disableFields}
                name="resources"
                label={Vocabulary.resources}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={meeting?.evaluation ? meeting?.evaluation : ""}
                id="evaluation"
                disabled={props.disableFields}
                name="evaluation"
                label={Vocabulary.evaluation}
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                value={meeting?.link ? meeting?.link : ""}
                id="link"
                name="link"
                disabled={props.disableFields}
                label={Vocabulary.link}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                noOptionsText={Vocabulary.noResultsFound}
                disabled={props.disableFields}
                handleHomeEndKeys
                options={autocompleteData.users ? autocompleteData.users : []}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                getOptionLabel={(option: any) =>
                  `${option.lastName ? option.lastName : ""} ${
                    option.firstName ? option.firstName : ""
                  }`
                }
                value={meeting.assignedUsers ? meeting.assignedUsers : []}
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  value: any
                ) => {
                  handleChange({
                    target: { name: "assignedUsers", value: value },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Vocabulary.meetingGuests}
                    required
                    inputProps={{
                      ...params.inputProps,
                      required: meeting.assignedUsers?.length === 0,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                value={meeting?.remarks ? meeting?.remarks : ""}
                id="remarks"
                disabled={props.disableFields}
                name="remarks"
                label={Vocabulary.observations}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                disabled={props.disableFields}
                fullWidth
                id="description"
                name="description"
                onChange={handleChange}
                rows={4}
                value={meeting?.description}
                label={Vocabulary.description}
              />
            </Grid>
          </Grid>
        </form>
      }
      actions={
        <>
          <Button
            variant="contained"
            className={genericStyles.cancelButton}
            onClick={handleCloseModal}
          >
            {Vocabulary.cancel}
          </Button>
          <Button
            type="submit"
            variant="contained"
            form="my-form"
            disabled={props.disableFields}
          >
            {Vocabulary.save}
          </Button>
        </>
      }
    />
  );
}

export default withRole(Meeting);
