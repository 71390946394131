import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  replaceAllUnescapedChars,
  restoreAllEscapedChars,
  sanitizeHtml,
} from "../../Utils/Utils";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { useEffect, useState } from "react";
import htmlToDraft from "html-to-draftjs";
import "../../Styles/WYSIWYGEditor.css";
import draftToHtml from "draftjs-to-html";
import genericStyles from "../../Styles/genericStyles.module.css";

type Props = {
  value: any;
  toolbar: any;
  mention?: any;
  hashtag?: any;
  onChange?: (value: any) => void;
  readOnly?: boolean;
  formRef?: any;
  reset?: boolean;
  classOverride?: any;
  id?: string;
  className?: string;
};

export default function WYSIWYGEditor(props: Props) {
  const {
    onChange,
    toolbar,
    mention,
    hashtag,
    readOnly,
    value,
    formRef,
    reset,
    classOverride,
    id,
    className,
  } = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);

  /**
   *
   */
  useEffect(() => {
    setUpdated(false);
  }, [reset]);
  /**
   *
   */
  useEffect(() => {
    if (!updated) {
      const defaultValue = value ? value : "";
      const escapedHtmlContent = sanitizeHtml(
        restoreAllEscapedChars(defaultValue)
      );

      const blocksFromHtml = htmlToDraft(escapedHtmlContent);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [value, updated]);

  /**
   *
   * @param editorState
   * @returns
   */
  const onEditorStateChange = (editorState: any) => {
    setUpdated(true);
    setEditorState(editorState);
    let text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    text = text.replace("wysiwyg-mention", "rdw-mention-link");
    const escapedHtmlContent = replaceAllUnescapedChars(text);
    if (onChange) onChange(escapedHtmlContent);
  };

  /**
   *
   * @param command
   * @returns
   */
  const myHandleReturn = (event: any) => {
    if (event.keyCode === 13) {
      if (formRef.current) {
        formRef.current.requestSubmit();
        setUpdated(false);
      } else {
        return false;
      }
    }
    return true;
  };

  return (
    <div id={id} className={`${genericStyles.fullWidthElement} ${className}`}>
      <Editor
        handleReturn={myHandleReturn}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        editorState={editorState}
        editorStyle={classOverride}
        handlePastedText={(
          text: string,
          html: string,
          editorState: EditorState,
          onChange: (editorState: EditorState) => void
        ) => false}
        onEditorStateChange={onEditorStateChange}
        toolbarHidden={readOnly}
        toolbar={toolbar}
        readOnly={readOnly || false}
        mention={mention}
        hashtag={hashtag}
      />
    </div>
  );
}
