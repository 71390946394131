/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import genericStyles from "../../Styles/genericStyles.module.css";
import MUIDataTableCustomStyle from "../MUIDataTable/MUIDataTableCustomStyle";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ModifiedTasksStateModel, UserModel } from "../../Utils/Models";
import { CommonTableOptions } from "../../Utils/Constants";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import useQueryStringParser from "../../Hooks/useQueryStringParser";
import { Chip, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { formatStringDate, handlePropertiesEqual } from "../../Utils/Utils";
import Config from "../../Utils/Config";
import styles from "../../Styles/modifiedTasks.module.css";
import ModifiedTasksFilters from "./ModifiedTasksFilters";

export default function ModifiedTasks() {
  const customSearchParams = useCustomSearchParam();
  const [state, setState] = useState<ModifiedTasksStateModel>(
    new ModifiedTasksStateModel()
  );
  useQueryStringParser((data: any) => {
    getModifiedTasks(
      data.page ? parseInt(data.page) : state.page,
      data.perPage ? parseInt(data.perPage) : state.perPage,
      data.search,
      data.modifiedResponsible,
      data.modifiedPeriodicity
    );
  });

  const modifiedTasksHeader = [
    {
      label: Vocabulary.fancyName,
      name: "newEntity",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return `${
            value.text.length >= 40
              ? `${value.text?.substring(0, 40)}...`
              : value.text
          }`;
        },
      },
    },
    {
      label: Vocabulary.startDate,
      name: "newEntity",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.endDate,
      name: "oldEntity",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.periodicity,
      name: "oldEntity",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.assignedUser,
      name: "oldEntity",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.modifiedBy,
      name: "createdBy",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.modifiedAt,
      name: "createdAt",
      options: {
        sort: false,
      },
    },
  ];

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  const getModifiedTasks = (
    page: number,
    perPage: number,
    search: string | null,
    modifiedResponsible: boolean | null,
    modifiedPeriodicity: boolean | null
  ) => {
    const url = `${urlEnum.modifiedTasks}?page=${page}&perPage=${perPage}${
      search ? `&search=${search}` : ""
    }${
      modifiedPeriodicity ? `&modifiedPeriodicity=${modifiedPeriodicity}` : ""
    }${
      modifiedResponsible ? `&modifiedResponsible=${modifiedResponsible}` : ""
    }`;
    getData(url).then((res: any) => {
      if (res && res.data)
        setState({
          ...state,
          tasks: res.data.results,
          count: res.data.nrResults,
          page: page,
          perPage: perPage,
          search: search,
        });
    });
  };

  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    return {
      ...CommonTableOptions,
      count: state.count,
      rowsPerPage: state.perPage,
      page: state.page,
      onChangePage: (page: any) => {
        console.log(page);
        customSearchParams.addParamToQueryString("page", page);
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        customSearchParams.addParamToQueryString("perPage", numberOfRows);
        window.scrollTo(0, 0);
      },
      customRowRender: (data: any, dataIndex: any, rowIndex: any) => {
        return renderCustomRow(data, dataIndex, rowIndex);
      },
    };
  };

  /**
   *
   * @param data
   * @param dataIndex
   * @param rowIndex
   * @returns
   */
  const renderCustomRow = (data: any, dataIndex: any, rowIndex: any) => {
    return (
      <TableRow key={dataIndex}>
        <TableCell>{data[0]}</TableCell>
        <TableCell>
          <Table>
            <TableBody>
              <TableRow>
                <Chip
                  className={styles.originalValueTableCell}
                  label={data[1].start}
                />
              </TableRow>
              <TableRow>
                <Chip
                  className={
                    handlePropertiesEqual(data[1].start, data[2].start)
                      ? styles.originalValueTableCell
                      : styles.modifiedValueTableCell
                  }
                  label={data[2].start}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
        <TableCell>
          <Table>
            <TableBody>
              <TableRow>
                <Chip
                  className={styles.originalValueTableCell}
                  label={data[1].end}
                />
              </TableRow>
              <TableRow>
                <Chip
                  className={
                    handlePropertiesEqual(data[1].end, data[2].end)
                      ? styles.originalValueTableCell
                      : styles.modifiedValueTableCell
                  }
                  label={data[1].end}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
        <TableCell>
          <Table>
            <TableBody>
              <TableRow>
                {data[1]?.periodicity ? (
                  <Chip
                    className={styles.originalValueTableCell}
                    label={data[1]?.periodicity?.translation}
                  />
                ) : null}
              </TableRow>
              <TableRow>
                {data[1]?.periodicity ? (
                  <Chip
                    className={
                      handlePropertiesEqual(
                        data[1]?.periodicity,
                        data[2]?.periodicity
                      )
                        ? styles.originalValueTableCell
                        : styles.modifiedValueTableCell
                    }
                    label={data[2]?.periodicity?.translation}
                  />
                ) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
        <TableCell>
          <Table>
            <TableBody>
              <TableRow>
                <Chip
                  className={styles.originalValueTableCell}
                  label={data[1]?.assignedUsers.map((user: UserModel) => {
                    return `${user.lastName || ""} ${user.firstName || ""}; `;
                  })}
                />
              </TableRow>
              <TableRow>
                <Chip
                  className={
                    handlePropertiesEqual(
                      data[1].assignedUsers,
                      data[2].assignedUsers
                    )
                      ? styles.originalValueTableCell
                      : styles.modifiedValueTableCell
                  }
                  label={data[2]?.assignedUsers.map((user: UserModel) => {
                    return `${user.lastName || ""} ${user.firstName || ""}; `;
                  })}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
        <TableCell>{`${data[5].lastName || ""} ${
          data[5].firstName || ""
        }`}</TableCell>
        <TableCell>
          {formatStringDate(data[6], Config.momentEUDateFormat)}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className={genericStyles.mainContainer}>
      <ModifiedTasksFilters />
      <div className={genericStyles.fullWidthElement}>
        <MUIDataTableCustomStyle
          title={Vocabulary.modifiedTasks}
          data={state.tasks}
          columns={modifiedTasksHeader}
          options={getTableOptions()}
        />
      </div>
    </div>
  );
}
