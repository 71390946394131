/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { RequestModel, RequestsState } from "../../Utils/Models";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import {
  Button,
  Divider,
  IconButton,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Delete, Edit, Visibility } from "@mui/icons-material";
import GenericModal from "../GenericModal";
import RequestModal from "./RequestModal";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import {
  calculateNumberOfPages,
  createURLFromParams,
  renderSubstring,
} from "../../Utils/Utils";
import { getData } from "../../Services/getData";
import QueryStringParser from "../QueryStringParser";
import { ERequestStatus } from "../../Utils/Enums";
import { deleteData } from "../../Services/deleteData";
import NoDataFound from "../NoDataFound";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { useLocation } from "react-router-dom";
import styles from "../../Styles/requests.module.css";
import RequestsList from "./RequestsList";
import ApprovalModal from "../ApprovalModal";
import RequestsFilters from "./RequestsFilters";
import { RequestsHeader } from "../../Utils/Constants";
import Config from "../../Utils/Config";

function PersonalRequests(props: any) {
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const refreshDataContext = useContext(RefreshDataContext);
  const [state, setState] = useState<RequestsState>(new RequestsState());

  const customSearchParams = useCustomSearchParam();
  const [preview, setPreview] = useState<boolean>(false);
  const location = useLocation();
  const requestsFiltersValidationSchema = yup.object().shape({
    status: yup.array(),
    departments: yup.array(),
    user: yup.object(),
    start: yup.object(),
    end: yup.object(),
  });
  const methods = useForm({
    defaultValues: {
      start: moment().startOf("month").subtract(2, "month"),
      end: moment().endOf("month"),
    },
    resolver: yupResolver(requestsFiltersValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });
  /**
   *
   * @param page
   * @param perPage
   * @param start
   * @param end
   * @param search
   * @param status
   */
  const gerRequests = (
    page: number,
    perPage: number,
    start: any,
    end: any,
    search: string | null,
    status?: any
  ) => {
    const url = createURLFromParams(
      `${urlEnum.getRequest}?page=${
        page > 0 ? page - 1 : 0
      }&perPage=${perPage}&start=${start}&end=${end}&bringOnlyForOwner=true${
        search ? `&search=${search}` : ""
      }&allParams=`,
      { status: status ? status : null }
    );
    getData(url).then((res: any) => {
      if (res) {
        setState({
          ...state,
          requests: res.data?.results,
          count: res.data?.nrResults,
          page: page,
          perPage: perPage,
          search: search,
        });
      }
    });
  };

  /**
   *
   */
  const toggleModalState = () => {
    setOpenModal(!openModal);
  };

  /**
   *
   */
  const toggleDeleteModalState = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  /**
   *
   * @param id
   */
  const deleteRequest = (id: string) => {
    if (id) {
      deleteData(`${urlEnum.deleteRequest}/${id}`).then((res) => {
        if (res) {
          getRequestsUsingStateData();
        }
      });
    }
  };

  /**
   *
   */
  const getRequestsUsingStateData = () => {
    gerRequests(
      state.page,
      state.perPage,
      moment(methods.getValues("start")).format(Config.momentUSDateFormat),
      moment(methods.getValues("end")).format(Config.momentUSDateFormat),
      state.search
    );
  };

  /**
   *
   */
  useEffect(() => {
    if (
      refreshDataContext.refresh &&
      location.pathname.includes(localUrlEnum.requests)
    ) {
      getRequestsUsingStateData();
      refreshDataContext.setRefresh(false);
    }
  }, [refreshDataContext.refresh]);

  /**
   *
   * @returns
   */
  const renderRequests = () => {
    return (
      <div className={styles.requestsListContainer}>
        <div className={styles.requestsHeader}>
          <RequestsFilters countRequests={state.count} methods={methods} />
          <Button
            variant="contained"
            startIcon={<Add />}
            className={genericStyles.addButton}
            onClick={() => {
              setState({ ...state, selectedRequest: new RequestModel() });
              toggleModalState();
            }}
          >
            {Vocabulary.add}
          </Button>
        </div>
        {state.requests.length ? (
          <>
            <RequestsList
              header={RequestsHeader}
              requests={state.requests}
              renderRequestOptions={renderRequestOptions}
              shouldRenderAdminSpecialOptions={false}
            />
            <div className={styles.paginationContainer}>
              <Pagination
                count={calculateNumberOfPages(state.count, state.perPage)}
                page={state.page}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  value: number
                ) => {
                  customSearchParams.addParamToQueryString("page", value);
                  window.scrollTo(0, 0);
                }}
                showFirstButton
                showLastButton
                variant="outlined"
              />
            </div>
          </>
        ) : (
          <NoDataFound classNames={genericStyles.NoRequestsFoundContainer} />
        )}
      </div>
    );
  };

  /**
   *
   * @param request
   * @returns
   */
  const renderRequestOptions = (request: any) => {
    return (
      <div className={styles.requestOptions}>
        <Tooltip title={Vocabulary.view}>
          <IconButton
            onClick={() => {
              setState({ ...state, selectedRequest: request });
              setPreview(true);
              toggleModalState();
            }}
          >
            <Visibility />
          </IconButton>
        </Tooltip>
        {request.status.name === ERequestStatus.STATUS_PENDING ? (
          <>
            <Divider orientation="vertical" flexItem />
            <Tooltip title={Vocabulary.edit}>
              <IconButton
                onClick={() => {
                  setState({ ...state, selectedRequest: request });
                  toggleModalState();
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            <Tooltip title={Vocabulary.delete}>
              <IconButton
                onClick={() => {
                  setState({ ...state, selectedRequest: request });
                  toggleDeleteModalState();
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <div className={genericStyles.mainContainer}>
      {renderRequests()}

      <QueryStringParser
        requestFunction={(data: any) => {
          gerRequests(
            data.page ? parseInt(data.page) : state.page,
            data.perPage ? parseInt(data.perPage) : state.perPage,
            data.start
              ? data.start
              : moment(methods.getValues("start")).format(
                  Config.momentUSDateFormat
                ),
            data.end
              ? data.end
              : moment(methods.getValues("end")).format(
                  Config.momentUSDateFormat
                ),
            data.search,
            data.status
          );
        }}
      />

      <ApprovalModal
        handleApproveAction={() => {
          deleteRequest(state.selectedRequest.id);
        }}
        openModal={openDeleteModal}
        handleClose={toggleDeleteModalState}
        message={Vocabulary.verificationDeleteRequest}
      />

      <GenericModal
        title={`${
          state.selectedRequest?.id
            ? `${Vocabulary.request}: ${renderSubstring(
                state.selectedRequest.text,
                35
              )}`
            : Vocabulary.newRequest
        } `}
        open={openModal}
        onClose={() => {
          setPreview(false);
          toggleModalState();
        }}
        children={
          <RequestModal
            toggleModalState={() => {
              setPreview(false);
              toggleModalState();
            }}
            requestId={state.selectedRequest?.id}
            previewMode={preview}
          />
        }
      />
    </div>
  );
}

export default PersonalRequests;
