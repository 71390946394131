import { useState } from "react";
import MUIDataTableCustomStyle from "../MUIDataTable/MUIDataTableCustomStyle";
import { Vocabulary } from "../../Utils/Vocabulary";
import { CommonTableOptions } from "../../Utils/Constants";
import QueryStringParser from "../QueryStringParser";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { TaskStatusModel, TaskStatusesState } from "../../Utils/Models";
import { Add, Circle, Delete, Edit } from "@mui/icons-material";
import {
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import styles from "../../Styles/kanban.module.css";
import genericStyles from "../../Styles/genericStyles.module.css";
import GenericModal from "../GenericModal";
import { updateData } from "../../Services/updateData";
import ApprovalModal from "../ApprovalModal";
import { deleteData } from "../../Services/deleteData";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";

export default function TaskStatuses() {
  const [selectedTaskStatus, setSelectedTaskStatus] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [state, setState] = useState<TaskStatusesState>({
    page: 0,
    perPage: 20,
    count: 1,
    statuses: [],
    search: "",
    sort: "ASC",
  });
  const customSearchParams = useCustomSearchParam();

  /**
   *
   * @param status
   */
  const handleEditStatus = (status: any) => {
    handleToggleModal();
    setSelectedTaskStatus(status);
  };

  const statusesHeader = [
    {
      label: Vocabulary.crtNo,
      name: "",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return state.page * state.perPage + meta.rowIndex + 1;
        },
      },
    },
    {
      label: Vocabulary.fancyName,
      name: "translation",
      options: { sort: false },
    },
    {
      label: Vocabulary.color,
      name: "color",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return <Circle sx={{ color: value }} />;
        },
      },
    },
    {
      label: Vocabulary.options,
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,

        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip
                  className={genericStyles.tooltipEditButton}
                  title={Vocabulary.edit}
                >
                  <ToggleButton
                    value="left"
                    aria-label="left aligned"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      handleEditStatus(state.statuses[rowIndex]);
                    }}
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                {state.statuses[rowIndex]?.isEditable && (
                  <Tooltip
                    className={genericStyles.tooltipDeleteButton}
                    title={Vocabulary.delete}
                  >
                    <ToggleButton
                      value="center"
                      aria-label="centered"
                      onClick={(event: any) => {
                        event.stopPropagation();
                        setSelectedTaskStatus(state.statuses[rowIndex]);
                        handleToggleDeleteModal();
                      }}
                    >
                      <Delete />
                    </ToggleButton>
                  </Tooltip>
                )}
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @param task
   */
  const handleUpdateTaskStatusEntity = (taskStatus: any) => {
    updateData(urlEnum.updateTaskStatus, taskStatus)
      .then((response: any) => {
        if (response) {
          getTaskStatuses(state.page, state.perPage, state.search);
          handleToggleModal();
        }
      })
      .catch((exception: any) => console.log(exception));
  };

  /**
   *
   * @param event
   */
  const handleChangeTaskStatus = (event: any) => {
    const newTask: any = Object.assign({}, selectedTaskStatus);
    newTask[event.target.name] = event.target.value;
    setSelectedTaskStatus(newTask);
  };

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  const getTaskStatuses = (
    page: number,
    perPage: number,
    search?: string | null
  ) => {
    getData(
      `${urlEnum.getTaskStatuses}?page=${page}&perPage=${perPage}${
        search ? `&search=${search}` : ""
      }&allParams=`
    ).then((res: any) => {
      if (res) {
        setState({
          ...state,
          statuses: res.data.results,
          count: res.data.nrResults,
        });
      }
    });
  };

  /**
   *
   */
  const getTableOptions = () => {
    return {
      ...CommonTableOptions,
      count: state.count,
      rowsPerPage: state.perPage,
      page: state.page,
      customToolbar: () => {
        return (
          <Button
            variant="contained"
            className={genericStyles.addButton}
            startIcon={<Add />}
            onClick={() => {
              handleToggleModal();
              setSelectedTaskStatus(new TaskStatusModel());
            }}
          >
            {Vocabulary.add}
          </Button>
        );
      },
      onChangePage: (page: any) => {
        customSearchParams.addParamToQueryString("page", page);
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        customSearchParams.addParamToQueryString("perPage", numberOfRows);
        window.scrollTo(0, 0);
      },
      onRowClick: (rowData: any, rowMeta: any) => {
        handleEditStatus(state.statuses[rowMeta.dataIndex]);
      },
    };
  };

  /**
   *
   * @param id
   */
  const deleteTaskStatus = () => {
    deleteData(`${urlEnum.deleteTaskStatus}/${selectedTaskStatus.id}`)
      .then((res: any) => {
        if (res) {
          getTaskStatuses(state.page, state.perPage, state.search);
        }
      })
      .catch((error: any) => console.log(error));
  };

  /**
   *
   */
  const handleToggleModal = () => {
    setOpenModal(!openModal);
  };

  /**
   *
   */
  const handleToggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  return (
    <>
      <QueryStringParser
        requestFunction={(data: any) => {
          getTaskStatuses(
            data.page ? parseInt(data.page) : state.page,
            data.perPage ? parseInt(data.perPage) : state.perPage,
            data.search
          );
        }}
      />
      <MUIDataTableCustomStyle
        title={Vocabulary.taskStatusesList}
        data={state.statuses}
        columns={statusesHeader}
        options={getTableOptions()}
      />
      <GenericModal
        title={
          selectedTaskStatus?.id !== null
            ? Vocabulary.editStatusProperties
            : Vocabulary.addNewStatus
        }
        children={
          <div className={styles.kanbanColumnHeader}>
            <TextField
              type="text"
              id="translation"
              name="translation"
              disabled={!selectedTaskStatus?.isEditable}
              fullWidth
              value={selectedTaskStatus?.translation}
              InputProps={{
                className: styles.kanbanColumnTitle,
              }}
              onChange={(e: any) => handleChangeTaskStatus(e)}
            />
            <input
              id="color"
              name="color"
              className={genericStyles.colorInput}
              type="color"
              value={selectedTaskStatus?.color}
              onChange={(e: any) => handleChangeTaskStatus(e)}
            />
          </div>
        }
        actions={
          <>
            <Button
              variant="contained"
              fullWidth
              onClick={handleToggleModal}
              className={genericStyles.cancelButton}
            >
              {Vocabulary.cancel}
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={() => {
                handleUpdateTaskStatusEntity(selectedTaskStatus);
              }}
            >
              {Vocabulary.save}
            </Button>
          </>
        }
        open={openModal}
        onClose={handleToggleModal}
      />
      <ApprovalModal
        handleApproveAction={deleteTaskStatus}
        openModal={openDeleteModal}
        handleClose={handleToggleDeleteModal}
      />
    </>
  );
}
