import { Box, Grid } from "@mui/material";
import { Vocabulary } from "../Utils/Vocabulary";
import TasksListCardWidget from "./Widgets/TasksListCardWidget";
import Config from "../Utils/Config";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { getData } from "../Services/getData";
import { localUrlEnum, urlEnum } from "../Utils/UrlEnum";
import { RefreshDataContext } from "../Context/RefreshDataContext";
import { useLocation } from "react-router-dom";
import styles from "../Styles/dashboard.module.css";
import SummaryOfUsersSubordinates from "./Widgets/SummaryOfUsersSubordinates";
import QueryStringParser from "./QueryStringParser";
import MainDashboardTabFilters from "./MainDashboardTabFilters";
import withRole from "../Hooks/withRole";
import { returnEntityIfExistsInArray } from "../Utils/Utils";
import { ComponentsNames } from "../Utils/Constants";
import MeetingsWidget from "./Widgets/MeetingsWidget";
import IntroWidget from "./Widgets/IntroWidget";
import { Sort } from "../Utils/Enums";
import GraphicResponsiveBar from "./Widgets/GraphicResponsiveBar";
import {
  FolderOutlined,
  PersonOutline,
  QuestionMark,
  Visibility,
} from "@mui/icons-material";

type Props = {
  shouldRenderUserFilter?: boolean;
};

function MainDashboardTab(props: Props) {
  const refreshContext = useContext(RefreshDataContext);
  const location = useLocation();
  const authenticatedUser: string = localStorage.getItem("userId") || "";
  const { shouldRenderUserFilter } = props;
  const [filterData, setFilterData] = useState<any>({
    start: moment().clone().startOf("isoWeek").subtract(1, "days"),
    end: moment().clone().endOf("isoWeek").add(1, "days"),
    user: authenticatedUser,
    sort: Sort.ASC,
    sortProperty: "start",
  });
  const [state, setState] = useState<any>({
    mainData: [],
    summaryData: [],
    todaysMeetings: [],
    users: [],
    statistics: { taskCountByMonth: [], keys: [] },
  });

  /**
   *
   */
  useEffect(() => {
    if (
      refreshContext.refresh &&
      location.pathname === localUrlEnum.dashboard
    ) {
      getDataFromServer(
        filterData.start.format(Config.momentUSDateFormat),
        filterData.end.format(Config.momentUSDateFormat),
        authenticatedUser,
        filterData.sortProperty,
        filterData.sort
      );
      refreshContext.setRefresh(false);
    }
  }, [refreshContext.refresh]);

  /**
   *
   * @param start
   * @param end
   * @param userId
   */
  const getDataFromServer = (
    start: string,
    end: string,
    userId: string,
    sortProperty: string,
    sort: string
  ) => {
    //get today start dateTime and end dateTime
    const todayStart = moment()
      .clone()
      .startOf("day")
      .format(Config.momentUSDateAndTimeFormat);
    const todayEnd = moment()
      .clone()
      .endOf("day")
      .format(Config.momentUSDateAndTimeFormat);
    const promises = [
      getData(
        `${urlEnum.getTaskDashboard}/${userId}/${start}/${end}/${sortProperty}/${sort}`
      ),
      getData(`${urlEnum.dashboardSummary}/${start}/${end}`),
      getData(`${urlEnum.getDashboardsMeetings}/${todayStart}/${todayEnd}`),
      getData(`${urlEnum.getTaskStatistics}`),
      shouldRenderUserFilter
        ? getData(
            `${urlEnum.getUsersForFilter}/${localStorage.getItem("userId")}`
          )
        : null,
    ];
    Promise.all(promises)
      .then((values: any) => {
        setState({
          mainData: values[0]?.data,
          summaryData: values[1]?.data,
          todaysMeetings: values[2]?.data,
          statistics: values[3]?.data,
          users: values[4]?.data,
        });
        setFilterData({
          ...filterData,
          start: moment(start),
          end: moment(end),
          user: returnEntityIfExistsInArray(values[4]?.data, userId),
          sort: sort,
          sortProperty: sortProperty,
        });
        refreshContext.setRefresh(false);
      })
      .catch((error) => {
        refreshContext.setRefresh(false);
      });
  };

  return (
    <div className={styles.mainDashboardContainer}>
      <QueryStringParser
        requestFunction={(data: any) => {
          getDataFromServer(
            data.start
              ? data.start
              : filterData.start.format(Config.momentUSDateFormat),
            data.end
              ? data.end
              : filterData.end.format(Config.momentUSDateFormat),
            data.user ? data.user : filterData.user,
            data.sortProperty ? data.sortProperty : filterData.sortProperty,
            data.sort ? data.sort : filterData.sort
          );
        }}
      />
      <Grid container spacing={3}>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Box className={`${styles.taskPaper}`}>
            <Grid container spacing={3}>
              <MainDashboardTabFilters
                name={ComponentsNames.MainDashboardTabFilters}
                users={state.users}
                filterData={filterData}
              />

              <Grid item lg={shouldRenderUserFilter ? 3 : 4} md={6} xs={12}>
                <TasksListCardWidget
                  avatar={<PersonOutline fontSize="large" />}
                  id={"specificTasks"}
                  title={Vocabulary.yourTasks}
                  taskList={state.mainData?.specificTasks}
                  totalFinished={state.mainData?.nrOfSpecificFinishedTasks}
                />
              </Grid>
              <Grid item lg={shouldRenderUserFilter ? 3 : 4} md={6} xs={12}>
                <TasksListCardWidget
                  avatar={<FolderOutlined fontSize="large" />}
                  id={"projectTasks"}
                  title={Vocabulary.tasksOnProjects}
                  taskList={state.mainData?.projectTasks}
                  totalFinished={state.mainData?.nrOfProjectFinishedTasks}
                />
              </Grid>
              <Grid item lg={shouldRenderUserFilter ? 3 : 4} md={6} xs={12}>
                <TasksListCardWidget
                  avatar={<QuestionMark fontSize="large" />}
                  id={"additionalTasks"}
                  title={Vocabulary.additionalTasks}
                  taskList={state.mainData?.allocatedTasks}
                  totalFinished={state.mainData?.nrOfAllocatedFinishedTasks}
                />
              </Grid>
              {shouldRenderUserFilter && (
                <Grid item lg={3} md={6} xs={12}>
                  <TasksListCardWidget
                    avatar={<Visibility fontSize="large" />}
                    id={"supervisedTasks"}
                    title={Vocabulary.supervisedTasks}
                    taskList={state.mainData?.supervisedTasks}
                    totalFinished={state.mainData?.nrOfAllocatedFinishedTasks}
                  />
                </Grid>
              )}
              <Grid item lg={12} md={12} xs={12}>
                <GraphicResponsiveBar
                  title={Vocabulary.tasksPerMonth}
                  data={state.statistics?.taskCountByMonth}
                  keys={state.statistics?.keys}
                  name={ComponentsNames.GraphicResponsiveBar}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <IntroWidget />
            </Grid>
            <Grid item xs={12}>
              {state.summaryData?.length > 0 && (
                <SummaryOfUsersSubordinates
                  summaryData={state.summaryData}
                  name={ComponentsNames.SummaryOfUsersSubordinates}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <MeetingsWidget
                name={ComponentsNames.MeetingsWidget}
                meetings={state.todaysMeetings}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRole(MainDashboardTab);
