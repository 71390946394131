/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "@mui/material";
import styles from "../Styles/dashboard.module.css";
import genericStyles from "../Styles/genericStyles.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import KanbanBoard from "../Components/Tasks/KanbanBoard";
import { useEffect, useState } from "react";
import CustomTabPanel from "../Components/CustomTabPanel";
import DynamicTabs from "../Components/DynamicTabs";
import MainDashboardTab from "../Components/MainDashboardTab";
import Config from "../Utils/Config";
import moment from "moment";
import { ComponentsNames } from "../Utils/Constants";
import { useSearchParams } from "react-router-dom";

function Dashboard(props: any) {
  const [tabValue, setTabValue] = useState(0);
  const start = moment().clone().startOf("isoWeek");
  const end = moment().clone().endOf("isoWeek");
  const [searchParams, setSearchParams] = useSearchParams();
  /**
   *
   */
  useEffect(() => {
    const tab = searchParams.get("tab");
    setTabValue(tab ? parseInt(tab) : 0);
  }, [searchParams]);

  return (
    <>
      <div className={genericStyles.header}>
        <DynamicTabs
          tabValue={tabValue}
          labels={[Vocabulary.dashboard, Vocabulary.kanban]}
        />
      </div>
      <div className={genericStyles.tabPanelContainer}>
        <CustomTabPanel value={tabValue} index={0}>
          <MainDashboardTab
            name={ComponentsNames.MainDashboardTab}
            key={new Date().getTime()}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <div className={styles.tabHeader}>{`${
            Vocabulary.week
          }: ${start.format(Config.momentEUDateFormat)} / ${end.format(
            Config.momentEUDateFormat
          )}`}</div>
          <Box className={`${styles.taskPaper}`}>
            <KanbanBoard />
          </Box>
        </CustomTabPanel>
      </div>
    </>
  );
}

export default Dashboard;
