import { Avatar } from "@mui/material";
import { UserModel } from "../../Utils/Models";
import genericStyles from "../../Styles/genericStyles.module.css";
import { foldersNames, urlEnum } from "../../Utils/UrlEnum";
import { Fragment } from "react";
type Props = {
  collaborators: any[];
};

export default function Collaborators(props: Props) {
  return (
    <div className={genericStyles.flexContainer}>
      {props.collaborators?.map((collaborator: UserModel, index: number) => {
        return (
          <Fragment key={index}>
            {index <= 2 ? (
              <Avatar
                alt={`${collaborator.lastName?.charAt(0).toUpperCase()}`}
                className={genericStyles.collaboratorAvatar}
                src={`${urlEnum.getFilesAndMedia}${foldersNames.usersProfiles}${collaborator?.profilePicture}`}
              />
            ) : null}
          </Fragment>
        );
      })}
      {props.collaborators.length > 3 ? (
        <>
          <Avatar
            alt={`+${props.collaborators.length - 3}`}
            className={genericStyles.collaboratorAvatar}
            src={`${urlEnum.getFilesAndMedia}${foldersNames.usersProfiles}${props.collaborators[3]?.profilePicture}`}
          >
            {`+${props.collaborators.length - 3}`}
          </Avatar>
        </>
      ) : null}
    </div>
  );
}
