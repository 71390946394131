import React, { createContext, useEffect, useState } from "react";
import { localUrlEnum } from "../Utils/UrlEnum";

export const CacheContext = createContext<any>({
  cache: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCache: (value: any) => {},
});

export const CacheContextProvider = (props: any) => {
  useEffect(() => {
    const cacheData = localStorage.getItem("cacheData");
    const token = localStorage.getItem("access_token");
    if (cacheData && cacheData !== "") {
      setCache(JSON.parse(cacheData));
    } else if ((!cacheData || cacheData === "") && token && token !== "") {
      window.location.href = localUrlEnum.dashboard;
      localStorage.setItem("access_token", "");
    }
  }, [localStorage]);

  const setCache = (cache: Array<any>) => {
    localStorage.setItem("cacheData", JSON.stringify(cache));
    setState({ ...state, cache: cache });
  };
  const initState = {
    cache: new Array<any>(),
    setCache: setCache,
  };
  const [state, setState] = useState(initState);
  return (
    <CacheContext.Provider value={state}>
      {props.children}
    </CacheContext.Provider>
  );
};
