import { SelectionRangeModel, TaskPeriodicityModel } from "../../Utils/Models";
import { DateRangePicker } from "react-date-range";
import styles from "../../Styles/task.module.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import { format, parseISO } from "date-fns";
import { ro } from "date-fns/locale";
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import Config from "../../Utils/Config";
import { ETaskPeriodicity } from "../../Utils/Enums";
import { CustomThemeContext } from "../../Context/CustomThemeContext";

type Props = {
  task: TaskPeriodicityModel | any;
  setOpenPeriodicity: (value: boolean) => void;
  periodicities: any[];
  handleChange: (event: any) => void;
  handleChangeStartAndEnd: (start: string | Date, end: string | Date) => void;
};

export default function TaskPeriodicity(props: Props) {
  const {
    task,
    periodicities,
    handleChange,
    setOpenPeriodicity,
    handleChangeStartAndEnd,
  } = props;
  const themeContext: any = useContext(CustomThemeContext);
  const taskSelectionDate = {
    startDate: task.start ? parseISO(task.start) : new Date(),
    endDate: task.end ? parseISO(task.end) : new Date(),
    key: "selection",
  };
  const [selectionRange, setSelectionRange] = useState(
    task.id ? taskSelectionDate : new SelectionRangeModel()
  );

  /**
   *
   * @param ranges
   */
  const handleSelect = (ranges: any) => {
    setSelectionRange(ranges.selection);
    handleChangeStartAndEnd(
      format(ranges.selection.startDate, Config.rangeDatePickerFormat),
      format(ranges.selection.endDate, Config.rangeDatePickerFormat)
    );
  };

  /**
   *reset component to default values
   */
  const handleReset = () => {
    setSelectionRange(new SelectionRangeModel());
    handleChange({ target: { name: "periodicity", value: null } });
  };

  /**
   * Hide the left container from the date range picker
   */
  useEffect(() => {
    const leftContainer = document.getElementsByClassName(
      "rdrDefinedRangesWrapper"
    );
    leftContainer[0].setAttribute("style", "display: none");
  }, []);

  /**
   *
   * @returns
   */
  const renderBottomOptions = () => {
    return (
      <>
        <Divider variant="middle" />
        <div className={styles.optionsContainer}>
          <Button onClick={handleReset} variant="contained">
            {Vocabulary.reset}
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            {Vocabulary.close}
          </Button>
        </div>
      </>
    );
  };

  /**
   *
   * @returns
   */
  const renderSkipWeekendsComponent = () => {
    return task.periodicity?.name === ETaskPeriodicity.PERIODICITY_DAILY ? (
      <>
        <div className={styles.periodicityContainer}>
          <FormGroup>
            <FormControlLabel
              checked={task.skipWeekend}
              onChange={(event: any) =>
                handleChange({
                  target: { name: "skipWeekend", value: event.target.checked },
                })
              }
              control={<Checkbox color="primary" />}
              label={Vocabulary.skipWeekend}
            />
          </FormGroup>
        </div>
      </>
    ) : null;
  };

  /**
   *
   * @returns
   */
  const renderCustomFilters = () => {
    return (
      <>
        <Divider variant="middle" />
        <div className={styles.periodicityContainer}>
          <Typography>{Vocabulary.repeatsOn}:</Typography>
          <Autocomplete
            noOptionsText={Vocabulary.noResultsFound}
            id="periodicity"
            className={styles.taskAutocomplete}
            disablePortal
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            getOptionLabel={(option: any) =>
              option?.translation ? option?.translation : ""
            }
            options={periodicities || []}
            value={task.periodicity ? task.periodicity : null}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
              />
            )}
            onChange={(event: SyntheticEvent<Element, Event>, value: any) => {
              handleChange({ target: { name: "periodicity", value: value } });
            }}
          />
        </div>
      </>
    );
  };

  /**
   *
   */
  const handleSubmit = () => {
    if (selectionRange.startDate && selectionRange.endDate)
      setOpenPeriodicity(false);
  };

  return (
    <>
      <DateRangePicker
        locale={ro}
        ranges={[selectionRange]}
        onChange={handleSelect}
        dateDisplayFormat={Config.momentEUDateFormatForDateRange}
        staticRanges={[]}
        inputRanges={[]}
        editableDateInputs={true}
        startDatePlaceholder={Vocabulary.startDate}
        endDatePlaceholder={Vocabulary.endDate}
        rangeColors={[themeContext.theme?.palette.secondary.main]}
      />
      {renderCustomFilters()}
      {renderSkipWeekendsComponent()}
      {renderBottomOptions()}
    </>
  );
}
