import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { entityPath } from "../../Utils/UrlEnum";
import FileExplorerMin from "../Widgets/FileExplorerMin";
import { ComponentsNames } from "../../Utils/Constants";
import { useLocation, useParams } from "react-router-dom";
import ChatWindow from "./ChatWindow";

export default function ChatContainer() {
  const [chatId, setChatId] = useState<string>("");
  const location = useLocation();
  const { id } = useParams();

  /**
   *
   */
  useEffect(() => {
    if (id && id !== "") {
      setChatId(id);
    }
  }, [location]);

  return chatId ? (
    <Grid container spacing={3}>
      <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
        <ChatWindow id={chatId} />
      </Grid>
      <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
        <FileExplorerMin
           shouldCRUDFile={true}
          id={chatId}
          name={ComponentsNames.FileExplorerMin}
          baseFolderPath={chatId ? `${entityPath.chat}/${chatId}` : undefined}
        />
      </Grid>
    </Grid>
  ) : null;
}
