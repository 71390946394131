import { createContext, useState } from "react";

export const RefreshDataContext = createContext({
  refresh: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRefresh: (value: boolean) => {},
});

export const RefreshDataContextProvider = (props: any) => {
  const setRefresh = (refresh: boolean) => {
    setState({ ...state, refresh: refresh });
  };
  const initState = {
    refresh: false,
    setRefresh: setRefresh,
  };
  const [state, setState] = useState(initState);
  return (
    <RefreshDataContext.Provider value={state}>
      {props.children}
    </RefreshDataContext.Provider>
  );
};
