/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Button } from "@mui/material";
import { useState } from "react";
import { read, utils } from "xlsx";
import GenericModal from "./GenericModal";
import { Vocabulary } from "../Utils/Vocabulary";

type ImportExcelModalProps = {
  headers: string[];
  title: string;
  setLoading?: (loading: boolean) => void;
  openImportModal: boolean;
  setOpenImportModal: (openImportModal: boolean) => void;
  getNewRow: (row: any) => void;
  getRowsAsArrayData: (data: any) => void;
};

const sheetHeader = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
const SheetJSFT = ["xlsx", "xls", "csv"]
  .map(function (x) {
    return `.${x}`;
  })
  .join(",");

export default function ImportExcelModal(props: ImportExcelModalProps) {
  const [fileName, setFileName] = useState<any>(null);

  /**
   *
   * @param e
   */
  function handleChange(e: any) {
    if (e.target.files && e.target.files[0]) {
      setFileName(e.target.files[0]);
    }
  }

  /**
   *
   * @param headerRow
   * @param index
   * @returns
   */
  function getNewHeaderName(headerRow: any, index: string) {
    if (headerRow[index]) {
      headerRow[index] = headerRow[index].replaceAll(" ", "");
      const found = props.headers.find(
        (h: any) => headerRow[index].toUpperCase() === h.toUpperCase()
      );
      if (found)
        return {
          cell: index,
          value: found,
        };
    }
    return "";
  }

  /**
   *
   * @param headerRow
   * @returns
   */
  function getNewHeader(headerRow: any) {
    const newHeader = new Array<any>();
    sheetHeader.forEach((sh) => {
      if (getNewHeaderName(headerRow, sh) !== "")
        newHeader.push(getNewHeaderName(headerRow, sh));
    });
    return newHeader;
  }

  /**
   *
   */
  function importData() {
    props.setLoading ? props.setLoading(true) : null;
    const reader = new FileReader();
    if (reader)
      reader.onload = (event: any) => {
        // get just first row
        const wb = read(event.target.result, { sheetRows: 1 });
        // get all rows
        const wbAll = read(event.target.result);
        const sheets = wb.SheetNames;
        const data = [];
        if (sheets.length) {
          const headerRow = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            header: sheetHeader,
          });
          //change excel header
          const newHeader = getNewHeader(headerRow[0]);
          newHeader.forEach((header: any) => {
            utils.sheet_add_aoa(wbAll.Sheets[sheets[0]], [[header.value]], {
              origin: `${header.cell}1`,
            });
          });
          //extract rows
          const rows = utils.sheet_to_json(wbAll.Sheets[sheets[0]]) as any;

          for (let i = 0; i < rows.length; i++) {
            //get row data
            const element = props.getNewRow(rows[i]);
            data.push(element);
          }

          props.getRowsAsArrayData(data);
          props.setLoading ? props.setLoading(false) : null;
        }
      };
    reader.readAsArrayBuffer(fileName);
  }
  return (
    <>
      <GenericModal
        open={props.openImportModal}
        onClose={() => props.setOpenImportModal(false)}
        title={props.title}
        children={
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              importData();
            }}
          >
            <input
              type="file"
              id="file"
              required
              name="filename"
              accept={SheetJSFT}
              onChange={handleChange}
            />
            <Button
              variant="contained"
              type="submit"
              size="large"
              color="primary"
            >
              {Vocabulary.save}
            </Button>
          </form>
        }
      />
    </>
  );
}
