import { useSearchParams } from "react-router-dom";

export default function useCustomSearchParam() {
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   *
   * @param name
   * @param value
   */
  const addParamToQueryString = (name: any, value: any) => {
    searchParams.set(name, value);
    setSearchParams(searchParams);
  };
  /**
   *
   * @param name
   * @param value
   */
  const removeParamFromQueryString = (name: any) => {
    name?.map((param: any) => {
      searchParams.delete(param);
    });
    setSearchParams(searchParams);
  };

  return {
    addParamToQueryString,
    removeParamFromQueryString,
    searchParams,
    setSearchParams,
  };
}
