import { GlobalContextProvider } from "./Context/GlobalContext";
import { RefreshDataContextProvider } from "./Context/RefreshDataContext";
import { DrawerStateContextProvider } from "./Context/DrawerStateContext";
import { WebSocketProvider } from "./Context/WebSocketContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocalRouter from "./Router/LocalRouter";
import { ComponentsNames } from "./Utils/Constants";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/LoginPage";
import { localUrlEnum } from "./Utils/UrlEnum";
import Loading from "./Utils/Loading";

import CustomThemeProvider from "./Context/CustomThemeContext";
import { CacheContextProvider } from "./Context/CacheContext";

export default function App() {
  return (
    <CustomThemeProvider>
      <CacheContextProvider>
        <GlobalContextProvider>
          <RefreshDataContextProvider>
            <DrawerStateContextProvider>
              <WebSocketProvider>
                <ToastContainer hideProgressBar={true} />
                {window.localStorage.getItem("userRoles") ? (
                  <LocalRouter name={ComponentsNames.LocalRouter} />
                ) : (
                  <BrowserRouter>
                    <Routes>
                      <Route
                        path={localUrlEnum.pageNotFound}
                        element={<Login />}
                      />
                    </Routes>
                  </BrowserRouter>
                )}
                <Loading />
              </WebSocketProvider>
            </DrawerStateContextProvider>
          </RefreshDataContextProvider>
        </GlobalContextProvider>
      </CacheContextProvider>
    </CustomThemeProvider>
  );
}
