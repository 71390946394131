import { Autocomplete, TextField } from "@mui/material";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ManageAccounts, TaskAltSharp } from "@mui/icons-material";
import withRole from "../../Hooks/withRole";
import FilterContainer from "../FilterContainer";
import DatePickersIntervalForFilters from "../DatePickersIntervalForFilters";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import { Controller } from "react-hook-form";
import moment from "moment";
import { useEffect } from "react";

type Props = {
  name: string;
  methods: any;
  filtersData: any;
  shouldRenderProjectManagerFilter?: boolean;
};

function ProjectsFilters(props: Props) {
  const { filtersData, methods, shouldRenderProjectManagerFilter } = props;
  const customSearchParams = useCustomSearchParam();

  /**
   *
   * @param key
   * @param value
   */
  const handleChangeFilters = (key: string, value: any) => {
    if (value !== null && value !== undefined) {
      //if value is an array then create a string from with, whit values separated from comma
      if (Array.isArray(value)) {
        value = value?.map((x) => x.id).join(";");
      }
      customSearchParams.addParamToQueryString(key, value);
    } else {
      //check if parameter deleted is type, then delete selected project as well
      customSearchParams.removeParamFromQueryString([key]);
    }
    window.scrollTo(0, 0);
  };

  /**
   *
   */
  const resetFilters = () => {
    methods.reset();
    customSearchParams.removeParamFromQueryString([
      "status",
      "start",
      "end",
      "projectManager",
      "search",
    ]);
  };

  /**
   *
   * @param property
   * @returns
   */
  const getOptionsArray = (property: string) => {
    switch (property) {
      case "status":
        return filtersData?.statuses;
      case "projectManager":
        return filtersData?.users;
      default:
        return [];
    }
  };

  /**
   *
   * @param methods
   */
  const resetFilterData = () => {
    const urlParams: any = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParams) {
      const stringValuesArray = value.split(";");
      const objectValuesArray: any = [];
      switch (key) {
        case "status":
          //for each value in array, find the object in the options array and add it to the selectedValues object
          stringValuesArray.forEach((value: any) => {
            const newValue = getOptionsArray(key).find((option: any) => {
              return option.id === value;
            });
            objectValuesArray.push(newValue);
          });
          methods.setValue(key, objectValuesArray);
          break;
        case "projectManager":
          //for each value in array, find the object in the options array and add it to the selectedValues object
          stringValuesArray.forEach((value: any) => {
            const newValue = getOptionsArray(key).find((option: any) => {
              return option.id === value;
            });
            objectValuesArray.push(newValue);
          });
          methods.setValue(key, objectValuesArray[0]);
          break;
        case "start":
        case "end":
          methods.setValue(key, moment(value));
          break;
        default:
          methods.setValue(key, value);
          break;
      }
    }
  };

  /**
   *
   */
  useEffect(() => {
    if (filtersData?.statuses?.length > 0) {
      resetFilterData();
    }
  }, [filtersData]);

  return (
    <FilterContainer
      resetFilters={resetFilters}
      filters={
        <>
          <div className={genericStyles.filter}>
            <TaskAltSharp color="secondary" sx={{ marginRight: "5px" }} />
            <Controller
              name="status"
              control={methods.control}
              defaultValue={[]}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  noOptionsText={Vocabulary.noResultsFound}
                  className={genericStyles.fullWidthElement}
                  multiple
                  id={"status"}
                  options={filtersData?.statuses || []}
                  getOptionLabel={(option: any) => option.translation}
                  isOptionEqualToValue={(option: any, value: any) => {
                    const valueId = value.id ? value.id : value;
                    return option.id === valueId;
                  }}
                  onChange={(event, value) => {
                    field.onChange(value);
                    handleChangeFilters(
                      "status",
                      value.length > 0 ? value : null
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.chooseStatus}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </div>
          {shouldRenderProjectManagerFilter && (
            <div className={genericStyles.filter}>
              <ManageAccounts color="secondary" sx={{ marginRight: "5px" }} />
              <Controller
                name="projectManager"
                control={methods.control}
                defaultValue={null}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...field}
                    noOptionsText={Vocabulary.noResultsFound}
                    className={genericStyles.fullWidthElement}
                    id={"projectManager"}
                    options={filtersData?.users || []}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) =>
                      `${option?.lastName ? option?.lastName : ""} ${
                        option?.firstName ? option?.firstName : ""
                      }`
                    }
                    onChange={(event, value) => {
                      field.onChange(value);
                      handleChangeFilters("projectManager", value?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={Vocabulary.chooseProjectManager}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </div>
          )}
          <DatePickersIntervalForFilters
            methods={methods}
            handleChangeFilters={handleChangeFilters}
          />
        </>
      }
    />
  );
}

export default withRole(ProjectsFilters);
