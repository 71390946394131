/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import genericStyles from "../Styles/genericStyles.module.css";
import DynamicTabs from "../Components/DynamicTabs";
import CustomTabPanel from "../Components/CustomTabPanel";
import PersonalRequests from "../Components/Requests/PersonalRequests";
import withRole from "../Hooks/withRole";
import RequestsManagement from "../Components/Requests/RequestsManagement";

type Props = {
  tabs: string[];
  name: string;
  shouldRenderSearchBar?: boolean;
  shouldRenderManagementTab?: boolean;
};

function RequestTabs(props: Props) {
  const [tabValue, setTabValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   *
   */
  useEffect(() => {
    const tab = searchParams.get("tab");
    setTabValue(tab ? parseInt(tab) : 0);
  }, [searchParams]);

  return (
    <>
      <div className={genericStyles.header}>
        <DynamicTabs tabValue={tabValue} labels={props.tabs} />
      </div>
      <div className={genericStyles.tabPanelContainer}>
        <CustomTabPanel value={tabValue} index={0}>
          <PersonalRequests />
        </CustomTabPanel>
        {props.shouldRenderManagementTab && (
          <CustomTabPanel value={tabValue} index={1}>
            <RequestsManagement />
          </CustomTabPanel>
        )}
      </div>
    </>
  );
}

export default withRole(RequestTabs);
