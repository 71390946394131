import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import GenericModal from "../GenericModal";
import MUIDataTableCustomStyle from "../MUIDataTable/MUIDataTableCustomStyle";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { SubTaskModel, TaskStatusModel } from "../../Utils/Models";
import { Delete, Edit, Save } from "@mui/icons-material";
import { deleteData } from "../../Services/deleteData";
import { urlEnum } from "../../Utils/UrlEnum";
import { postData } from "../../Services/postData";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { getData } from "../../Services/getData";
import { RefreshDataContext } from "../../Context/RefreshDataContext";

type Props = {
  open: boolean;
  onClose: () => void;
  statuses: TaskStatusModel[] | [];
  parentTaskId: string | null;
  shouldFieldsBeDisabled: boolean;
  isParentFinished: boolean;
};

function SubtasksModal(props: Props) {
  const [task, setTask] = useState<SubTaskModel>(new SubTaskModel());
  const [subtasks, setSubtasks] = useState<[]>([]);
  const refreshDataContext = useContext(RefreshDataContext);
  /**
   *
   */
  useEffect(() => {
    if (props.parentTaskId) {
      getSubtasks();
    }
  }, [props.parentTaskId]);

  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    return {
      download: false,
      serverSide: true,
      pagination: false,
      print: false,
      viewColumns: false,
      search: false,
      filter: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
    };
  };

  /**
   *
   */
  const subtasksHeader = [
    {
      label: Vocabulary.crtNo,
      name: "",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      label: Vocabulary.name,
      name: "text",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return <p>{value ? value.translation : "-"}</p>;
        },
      },
    },
    {
      label: Vocabulary.options,
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <ToggleButtonGroup exclusive aria-label="text alignment">
              <Tooltip title={Vocabulary.edit}>
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  disabled={props.isParentFinished}
                  onClick={() => {
                    setTask(subtasks[dataIndex]);
                  }}
                >
                  <Edit />
                </ToggleButton>
              </Tooltip>
              <Tooltip title={Vocabulary.delete}>
                <ToggleButton
                  value="center"
                  disabled={props.shouldFieldsBeDisabled}
                  aria-label="centered"
                  onClick={() => {
                    deleteSubtasks(dataIndex);
                  }}
                >
                  <Delete />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          );
        },
      },
    },
  ];

  /**
   *
   * @param index
   */
  const deleteSubtasks = (index: number) => {
    const selectedSubtask: any = subtasks[index];
    if (selectedSubtask?.id) {
      deleteSubtaskFromLocalArray(selectedSubtask?.id);
    }
    deleteData(`${urlEnum.deleteTask}/${selectedSubtask?.id}`)
      .then((res: any) => {
        if (res) {
          //delete task from local array
          deleteSubtaskFromLocalArray(index);
          refreshDataContext.setRefresh(true);
        }
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  /**
   *
   */
  const getSubtasks = () => {
    getData(`${urlEnum.getSubtasksByParent}/${props.parentTaskId}`).then(
      (res: any) => {
        if (res) {
          setSubtasks(res.data);
        }
      }
    );
  };

  /**
   *
   * @param index
   */
  const deleteSubtaskFromLocalArray = (index: number) => {
    const newSubtasks: any = Object.assign([], subtasks);
    newSubtasks.splice(index, 1);
    setSubtasks(newSubtasks);
  };

  /**
   *
   * @param event
   */
  const handleChange = (event: any) => {
    const newTask: any = Object.assign({}, task);
    newTask[event.target.name] = event.target.value;
    setTask(newTask);
  };

  /**
   *
   */
  const handleAddSubtasks = () => {
    postData(`${urlEnum.createSubtasks}/${props.parentTaskId}`, task).then(
      (res: any) => {
        if (res && !res.errors) {
          setTask(new SubTaskModel());
          getSubtasks();
          refreshDataContext.setRefresh(true);
        }
      }
    );
  };

  /**
   *
   */
  const handleOnCloseModal = () => {
    props.onClose();
  };

  /**
   *
   */
  const emptyFields = () => {
    setTask(new SubTaskModel());
  };

  return (
    <GenericModal
      open={props.open}
      onClose={handleOnCloseModal}
      title={Vocabulary.subtasks}
      children={
        <ValidatorForm onSubmit={handleAddSubtasks}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextValidator
                id="text"
                name="text"
                disabled={props.shouldFieldsBeDisabled}
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                label={Vocabulary.fancyName}
                fullWidth
                onChange={handleChange}
                value={task.text ?? ""}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="status"
                noOptionsText={Vocabulary.noResultsFound}
                disablePortal
                getOptionLabel={(option: any) => option.translation}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.id === value?.id
                }
                options={props.statuses}
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  value: any
                ) => {
                  handleChange({ target: { name: "status", value: value } });
                }}
                value={task.status ?? null}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={Vocabulary.status}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                id="description"
                name="description"
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                label={Vocabulary.description}
                fullWidth
                disabled={props.shouldFieldsBeDisabled}
                multiLine
                rows={3}
                onChange={handleChange}
                value={task.description ?? ""}
                required
              />
            </Grid>

            <Grid item xs={7}>
              <Button
                startIcon={<Save />}
                variant="contained"
                type="submit"
                fullWidth
                disabled={props.shouldFieldsBeDisabled && !task.id}
              >
                {task.id ? Vocabulary.editSubtask : Vocabulary.addNewSubtask}
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                startIcon={<Delete />}
                variant="contained"
                fullWidth
                onClick={emptyFields}
                color="header"
              >
                {Vocabulary.emptyFields}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <MUIDataTableCustomStyle
                title={""}
                data={subtasks}
                columns={subtasksHeader}
                options={getTableOptions()}
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      }
    />
  );
}

export default SubtasksModal;
