import moment, { Moment } from "moment";
import Config from "./Config";
import { EAuditAction, ERequestStatus, ETaskStatus } from "./Enums";

export class LoginModel {
  email: string | null = "";
  password: string | null = "";
  reCaptcha: string | null = "";
}

export class UserModel {
  id: string | null = null;
  lastName: string | null = "";
  firstName: string | null = "";
  email: string | null = "";
  departmentName: string | null = "";
  password: string | null = null;
  rolesArray: object[] | null = [];
  subordinateDepartmentsArray: object[] | null = [];
  profilePicture: any = "";
  position: string | null = "";
  isVisible = true;
  file: any;
}

export class DepartmentModel {
  id = null;
  name: string | null = "";
}

export interface Task {
  id: string;
  text: string;
  description: string;
  priority: string;
  start: string;
  end: string;
  project: string;
}
export class MinutesModel {
  agenda = "";
  createdAt: Date = new Date();
}

export class MeetingModel {
  id: string | null = null;
  text: string | null = null;
  description: string | null = "";
  link: string | null = "";
  location: string | null = "";
  departments: Array<DepartmentModel> | null = [];
  assignedUsers: Array<UserModel> | null = null;
  objective: string | null = "";
  resources: string | null = "";
  evaluation: string | null = "";
  remarks: string | null = "";
  minutes: MinutesModel = new MinutesModel();
  periodicity: TaskPeriodicityModel = new TaskPeriodicityModel();
  type: MeetingTypeModel = new MeetingTypeModel();
  status: MeetingStatusModel = new MeetingStatusModel();
  responsible: UserModel | undefined = undefined;
  createdBy: UserModel | null = null;
  skipWeekend = true;
  start: string | Date | Moment = moment();
  end: string | Date | Moment = moment().add(1, "hours");
  barColor = "#b291a2";
}

export class MeetingAutocompleteData {
  users: Array<UserModel> = [];
  periodicities: Array<TaskPeriodicityModel> = [];
  types: Array<MeetingTypeModel> = [];
  departments: Array<DepartmentModel> = [];
  statuses: Array<MeetingTypeModel> = [];
}

export class RequestModel {
  id: string | null = null;
  text = "";
  description = "";
  observations: string | null = "";
  status: RequestStatusModel = new RequestStatusModel();
  assignedUsers: Array<AssignedUserModel> | [] = [];
  departments: Array<DepartmentModel> | [] = [];
  createdBy: UserModel | null = null;
  denyReason: string | null = "";
  solvedBy: UserModel | null = null;
  createdAt: string | Date = moment().format(Config.momentUSDateFormat);
  updatedAt: string | Date = moment().format(Config.momentUSDateFormat);
  deletedAt: string | Date | null = null;
}

export class TaskModel {
  id: string | null = null;
  text: string | null = "";
  skipWeekend: boolean | null = true;
  description: string | null = "";
  status: TaskStatusModel = new TaskStatusModel();
  assignedUsers: Array<AssignedUserModel> | [] = [];
  priority: TaskPriorityModel = new TaskPriorityModel();
  start: string | Date = moment().format(Config.momentUSDateFormat);
  end: string | Date = moment().format(Config.momentUSDateFormat);
  finishDate: string | Date | null = null;
  remarks: string | null = "";
  equipment: string | null = "";
  budget: string | null = "";
  progress: any = 0;
  project: ProjectModel | null | any = null;
  type: TaskTypeModel = new TaskTypeModel();
  periodicity: TaskPeriodicityModel = new TaskPeriodicityModel();
  parent: TaskModel | null = null;
  createdBy: UserModel | null = null;
  isFlexible = false;
  finishCondition: number | null = 0;
  backColor = "";
  barColor = "";
  disabled = false;
  departments: Array<DepartmentModel> | [] = [];

  constructor(
    priority?: TaskPriorityModel,
    status?: TaskStatusModel,
    periodicity?: TaskPeriodicityModel,
    type?: TaskTypeModel,
    project?: any
  ) {
    if (priority) this.priority = priority;
    if (status) this.status = status;
    if (periodicity) this.periodicity = periodicity;
    if (type) this.type = type;
    if (project) this.project = project;
  }
}

export class SubTaskModel {
  id: string | null = null;
  text: string | null = "";
  description: string | null = "";
  status: TaskStatusModel = new TaskStatusModel();
  finishCondition: number | null = 0;
  parent: TaskModel | null = null;
  createdBy: UserModel | null = null;
}

export class GenericTaskSubEntityModel {
  id: string | null = null;
  name: string | null = "";
  translation: string | null = "";
}

export class NotificationModel {
  id: number | null = null;
  isRead: boolean | null = null;
  message: string | null = null;
  user: UserModel | null = null;
  entityId: string | null = null;
  entityType: string | null = null;
}

export class TaskPriorityModel extends GenericTaskSubEntityModel {
  color = "";
}

export class MeetingTypeModel extends GenericTaskSubEntityModel {
  color = "";
}

export class TaskStatusModel extends GenericTaskSubEntityModel {
  color = "#000000";
  isEditable = true;
  name = ETaskStatus.STATUS_CUSTOM;
  deletedAt = null;
  createdAt = "";
  updatedAt = "";
}
export class RequestStatusModel extends GenericTaskSubEntityModel {
  color = "#000000";
  name = ERequestStatus.STATUS_PENDING;
  deletedAt = null;
  createdAt = "";
  updatedAt = "";
}

export class TaskTypeModel extends GenericTaskSubEntityModel {}
export class TaskPeriodicityModel extends GenericTaskSubEntityModel {
  nrOfDays = 0;
}

export class MeetingStatusModel extends GenericTaskSubEntityModel {
  color = "";
}
export class AssignedUserModel {
  id: number | null = null;
  firstName: string | null = "";
  lastName: string | null = "";
  profilePicture: string | undefined = "";
}
export class ProjectModel {
  id: string | undefined = "";
  name: string | null = null;
  description: string | null = "";
  start: string | null = null;
  end: string | null = null;
  projectManager: string | null = "";
  status: string | null = "";
  projectUsers: Array<UserModel> = [];
  fileGallery: Array<Attachment> | null = null;
  customFields: Array<UserModel> = [];
}

export class CustomField {
  name: string | null = null;
  value: string | null = null;
}

export class SelectionRangeModel {
  startDate: Date | undefined = new Date();
  endDate: Date | undefined = new Date();
  key = "selection";
}

export class AutocompleteDataForRequests {
  departments: [] = [];
  users: [] = [];
  statuses: [] = [];
}

export class AutocompleteDataForMeetings {
  departments: [] = [];
  types: [] = [];
  periodicities: [] = [];
  users: [] = [];
}

export class AutocompleteDataForTask {
  users: [] = [];
  priorities: [] = [];
  statuses: [] = [];
  periodicities: [] = [];
  types: [] = [];
  projects: [] = [];
  departments: [] = [];
}

export class ChatListAutocompleteModel {
  users: [] = [];
}

export class CalendarFiltersAutocompleteModel {
  users: UserModel[] = [];
}

export class CalendarFiltersModel {
  type: number | null = null;
  start: string | null = null;
  end: string | null = null;
  difference = 0;
  user: any = null;
}

export class BaseStateModel {
  page = 0;
  perPage = 20;
  count = 0;
  search: string | null = "";
  sort = "DESC";
}

export class AssignedUsersOptionsStateModal {
  checkAllUsers = false;
  chooseAllDepartmentHead = false;
  selectedDepartments = [];
  option = 0;
}

export class TasksStateModel extends BaseStateModel {
  selectedTask: TaskModel | null = new TaskModel();
  tasks: TaskModel[] | [] = [];
  sortProperty = "end";
  sort = "ASC";
  processingTasksCount = 0;
  notStartedTasksCount = 0;
}

export class ModifiedTasksStateModel extends BaseStateModel {
  tasks: AuditEntityModel[] | [] = [];
  sortProperty = "end";
  sort = "ASC";
}

export class AuditEntityModel {
  id: string | null = null;
  action: EAuditAction | null = null;
  entityName: string | null = null;
  entityId: string | null = null;
  oldEntity: any | null = null;
  newEntity: any | null = null;
  createdBy: UserModel | null = null;
  createdAt: string | Date = "";
}

export class ChatsStateModel extends BaseStateModel {
  chats: any = [];
}

export class ChatModel {
  id: string | null = null;
  name: string | null = null;
  participants: Array<UserModel> = [];
  messages: Array<MessageModel> | [] = [];
  project: ProjectModel | null = null;
}
export class MessageModel {
  message: string | null = null;
  sender: UserModel | null = null;
  createdAt: string | Date = "";
}

export class ProjectsState extends BaseStateModel {
  projects: any = [];
  selectedProject: ProjectModel | null = new ProjectModel();
}

export class RequestsState extends BaseStateModel {
  requests: any = [];
  selectedRequest: any = null;
  page = 1;
}

export class UsersState extends BaseStateModel {
  users: any = [];
  roleFilter: any = null;
  departmentFilter: any = null;
}

export class TaskStatusesState extends BaseStateModel {
  statuses: any = [];
}

export class MeetingsState extends BaseStateModel {
  meetings: any = [];
  selectedMeeting: MeetingModel | null = null;
}

export class UserImportModel {
  Nume: string | null = null;
  Prenume: string | null = null;
  Email: string | null = null;
  Departament: string | null = null;
  Cont: string | null = null;
  Functie: string | null = null;
  Roluri: string | null = null;
  Subordine: string | null = null;
}

export class Attachment {
  name: string | null = "";
  path: string | null = "";
  isDir = false;
  thumb: string | null = "/Images/Icons/Generic-icon.png";
  fileGallery: Attachment[] | null = null;
}

export class DailyWorkloadDashboardWidgetModel {
  dailyTasksNr = 0;
  projectsNr = 0;
  dailyMeetingsNr = 0;
  quoteOfTheDay = "";
}

export class ClientContractFile {
  id = 0;
  title = "";
  name = "";
  path = "";
  fileContent: any | null = null;
}

export class ClientData {
  id = 0;
  name = "";
  paymentDeadline = "";
  discountOnInvoice: any = null;
  annualBonusesAndGoals: any = null;
}

export class DocumentGeneratorState {
  clients: any = [];
  contractTemplatesFiles: ClientContractFile[] = [];
  clientsDataFiles: ClientContractFile[] = [];
  selectedClients: ClientData[] = [];
  selectedContractTemplateFiles: ClientContractFile[] = [];
}
