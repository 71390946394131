/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import DynamicTabs from "../Components/DynamicTabs";
import genericStyles from "../Styles/genericStyles.module.css";
import { useSearchParams } from "react-router-dom";
import CustomTabPanel from "../Components/CustomTabPanel";
import Tasks from "../Pages/Tasks";
import UnassignedTasks from "../Components/Reports/UnassignedTasks";
import ModifiedTasks from "../Components/Tasks/ModifiedTasks";
import withRole from "../Hooks/withRole";
import {
  TaskReportsAdminTabs,
  TaskReportsDepartmentHeadTabs,
} from "../Utils/Constants";

type Props = {
  shouldRenderSearchBar?: boolean;
  shouldRenderModifiedTasksTab?: boolean;
};

function ReportsAndUnassignedTasksPage(props: Props) {
  const [tabValue, setTabValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  /**
   *
   */
  useEffect(() => {
    const tab = searchParams.get("tab");
    setTabValue(tab ? parseInt(tab) : 0);
  }, [searchParams]);

  return (
    <>
      <div className={genericStyles.header}>
        <DynamicTabs
          tabValue={tabValue}
          labels={
            props.shouldRenderModifiedTasksTab
              ? TaskReportsAdminTabs
              : TaskReportsDepartmentHeadTabs
          }
        />
      </div>
      <div className={genericStyles.tabPanelContainer}>
        <CustomTabPanel value={tabValue} index={0}>
          <Tasks />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <UnassignedTasks />
        </CustomTabPanel>
        {props.shouldRenderModifiedTasksTab && (
          <CustomTabPanel value={tabValue} index={2}>
            <ModifiedTasks />
          </CustomTabPanel>
        )}
      </div>
    </>
  );
}

export default withRole(ReportsAndUnassignedTasksPage);
