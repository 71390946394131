import { Grid, Paper, Typography } from "@mui/material";
import { RequestModel } from "../../Utils/Models";
import styles from "../../Styles/requests.module.css";
import genericStyles from "../../Styles/genericStyles.module.css";
import { Fragment } from "react";
import { renderSubstring } from "../../Utils/Utils";
import { Vocabulary } from "../../Utils/Vocabulary";

type Props = {
  requests: RequestModel[];
  renderRequestOptions: (request: RequestModel) => JSX.Element;
  header: any[];
  shouldRenderAdminSpecialOptions?: boolean;
};

function RequestsList(props: Props) {
  const { header } = props;
  return (
    <Fragment>
      <Grid container spacing={2} className={styles.requestListHeader}>
        {header?.map((item: any, index: number) => {
          return item.name === Vocabulary.createdBy ? (
            props.shouldRenderAdminSpecialOptions && (
              <Grid
                key={index}
                className={styles.requestItem}
                item
                xs={12}
                sm={12}
                md={1}
                xl={item.maxWidth}
              >
                <Typography sx={{ fontWeight: "bold" }}>{item.name}</Typography>
              </Grid>
            )
          ) : (
            <Grid
              key={index}
              className={styles.requestItem}
              item
              xs={12}
              sm={12}
              md={1}
              xl={item.maxWidth}
            >
              <Typography sx={{ fontWeight: "bold" }}>{item.name}</Typography>
            </Grid>
          );
        })}
      </Grid>
      {props.requests?.map((request: RequestModel, index: number) => {
        return (
          <Paper key={index} className={styles.requestListPaper}>
            <Grid container spacing={2}>
              <Grid
                className={styles.requestItem}
                item
                xs={12}
                sm={12}
                md={1}
                xl={1}
              >
                <div
                  className={genericStyles.requestColorCircle}
                  style={{ backgroundColor: request.status.color }}
                />
                <Typography>{request.status.translation}</Typography>
              </Grid>
              <Grid
                className={styles.requestItemBasinInfo}
                item
                xs={12}
                sm={12}
                md={12}
                xl={5}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {request.text}
                </Typography>
                <Typography variant="caption">
                  {renderSubstring(request.description, 250)}
                </Typography>
              </Grid>
              <Grid
                className={styles.requestItem}
                item
                xs={12}
                sm={12}
                md={12}
                xl={1}
              >
                {props.shouldRenderAdminSpecialOptions && (
                  <Typography>{`${request.createdBy?.lastName || ""} ${
                    request.createdBy?.firstName || ""
                  }`}</Typography>
                )}
              </Grid>
              <Grid
                className={styles.requestItem}
                item
                xs={12}
                sm={12}
                md={12}
                xl={1}
              >
                <Typography>{`${request.solvedBy?.lastName || ""} ${
                  request.solvedBy?.firstName || ""
                }`}</Typography>
              </Grid>
              <Grid
                className={`${styles.requestItem} ${styles.departmentItem}`}
                item
                xs={12}
                sm={12}
                md={12}
                xl={3}
              >
                {request.departments?.map((department: any, index: any) => {
                  return (
                    <Typography key={index}>{`${department.name}${
                      index < request.departments.length - 1 ? "; " : ""
                    }`}</Typography>
                  );
                })}
              </Grid>
              <Grid
                className={styles.requestItem}
                item
                xs={12}
                sm={12}
                md={12}
                xl={1}
              >
                {props.renderRequestOptions(request)}
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </Fragment>
  );
}

export default RequestsList;
