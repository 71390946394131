import React, { useEffect } from "react";

type Props = {
  requestFunction: (params: any) => void;
};
/**
 * This component takes the parameters from the query string and returns an object.
 * @param props
 * @returns
 */
export default function QueryStringParser(props: Props) {
  /**
   *
   */
  useEffect(() => {
    const url = new URL("", window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const entries = searchParams.entries();
    props.requestFunction(Object.fromEntries(entries));
  }, [document.location.search]);

  return <></>;
}
