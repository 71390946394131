/* eslint-disable no-unused-vars */

export enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ERequestStatus {
  STATUS_PENDING = "STATUS_PENDING",
  STATUS_APPROVED = "STATUS_APPROVED",
  STATUS_REJECTED = "STATUS_REJECTED",
}

export enum ETaskStatus {
  STATUS_FINISHED = "STATUS_FINISHED",
  STATUS_IN_PROGRESS = "STATUS_IN_PROGRESS",
  STATUS_NOT_STARTED = "STATUS_NOT_STARTED",
  STATUS_CUSTOM = "STATUS_CUSTOM",
}

export enum EProjectStatus {
  STATUS_DRAFT = "STATUS_DRAFT",
  STATUS_ACTIVE = "STATUS_ACTIVE",
  STATUS_ENDED = "STATUS_ENDED",
}

export enum ETaskPriority {
  PRIORITY_LOW = "PRIORITY_LOW",
  PRIORITY_MEDIUM = "PRIORITY_MEDIUM",
  PRIORITY_HIGH = "PRIORITY_HIGH",
}

export enum ETaskType {
  TASK_ALLOCATED = "TASK_ALLOCATED",
  TASK_PROJECT = "TASK_PROJECT",
  TASK_SPECIFIC = "TASK_SPECIFIC",
}

export enum ETaskPeriodicity {
  PERIODICITY_DAILY = "PERIODICITY_DAILY",
  PERIODICITY_WEEKLY = "PERIODICITY_WEEKLY",
  PERIODICITY_MONTHLY = "PERIODICITY_MONTHLY",
  PERIODICITY_YEARLY = "PERIODICITY_YEARLY",
  PERIODICITY_SEMESTRIAL = "PERIODICITY_SEMESTRIAL",
  PERIODICITY_TRIMESTRIAL = "PERIODICITY_TRIMESTRIAL",
  PERIODICITY_UNIQUE = "PERIODICITY_UNIQUE",
}

export enum EMeetingType {
  MEETING_OPEN = "TYPE_OPEN",
  MEETING_PRIVATE = "TYPE_PRIVATE",
}

export enum EMeetingStatus {
  MEETING_STATUS_PLANNED = "MEETING_STATUS_PLANNED",
  MEETING_STATUS_IN_PROGRESS = "MEETING_STATUS_IN_PROGRESS",
  MEETING_STATUS_COMPLETED = "MEETING_STATUS_COMPLETED",
  MEETING_STATUS_CANCELLED = "MEETING_STATUS_CANCELLED",
}

export enum ERole {
  ROLE_ADMINISTRATOR = "ROLE_ADMINISTRATOR",
  ROLE_DEPARTMENT_HEAD = "ROLE_DEPARTMENT_HEAD",
  ROLE_USER = "ROLE_USER",
  ROLE_STORE_HEAD = "ROLE_STORE_HEAD",
  ROLE_STORE_MANAGER = "ROLE_STORE_MANAGER",
  ROLE_DESIGNER = "ROLE_DESIGNER",
  ROLE_PROJECTS_ADMINISTRATOR = "ROLE_PROJECTS_ADMINISTRATOR",
}

export enum EAuditAction {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  SOFT_DELETE = "SOFT_DELETE",
}
