import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Step,
  StepButton,
  Stepper,
  TextField,
  Tooltip,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import styles from "../../Styles/project.module.css";
import genericStyles from "../../Styles/genericStyles.module.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Config from "../../Utils/Config";
import { postData } from "../../Services/postData";
import { urlEnum } from "../../Utils/UrlEnum";
import { ProjectSteps } from "../../Utils/Constants";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Delete } from "@mui/icons-material";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { getData } from "../../Services/getData";
import { EProjectStatus } from "../../Utils/Enums";

type Props = {
  closeModal: () => void;
  project?: any;
  handleChangeProject?: (project: any) => void;
  isProjectManager?: boolean;
};

export default function ProjectModal(props: Props) {
  const { isProjectManager } = props;
  const [activeStep, setActiveStep] = useState(0);
  const refreshContext = useContext(RefreshDataContext);
  const [filters, setFilters] = useState<any>({
    statuses: [],
    users: [],
  });
  const projectValidationSchema = yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string().required(Vocabulary.requiredField),
      startDate: yup.string().required(Vocabulary.requiredField),
      endDate: yup.string().required(Vocabulary.requiredField),
      status: yup.object().required(Vocabulary.requiredField),
      description: yup.string().required(Vocabulary.requiredField),
      projectManager: yup.object().required(Vocabulary.requiredField),
      customFields: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string(),
            value: yup.string(),
          })
        )
        .nullable(),
      projectUsers: yup.array(),
    })
    .required();

  const { register, control, handleSubmit, reset, getValues, formState } =
    useForm({
      defaultValues: {
        status: filters.statuses?.find((status: any) => {
          return status.name === EProjectStatus.STATUS_DRAFT;
        }),
      },
      resolver: yupResolver(projectValidationSchema),
      mode: "onChange",
      reValidateMode: "onChange",
      context: undefined,
      shouldFocusError: true,
      shouldUnregister: false,
      criteriaMode: "firstError",
    });

  const { append, remove } = useFieldArray({
    control,
    name: "customFields",
  });

  /**
   *
   */
  useEffect(() => {
    if (props?.project?.id) {
      reset(props.project);
    } else if (filters.statuses?.length) {
      const defaultValues: any = {};
      defaultValues.status = filters.statuses?.find((status: any) => {
        return status.name === EProjectStatus.STATUS_DRAFT;
      });
      reset({ ...defaultValues });
    }
  }, [filters.statuses, props.project]);

  /**
   *
   */
  useEffect(() => {
    const promises = [
      getData(urlEnum.getProjectStatuses),
      getData(
        `${urlEnum.getUsersForFilter}/${localStorage.getItem(
          "userId"
        )}?shouldGetAll=true`
      ),
    ];
    Promise.all(promises)
      .then((res) => {
        setFilters({
          ...filters,
          statuses: res[0].data,
          users: res[1].data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   *
   */
  const handleSaveProject = () => {
    postData(urlEnum.createProject, getValues()).then((res: any) => {
      if (res) {
        refreshContext.setRefresh(true);
        if (props.handleChangeProject) props.handleChangeProject(res.data);
        props.closeModal();
      }
    });
  };

  /**
   *
   */
  const addNewCustomField = (number: number) => {
    append({
      name: "",
      value: "",
    });
  };

  /**
   *
   * @param index
   * @returns
   */
  const deleteCustomField = (number: number) => {
    remove(number);
  };

  /**
   *
   * @param step
   * @returns
   */
  const handleChangeStep = (step: number) => {
    setActiveStep(step);
  };

  /**
   *
   * @returns
   */
  const renderCustomFieldsStep = () => {
    return (
      <>
        {getValues("customFields")?.map((value: any, number: number) => {
          return (
            <>
              <Grid item md={5} sm={5} xs={12}>
                <TextField
                  key={number}
                  variant="outlined"
                  label={Vocabulary.name}
                  disabled={isProjectManager}
                  fullWidth
                  {...register(`customFields.${number}.name`)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <TextField
                  key={number}
                  variant="outlined"
                  disabled={isProjectManager}
                  {...register(`customFields.${number}.value`)}
                  label={Vocabulary.value}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={1} sm={1} xs={12}>
                <Tooltip title={Vocabulary.delete}>
                  <IconButton
                    disabled={isProjectManager}
                    onClick={() => deleteCustomField(number)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Grid>
            </>
          );
        })}
        <Grid item md={12} sm={12} xs={12}>
          <Button
            variant="contained"
            color="secondary"
            disabled={isProjectManager}
            onClick={() =>
              addNewCustomField(getValues("customFields")?.length ?? 0)
            }
          >
            {Vocabulary.addCustomField}
          </Button>
        </Grid>
      </>
    );
  };

  /**
   *
   * @returns
   */
  const renderBasicInfoStep = () => {
    return (
      <>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            {...register("name")}
            error={typeof formState.errors.name === "object"}
            helperText={formState.errors.name?.message?.toString()}
            variant="outlined"
            label={Vocabulary.fancyName}
            disabled={isProjectManager}
            fullWidth
            id={"name"}
            name={"name"}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Controller
            name="startDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format={Config.momentEUDateFormat}
                  label={Vocabulary.startDate}
                  className={genericStyles.fullWidthElement}
                  disabled={isProjectManager}
                  slotProps={{
                    textField: {
                      helperText: error?.message,
                      error: !!error,
                    },
                  }}
                  value={
                    getValues("startDate")
                      ? moment(getValues("startDate"))
                      : null
                  }
                  onChange={(date: any) =>
                    onChange(date.format(Config.momentUSDateFormat))
                  }
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Controller
            name="endDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format={Config.momentEUDateFormat}
                  label={Vocabulary.endDate}
                  disabled={isProjectManager}
                  className={genericStyles.fullWidthElement}
                  minDate={
                    getValues("startDate")
                      ? moment(getValues("startDate"))
                      : null
                  }
                  slotProps={{
                    textField: {
                      helperText: error?.message,
                      error: !!error,
                    },
                  }}
                  value={
                    getValues("endDate") ? moment(getValues("endDate")) : null
                  }
                  onChange={(date: any) =>
                    onChange(date.format(Config.momentUSDateFormat))
                  }
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Controller
            name="projectManager"
            control={control}
            render={({ field: { ref, ...field } }) => {
              return (
                <Autocomplete
                  noOptionsText={Vocabulary.noResultsFound}
                  {...field}
                  handleHomeEndKeys
                  disabled={isProjectManager}
                  options={filters?.users||[]}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option: any) =>
                    `${option.lastName ?? ""} ${option.firstName ?? ""}`
                  }
                  value={getValues("projectManager") || null}
                  onChange={(event, value) => field.onChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.projectManager}
                      inputRef={ref}
                      error={
                        typeof formState.errors.projectManager === "object"
                      }
                      helperText={formState.errors.projectManager?.message?.toString()}
                    />
                  )}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Controller
            name="status"
            control={control}
            defaultValue={filters.statuses?.find((status: any) => {
              return status.name === EProjectStatus.STATUS_DRAFT;
            })}
            render={({ field: { ref, ...field } }) => {
              return (
                <Autocomplete
                  {...field}
                  noOptionsText={Vocabulary.noResultsFound}
                  disablePortal
                  handleHomeEndKeys
                  disabled={isProjectManager}
                  options={filters?.statuses||[]}
                  getOptionLabel={(option: any) => option.translation}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={getValues("status") || null}
                  onChange={(e, value) => field.onChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.status}
                      inputRef={ref}
                      error={typeof formState.errors.status === "object"}
                      helperText={formState.errors.status?.message?.toString()}
                    />
                  )}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name="projectUsers"
            control={control}
            render={({ field: { ref, ...field } }) => {
              return (
                <Autocomplete
                  {...field}
                  noOptionsText={Vocabulary.noResultsFound}
                  multiple
                  handleHomeEndKeys
                  options={filters?.users||[]}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option: any) =>
                    `${option.lastName ? option.lastName : ""} ${
                      option.firstName ? option.firstName : ""
                    }`
                  }
                  value={getValues("projectUsers") || []}
                  onChange={(event, value) => field.onChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.projectUsers}
                      inputRef={ref}
                      error={typeof formState.errors.projectUsers === "object"}
                      helperText={formState.errors.projectUsers?.message?.toString()}
                    />
                  )}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            {...register("description")}
            error={typeof formState.errors.description === "object"}
            helperText={formState.errors.description?.message?.toString()}
            variant="outlined"
            label={Vocabulary.description}
            fullWidth
            disabled={isProjectManager}
            multiline
            minRows={4}
            id={"description"}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </>
    );
  };

  /**
   *
   * @returns
   */
  const renderCurrentStep = () => {
    switch (activeStep) {
      case 0:
        return renderBasicInfoStep();
      case 1:
        return renderCustomFieldsStep();
      default:
        return null;
    }
  };

  return (
    <form id="projectForm" onSubmit={handleSubmit(handleSaveProject)}>
      <Grid container spacing={2}>
        {renderCurrentStep()}
      </Grid>
      <Stepper nonLinear activeStep={activeStep} className={styles.stepper}>
        {ProjectSteps?.map((step: any, index: number) => (
          <Step key={index}>
            <StepButton
              icon={step.icon}
              onClick={() => handleChangeStep(index)}
            >
              {step.name}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </form>
  );
}
