/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Slider,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { entityPath, foldersNames, urlEnum } from "../../Utils/UrlEnum";
import { DepartmentModel, TaskModel } from "../../Utils/Models";
import { Groups, KeyboardTab } from "@mui/icons-material";
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/task.module.css";
import genericStyles from "../../Styles/genericStyles.module.css";
import CircularProgressWithLabel from "../Widgets/ProgressWidget";
import TaskPeriodicity from "./TaskPeriodicity";
import { postData } from "../../Services/postData";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import PropertyContainer from "./PropertyContainer";
import { getData } from "../../Services/getData";
import {
  ComponentsNames,
  specialFinishConditions,
} from "../../Utils/Constants";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import SubtasksModal from "./SubtasksModal";

import { GlobalContext } from "../../Context/GlobalContext";
import FileExplorerMin from "../Widgets/FileExplorerMin";
import {
  ERole,
  ETaskPeriodicity,
  ETaskPriority,
  ETaskStatus,
  ETaskType,
} from "../../Utils/Enums";
import { DrawerStateContext } from "../../Context/DrawerStateContext";
import { CustomThemeContext } from "../../Context/CustomThemeContext";
import { getNumberOfLinesFromText } from "../../Utils/Utils";
import AssignedUsersOptions from "./AssignedUsersOptions";
import withRole from "../../Hooks/withRole";

type Props = {
  shouldRenderAllData?: boolean;
  advancedOptions?: boolean;
};

function Task(props: Props) {
  const drawerStateContext = useContext(DrawerStateContext);
  const [task, setTask] = useState<TaskModel>(new TaskModel());
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openPeriodicity, setOpenPeriodicity] = useState(false);
  const [autocompleteData, setAutocompleteData] = useState<any>();
  const [usersCopy, setUsersCopy] = useState<any>([]);
  const themeContext: any = useContext(CustomThemeContext);
  const [openModal, setOpenModal] = useState(false);
  const userId = localStorage.getItem("userId");
  const refreshDataContext = useContext(RefreshDataContext);
  const globalContext = useContext(GlobalContext);
  const [shouldFieldsBeDisabled, setShouldFieldsBeDisabled] = useState(false);
  const [
    anchorSpecialAssignedUsersOptions,
    setAnchorSpecialAssignedUsersOptions,
  ] = useState<HTMLButtonElement | null>(null);

  /**
   *
   */
  const handleCloseSpecialAssignedUsersOptions = () => {
    setAnchorSpecialAssignedUsersOptions(null);
  };

  /**
   * Get data for autocomplete
   */
  useEffect(() => {
    const promises = [
      getData(`${urlEnum.getUsersForFilter}/${userId}`),
      getData(`${urlEnum.getTaskPriorities}`),
      getData(`${urlEnum.getTaskStatusesForFilter}`),
      getData(`${urlEnum.getTaskPeriodicities}`),
      getData(`${urlEnum.getTaskTypes}`),
      getData(`${urlEnum.getProjectsForFilter}/${userId}`),
      getData(urlEnum.getDepartmentsForFilter),
    ];
    Promise.all(promises)
      .then((res) => {
        setAutocompleteData({
          ...autocompleteData,
          users: res[0]?.data,
          priorities: res[1]?.data,
          statuses: res[2]?.data,
          periodicities: res[3]?.data,
          types: res[4]?.data,
          projects: res[5]?.data,
          departments: res[6]?.data,
        });
        createNewTask(res[3]?.data, res[1]?.data, res[2]?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (
      globalContext.taskId === "" &&
      autocompleteData?.users &&
      autocompleteData?.users.length > 0
    ) {
      createNewTask(
        autocompleteData?.periodicities,
        autocompleteData?.priorities,
        autocompleteData?.statuses
      );
    }
  }, [drawerStateContext.open]);

  /**
   *
   */
  const createNewTask = (
    periodicities: any,
    priorities: any,
    statuses: any
  ) => {
    const defaultPeriodicity = periodicities?.filter(
      (p: any) => p.name === ETaskPeriodicity.PERIODICITY_UNIQUE
    );
    const defaultPriority = priorities?.filter(
      (p: any) => p.name === ETaskPriority.PRIORITY_MEDIUM
    );
    const defaultStatus = statuses?.filter(
      (t: any) => t.name === ETaskStatus.STATUS_NOT_STARTED
    );
    let defaultType = null;
    if (globalContext.projectSelected) {
      defaultType =
        autocompleteData?.types &&
        autocompleteData?.types?.filter(
          (t: any) => t.name === ETaskType.TASK_PROJECT
        );
    }

    setTask(
      new TaskModel(
        defaultPeriodicity?.length ? defaultPriority[0] : null,
        defaultPriority?.length ? defaultStatus[0] : null,
        defaultStatus?.length ? defaultPeriodicity[0] : null,
        defaultType?.length ? defaultType[0] : null,
        globalContext.projectSelected ? globalContext.projectSelected : null
      )
    );
  };

  /**
   * Set task
   */
  useEffect(() => {
    if (globalContext.taskId !== "") {
      getTaskById(globalContext.taskId);
    }
  }, [globalContext.taskId]);

  /**
   *
   * @param id
   */
  const getTaskById = (id: string) => {
    getData(`${urlEnum.getTasks}/${id}`).then((res) => {
      if (res) {
        setTask(res.data);
        drawerStateContext.setOpen(true);
      }
    });
  };

  /**
   * set disabled fields is user did not create this task and it's not admin
   */
  useEffect(() => {
    if (
      !props.shouldRenderAllData &&
      task.createdBy?.id !== undefined &&
      task.createdBy?.id !== userId &&
      task.assignedUsers.length > 0
    ) {
      setShouldFieldsBeDisabled(true);
    } else {
      setShouldFieldsBeDisabled(false);
    }
  }, [task.id]);

  /**
   *  Toggle drawer
   */
  const toggleDrawer = () => {
    drawerStateContext.setOpen(false);
    if (drawerStateContext.open) {
      globalContext.setTaskId("");
    }
  };

  /**
   *
   * @param option
   * @param departments
   */
  const handleAdvancedAssignedUsersOptions = (
    option: number,
    departments?: Array<DepartmentModel>
  ) => {
    switch (option) {
      case 1:
        setTask({
          ...task,
          assignedUsers: autocompleteData?.users,
        });
        break;
      case 2:
        //all department heads (in roles contains department head)
        setTask({
          ...task,
          assignedUsers: autocompleteData?.users.filter(
            (u: any) =>
              u.roles.find(
                (r: any) => r.name === ERole.ROLE_DEPARTMENT_HEAD
              ) !== undefined
          ),
        });

        break;
      case 3:
        // all users from departments property
        setTask({
          ...task,
          assignedUsers: autocompleteData?.users.filter((u: any) =>
            departments?.some((d: any) => d.id === u?.department?.id)
          ),
        });

        break;
      default:
        break;
    }
  };

  /**
   * Set connected user as assigned user if task type is specific
   */
  useEffect(() => {
    let connectedUser;
    switch (task?.type?.name) {
      case ETaskType.TASK_SPECIFIC:
        if (task.id === null) {
          connectedUser = autocompleteData?.users.filter(
            (u: any) => u.id === userId
          );
          setTask({
            ...task,
            assignedUsers: [connectedUser[0]],
            project: null,
          });
        }
        break;
      case ETaskType.TASK_PROJECT:
        if (task.project?.id) {
          setUsersCopy(autocompleteData?.users);
          const projectUsers = task.project?.projectUsers;
          //if list of users doesn't contain manager of project, add him
          if (
            projectUsers.find(
              (u: any) => u.id === task.project?.projectManager.id
            ) === undefined
          ) {
            projectUsers.push(task.project?.projectManager);
          }
          setAutocompleteData({
            ...autocompleteData,
            users: projectUsers,
          });
        }
        break;
      default:
        break;
    }

    if (usersCopy.length > 0) {
      setAutocompleteData({ ...autocompleteData, users: usersCopy });
      setUsersCopy([]);
    }
  }, [task?.type, task?.project]);

  /**
   *
   * @param event
   */
  const handleChange = (event: any) => {
    const newTask: any = Object.assign({}, task);
    newTask[event.target.name] = event.target.value;
    if (event.target.name === "assignedUsers") {
      //get distinct departments from assigned users
      const assignedUsersDepartments = event.target.value
        ?.map((u: any) => u.department)
        .filter(
          (value: any, index: any, self: any) =>
            self.findIndex((v: any) => v?.id === value?.id) === index
        );

      newTask.departments = assignedUsersDepartments;
    }
    setTask(newTask);
  };

  /**
   * Save or update task
   */
  const handleSaveTask = () => {
    postData(urlEnum.createTask, task).then((res: any) => {
      if (res) {
        toggleDrawer();
        refreshDataContext.setRefresh(true);
      }
    });
  };

  /**
   *  Check if task type is project
   * @returns
   */
  const shouldRenderProject = () => {
    if (task?.type?.translation === Vocabulary.project) {
      return true;
    }
    return false;
  };

  /**
   * Set start and end date
   * @param start
   * @param end
   */
  const handleChangeStartAndEnd = (start: any, end: any) => {
    setTask({ ...task, start: start, end: end });
  };
  /**
   *
   */
  const handleChangeModalState = () => {
    setOpenModal(!openModal);
  };

  /**
   *
   * @param event
   */
  const handleChangeSwitch = (event: any) => {
    const newTask: any = Object.assign({}, task);
    newTask[event.target.name] = event.target.checked;
    setTask(newTask);
  };

  /**
   *
   * @returns
   */
  const shouldRenderCreatedBy = () => {
    return (
      (task?.type?.name === ETaskType.TASK_PROJECT ||
        task?.type?.name === ETaskType.TASK_ALLOCATED) &&
      task?.createdBy?.id !== userId
    );
  };

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": styles.drawerContainer,
      }}
      className={styles.drawerContainer}
      anchor={"right"}
      open={drawerStateContext.open}
      onClose={(event: object, reason: string) => {
        if (reason !== "backdropClick") {
          toggleDrawer();
        }
      }}
    >
      <ValidatorForm className={styles.taskForm} onSubmit={handleSaveTask}>
        <Grid container spacing={1} className={styles.drawerHeader}>
          <Grid item xs={6}>
            {task.id && (
              <Button
                onClick={handleChangeModalState}
                variant="contained"
                color="secondary"
                sx={{
                  backgroundColor: themeContext.theme?.palette.secondary.dark,
                }}
                className={styles.subtaskButton}
              >
                {Vocabulary.subtasks}
              </Button>
            )}
          </Grid>
          <Grid item xs={1} container alignItems={"center"}>
            <CircularProgressWithLabel value={task?.progress} />
          </Grid>
          <Grid
            item
            xs={5}
            container
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Tooltip title={Vocabulary.close}>
              <KeyboardTab
                color="secondary"
                className={styles.closeIcon}
                fontSize={"medium"}
                onClick={toggleDrawer}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={styles.drawerContent}>
          <Grid item xs={3} className={genericStyles.gridContainer}>
            <Typography>{Vocabulary.fancyName}</Typography>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={8}>
            <TextValidator
              value={task?.text ? task?.text : ""}
              id="text"
              name="text"
              disabled={shouldFieldsBeDisabled}
              multiline
              rows={getNumberOfLinesFromText(task?.text)}
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              fullWidth
              required
              onChange={handleChange}
            />
          </Grid>
          {task.parent && (
            <PropertyContainer
              property={Vocabulary.primaryTask}
              propertyInput={
                <Button
                  variant="text"
                  color="dashboard"
                  fullWidth
                  onClick={() => {
                    globalContext.setTaskId(task.parent?.id ?? "");
                  }}
                >
                  {task.parent?.text}
                </Button>
              }
            />
          )}
          {shouldRenderCreatedBy() ? (
            <PropertyContainer
              property={Vocabulary.assignedBy}
              propertyInput={
                <Typography>{`${task.createdBy?.lastName || ""} ${
                  task.createdBy?.firstName || ""
                }`}</Typography>
              }
            />
          ) : null}
          <PropertyContainer
            property={Vocabulary.type}
            propertyInput={
              <Autocomplete
                id="type"
                disablePortal
                noOptionsText={Vocabulary.noResultsFound}
                disabled={shouldFieldsBeDisabled || task.parent !== null}
                className={styles.taskType}
                getOptionLabel={(option: any) =>
                  option.translation ? option.translation : ""
                }
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  value: any
                ) => {
                  handleChange({ target: { name: "type", value: value } });
                }}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                options={autocompleteData?.types ? autocompleteData.types : []}
                value={task.type ? task.type : null}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    required
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    label={
                      !task?.type || task?.type?.translation === ""
                        ? Vocabulary.chooseType
                        : null
                    }
                  />
                )}
              />
            }
          />
          {shouldRenderProject() ? (
            <PropertyContainer
              property={Vocabulary.project}
              propertyInput={
                <Autocomplete
                  id="project"
                  disablePortal
                  noOptionsText={Vocabulary.noResultsFound}
                  disabled={shouldFieldsBeDisabled || task.parent !== null}
                  getOptionLabel={(option: any) =>
                    option.name ? option.name : ""
                  }
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    value: any
                  ) => {
                    handleChange({
                      target: { name: "project", value: value },
                    });
                  }}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  fullWidth
                  options={
                    autocompleteData?.projects ? autocompleteData?.projects : []
                  }
                  value={task.project ? task.project : null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      required
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      label={
                        !task?.project || task?.project?.name === ""
                          ? Vocabulary.chooseProject
                          : null
                      }
                    />
                  )}
                />
              }
            />
          ) : null}

          <PropertyContainer
            property={Vocabulary.assignedUser}
            propertyIcon={
              props.advancedOptions &&
              task?.type?.name === ETaskType.TASK_ALLOCATED && (
                <>
                  <IconButton
                    onClick={(event: any) => {
                      setAnchorSpecialAssignedUsersOptions(event.currentTarget);
                    }}
                  >
                    <Groups />
                  </IconButton>
                  <AssignedUsersOptions
                    handleAdvancedAssignedUsersOptions={
                      handleAdvancedAssignedUsersOptions
                    }
                    shouldRenderAllData={props.shouldRenderAllData}
                    id={
                      anchorSpecialAssignedUsersOptions
                        ? "simple-popover"
                        : undefined
                    }
                    departments={autocompleteData?.departments}
                    anchorEl={anchorSpecialAssignedUsersOptions}
                    handleClose={handleCloseSpecialAssignedUsersOptions}
                    open={Boolean(anchorSpecialAssignedUsersOptions) || false}
                    users={autocompleteData?.users}
                  />
                </>
              )
            }
            propertyInput={
              <Autocomplete
                id="assignedUsers"
                sx={{ maxHeight: 150, overflowY: "auto", overflowX: "hidden" }}
                noOptionsText={Vocabulary.noResultsFound}
                disablePortal
                multiple
                disabled={shouldFieldsBeDisabled || task.parent !== null}
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  value: any
                ) => {
                  handleChange({
                    target: { name: "assignedUsers", value: value },
                  });
                }}
                getOptionLabel={(option) =>
                  `${option?.lastName ? option?.lastName : ""} ${
                    option?.firstName ? option?.firstName : ""
                  }`
                }
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                options={autocompleteData?.users ? autocompleteData?.users : []}
                value={task.assignedUsers ? task.assignedUsers : []}
                fullWidth
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    variant="standard"
                    required={shouldFieldsBeDisabled}
                    label={
                      task?.assignedUsers?.length > 0
                        ? ""
                        : Vocabulary.noUserAssigned
                    }
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
                renderTags={(value: any, getTagProps: any) =>
                  value?.map((option: any, index: number) => (
                    <Chip
                      avatar={
                        <Avatar
                          className={styles.userAvatar}
                          src={`${urlEnum.getFilesAndMedia}${foldersNames.usersProfiles}${option?.profilePicture}`}
                        />
                      }
                      variant="outlined"
                      label={`${option?.lastName ? option?.lastName : ""} ${
                        option?.firstName ? option.firstName : ""
                      }`}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            }
          />
          <PropertyContainer
            property={Vocabulary.priority}
            propertyInput={
              <Autocomplete
                id="priority"
                disablePortal
                noOptionsText={Vocabulary.noResultsFound}
                disabled={shouldFieldsBeDisabled || task.parent !== null}
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  value: any
                ) => {
                  handleChange({
                    target: { name: "priority", value: value },
                  });
                }}
                getOptionLabel={(option: any) => option.translation}
                options={
                  autocompleteData?.priorities
                    ? autocompleteData?.priorities
                    : []
                }
                value={task.priority ? task.priority : null}
                fullWidth
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    required
                    label={
                      !task?.priority || task?.priority?.translation === ""
                        ? Vocabulary.choosePriority
                        : null
                    }
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      startAdornment:
                        !task?.priority ||
                        task?.priority?.translation === "" ? null : (
                          <div
                            className={genericStyles.colorCircle}
                            style={{
                              backgroundColor: task?.priority?.color,
                            }}
                          />
                        ),
                    }}
                  />
                )}
              />
            }
          />
          <PropertyContainer
            property={Vocabulary.status}
            propertyInput={
              <Autocomplete
                id="status"
                noOptionsText={Vocabulary.noResultsFound}
                disablePortal
                getOptionLabel={(option: any) => option.translation}
                options={
                  autocompleteData?.statuses ? autocompleteData?.statuses : []
                }
                disabled={task.assignedUsers?.length === 0}
                value={task.status ?? null}
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  value: any
                ) => {
                  handleChange({ target: { name: "status", value: value } });
                }}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    required
                    error={task.assignedUsers?.length === 0}
                    helperText={
                      task.assignedUsers?.length === 0
                        ? Vocabulary.noAssignedUsers
                        : ""
                    }
                    label={
                      !task?.status || task?.status?.translation === ""
                        ? Vocabulary.chooseStatus
                        : null
                    }
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      startAdornment:
                        task?.status?.translation === "" ? null : (
                          <div
                            className={genericStyles.colorCircle}
                            style={{
                              backgroundColor: task?.status?.color,
                            }}
                          />
                        ),
                    }}
                  />
                )}
              />
            }
          />

          <PropertyContainer
            property={Vocabulary.budget}
            propertyInput={
              <TextField
                id="budget"
                name="budget"
                fullWidth
                multiline
                rows={getNumberOfLinesFromText(task?.budget)}
                value={task?.budget}
                onChange={handleChange}
              />
            }
          />

          <PropertyContainer
            property={Vocabulary.observations}
            propertyInput={
              <TextField
                id="remarks"
                name="remarks"
                multiline
                rows={getNumberOfLinesFromText(task?.remarks)}
                fullWidth
                value={task?.remarks}
                onChange={handleChange}
              />
            }
          />

          <PropertyContainer
            property={Vocabulary.equipments}
            propertyInput={
              <TextField
                fullWidth
                id="equipment"
                name="equipment"
                multiline
                rows={getNumberOfLinesFromText(task?.equipment)}
                value={task?.equipment}
                onChange={handleChange}
              />
            }
          />

          <PropertyContainer
            property={Vocabulary.periodicity}
            propertyInput={
              <Chip
                disabled={
                  task.isFlexible
                    ? false
                    : shouldFieldsBeDisabled || task.parent !== null
                }
                label={
                  !task?.periodicity || task?.periodicity?.translation === ""
                    ? Vocabulary.choosePeriodicity
                    : task?.periodicity?.translation
                }
                color={"secondary"}
                onClick={(event: any) => {
                  setAnchorEl(event.currentTarget);
                  setOpenPeriodicity(!openPeriodicity);
                }}
              />
            }
          />

          <Popover
            open={openPeriodicity}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={() => {
              setAnchorEl(null);
              setOpenPeriodicity(!openPeriodicity);
            }}
          >
            <TaskPeriodicity
              setOpenPeriodicity={setOpenPeriodicity}
              handleChange={handleChange}
              task={task}
              handleChangeStartAndEnd={handleChangeStartAndEnd}
              periodicities={autocompleteData?.periodicities}
            />
          </Popover>
          <PropertyContainer
            property={Vocabulary.flexibleTask}
            propertyInput={
              <FormControlLabel
                label={`(${Vocabulary.flexibleTaskObservations})`}
                labelPlacement="end"
                control={
                  <Switch
                    id="isFlexible"
                    name="isFlexible"
                    disabled={shouldFieldsBeDisabled || task.parent !== null}
                    onChange={handleChangeSwitch}
                    checked={task.isFlexible}
                    sx={{ color: themeContext.theme?.palette.primary.light }}
                  />
                }
              />
            }
          />
          <PropertyContainer
            property={Vocabulary.progress}
            propertyInput={
              <Slider
                max={100}
                min={0}
                defaultValue={0}
                onChange={(event: any, value: any) => {
                  handleChange({
                    target: { name: "progress", value: value / 100 },
                  });
                }}
                value={
                  task.progress !== null && task.progress !== undefined
                    ? parseFloat(task.progress) * 100
                    : 0
                }
              />
            }
          />
          <PropertyContainer
            property={Vocabulary.specialFinishConditions}
            propertyInput={
              <Select
                fullWidth
                name="finishCondition"
                id="finishCondition"
                disabled={shouldFieldsBeDisabled || task.parent !== null}
                value={task.finishCondition}
                onChange={handleChange}
              >
                {specialFinishConditions?.map((value: any, key: any) => {
                  return (
                    <MenuItem value={value.value} key={key}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            }
          />
          <Grid item xs={12} className={genericStyles.gridContainer}>
            <TextField
              multiline
              fullWidth
              id="description"
              name="description"
              onChange={handleChange}
              disabled={shouldFieldsBeDisabled}
              rows={4}
              value={task?.description}
              label={Vocabulary.description}
            />
          </Grid>
          <Grid item xs={12}>
            {task.id && (
              <FileExplorerMin
                shouldCRUDFile={true}
                name={ComponentsNames.FileExplorerMin}
                id={task.id}
                baseFolderPath={
                  task.id ? `${`${entityPath.tasks}/${task.id}`}` : undefined
                }
              />
            )}
          </Grid>
        </Grid>
        <Grid container className={styles.drawerFooter}>
          <Grid item xs={12} className={styles.saveButtonContainer}>
            <Button type="submit" variant="contained">
              {Vocabulary.save}
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
      <SubtasksModal
        shouldFieldsBeDisabled={shouldFieldsBeDisabled}
        statuses={autocompleteData?.statuses ? autocompleteData?.statuses : []}
        open={openModal}
        onClose={handleChangeModalState}
        parentTaskId={task.id}
        isParentFinished={task.status?.name === ETaskStatus.STATUS_FINISHED}
      />
    </Drawer>
  );
}

export default withRole(Task);
