import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import styles from "../Styles/genericSettings.module.css";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Vocabulary } from "../Utils/Vocabulary";
import genericStyles from "../Styles/genericStyles.module.css";
import { getData } from "../Services/getData";
import { urlEnum } from "../Utils/UrlEnum";
import { useEffect } from "react";
import { postData } from "../Services/postData";
import DropzoneComponent from "./DropzoneComponent";

export default function GenericSettings() {
  const genericSettingsValidationSchema = yup.object().shape({
    id: yup.string(),
    quoteOfTheDay: yup.string(),
    loginPageImage: yup.string(),
    loginPageImagePath: yup.string().nullable(),
  });
  const { register, handleSubmit, reset, getValues, control } = useForm({
    resolver: yupResolver(genericSettingsValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  useEffect(() => {
    getConfiguration();
  }, []);

  /**
   *
   */
  const getConfiguration = () => {
    getData(urlEnum.getConfiguration).then((res) => {
      if (res && res.data) {
        reset(res.data);
      }
    });
  };

  /**
   *
   */
  const handleSaveConfiguration = () => {
    const data: any = getValues();
    //create formData for the body of the request
    const formData = new FormData();
    formData.append("quoteOfTheDay", data.quoteOfTheDay);
    if (data.loginPageImage !== null && data.loginPageImage !== undefined)
      formData.append("file", data.loginPageImage);
    formData.append("id", data.id);
    postData(urlEnum.createConfiguration, formData).then((res) => {
      if (res && res.data) {
        reset(res.data);
      }
    });
  };

  return (
    <Paper className={styles.genericSettingsPaper}>
      <form onSubmit={handleSubmit(handleSaveConfiguration)}>
        <Grid container spacing={3}>
          <Grid className={styles.textContainer} item xl={1} xs={12}>
            <Typography>{Vocabulary.quoteOfTheDay}</Typography>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xl={10} xs={12}>
            <TextField
              {...register("quoteOfTheDay")}
              variant="outlined"
              fullWidth
              id={"quoteOfTheDay"}
              name={"quoteOfTheDay"}
            />
          </Grid>
          <Grid className={styles.textContainer} item xl={1} xs={12}>
            <Typography>{Vocabulary.loginPageImage}</Typography>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={12} sm={12} md={12} xl={10}>
            <Controller
              control={control}
              name="loginPageImage"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DropzoneComponent
                  onSave={(file: any) => {
                    onChange(file);
                  }}
                  paths={[
                    `${urlEnum.getFilesAndMedia}${getValues(
                      "loginPageImagePath"
                    )}`,
                  ]}
                />
              )}
            />
          </Grid>
        </Grid>
        <Typography sx={{ visibility: "hidden" }}>
          {getValues("loginPageImagePath")}
        </Typography>
        <Box className={genericStyles.styleEditorBox}>
          <Button type="submit" variant="contained">
            {Vocabulary.save}
          </Button>
        </Box>
      </form>
    </Paper>
  );
}
