import { createContext, useContext, useEffect, useState } from "react";
import SockJS from "sockjs-client";
import Stomp, { over } from "stompjs";
import { urlEnum } from "../Utils/UrlEnum";
const token = localStorage.getItem("access_token");

interface WebSocketContextType {
  stompClient: Stomp.Client | null;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined
);

/**
 *
 * @returns
 */
export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};

export const WebSocketProvider = (props: any) => {
  const [stompClient, setStompClient] = useState<Stomp.Client | null>(null);

  useEffect(() => {
    const socket = new SockJS(
      `${process.env.REACT_APP_URL}${urlEnum.webSocket}`
    );
    const client: any = over(socket);
    client.debug = null;
    client.connect(
      { Authorization: `Bearer ${token}` },
      () => {
        setStompClient(client);
      },
      (error: any) => {
        console.error("WebSocket connection error: ", error);
      }
    );
  }, []);

  return (
    <WebSocketContext.Provider value={{ stompClient }}>
      {props.children}
    </WebSocketContext.Provider>
  );
};
