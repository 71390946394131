import React, { useContext } from "react";
import {
  // Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";
import { Task } from "../../Utils/Models";
import styles from "./../../Styles/taskWidget.module.css";
import genericStyles from "../../Styles/genericStyles.module.css";
import { MoreVert } from "@mui/icons-material";
import { GlobalContext } from "../../Context/GlobalContext";
import { formatStringDate } from "../../Utils/Utils";
import Config from "../../Utils/Config";
// import { foldersNames, urlEnum } from "../../Utils/UrlEnum";
import Collaborators from "../Projects/Collaborators";

type Props = {
  task: any;
  handleDragStart: (e: React.DragEvent<HTMLDivElement>, task: Task) => void;
  handleDragEnterTask: (
    e: React.DragEvent<HTMLDivElement>,
    task: Task,
    currentColumnId: any
  ) => void;
  handleDragLeaveTask: () => void;
  handleDragOver: (e: React.DragEvent<HTMLDivElement>, taskId: number) => void;
  handleDragEnd: () => void;
  draggedTask: any;
  draggedOverTask: number | null;
  currentColumnId: any;
};

export default function TaskWidget(props: Props) {
  const {
    task,
    handleDragEnd,
    handleDragEnterTask,
    handleDragLeaveTask,
    handleDragOver,
    handleDragStart,
    draggedTask,
    draggedOverTask,
    currentColumnId,
  } = props;
  const globalTask = useContext(GlobalContext);

  /**
   *
   * @param id
   */
  const handleEditTask = (id: string) => {
    globalTask.setTaskId(id);
  };

  return (
    <Card
      key={task?.id}
      className={`${styles.kanbanTask} ${
        draggedTask?.id === task.id ? genericStyles.dragged : ""
      } ${draggedOverTask === task.id ? genericStyles.draggedOver : ""}`}
      draggable
      onDragStart={(e) => handleDragStart(e, task)}
      onDragEnter={(e) => handleDragEnterTask(e, task, currentColumnId)}
      onDragLeave={handleDragLeaveTask}
      onDragOver={(e) => handleDragOver(e, task.id)}
      onDragEnd={handleDragEnd}
    >
      <CardHeader
        className={styles.taskWidgetCardHeader}
        title={<Typography>{task?.text}</Typography>}
        avatar={
          <Chip
            label={task.priority?.translation}
            sx={{ backgroundColor: task.priority?.color }}
          />
        }
        action={
          <IconButton onClick={() => handleEditTask(task.id)}>
            <MoreVert />
          </IconButton>
        }
      />
      {task?.description ? (
        <CardContent>{task?.description}</CardContent>
      ) : null}
      <CardActions className={styles.taskWidgetCardHeader}>
        <Typography>{`${formatStringDate(
          task?.start,
          Config.momentEUDateFormat
        )} / ${formatStringDate(
          task?.end,
          Config.momentEUDateFormat
        )}`}</Typography>
        <div className={styles.taskAssignedUsers}>
          <Collaborators collaborators={task.assignedUsers} />
        </div>
      </CardActions>
    </Card>
  );
}
