import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import DropzoneComponent from "../DropzoneComponent";
import { useEffect, useRef, useState } from "react";
import { UserModel } from "../../Utils/Models";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Lock, Visibility } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { foldersNames, urlEnum } from "../../Utils/UrlEnum";
import styles from "../../Styles/usersPage.module.css";
import { ERole } from "../../Utils/Enums";

type Props = {
  filterData: any;
  shouldHandleSubmit: boolean;
  handleCreateOrUpdateUser: (user: any) => void;
  selectedUser?: UserModel | any;
};

export default function UserForm(props: Props) {
  const [user, setUser] = useState(props.selectedUser);
  const ref: any = useRef();
  const firstUpdate = useRef(true);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (props.selectedUser) {
      setUser(props.selectedUser);
    }
  }, [props.selectedUser.id]);

  /**
   *
   * @param event
   */
  const onChangeTextField = (event: any) => {
    const newUser: any = Object.assign({}, user);
    newUser[event.target.name] = event.target.value;
    setUser(newUser);
  };

  /**
   *
   * @param event
   */
  const handleChangeSwitch = (event: any) => {
    const newUser: any = Object.assign({}, user);
    newUser[event.target.name] = event.target.checked;
    setUser(newUser);
  };

  /**
   *
   * @param file
   */
  const onChangeFile = (file: any) => {
    const newUser: any = Object.assign({}, user);
    newUser.file = file;
    setUser(newUser);
  };

  /**
   *
   */
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!firstUpdate.current) ref.current.submit();
  }, [props.shouldHandleSubmit]);

  /**
   *
   */
  const handleSubmit = () => {
    props.handleCreateOrUpdateUser(user);
  };

  /**
   *
   */
  const generatePassword = () => {
    const newUser: any = Object.assign({}, user);
    const pass = uuidv4();
    newUser.password = pass.substring(0, 10);
    setUser(newUser);
  };

  return (
    <ValidatorForm ref={ref} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <TextValidator
            id="lastName"
            name="lastName"
            label={Vocabulary.lastName}
            fullWidth
            validators={["required"]}
            errorMessages={[Vocabulary.requiredField]}
            variant="outlined"
            value={user?.lastName ? user.lastName : ""}
            onChange={onChangeTextField}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextValidator
            name="firstName"
            id="firstName"
            label={Vocabulary.firstName}
            fullWidth
            variant="outlined"
            validators={["required"]}
            errorMessages={[Vocabulary.requiredField]}
            value={user?.firstName ? user.firstName : ""}
            onChange={onChangeTextField}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextValidator
            variant="outlined"
            name="email"
            id="email"
            label={Vocabulary.email}
            fullWidth
            validators={["required", "isEmail"]}
            errorMessages={[
              Vocabulary.requiredField,
              Vocabulary.emailValidation,
            ]}
            value={user?.email ? user.email : ""}
            onChange={onChangeTextField}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextValidator
            variant="outlined"
            name="position"
            id="position"
            label={Vocabulary.position}
            fullWidth
            validators={["required"]}
            errorMessages={[Vocabulary.requiredField]}
            value={user?.position ? user.position : ""}
            onChange={onChangeTextField}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            variant="outlined"
            name="departmentName"
            select
            id="departmentName"
            label={Vocabulary.department}
            fullWidth
            value={user?.departmentName ? user.departmentName : ""}
            onChange={onChangeTextField}
          >
            {props.filterData.departments?.map((value: any, key: any) => {
              return (
                <MenuItem value={value.name} key={key}>
                  {value.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextValidator
            variant="outlined"
            select
            label={Vocabulary.role}
            fullWidth
            SelectProps={{
              multiple: true,
            }}
            validators={["required"]}
            errorMessages={[Vocabulary.requiredField]}
            id={"rolesArray"}
            name={"rolesArray"}
            value={user?.rolesArray ? user.rolesArray : []}
            onChange={onChangeTextField}
          >
            {props.filterData.roles?.map((value: any, key: any) => {
              return (
                <MenuItem value={value.name} key={value.id}>
                  {value.translation}
                </MenuItem>
              );
            })}
          </TextValidator>
        </Grid>
        {user.rolesArray.indexOf(ERole.ROLE_DEPARTMENT_HEAD) > -1 ? (
          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              variant="outlined"
              select
              label={Vocabulary.subordinateDepartments}
              fullWidth
              SelectProps={{
                multiple: true,
              }}
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              id={"subordinateDepartmentsArray"}
              name={"subordinateDepartmentsArray"}
              value={
                user.subordinateDepartmentsArray
                  ? user.subordinateDepartmentsArray
                  : []
              }
              onChange={onChangeTextField}
            >
              {props.filterData.departments?.map((value: any, key: any) => {
                return (
                  <MenuItem value={value.name} key={key}>
                    {value.name}
                  </MenuItem>
                );
              })}
            </TextValidator>
          </Grid>
        ) : null}

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            variant="outlined"
            id="password"
            name="password"
            value={user.password ? user.password : ""}
            type={!showPassword ? "password" : "text"}
            label={Vocabulary.password}
            fullWidth
            onChange={(e) => onChangeTextField(e)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className={styles.userFormVisibilityIconButton}
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    <Visibility />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          className={styles.userFormGeneratePasswordButton}
        >
          <Button
            variant="contained"
            endIcon={<Lock />}
            onClick={generatePassword}
          >
            {Vocabulary.generate}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <FormControlLabel
            label={`(${Vocabulary.accountStatus})`}
            labelPlacement="end"
            control={
              <Switch
                id="status"
                name="status"
                onChange={handleChangeSwitch}
                checked={user.status}
                color="secondary"
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <DropzoneComponent
            onSave={onChangeFile}
            paths={[
              `${urlEnum.getFilesAndMedia}${foldersNames.usersProfiles}${user.profilePicture}`,
            ]}
          />
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}
