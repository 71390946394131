import { Vocabulary } from "../Utils/Vocabulary";
import { Button, Typography } from "@mui/material";
import genericStyles from "../Styles/genericStyles.module.css";
import GenericModal from "./GenericModal";
type Props = {
  openModal: boolean;
  handleClose: () => void;
  handleApproveAction: () => void;
  message?: string;
};

export default function ApprovalModal(props: Props) {
  const { openModal, handleClose, message, handleApproveAction } = props;
  return (
    <GenericModal
      title={Vocabulary.verifyAction}
      children={
        <>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            {message ? message : Vocabulary.verificationDelete}
          </Typography>
        </>
      }
      actions={
        <>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
            }}
            className={genericStyles.cancelButton}
          >
            {Vocabulary.cancel}
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              handleApproveAction();
              handleClose();
            }}
          >
            {Vocabulary.continue}
          </Button>
        </>
      }
      open={openModal}
      onClose={() => {
        handleClose();
      }}
    />
  );
}
