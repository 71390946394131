import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { MeetingModel } from "../../Utils/Models";
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/dashboard.module.css";
import { formatStringDate } from "../../Utils/Utils";
import Config from "../../Utils/Config";
import { Fragment, useContext } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import { Person2 } from "@mui/icons-material";
import moment from "moment";
import withRole from "../../Hooks/withRole";

type Props = {
  meetings: MeetingModel[];
  shouldAddNewMeetings?: boolean;
};

function MeetingsWidget(props: Props) {
  const { meetings } = props;
  const globalContext = useContext(GlobalContext);

  /**
   *
   * @param meeting
   */
  const handleSelectMeeting = (meeting: MeetingModel) => {
    if (meeting?.id) globalContext.setMeetingSelectedId(meeting.id);
  };

  return (
    <Card className={styles.meetingsContainer}>
      <CardHeader
        title={
          <Typography sx={{ fontWeight: "bold" }}>
            {`${Vocabulary.todaysMeetings} (${meetings?.length})`}
          </Typography>
        }
        subheader={formatStringDate(moment(), Config.momentEUDateFormat)}
      />
      <CardContent className={styles.meetingsCardContent}>
        <List dense={true}>
          {meetings?.length > 0 ? (
            meetings?.map((meeting: MeetingModel, index: number) => (
              <ListItem
                key={index}
                onClick={() => {
                  handleSelectMeeting(meeting);
                }}
              >
                <ListItemText
                  primary={meeting.text}
                  secondary={`${formatStringDate(
                    meeting.start,
                    Config.momentEUTimeFormatForCalendar
                  )} - ${formatStringDate(
                    meeting.end,
                    Config.momentEUTimeFormatForCalendar
                  )}`}
                />
                <ListItemText
                  primary={
                    <Chip
                      sx={{ backgroundColor: meeting.status.color }}
                      label={meeting.status.translation}
                    />
                  }
                />

                <Fragment>
                  <Typography>
                    {meeting.responsible !== null
                      ? meeting.assignedUsers?.length
                        ? meeting.assignedUsers?.length + 1
                        : 0
                      : meeting.assignedUsers?.length}
                  </Typography>
                  <Person2 />
                </Fragment>
              </ListItem>
            ))
          ) : (
            <>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      sx={{ paddingBottom: 1, paddingTop: 0 }}
                      textAlign={"center"}
                    >
                      {Vocabulary.noFutureMeetings}
                    </Typography>
                  }
                />
              </ListItem>
              {props.shouldAddNewMeetings && (
                <Button
                  onClick={() => {
                    globalContext.setMeetingSelectedId("");
                  }}
                  color="blueGrey"
                  variant="contained"
                  style={{ borderRadius: 15 }}
                >
                  {Vocabulary.planNewMeeting}
                </Button>
              )}
            </>
          )}
        </List>
      </CardContent>
    </Card>
  );
}

export default withRole(MeetingsWidget);
