import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ClientsTableColumns } from "../Utils/Constants";
import {
  customPaths,
  entityPath,
  localUrlEnum,
  urlEnum,
} from "../Utils/UrlEnum";
import { Vocabulary } from "../Utils/Vocabulary";
import genericStyles from "../Styles/genericStyles.module.css";
import { useContext, useEffect, useState } from "react";
import { getData } from "../Services/getData";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  roRO,
} from "@mui/x-data-grid";
import styles from "../Styles/documentGenerator.module.css";
import NoDataFound from "../Components/NoDataFound";
import UploadFileForm from "../Components/Widgets/UploadFileForm";
import { RefreshDataContext } from "../Context/RefreshDataContext";
import { useLocation } from "react-router-dom";
import { DocumentGeneratorState } from "../Utils/Models";
import { postData } from "../Services/postData";
import { downloadFile } from "../Utils/Utils";

export default function DocumentGenerator() {
  const [state, setState] = useState<DocumentGeneratorState>(
    new DocumentGeneratorState()
  );
  const refreshContext = useContext(RefreshDataContext);
  const location = useLocation();

  /**
   *
   * @param event
   * @returns
   */
  const handleChange = (event: any) => {
    const existingFiles = state.contractTemplatesFiles.filter(
      (st: any) => st.fileContent !== null
    );
    const { value } = event.target;
    if (value[value?.length - 1] === "all") {
      setState({
        ...state,
        selectedContractTemplateFiles:
          state.selectedContractTemplateFiles?.length === existingFiles?.length
            ? []
            : existingFiles,
      });
      return;
    }
    setState({
      ...state,
      selectedContractTemplateFiles: value,
    });
  };

  /**
   *
   */
  const getAllData = () => {
    getData(urlEnum.getClients).then((response: any) => {
      if (response && response?.data)
        setState({
          ...state,
          clients: response.data.clients,
          contractTemplatesFiles: response.data.contractTemplatesFiles,
          clientsDataFiles: response.data.clientsDataFiles,
        });
    });
  };
  /**
   *
   */
  useEffect(() => {
    getAllData();
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (
      refreshContext.refresh &&
      location.pathname.includes(localUrlEnum.documentGenerator)
    ) {
      getAllData();
      refreshContext.setRefresh(false);
    }
  }, [refreshContext.refresh]);

  /**
   *
   * @param event
   */
  const generateDocuments = (event: any) => {
    event.preventDefault();
    const contractGenerationDTO = {
      selectedTemplates: state.selectedContractTemplateFiles,
      selectedClients: state.selectedClients,
    };
    postData(urlEnum.generateContracts, contractGenerationDTO).then(
      (response: any) => {
        if (response) {
          downloadFile({
            path: customPaths.downloadClientsContracts,
            name: "clients.zip",
          });
        }
      }
    );
  };

  /**
   *
   * @returns
   */
  const renderCustomToolBar = () => {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </div>
        {state.selectedClients?.length > 0 && (
          <FormControl style={{ width: 300 }}>
            {state.selectedContractTemplateFiles?.length === 0 && (
              <InputLabel id="contractTemplates">
                {Vocabulary.chooseDocumentsToComplete}
              </InputLabel>
            )}
            <Select
              required
              variant="standard"
              labelId="contractTemplates"
              multiple
              value={state.selectedContractTemplateFiles}
              onChange={handleChange}
              renderValue={(selected) =>
                selected?.map((st: any) => st.name).join(", ")
              }
            >
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={
                      state.contractTemplatesFiles?.length > 0 &&
                      state.selectedContractTemplateFiles?.length ===
                        state.contractTemplatesFiles?.length
                    }
                    indeterminate={
                      state.selectedContractTemplateFiles?.length > 0 &&
                      state.selectedContractTemplateFiles?.length <
                        state.contractTemplatesFiles?.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={Vocabulary.selectAll} />
              </MenuItem>
              {state.contractTemplatesFiles?.map((option: any) => {
                if (option.fileContent)
                  return (
                    <MenuItem key={option.id} value={option}>
                      <ListItemIcon>
                        <Checkbox
                          checked={
                            state.selectedContractTemplateFiles
                              ?.map((st: any) => st.id)
                              .indexOf(option.id) > -1
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  );
                return null;
              })}
            </Select>
          </FormControl>
        )}
      </GridToolbarContainer>
    );
  };

  /**
   *
   * @param ids
   */
  const handleChangeRowSelection = (ids: any) => {
    //get clients based on ids
    const details = ids.map((id: any) => {
      return state.clients?.find((client: any) => client.id === id);
    });
    if (ids?.length === 0) {
      setState({
        ...state,
        selectedContractTemplateFiles: [],
        selectedClients: details,
      });
    } else {
      setState({ ...state, selectedClients: details });
    }
  };

  return (
    <form className={genericStyles.mainContainer} onSubmit={generateDocuments}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={10} lg={11}>
          <Typography variant="h5">{Vocabulary.documentsGenerator}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={1}>
          <Button
            type="submit"
            variant="contained"
            className={genericStyles.addButton}
          >
            {Vocabulary.generate}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Typography variant="h6">
            {`${Vocabulary.clients} (${state.clients?.length || 0})`}{" "}
          </Typography>
          <DataGrid
            style={{ width: "100%", minHeight: 500 }}
            sx={{
              width: "100%",
              "& .MuiDataGrid-toolbarContainer": {
                backgroundColor: "#f6f6f6",
                fontSize: 16,
                fontWeight: "bold",
                borderTopRightRadius: 15,
                borderTopLeftRadius: 15,
                padding: 2,
                "& .MuiButton-root": {
                  color: "rgba(0, 0, 0, 0.54)",
                },
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f9f9f9",
                fontSize: 16,
                fontWeight: "bold",
              },
            }}
            slots={{
              toolbar: renderCustomToolBar,
              noRowsOverlay: NoDataFound,
            }}
            onRowSelectionModelChange={(ids: any) => {
              handleChangeRowSelection(ids);
            }}
            editMode="row"
            disableRowSelectionOnClick
            checkboxSelection
            className={styles.dataGrid}
            columns={ClientsTableColumns}
            rows={state.clients}
            hideFooterPagination={false}
            localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={12}>
              <Typography variant="h6">{Vocabulary.templates}</Typography>
              {state.contractTemplatesFiles?.map(
                (template: any, index: number) => {
                  return (
                    <UploadFileForm
                      id={`template${template.id}`}
                      key={`template${template.id}`}
                      uploadPath={entityPath.templates}
                      fileData={template}
                    />
                  );
                }
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Typography variant="h6">{Vocabulary.clientsData}</Typography>
              {state.clientsDataFiles?.map((file: any, index: number) => {
                return (
                  <UploadFileForm
                    id={`clientFile${file.id}`}
                    key={`clientFile${file.id}`}
                    uploadPath={entityPath.clients}
                    fileData={file}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
