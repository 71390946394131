import { UserModel } from "../Utils/Models";
import genericStyles from "../Styles/genericStyles.module.css";
import { foldersNames, urlEnum } from "../Utils/UrlEnum";
import { Avatar, Typography } from "@mui/material";

type Props = {
  users: UserModel[];
};

export default function ListOfUsersComponent(props: Props) {
  const { users } = props;
  return (
    <>
      {users?.map((user: any, index: number) => {
        return (
          <div key={index} className={genericStyles.listOfUsers}>
            <Avatar
              className={`${genericStyles.listOfUsersAvatar} ${genericStyles.assignedUsersAvatar}`}
              src={`${urlEnum.getFilesAndMedia}${foldersNames.usersProfiles}${user?.profilePicture}`}
            />
            <Typography
              variant="body2"
              className={genericStyles.listOfUsersName}
            >{`${user.lastName ?? ""} ${user.firstName ?? ""}`}</Typography>
          </div>
        );
      })}
    </>
  );
}
