/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import DynamicTabs from "../Components/DynamicTabs";
import { Vocabulary } from "../Utils/Vocabulary";
import genericStyles from "../Styles/genericStyles.module.css";
import CustomTabPanel from "../Components/CustomTabPanel";
import MonthlyCalendarComponent from "../Components/Widgets/MonthlyCalendarComponent";
import WeeklyCalendar from "../Components/Widgets/WeeklyCalendar";
import { getData } from "../Services/getData";
import { urlEnum } from "../Utils/UrlEnum";
import { CalendarFiltersAutocompleteModel } from "../Utils/Models";
import { useSearchParams } from "react-router-dom";
import DailyCalendarComponent from "../Components/Widgets/DailyCalendarComponent";
import { ComponentsNames } from "../Utils/Constants";
import { isTablet } from "../Utils/Utils";
import MarelviCalendar from "../Components/Widgets/MarelviCalendar";

export default function Calendar() {
  const [tabValue, setTabValue] = useState(0);
  const currentUserId = localStorage.getItem("userId");
  const [searchParams, setSearchParams] = useSearchParams();
  const tabsOptions = isTablet()
    ? [Vocabulary.dailyCalendar]
    : [
        Vocabulary.monthlyCalendar,
        Vocabulary.weeklyCalendar,
        Vocabulary.dailyCalendar,
        Vocabulary.marelviMeetingsCalendar,
      ];
  const [calendarFilters, setCalendarFilters] =
    useState<CalendarFiltersAutocompleteModel>(
      new CalendarFiltersAutocompleteModel()
    );

  /**
   *
   * @param name
   * @param value
   */
  const removeParamFromQueryString = (name: any) => {
    name?.map((param: any) => {
      searchParams.delete(param);
    });
    setSearchParams(searchParams);
  };

  /**
   *
   */
  useEffect(() => {
    const tab = searchParams.get("tab");
    //delete other filters

    if (tab) {
      setTabValue(parseInt(tab));
      removeParamFromQueryString(["difference", "type", "user"]);
    }
    getData(`${urlEnum.getUsersForFilter}/${currentUserId}`).then(
      (res: any) => {
        setCalendarFilters({
          users: res.data,
        });
      }
    );
  }, [searchParams.get("tab")]);

  return (
    <>
      <div className={genericStyles.header}>
        <DynamicTabs tabValue={tabValue} labels={tabsOptions} />
      </div>

      {isTablet() ? (
        <CustomTabPanel value={tabValue} index={0}>
          <DailyCalendarComponent
            name={ComponentsNames.DailyCalendarComponent}
            calendarFilters={calendarFilters}
          />
        </CustomTabPanel>
      ) : (
        <>
          <CustomTabPanel value={tabValue} index={0}>
            <MonthlyCalendarComponent calendarFilters={calendarFilters} />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <WeeklyCalendar calendarFilters={calendarFilters} />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            <DailyCalendarComponent
              name={ComponentsNames.DailyCalendarComponent}
              calendarFilters={calendarFilters}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={3}>
            <MarelviCalendar />
          </CustomTabPanel>
        </>
      )}
    </>
  );
}
