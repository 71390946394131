import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import genericStyles from "../Styles/genericStyles.module.css";

type Props = {
  open: boolean;
  onClose: () => void;
  children: any;
  title: string;
  actions?: any;
  style?: any;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
};

export default function GenericModal(props: Props) {
  const { open, children, onClose, title, actions, maxWidth } = props;
  return (
    <Dialog
      open={open}
      style={props.style}
      maxWidth={maxWidth ? maxWidth : "sm"}
    >
      <DialogTitle
        className={genericStyles.genericModalTitleContainer}
        sx={{
          backgroundColor: "primary.main",
          color: "textColorSecondary.main",
        }}
      >
        <p>{title}</p>
        <p>
          <Close
            onClick={onClose}
            className={genericStyles.genericModalCloseIcon}
          />
        </p>
      </DialogTitle>
      <DialogContent className={genericStyles.genericModalDialogContent}>
        {children}
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}
