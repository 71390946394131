export const Vocabulary = {
  /** COMMON TEXT */
  login: "Conectare",
  loginValidationError: "Parola sau adresa de e-mail greșite!",
  requiredField: "Acest câmp este obliugatoriu.",
  emailValidation: "Introduceți  o adresă valida.",
  pageNotFound: "Pagina nu a fost găsită!",
  fileRequired: "Trebuie să adăugați cel puțin un fișier!",
  search: "Căutare",
  siteName: "MARELVI",
  logout: "Deconectare",
  myAccount: "Contul meu",
  address: "Adresă",
  yes: "Da",
  no: "Nu",
  noFileAttached: "Nu a fost atașat nici un fișier!",
  templates: "Șabloane",
  users: "Utilizatori",
  title: "Titlu",
  requestInfo: "Informații cerere",
  receivers: "Destinatari",
  user: "Utilizator",
  persons: "Persoane",
  public: "Public",
  open: "Deschis",
  generateDocs: "Generează",
  roleLegendUser: "personale",
  roleLegendAdmin: "companie",
  administrator: "Administrator",
  roleLegendDepartmentHead: "departament",
  tasksPerMonth: "Statistică lunară a task-urilor finalizate/totale",
  unassignedTasks: "Task-uri nealocate",
  modifiedTasks: "Task-uri modificate",
  unassigned: "Nealocate",
  assignedBy: "Alocate de",
  emptyFields: "Golește câmpurile",
  notifications: "Notificări",
  noNotifications: "Nu există notificări!",
  seeMore: "Vezi mai mult",
  participants: "Participanți",
  projectUsers: "Utilizatori proiect",
  meetingGuests: "Invitați",
  departments: "Departamente",
  noAttachments: "Nu există atașamente",
  lastName: "Nume",
  firstName: "Prenume",
  kanban: "Kanban",
  password: "Parolă",
  projects: "Proiecte",
  addAttachment: "Adaugă atașament",
  monthlyCalendar: "Calendar lunar",
  weeklyCalendar: "Calendar săptămânal",
  dailyCalendar: "Calendar zilnic",
  marelviMeetingsCalendar: "Calendar MARELVI",
  budget: "Buget",
  duplicateTask: "Duplică task",
  checkAllUsers: "Selectează toți utilizatorii",
  chooseAllDepartmentHead: "Selectează toți șefii de departament",
  progress: "Progres",
  advancedOptions: "Opțiuni avansate: ",
  observations: "Observații",
  observationsWithoutDiacritics: "Observatii",
  crtNo: "Nr. crt.",
  noAssignedUsers: "Dezactivat pană la alocarea unui responsabil.",
  email: "Email",
  roles: "Roluri",
  role: "Rol",
  department: "Departament",
  options: "Opțiuni",
  edit: "Editează",
  view: "Vizualizează",
  equipments: "Echipamente",
  staff: "Personal",
  week: "Săptămână",
  changeTaskStatus: "Schimbare status task",
  editStatusProperties: "Editează proprietățile statusului",
  addNewStatus: "Adaugă status nou",
  delete: "Șterge",
  description: "Descriere",
  noResultsFound: "Nu a fost găsit nici un rezultat...",
  nextPage: "Pagina următoare",
  previousPage: "Pagina anterioară",
  rowsPerPage: "Rânduri pe pagină",
  of: "din",
  downloadCSV: "Descarcă CSV",
  print: "Printează",
  downloadDocument: "Descarcă document",
  rowsDeleted: "rânduri șterse",
  rowsSelected: "rânduri selectate",
  selectAll: "Selectează tot",
  chooseFile: "Alege fișier",
  usersList: "Listă utilizatori",
  departmentsList: "Listă departamente",
  tasksList: "Listă task-uri",
  projectsList: "Listă proiecte",
  reportsList: "Listă rapoarte",
  representative: "Reprezentant",
  meetingsList: "Listă întâlniri",
  deleteFilters: "Șterge filtre",
  tasks: "Task-uri",
  reports: "Rapoarte",
  add: "Adaugă",
  import: "Import",
  project: "Proiect",
  otherUsers: "Alți utilizatori",
  addNewProject: "Adaugă proiect nou",
  editProject: "Editează proiect",
  media: "Media",
  name: "Nume",
  link: "Link",
  objective: "Obiectiv",
  resources: "Resurse",
  evaluation: "Evaluare",
  aproxDuration: "Durata aproximativa",
  location: "Locație",
  meetings: "Întâlniri",
  addNewMeeting: "Adaugă întâlnire nouă",
  editMeeting: "Editează întâlnire",
  assignedUser: "Responsabil",
  departmentHead: "Șef departament",
  projectManager: "Manager proiect",
  status: "Status",
  color: "Culoare",
  writeSomething: "Scrie ceva...",
  taskStatusesList: "Listă statusuri task",
  addNewUser: "Adaugă utilizator nou",
  editUser: "Editează utilizator",
  addNewDepartment: "Adauga departament nou",
  save: "Salvare",
  send: "Trimite",
  currentUser: "Utilizatorul curent",
  filters: "Filtre",
  cancel: "Anulare",
  addFiles: "Adaugă fișiere",
  file: "Fișier",
  modified: "Modificate",
  modifiedBy: "Modificat de",
  modifiedAt: "Modificat la",
  finish: "Finalizare",
  generate: "Generează",
  finished: "Finalizat",
  unfinished: "Nefinalizat",
  userName: "Nume utilizator",
  taskName: "Nume task",
  yourTasks: "Task-urile tale",
  actualEvent: "Eveniment actual",
  futureEvent: "Eveniment viitor",
  tasksOnProjects: "Task-uri pe proiecte",
  additionalTasks: "Task-uri adiționale",
  supervisedTasks: "Task-uri supravegheate",
  none: "Nici una",
  active: "Active",
  archived: "Arhivat",
  modifiedPeriodicity: "Periodicitate modificată",
  responsibleChanged: "Responsabil modificat",
  messages: "Mesagerie",
  conversations: "Conversații",
  requests: "Cereri",
  newRequest: "Cerere nouă",
  baseInfo: "Informații generale",
  dynamicInfo: "Informații dinamice",
  attachments: "Atașamente",
  otherInfos: "Alte informații",
  fancyName: "Denumire",
  startDate: "Data start",
  accountInfo: "Informații cont",
  close: "Închide",
  resetPassword: "Resetare parola",
  endDate: "Data final",
  configurations: "Configurări",
  subordinateDepartments: "Departamente subordonate",
  position: "Funcție",
  finishedTasks: "Task-uri finalizate",
  dragAndDrop:
    "Trageți și plasați orice fișier aici sau răsfoiți de pe dispozitiv",
  error: "Eroare!",
  notFound: "Nu a fost găsit.",
  dashboard: "Dashboard",
  newTask: "Task nou",
  task: "Task",
  collaborators: "Colaboratori",
  futureMeetings: "Întâlniri viitoare",
  noFutureMeetings: "Nu există întâlniri viitoare!",
  todaysMeetings: "Întâlniri de azi",
  noMessagesYet: "Nu există mesaje încă!",
  noConversationsAvailable: "Nu există conversații disponibile!",
  profilePicture: "Poza de profil",
  addCustomField: "Adaugă câmp personalizat",
  addNewConversation: "Adaugă conversație nouă",
  newConversation: "Conversație nouă",
  customField: "Câmp personalizat",
  value: "Valoare",
  priority: "Prioritate",
  start: "Start",
  state: "Stare",
  stop: "Stop",
  periodicity: "Periodicitate",
  type: "Tip",
  highPriority: "Înaltă",
  mediumPriority: "Medie",
  loadMoreMessages: "Încarcă mai multe mesaje",
  lowPriority: "Scăzută",
  chooseUser: "Alege utilizator",
  chooseProject: "Alege proiect",
  chooseDepartment: "Alege departament",
  chooseDepartments: "Alege departamente",
  chooseMonth: "Alege lună",
  chooseStatus: "Alege status",
  chooseDocumentsToComplete: "Alege documentele de completat",
  isNecessaryToChooseDocsAndClients:
    "Este necesar să alegeți documentele și clienții pentru a genera contractele!",
  taskStatuses: "Statusuri task",
  generics: "Generice",
  delay: "Întârziere",
  pageInProgress: "Pagina este în construcție!",
  sort: "Sortare",
  styles: "Stiluri CSS",
  taskRequests: "Cereri task",
  results: "Rezultate",
  createdBy: "Creat de: ",
  solvedBy: "Rezolvat de",
  chooseProjectManager: "Alege manager proiect",
  flexibleTask: "Task flexibil",
  noUserFound: "Nu a fost găsit nici un utilizator!",
  flexible: "Flexibil",
  approve: "Aprobă",
  reject: "Respinge",
  personalRequests: "Cereri personale",
  requestsToResolve: "Cereri de rezolvat",
  allRequests: "Toate cererile",
  createNewRequests: "Crează cerere nouă",
  request: "Cerere",
  days: "zile",
  loginPageImage: "Imaginea din pagina de conectare",
  quoteOfTheDay: "Citatul zilei",
  addSubtask: "Adaugă subtask",
  subtask: "Subtask",
  subtasks: "Subtask-uri",
  accountStatus: "Status cont",
  meetingFinished: "Întâlnire finalizată",
  flexibleTaskObservations:
    "Permite posibilitatea de modificare a periodicitații",
  specialFinishConditions: "Condiție specială de finalizare",
  taskChengingStatusVerification:
    "Ești sigur că dorești să schimbi statusul task-ului?",
  choosePriority: "Alege prioritate",
  noUserAssigned: "Nu există utilizator asignat",
  choosePeriodicity: "Alege periodicitate",
  chooseType: "Alege tip",
  primaryTask: "Task principal",
  calendar: "Calendar",
  list: "Listă",
  reset: "Resetează",
  repeatsOn: "Repetă",
  specific: "Specific",
  nextMonth: "Luna următoare",
  deadline: "Termen limită",
  previousMonth: "Luna anterioară",
  nextWeek: "Săptămâna următoare",
  skipWeekend: "Sari peste weekend",
  previousWeek: "Săptămâna anterioară",
  verifyAction: "Verificare acțiune",
  explanations: "Explicații",
  finishedMeeting: "Finalizată",
  deallocateTasks: "Dealocă task-uri",
  planNewMeeting: "Planifică întâlnire nouă",
  continue: "Continuă",
  saturday: "Sâmbătă",
  sunday: "Duminică",
  monday: "Luni",
  tuesday: "Marți",
  wednesday: "Miercuri",
  thursday: "Joi",
  friday: "Vineri",
  january: "Ianuarie",
  february: "Februarie",
  march: "Martie",
  april: "Aprilie",
  may: "Mai",
  june: "Iunie",
  july: "Iulie",
  august: "August",
  september: "Septembrie",
  october: "Octombrie",
  november: "Noiembrie",
  december: "Decembrie",
  accountSettings: "Setări cont",
  create: "Crează",
  upload: "Încarcă",
  loading: "Se încarcă",
  clientsData: "Detalii clienți",
  clients: "Clienți",
  partners: "Parteneri",
  partnerName: "Denumire partener",
  summary: "Minuta",
  select: "Selectați",
  back: "Înapoi",
  home: "Acasă",
  pleaseLoadXLSXdocs: "Vă rugăm să încărcați documentele XLSX!",
  new: "Nou",
  agenda: "Ordinea de zi",
  members: "Membri",
  allowBrowserNotifications: "Permiteți notificările browserului?",
  yourTeam: "Echipa ta",
  finishedTasksSummary: "din care finalizate:",
  lateTasks: "Task-uri întârziate",
  saveAndClose: "Salvează și închide",
  seeTasks: "Vezi task-uri",
  allow: "Permite",
  hello: "Salutare",
  asc: "Crescător",
  documentsGenerator: "Generator documente",
  desc: "Descrescător",
  todaysStatus: "statusul tau pentru ziua de astăzi",
  messageOfTheDay: "Mesajul zilei",
  block: "Blochează",
  addNewSubtask: "Adaugă subtask nou",
  editSubtask: "Editează subtask",
  printPDF: "Printează PDF",

  denyReason: "Motiv respingere",
  missingTemplate: "Nu există șablon!",
  missingFile: "Fișierul nu există!",
  downloadPDF: "Descarcă PDF",
  download: "Descarcă",
  attachedFiles: "Fișiere atașate",
  missingAttachment: "Lipsește atașamentul",
  approveRequestMessage:
    "Pentru a aproba cererea, trebuie să alegeți cel puțin un responsabil!",
  rejectRequestMessage:
    "Pentru a respinge cererea, trebuie să completați motivul respingerii!",
  deallocateMessage:
    "Ești sigur că dorești să dealoci task-urile? Toate task-urile nefinalizate ale acestui utilizator vor fi dealocate!",
  verificationDelete: "Ești sigur că dorești să ștergi această informație?",
  verificationDeleteRequest: "Ești sigur că dorești să ștergi această cerere?",
  verificationApproveRequest:
    "Ești sigur că dorești să aprobii această cerere?",
  verificationRejectRequest:
    "Ești sigur că dorești să respingi această cerere?",
  getFileAddedMessage: "Fișierul a fost adăugat cu succes!",
  getDropRejectMessage: "Fișierul nu poate fi adăugat!",
  getFileRemovedMessage: "Fișierul a fost șters cu succes!",
  getFileLimitExceedMessage: "Numărul maxim de fișiere a fost atins!",
  /**ERRORS */
  fileAlreadyExists: " există deja. Ești sigur că dorești să îl înlocuiești?",
  systemError: "Eroare de sistem. Contactează administratorul!",
  getDataError: "Eroare la preluarea datelor!",
  deleteDataError: "Eroare la ștergerea datelor!",
  importDataError: "Eroare la importul datelor!",
  createDataError: "Eroare la adăugarea datelor!",
  updateDataError: "Eroare la actualizarea datelor!",
  deleteDataSuccess: "Datele au fost șterse cu succes!",
  importDataSuccess: "Datele au fost importate cu succes!",
  createDataSuccess: "Datele au fost adăugate cu succes!",
  updateDataSuccess: "Datele au fost actualizate cu succes!",
  generateContractsError:
    "Eroare la generare. Trebuie să alegeți cel puțin un client și un document!",
  generalAttachmentError: "Eroare la adăugarea atașamentului!",
  attachingFilesNumberError:
    "Eroare la adăugarea fișierelor. Numărul maxim de fișiere este ",
  futureEventsHelperText:
    "Evenimentele viitoare sunt marcate prin culoarea gri și nu permit interacțiuni.",
};
