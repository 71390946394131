import { Button } from "@mui/material";
import genericStyles from "../Styles/genericStyles.module.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "../Styles/usersPage.module.css";

type Props = {
  routes: any;
  shouldRenderSearchBar?: boolean;
};

export default function SecondMenu(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { routes } = props;

  return (
    <div className={styles.usersPageMainContainer}>
      <div className={styles.usersPageSlider}>
        {routes?.map((route: any, index: number) => (
          <Button
            key={`button_${index}`}
            className={`${
              location.pathname.includes(route.path)
                ? genericStyles.selected
                : genericStyles.nonSelected
            } ${genericStyles.secondRouteButton}`}
            variant="contained"
            onClick={() => navigate(route.path)}
            startIcon={route.icon}
          >
            {route.name}
          </Button>
        ))}
      </div>
      <Outlet />
    </div>
  );
}
