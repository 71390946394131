/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import NoDataFound from "../NoDataFound";
import genericStyles from "../../Styles/genericStyles.module.css";
import RequestModal from "./RequestModal";
import GenericModal from "../GenericModal";
import * as yup from "yup";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Pagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { calculateNumberOfPages, createURLFromParams } from "../../Utils/Utils";
import RequestsList from "./RequestsList";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { AutocompleteDataForRequests, RequestsState } from "../../Utils/Models";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import { useLocation } from "react-router-dom";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import styles from "../../Styles/requests.module.css";
import { Cancel, CheckCircle, Visibility } from "@mui/icons-material";
import QueryStringParser from "../QueryStringParser";
import { updateData } from "../../Services/updateData";
import { GlobalContext } from "../../Context/GlobalContext";
import RequestsFilters from "./RequestsFilters";
import { RequestsHeader } from "../../Utils/Constants";
import Config from "../../Utils/Config";

function RequestsManagement() {
  const customSearchParams = useCustomSearchParam();
  const refreshDataContext = useContext(RefreshDataContext);
  const globalContext = useContext(GlobalContext);
  const location = useLocation();
  //null->closed, 0->preview, 1->approve, 2->reject
  const [openModal, setOpenModal] = useState<number | null>(null);
  const [preview, setPreview] = useState<boolean>(false);
  const [requestsFilters, setRequestsFilters] =
    useState<AutocompleteDataForRequests>(new AutocompleteDataForRequests());
  const [state, setState] = useState<RequestsState>(new RequestsState());
  const authenticatedUserId = localStorage.getItem("userId");
  const requestsFiltersValidationSchema = yup.object().shape({
    status: yup.array(),
    departments: yup.array(),
    user: yup.object(),
    start: yup.object(),
    end: yup.object(),
  });
  const assignedUsersFormValidationSchema = yup.object().shape({
    assignedUsers: yup.array().min(1, Vocabulary.requiredField),
  });
  const assignedUsersMethods = useForm({
    resolver: yupResolver(assignedUsersFormValidationSchema),
  });

  const denyReasonFormValidationSchema = yup.object().shape({
    denyReason: yup.string().required(Vocabulary.requiredField),
  });
  const denyReasonMethods = useForm({
    resolver: yupResolver(denyReasonFormValidationSchema),
  });

  const methods = useForm({
    defaultValues: {
      start: moment().startOf("month").subtract(2, "month"),
      end: moment().endOf("month"),
    },
    resolver: yupResolver(requestsFiltersValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  const gerRequests = (
    page: number,
    perPage: number,
    start: any,
    end: any,
    search: string | null,
    status?: any
  ) => {
    const url = createURLFromParams(
      `${urlEnum.getRequest}?page=${
        page > 0 ? page - 1 : 0
      }&perPage=${perPage}&start=${start}&end=${end}&bringOnlyForOwner=false${
        search ? `&search=${search}` : ""
      }&allParams=`,
      { status: status ? status : null }
    );
    getData(url).then((res: any) => {
      if (res) {
        setState({
          ...state,
          requests: res?.data?.results,
          count: res?.data?.nrResults,
          page: page,
          perPage: perPage,
          search: search,
        });
      }
    });
  };

  const closeModal = () => {
    setOpenModal(null);
  };

  /**
   *
   */
  const getRequestsUsingStateData = () => {
    gerRequests(
      state.page,
      state.perPage,
      moment(methods.getValues("start")).format(Config.momentUSDateFormat),
      moment(methods.getValues("end")).format(Config.momentUSDateFormat),
      state.search
    );
  };

  /**
   *
   */
  useEffect(() => {
    if (
      refreshDataContext.refresh &&
      location.pathname.includes(localUrlEnum.requests)
    ) {
      getRequestsUsingStateData();
      refreshDataContext.setRefresh(false);
    }
  }, [refreshDataContext.refresh]);

  /**
   *
   * @param requestId
   */
  const approveRequest = () => {
    updateData(`${urlEnum.approveRequest}`, state.selectedRequest).then(
      (res: any) => {
        if (res) {
          globalContext.setTaskId(res.data.id);
          getRequestsUsingStateData();
          closeModal();
        }
      }
    );
  };

  /**
   *
   * @param requestId
   */
  const rejectRequest = () => {
    updateData(`${urlEnum.rejectRequest}`, state.selectedRequest).then(
      (res: any) => {
        if (res) {
          getRequestsUsingStateData();
          closeModal();
        }
      }
    );
  };

  /**
   *
   */
  useEffect(() => {
    const promises = [
      getData(`${urlEnum.getUsersForFilter}/${authenticatedUserId}`),
    ];
    Promise.all(promises).then((res: any) => {
      if (res) {
        setRequestsFilters({
          ...requestsFilters,
          users: res[0].data,
        });
      }
    });
  }, []);

  /**
   *
   * @returns
   */
  const renderRequests = () => {
    return (
      <div className={styles.requestsListContainer}>
        <div className={styles.requestsHeader}>
          <RequestsFilters countRequests={state.count} methods={methods} />
        </div>
        {state.requests.length ? (
          <>
            <RequestsList
              header={RequestsHeader}
              shouldRenderAdminSpecialOptions={true}
              requests={state.requests}
              renderRequestOptions={renderRequestOptions}
            />
            <div className={styles.paginationContainer}>
              <Pagination
                count={calculateNumberOfPages(state.count, state.perPage)}
                page={state.page}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  value: number
                ) => {
                  customSearchParams.addParamToQueryString("page", value);
                  window.scrollTo(0, 0);
                }}
                showFirstButton
                showLastButton
                variant="outlined"
              />
            </div>
          </>
        ) : (
          <NoDataFound classNames={genericStyles.NoRequestsFoundContainer} />
        )}
      </div>
    );
  };

  /**
   *
   * @param request
   * @returns
   */
  const renderRequestOptions = (request: any) => {
    return (
      <div className={styles.requestOptions}>
        {request.solvedBy === null ? (
          <>
            <Tooltip title={Vocabulary.approve}>
              <IconButton
                onClick={() => {
                  setOpenModal(1);
                  setState({ ...state, selectedRequest: request });
                }}
              >
                <CheckCircle />
              </IconButton>
            </Tooltip>
            <Tooltip title={Vocabulary.reject}>
              <IconButton
                onClick={() => {
                  setOpenModal(2);
                  setState({ ...state, selectedRequest: request });
                }}
              >
                <Cancel />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
        <Tooltip title={Vocabulary.view}>
          <IconButton
            onClick={() => {
              setState({ ...state, selectedRequest: request });
              setPreview(true);
              setOpenModal(0);
            }}
          >
            <Visibility />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  /**
   *
   * @returns
   */
  const renderApproveRequest = () => {
    return (
      <GenericModal
        open={openModal === 1}
        onClose={closeModal}
        title={Vocabulary.verificationApproveRequest}
        children={
          <>
            <Typography variant="h6" sx={{ marginBottom: "10px" }}>
              {Vocabulary.approveRequestMessage}
            </Typography>
            <form onSubmit={assignedUsersMethods.handleSubmit(approveRequest)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="assignedUsers"
                    control={assignedUsersMethods.control}
                    defaultValue={[]}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        {...field}
                        multiple={true}
                        options={requestsFilters?.users}
                        getOptionLabel={(option: any) =>
                          `${option?.lastName ? option?.lastName : ""} ${
                            option?.firstName ? option?.firstName : ""
                          }`
                        }
                        value={state.selectedRequest?.assignedUsers}
                        onChange={(event: any, value: any) => {
                          field.onChange(value);
                          setState({
                            ...state,
                            selectedRequest: {
                              ...state.selectedRequest,
                              assignedUsers: value,
                            },
                          });
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            error={!!error}
                            helperText={error?.message}
                            id="assignedUsers"
                            type="search"
                            label={Vocabulary.users}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button type="submit" fullWidth variant="contained">
                    {Vocabulary.save}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={closeModal}
                    variant="contained"
                    className={genericStyles.cancelButton}
                    fullWidth
                  >
                    {Vocabulary.cancel}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        }
      />
    );
  };

  /**
   *
   * @returns
   */
  const renderRejectRequest = () => {
    return (
      <GenericModal
        open={openModal === 2}
        onClose={closeModal}
        title={Vocabulary.verificationRejectRequest}
        children={
          <>
            <Typography variant="h6" sx={{ marginBottom: "10px" }}>
              {Vocabulary.rejectRequestMessage}
            </Typography>
            <form onSubmit={assignedUsersMethods.handleSubmit(rejectRequest)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Controller
                    control={denyReasonMethods.control}
                    name="denyReason"
                    defaultValue={""}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <TextField
                        required
                        id={field.name}
                        label={Vocabulary.denyReason}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        onChange={(event: any) => {
                          field.onChange(event.target.value);
                          setState({
                            ...state,
                            selectedRequest: {
                              ...state.selectedRequest,
                              denyReason: event.target.value,
                            },
                          });
                        }}
                        margin="dense"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button type="submit" fullWidth variant="contained">
                    {Vocabulary.save}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={closeModal}
                    variant="contained"
                    className={genericStyles.cancelButton}
                    fullWidth
                  >
                    {Vocabulary.cancel}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        }
      />
    );
  };

  return (
    <div className={genericStyles.mainContainer}>
      {renderRequests()}
      <QueryStringParser
        requestFunction={(data: any) => {
          gerRequests(
            data.page ? parseInt(data.page) : state.page,
            data.perPage ? parseInt(data.perPage) : state.perPage,
            data.start
              ? data.start
              : moment(methods.getValues("start")).format(
                  Config.momentUSDateFormat
                ),
            data.end
              ? data.end
              : moment(methods.getValues("end")).format(
                  Config.momentUSDateFormat
                ),
            data.search,
            data.status
          );
        }}
      />
      {renderRejectRequest()}
      {renderApproveRequest()}
      <GenericModal
        title={`${
          state.selectedRequest?.id
            ? `${Vocabulary.request} ${state.selectedRequest?.text}`
            : Vocabulary.newRequest
        } `}
        open={openModal === 0}
        onClose={() => {
          closeModal();
          setPreview(false);
        }}
        children={
          <RequestModal
            toggleModalState={() => {
              setPreview(false);
              closeModal();
            }}
            requestId={state.selectedRequest?.id}
            previewMode={preview}
            otherOptions={() => {
              return state.selectedRequest.solvedBy === null ? (
                <>
                  <Grid item xs={12} sm={3}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="secondary"
                      onClick={() => {
                        setState({
                          ...state,
                          selectedRequest: state.selectedRequest,
                        });
                        setOpenModal(1);
                      }}
                      className={genericStyles.save}
                    >
                      {Vocabulary.approve}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={() => {
                        setState({
                          ...state,
                          selectedRequest: state.selectedRequest,
                        });
                        setOpenModal(2);
                      }}
                    >
                      {Vocabulary.reject}
                    </Button>
                  </Grid>
                </>
              ) : null;
            }}
          />
        }
      />
    </div>
  );
}

export default RequestsManagement;
