import { Avatar, Chip, Divider, Grid, Typography } from "@mui/material";
import genericStyles from "../../Styles/genericStyles.module.css";
import { foldersNames, urlEnum } from "../../Utils/UrlEnum";
import { Vocabulary } from "../../Utils/Vocabulary";
import useCustomSearchParam from "../../Hooks/useCustomSearchParam";
import styles from "../../Styles/dashboard.module.css";
import withRole from "../../Hooks/withRole";

type Props = {
  summaryData: any[];
  name: string;
  renderSelectFilterUser?: boolean;
};

function SummaryOfUsersSubordinates(props: Props) {
  const customSearchParam = useCustomSearchParam();
  const shouldRenderFilters = props?.renderSelectFilterUser;

  return (
    <div className={styles.summaryWidgetContainer}>
      <div className={styles.summaryHeader}>
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {Vocabulary.yourTeam}
        </Typography>
        <Typography variant="body2">{`${
          props.summaryData.length
        } ${Vocabulary.persons.toLowerCase()}`}</Typography>
      </div>
      <Divider sx={{ backgroundColor: "#B02D21" }} />
      <div className={styles.summaryList}>
        {props.summaryData?.map((summary: any, index: number) => {
          return (
            <Grid container spacing={3} key={index}>
              <Grid item xs={2}>
                <Avatar
                  alt={`${summary.subordinateUser.lastName
                    ?.charAt(0)
                    .toUpperCase()}`}
                  className={genericStyles.assignedUsersAvatar}
                  src={`${urlEnum.getFilesAndMedia}${foldersNames.usersProfiles}${summary.subordinateUser?.profilePicture}`}
                />
              </Grid>
              <Grid item xs={3} className={styles.summaryWidgetLine}>
                <Typography sx={{ fontWeight: "bold" }}>{`${
                  summary.subordinateUser.firstName || ""
                } ${summary.subordinateUser.lastName
                  ?.substring(0, 1)
                  .toUpperCase()}.`}</Typography>
              </Grid>
              <Grid
                item
                xs={shouldRenderFilters ? 4 : 7}
                className={styles.summaryWidgetLine}
              >
                <Typography variant="body2">{`${Vocabulary.tasks}: ${summary.nrOfTasks}/ ${Vocabulary.finishedTasksSummary} ${summary.nrOfFinishedTasks}`}</Typography>
              </Grid>
              {shouldRenderFilters && (
                <Grid item xs={3} className={styles.summaryWidgetLine}>
                  <Chip
                    color="primary"
                    label={Vocabulary.seeTasks.toLowerCase()}
                    onClick={() => {
                      customSearchParam.addParamToQueryString(
                        "user",
                        summary.subordinateUser.id
                      );
                    }}
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
      </div>
    </div>
  );
}

export default withRole(SummaryOfUsersSubordinates);
