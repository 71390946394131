/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import genericStyles from "../../Styles/genericStyles.module.css";
import styles from "../../Styles/requests.module.css";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { postData } from "../../Services/postData";
import { RefreshDataContext } from "../../Context/RefreshDataContext";
import { disableAllFields, getNumberOfLinesFromText } from "../../Utils/Utils";
import { CheckBox } from "@mui/icons-material";

type Props = {
  toggleModalState: () => void;
  previewMode: boolean;
  requestId?: string;
  otherOptions?: any;
};

function RequestModal(props: Props) {
  const { toggleModalState, requestId, previewMode, otherOptions } = props;
  const refreshContext = useContext(RefreshDataContext);
  const [filters, setFilters] = useState<any>({
    statuses: [],
    users: [],
    departments: [],
  });
  const requestValidationSchema = yup
    .object()
    .shape({
      id: yup.string(),
      text: yup.string().required(Vocabulary.requiredField),
      status: yup
        .object()
        .shape({
          id: yup.string(),
          name: yup.string(),
          translation: yup.string(),
          color: yup.string(),
        })
        .required(Vocabulary.requiredField),
      description: yup.string().required(Vocabulary.requiredField),
      observations: yup.string(),
      departments: yup.array(),
      assignedUsers: yup.array(),
      denyReason: yup.string().nullable(),
    })
    .required();
  const { register, control, handleSubmit, reset, getValues, formState } =
    useForm({
      resolver: yupResolver(requestValidationSchema),
      mode: "onChange",
      reValidateMode: "onChange",
      context: undefined,
      shouldFocusError: true,
      shouldUnregister: false,
      criteriaMode: "firstError",
    });

  /**
   *
   */
  useEffect(() => {
    const promises = [
      getData(urlEnum.getRequestStatusesForFilter),
      getData(
        `${urlEnum.getUsersForFilter}/${localStorage.getItem(
          "userId"
        )}?shouldGetAll=true`
      ),
      getData(urlEnum.getDepartmentsForFilter),
    ];
    Promise.all(promises).then((res: any) => {
      setFilters({
        statuses: res[0].data,
        users: res[1].data,
        departments: res[2].data,
      });
    });
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (requestId) {
      getData(`${urlEnum.getRequest}/${requestId}`).then((res: any) => {
        if (res.data) {
          reset(res.data);
        }
      });
    }
  }, [props.requestId]);

  /**
   *
   */
  const handleSaveRequest = () => {
    postData(urlEnum.createOrUpdateRequest, getValues()).then((res: any) => {
      if (res.data) {
        refreshContext.setRefresh(true);
        toggleModalState();
      }
    });
  };

  /**
   *
   */
  useEffect(() => {
    if (previewMode) disableAllFields();
  }, [previewMode]);

  return (
    <div id="previewDialogContent">
      <form id="requestForm" onSubmit={handleSubmit(handleSaveRequest)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <fieldset className={genericStyles.requestModalFieldSet}>
              <legend>
                <Typography variant="h6">{Vocabulary.requestInfo}</Typography>
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    {...register("text")}
                    variant={"standard"}
                    label={Vocabulary.title}
                    fullWidth
                    multiline
                    rows={getNumberOfLinesFromText(getValues("text"))}
                    required
                    id={"text"}
                    name={"text"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      disableUnderline: previewMode ? true : false, // <== added this
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    {...register("description")}
                    variant={"standard"}
                    label={Vocabulary.description}
                    fullWidth
                    multiline
                    required
                    rows={getNumberOfLinesFromText(getValues("description"))}
                    id={"description"}
                    name={"description"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      disableUnderline: previewMode ? true : false, // <== added this
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    {...register("observations")}
                    variant={"standard"}
                    label={Vocabulary.observations}
                    fullWidth
                    multiline
                    rows={getNumberOfLinesFromText(getValues("observations"))}
                    id={"observations"}
                    name={"observations"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      disableUnderline: previewMode ? true : false, // <== added this
                    }}
                  />
                </Grid>
                {previewMode ? (
                  <Divider textAlign="left" style={{ width: "100%" }}>
                    {Vocabulary.status}
                  </Divider>
                ) : null}
                {previewMode ? (
                  <>
                    <Grid item xs={12} sm={12} className={styles.requestItem}>
                      <div
                        className={genericStyles.requestColorCircle}
                        style={{ backgroundColor: getValues("status")?.color }}
                      />
                      <Typography>{`${
                        getValues("status")?.translation
                      }`}</Typography>
                    </Grid>

                    {getValues("denyReason") ? (
                      <Grid item xs={12} sm={12}>
                        <TextField
                          {...register("denyReason")}
                          variant={"standard"}
                          label={Vocabulary.denyReason}
                          fullWidth
                          multiline
                          rows={getNumberOfLinesFromText(
                            getValues("denyReason")
                          )}
                          id={"denyReason"}
                          name={"denyReason"}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            disableUnderline: previewMode ? true : false, // <== added this
                          }}
                        />
                      </Grid>
                    ) : null}
                  </>
                ) : null}
              </Grid>
            </fieldset>
          </Grid>
          <Grid item xs={12} sm={6}>
            <fieldset className={genericStyles.requestModalFieldSet}>
              <legend>
                <Typography variant="h6">{Vocabulary.receivers}</Typography>
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormGroup>
                    <FormControlLabel
                      defaultChecked
                      control={<CheckBox color="primary" />}
                      label={Vocabulary.administrator}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="departments"
                    control={control}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <Autocomplete
                          multiple
                          {...field}
                          handleHomeEndKeys
                          noOptionsText={Vocabulary.noResultsFound}
                          options={filters.departments}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                          disabled={previewMode}
                          value={getValues("departments") || []}
                          onChange={(event, value) => field.onChange(value)}
                          getOptionLabel={(option: any) =>
                            option.name ? option.name : ""
                          }
                          renderInput={(params) => (
                            <TextField
                              variant={"standard"}
                              {...params}
                              label={Vocabulary.departments}
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: previewMode ? true : false,
                              }}
                            />
                          )}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </fieldset>
          </Grid>

          {otherOptions ? (
            otherOptions()
          ) : previewMode ? (
            <Grid item xs={12} sm={6} />
          ) : null}
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={props.toggleModalState}
              className={genericStyles.cancelButton}
            >
              {Vocabulary.cancel}
            </Button>
          </Grid>
          {previewMode ? null : (
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                form="requestForm"
              >
                {Vocabulary.save}
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}
export default RequestModal;
